<template>
  <div v-if="loaded" style="position: relative">
    <div v-if="modal">
      <!-- modal -->
      <v-dialog v-model="showDialog" max-width="350" @click:outside="setDialog(false)">
        <EditableFields
          v-if="showDialog"
          v-bind="$props"
          editing
          modal
          :input="myInput"
          :other-info="otherInfo"
          @setModal="setDialog(false)"
          @setInput="setInput"
        />
      </v-dialog>
      <!-- end modal -->
      <EditableFields
        v-bind="$props"
        open-modal
        :in-list="inList"
        :items="items"
        :editing="false"
        modal
        :title="title"
        :input="myInput"
        :other-info="otherInfo"
        @setModal="setDialog(true)"
        @setInput="setInput"
      />
      <!-- end modal -->
    </div>

    <div v-else>
      <EditableFields
        v-bind="$props"
        :modal="false"
        :input="myInput"
        @setVariable="setInput"
        @setInput="setInput"
        @changeToken="changeToken"
        @setOtherInfo="setOtherInfo"
      />
    </div>
  </div>
</template>
<script>
import EditableFields from './EditableFields.vue';

export default {
  name: 'EditableUtil',
  components: { EditableFields },
  // Because the components ad bound to the child component with v-bind="$props", we disable the rule here
  /* eslint-disable vue/no-unused-properties */
  props: {
    /* if dense, title is shown in placeholder instead */
    dense: {
      required: false,
      type: Boolean,
      default: false,
    },
    /* extra classes for subcontent if needed */
    extraClasses: {
      required: false,
      type: String,
      default: '',
    },
    /* if field has any props, you can add */
    fieldProps: {
      required: false,
      type: Object,
      default: () => Object(),
    },

    /* icon shown */
    icon: {
      required: false,
      type: String,
      default: '',
    },
    /* Background color */
    backgroundColor: {
      required: false,
      type: String,
      default: 'white',
    },
    /* autofocus */
    autofocus: {
      required: false,
      type: Boolean,
      default: true,
    },
    /* title shown */
    title: {
      required: false,
      type: String,
      default: '',
    },
    /* text show in dropdown */
    propertyName: {
      required: false,
      type: String,
      default: '',
    },
    /* either text, textarea, date, dropdown */
    propertyId: {
      required: false,
      type: String,
      default: 'id',
    },
    /* either text, textarea, date, dropdown */
    type: {
      required: false,
      type: String,
      default: 'text',
    },
    /* items for dropdown */
    items: {
      required: false,
      type: Array,
      default: () => [],
    },
    subItems: {
      required: false,
      type: Array,
      default: () => [],
    },
    /* if disabled, can never be edited */
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    /* For complex information, icon should open editable modal */
    modal: {
      required: false,
      type: Boolean,
      default: false,
    },
    /* starting text */
    input: {
      required: false,
      type: [String, Array, Number, Object],
      default: '',
    },
    /* variable name for patch */
    varName: {
      required: false,
      type: String,
      default: '',
    },
    /* draggable (when withing group) */
    draggable: {
      required: false,
      type: Boolean,
      default: false,
    },
    /* specifies wether group is part of a list */
    inList: {
      required: false,
      type: Boolean,
      default: false,
    },
    isOtro: {
      required: false,
      type: Boolean,
      default: false,
    },
    showEye: {
      required: false,
      type: Boolean,
      default: false,
    },
    place: {
      required: false,
      type: String,
      default: '',
    },
    infoOther: {
      required: false,
      type: String,
      default: '',
    },
    elementsRow: {
      required: false,
      type: Number,
      default: 3,
    },
    dropdownColor: {
      type: String,
      default: 'white',
      required: false,
    },
    textColor: {
      type: String,
      default: 'white',
      required: false,
    },
    prependIcon: {
      type: String,
      default: '',
      required: false,
    },
    titleText: {
      type: Boolean,
      default: true,
      required: false,
    },
    colorTextArea: {
      type: String,
      default: 'white',
      required: false,
    },
  },
  data() {
    return {
      loaded: false,
      myInput: '',
      showDialog: false,
      editing: false,
      show: true,
    };
  },
  watch: {
    input(newVal) {
      this.myInput = newVal;
    },
  },
  mounted() {
    this.myInput = this.input;
    this.loaded = true;
  },
  methods: {
    setDialog(showDialog) {
      this.showDialog = showDialog;
    },
    setInput(input) {
      this.myInput = input;
      this.setVariable(this.varName, this.myInput);
    },
    setOtherInfo(otherInfo) {
      this.setVariable(this.varName, otherInfo);
    },
    setVariable(varName, varValue) {
      this.$emit('setVariable', varName, varValue);
    },
    changeToken(token) {
      this.$emit('changeToken', token);
    },
  },
};
</script>
