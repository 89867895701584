var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-section-content",staticStyle:{"width":"100%","max-width":"100%"}},[(_vm.loading)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"300","type":"card"}}):_c('GmapMap',{ref:"dashboardMapRef",class:_vm.$vuetify.breakpoint.mobile
        ? 'vue-map-container-mobile'
        : 'vue-map-container',staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.homeCoordinates ? _vm.homeCoordinates : _vm.defaultCoordinates,"zoom":_vm.zoom,"options":{
      disableDefaultUI: true,
      clickableIcons: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      styles: _vm.mapStyle,
      gestureHandling: 'greedy',
    }}},[(_vm.favoriteSchoolsDetails.length > 0)?_c('div',_vm._l((_vm.favoriteSchoolsDetails),function(school,i){return _c('GmapCustomMarker',{key:i,attrs:{"id":`id-markermap-${school.uuid}`,"marker":{
          lat: school.location.lat,
          lng: school.location.lon,
        },"alignment":"top"}},[_c('img',{attrs:{"src":require("@/assets/dashboard/icons/like.svg"),"alt":"like"}})])}),1):_vm._e(),_c('GmapCustomMarker',{attrs:{"marker":_vm.homeCoordinates ? _vm.homeCoordinates : _vm.defaultCoordinates,"alignment":"top"}},[_c('img',{attrs:{"src":require("@/assets/dashboard/icons/home.svg"),"alt":"home"}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }