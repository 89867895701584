<template>
  <LinkBank
    v-if="widgetInterface"
    :interface="widgetInterface"
    @success="success($event)"
    @exit="exit"
  />
  <SpinningLoader v-else-if="loading" :show-logo="false" active />
</template>

<script>

import LinkBank from '@/components/organisms/widget/LinkBank.vue';
import SpinningLoader from '@/components/organisms/general/SpinningLoader.vue';
import { getProviderLinkInterface } from '@/utils/payments';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LinkBankIntent',
  components: {
    LinkBank,
    SpinningLoader,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    accountType: {
      type: String,
      default: 'business',
    },
  },
  data: () => ({
    widgetInterface: null,
    bankAccProvId: null,
  }),
  computed: {
    ...mapGetters({
      currentProvider: 'payments/currentProvider',
      campusDetail: 'roles/currentHeadMasterMainSchool',
      loading: 'payments/loading',
    }),
  },
  watch: {
    visible() {
      if (this.visible) {
        this.initializeAttempt();
      } else {
        this.widgetInterface = null;
      }
    },
  },

  methods: {
    ...mapActions({
      initializeLinkIntent: 'payments/initializeLinkIntent',
      exchangeToken: 'payments/exchangeToken',
      snackbarError: 'utils/error',
      activateModal: 'utils/activateModal',
      setBankAccount: 'payments/setBankAccount',
    }),
    initializeOutcomeModal({
      status, callbacks,
      options = null,
      bankAccProvId = null,
    }) {
      if (status === 'error' || status === 'success') {
        this.activateModal({
          name: 'LinkBankConfirmation',
          data: {
            status,
          },
          callbacks,
        });
      } else {
        this.activateModal({
          name: 'LinkBankSelectAccount',
          data: {
            accounts: options,
            bankAccProvId,
          },
          callbacks,
        });
      }
    },
    success(response) {
      this.$emit('finished');
      this.exchangeToken({
        data: response,
        entityCode: this.campusDetail.campus_code,
        bankAccProvId: this.bankAccProvId,
      }).then(
        (data) => {
          if (data.registered) {
            this.setBankAccount({ bankAccount: data.available_accounts[0] });
            this.initializeOutcomeModal({
              status: 'success',
            });
          } else {
            this.initializeOutcomeModal({
              status: 'select-account',
              options: data.available_accounts,
              bankAccProvId: this.bankAccProvId,
            });
          }
        },
      ).catch(() => {
        this.initializeOutcomeModal({
          status: 'error',
        });
      });
    },
    exit() {
      this.$emit('finished');
      this.initializeOutcomeModal({
        status: 'error',
      });
    },
    async loadInterface({ linkIntent }) {
      this.widgetInterface = getProviderLinkInterface({ provider: this.currentProvider, linkIntent });
    },
    async initializeAttempt() {
      if (this.currentProvider) {
        this.initializeLinkIntent({
          provider: this.currentProvider,
          entityCode: this.campusDetail.campus_code,
          entityName: this.campusDetail.campus_name,
          accountType: this.accountType,
        })
          .then((linkIntent) => {
            this.bankAccProvId = linkIntent.bank_account_provider_id;
            this.loadInterface({ linkIntent });
          }).catch(() => {
            this.snackbarError({
              message: 'payments.errors.initializeLinkIntent',
            });
          });
      }
    },
  },
};
</script>
