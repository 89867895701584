<template>
  <div v-if="active" class="spinning-loader">
    <div class="spinning-loader__container">
      <LoaderCircular :show="active" :size="60" :width="8" />
    </div>
    <div v-if="showLogo" class="spinning_loader__footer">
      <TetherLogo
        alignment="horizontal"
        width="60%"
      />
    </div>
  </div>
</template>

<script>
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import TetherLogo from '@/components/molecules/logos/TetherLogo.vue';

export default {
  name: 'SpinningLoader',
  components: {
    LoaderCircular,
    TetherLogo,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
.spinning-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #fff; */
}

.spinning-loader__container {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinning-loader__footer {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
