<template>
  <section
    class="clickable public-register--admin"
  >
    <div class="public-register--kit">
      <TextAtom
        class="mb-1"
        :value="`dashboard.digital_profile.kit-admin.title`"
        :color="'primary-dark'"
        font="poppins"
        weight="600"
        size="medium"
        tag="label"
      />
      <TextAtom
        class="mt-2"
        :value="`dashboard.digital_profile.kit-admin.subtitle`"
        :color="'primary-dark'"
        font="poppins"
        weight="400"
        size="title"
      />
    </div>
    <div
      class="public-register--button"
      @click="goTo('applicant_files')"
      @keydown="goTo"
    >
      <CheckButton
        :text="'dashboard.digital_profile.kit-admin.button'"
      />
    </div>
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CheckButton from '@/components/atoms/buttons/CheckButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EnrollmentList',
  components: {
    TextAtom,
    CheckButton,
  },
  computed: {
    ...mapGetters({
      widgetsDashboardLink: 'widgets/widgetsDashboardLink',
    }),
  },
  methods: {
    goTo(widget) {
      const { link } = this.widgetsDashboardLink.find((w) => w.label === widget);
      window.open(link, '_blank').focus();
    },
  },
};
</script>
