var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dge-application",class:{
    'dge-application--mobile': _vm.mobile,
  }},[_c('MainHeader',{staticClass:"dge-application__header",attrs:{"header-content":['mainTitle'],"main-title-text":'digital_enrollment.application.titles.main'}}),_c('div',{staticClass:"dge-application__content",class:{
      'dge-application__content--mobile': _vm.mobile,
    }},[_c('div',{staticClass:"dge-application__content__column dge-application__content__column--small dge-application__content__column--with-background",class:{
        'dge-application__content__column--mobile': _vm.mobile,
      }},[_c('ResumedAdmissionState',{attrs:{"admission":_vm.admission,"column":"","hide-address-icon":"","hide-status":""}})],1),_c('div',{staticClass:"dge-application__content__column dge-application__content__column--large",class:{
        'dge-application__content__column--mobile': _vm.mobile,
      }},[_c('MainTitle',{attrs:{"text":'digital_enrollment.application.titles.current_state',"left-align":"","small":""}}),_c('ApplicationStep',{key:`${_vm.currentStateKey}-${_vm.admission.id}`,attrs:{"step":_vm.currentStateKey,"notifications":_vm.getNotifications(_vm.currentStateKey),"action":_vm.currentState.action,"subtitle-args":_vm.currentState.subtitleArgs,"step-provider-data":_vm.currentState.provider,"loading":_vm.currentState.loading}}),(_vm.pendingProcesses.length > 0)?_c('ApplicationStepsGroup',{attrs:{"title-text":'digital_enrollment.application.titles.pending_steps',"steps":_vm.pendingProcesses,"counter-icon":'mdi-clock-outline',"counter-type":'error'}}):_vm._e(),(_vm.completedProcesses.length > 0)?_c('ApplicationStepsGroup',{attrs:{"title-text":'digital_enrollment.application.titles.completed_steps',"steps":_vm.completedProcesses,"counter-icon":'mdi-check-circle-outline',"counter-type":'primary'}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }