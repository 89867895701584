<template>
  <div class="subsection-container" :style="{ '--n-rows': 1 }">
    <Subsection
      bg-color="transparent"
      :style="{
        'background-image':
          'url('
          + require('@/assets/dashboard/widgets/explorer-widget.svg')
          + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'border-radius': '5px',
        'background-position': 'center',
      }"
      flex="column"
      clickable
      @onClick="goTo('map')"
    >
      <div v-t="'dashboard.tools.explorer'" class="widget-tool" />
    </Subsection>

    <Subsection
      v-if="false"
      bg-color="#F5F5F5"
      flex="column"
      clickable
      align="right"
      @onClick="toggleModal()"
    >
      <div class="widget-tool">
        {{ $t("dashboard.tools.resume-postulacion") }}
      </div>
      <div class="widget-tool--des">
        {{ $t("dashboard.tools.resume-postulacion-des") }}
      </div>
      <img
        style="
          position: absolute;
          bottom: 8px;
          margin-left: 4px;
          margin-right: auto;
          width: 85%;
          height: 50%;
        "
        src="@/assets/dashboard/widgets/graph-app-widget.svg"
        alt="graph"
      />
    </Subsection>
    <Location />

    <!-- <Subsection
      v-if="false"
      bg-color="#F5F5F5"
      flex="column"
      clickable
      @onClick="setShowFeedback"
    >
      <div class="widget-tool">
        {{ $t("dashboard.tools.feedback-postulacion") }}
      </div>
      <div class="widget-tool--des">
        {{ $t("dashboard.tools.feedback-postulacion-des") }}
      </div>
      <img
        style="
          position: absolute;
          bottom: 10px;
          margin-left: 10px;
          margin-right: auto;
          width: 80%;
        "
        src="@/assets/dashboard/widgets/graph2-widget.svg"
        alt="graph"
      />
    </Subsection> -->
    <!-- <ApplicationHistory
      :show-modal="showModal"
      :delete-app="false"
      :student-option="true"
      :app-uuid="appUuid"
      @toggleModal="toggleModal"
    /> -->
  </div>
</template>

<script>
// It has been omitted cause it's not used at the moment, but may be used in the future.
// import ApplicationHistory from '@/components/explorer/application/ApplicationHistory.vue';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';
import Subsection from './Subsection.vue';
import Location from './Location.vue';

export default {
  name: 'ToolsSection',
  components: {
    Subsection,
    Location,
    // ApplicationHistory,
  },
  data() {
    return {
      showFeedback: false,
      typeFeedback: 'FeedbackPreApplication',
      showModal: false,
      // appUuid: null,
    };
  },
  computed: {
    ...mapGetters({
      isShowDigitalProfile: 'feedback/isShowDigitalProfile',
      currentStudent: 'authentication/currentStudent',
      studentList: 'authentication/students',
    }),
  },
  mounted() {
    this.setShowFeedback();
    const hasCurrentStudent = JSON.stringify(this.currentStudent) !== '{}';
    if (!hasCurrentStudent && this.studentList.length > 0) {
      this.changeCurrentStudent(this.studentList[0].uuid);
    }
  },
  methods: {
    ...mapActions({
      setIsShowDigitalProfile: 'feedback/setIsShowDigitalProfile',
      setCurrentStudent: 'authentication/setCurrentStudent',
      retrieveExploredCampuses: 'institutions/retrieveExploredCampuses',
      retrieveExploredSchoolCard: 'institutions/retrieveExploredSchoolCard',
      retrieveCampuses: 'institutions/retrieveCampuses',
      raiseError: 'utils/error',
    }),
    setShowFeedback() {
      this.showFeedback = true;
      if (this.showFeedback) {
        this.typeFeedback = 'FeedbackPreApplication';
      }

      this.setIsShowDigitalProfile(false);
    },
    goTo(page) {
      if (page === 'map') {
        trackMixPanel('click_dashboard_module_gallery');
      }
      this.$router.push(page);
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    changeCurrentStudent(studentUUID) {
      const student = this.studentList.find((st) => st.uuid === studentUUID);
      // this.$emit('uuid', student.uuid);
      this.setCurrentStudent({ student, hotjar: this.$hj })
        // .then(() => this.retrieveFavorites())
        .then(() => this.retrieveExploredCampuses())
        .then(() => this.retrieveExploredSchoolCard())
        .then(() => this.retrieveCampuses({ callback: null }))
        .catch((error) => this.raiseError(error));
    },
  },
};
</script>
