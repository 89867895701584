<template>
  <section class="d-flex fill-width dashboard-navbar">
    <ImageAtom
      v-if="mobile"
      class="dashboard-navbar-icon"
      :source="require('@/assets/iconsChile/header/menu.svg')"
      @click="$emit('menuClick', true)"
      @keydown.enter="$emit('menuClick', true)"
    />
    <Breadcrumbs
      :style=" mobile ? '' : 'margin-left: 20px'"
      :breadcrumb-array="breadcrumbs"
    />
    <CampusSearchBar
      v-if="isAmbassador"
      in-dashboard
      :mobile-width="mobile ? true : false"
      @setLoading="emitLoading"
    />
    <NavbarSelect
      v-if="isHeadMaster && currentHeadMasterSchools"
      :placeholder="currentHeadMasterMainSchool.campus_name"
      :items="schools"
      @setChange="changeState"
    />
  </section>
</template>

<script>
import ImageAtom from '@/components/atoms/images/Image.vue';
import NavbarSelect from '@/components/atoms/selects/NavbarSelect.vue';
import CampusSearchBar from '@/components/explorer/map/CampusSearchBar.vue';
import Breadcrumbs from '@/components/molecules/sections/Breadcrumbs.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardNavbar',
  components: {
    Breadcrumbs, ImageAtom, NavbarSelect, CampusSearchBar,
  },
  computed: {
    ...mapGetters({
      isHeadMaster: 'roles/isHeadMaster',
      isAmbassador: 'roles/isAmbassador',
      currentHeadMasterSchools: 'roles/currentHeadMasterSchools',
      currentHeadMasterMainSchool: 'roles/currentHeadMasterMainSchool',
      breadcrumbs: 'utils/breadcrumbs',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    schools() {
      if (!this.currentHeadMasterSchools) {
        return [];
      }
      return this.currentHeadMasterSchools.map((item, index) => ({
        label: `${item.campus_name}`,
        campus_uuid: item.uuid,
        value: index,
      }));
    },
  },
  methods: {
    changeState(index) {
      this.$emit('setChange', index);
    },
    emitLoading(bool) {
      this.$emit('setLoading', bool);
    },
  },
};
</script>
