<template>
  <div>
    <div v-if="mobile">
      <v-select
        v-model="currentCategorySelect"
        class="custom-select text--center"
        :items="sections"
        item-text="title"
        return-object
        solo
        color="#2B5BFF"
        dense
        hide-details
        @input="dropdownSelect"
      >
        <template slot="selection" slot-scope="data">
          <div class="d-flex flex-row">
            <picture class="panel-icon">
              <v-icon color="#2B5BFF">
                {{ data.item.icon }}
              </v-icon>
            </picture>
            <span class="mt-3"> {{ data.item.text }} </span>
          </div>
        </template>
        <template slot="item" slot-scope="data">
          <div class="d-flex flex-row">
            <picture class="panel-icon">
              <v-icon color="#2B5BFF">
                {{ data.item.icon }}
              </v-icon>
            </picture>
            <span class="mt-4"> {{ data.item.text }} </span>
          </div>
        </template>
      </v-select>
    </div>
    <div
      v-else
      class="dashboard-grid"
      :style="{ '--n-elements-per-row': 2, '--element-size': 'minmax(50px, 1fr)' }"
    >
      <section
        v-for="(section, index) in sections"
        :key="index"
      >
        <!-- BUGGED Section: Hide vacancy selector -->
        <Subcontent
          v-if="section.name !== 'admission-vacancies'"
          :extra-classes="[
            section.name == currentCategory
              ? 'm-2 relative rounded clickable admission-category-selected'
              : 'm-2 relative rounded clickable admission-category',
            'dashboard-admissions-panel',
          ]"
          @onClick="select(section.name)"
        >
          <div class="absolute-top-left m-2">
            <v-icon :color="section.name == currentCategory ? 'white' : '#2B5BFF'">
              {{ section.icon }}
            </v-icon>
          </div>
          <div class="absolute-bottom-left m-2 text-left">
            {{ section.text }}
          </div>
        </Subcontent>
      </section>
    </div>
    <!--<Subsection
    v-for="(section, index) in sections"
    :key="index" @onClick="select(section.name)"
    :bgColor="getBgColor(section.name)"
    :color="getColor(section.name)"
    :clickable="true"
  >
    <div
      class="subsection-content-icon"
      :style="{'--left': '-10px'}"
    >
      <v-icon>
        {{section.icon }}
      </v-icon>
    </div>
    <span
      class="subsection-content-text"
      :style="{color: currentCategory == section.name ? 'white' : 'blue'}"
    >
      {{section.text}}
    </span>
  </Subsection>-->
  </div>
</template>
<script>
// import Subsection from '../Subsection.vue';
import trackMixPanel from '@/utils/mixpanel';
import { mapGetters } from 'vuex';
import Subcontent from '../../utils/Subcontent.vue';

export default {
  name: 'AdmissionCategorySelector',
  components: { Subcontent },
  data() {
    return {
      currentCategorySelect: null,
      currentCategory: 'admission-process',
      sections: [
        {
          name: 'admission-process',
          text: this.$t('dashboard.admissions.category_selector.admission_process'),
          icon: 'mdi-pencil',
        },
        {
          name: 'admission-frequently-asked-questions',
          text: this.$t('dashboard.admissions.category_selector.frequently_questions'),
          icon: 'mdi-frequently-asked-questions',
        },
        {
          name: 'admission-vacancies',
          text: this.$t('dashboard.admissions.category_selector.vacancies'),
          icon: 'mdi-account-check',
        },
      ],
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
  },
  mounted() {
    const [firstSection] = this.sections;
    this.currentCategorySelect = firstSection;
  },
  methods: {
    /*
    getBgColor(name) {
      if (name === this.currentCategory) {
        return 'blue';
      }
      return 'white';
    },
    getColor(name) {
      if (name === this.currentCategory) {
        return 'white';
      }
      return 'blue';
    },
    */
    select(name) {
      this.currentCategory = name;
      this.$parent.$emit('setCurrentCategory', name);
      const clickText = 'click_dashboard_';
      trackMixPanel(clickText.concat(name));
    },
    dropdownSelect() {
      this.currentCategory = this.currentCategorySelect.name;
      this.$parent.$emit('setCurrentCategory', this.currentCategorySelect.name);
    },
  },
};
</script>
<style>
.admission-category {
  height: 150px;
  min-height: 150px;
}
.admission-category-selected {
  height: 150px;
  min-height: 150px;
  background-color: #2b5bff;
  color: white;
}
</style>
