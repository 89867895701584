<template>
  <v-data-table
    disable-pagination
    :headers="headers"
    :items="digitalEnrollmentApplications"
    hide-default-footer
    @click:row="goToApplication"
  >
    <template #top>
      <MainHeader
        :header-content="['mainTitle']"
        :main-title-text="'digital_enrollment.applications_table.title'"
        style="padding-top: 20px; margin-left: 28px"
      />
    </template>
    <template #[`header.campus`]="{ header }">
      <div class="data_table-headers">
        {{ header.text }}
      </div>
    </template>
    <template #[`header.program`]="{ header }">
      <div
        class="data_table-headers data_table-headers--gray justify-space-between"
        @click="sortPrograms()"
        @keydown.enter="sortPrograms()"
      >
        {{ header.text }}
        <MdiIcon
          v-if="false"
          :color="'#1E0C61'"
          :icon="sorted ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          small
          style="cursor: pointer;"
        />
      </div>
    </template>
    <template #[`header.admissionStatus`]="{ header }">
      <div class="data_table-headers">
        {{ header.text }}
      </div>
    </template>
    <template #[`item.campus`]="{ item }">
      <ResumedAdmissionState :admission="item" hide-status hide-student hide-program />
    </template>
    <template #[`item.program`]="{ item }">
      <PrimaryText bold neutral800 align-left :text="item.program.name.split(',')[0]" />
      <PrimaryText bold neutral800 align-left :text="item.program.name.split(',')[1]" />
    </template>
    <template #[`item.status`]="{ item }">
      <AdmissionStatusChip
        :admission-status="item.status"
      />
    </template>
  </v-data-table>
</template>

<script>
import MdiIcon from '@/components/atoms/icons/MdiIcon.vue';
import PrimaryText from '@/components/atoms/labels/PrimaryText.vue';
import AdmissionStatusChip from '@/components/molecules/chips/AdmissionStatusChip.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';
import ResumedAdmissionState from '@/components/organisms/digital_enrollment/ResumedAdmissionState.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ApplicationsTable',
  components: {
    MainHeader, ResumedAdmissionState, PrimaryText, MdiIcon, AdmissionStatusChip,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('digital_enrollment.applications_table.headers.institution'),
          align: 'left',
          sortable: false,
          value: 'campus',
          id: 0,
        },
        {
          text: this.$t('digital_enrollment.applications_table.headers.program'),
          align: 'left',
          sortable: false,
          value: 'program',
          id: 1,
        },
        {
          text: this.$t('digital_enrollment.applications_table.headers.status'),
          align: 'left',
          sortable: false,
          value: 'status',
          id: 2,
        },
      ],
      sorted: false,
    };
  },
  computed: {
    ...mapGetters({
      digitalEnrollmentApplications: 'digitalEnrollment/digitalEnrollmentApplications',
    }),
  },
  methods: {
    ...mapActions({
      setDgeSelectedApplication: 'digitalEnrollment/setDgeSelectedApplication',
    }),
    goToApplication(item) {
      this.$router.push({ name: 'DigitalEnrollmentApplication', params: { id: item.id } });
    },
    sortPrograms() {
      if (!this.sorted) {
        return this.sorted;
      }
      this.sorted = false;
      return this.sorted;
    },
  },
};
</script>
