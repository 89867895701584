<template>
  <div class="dashboard-section-content admissions-container d-flex flex-row">
    <div class="text-left">
      {{ $t("dashboard.admissions.description") }}
    </div>
    <v-btn
      :class="
        mobile ? 'digital-profile-button__mobile' : 'digital-profile-button'
      "
      :loading="!loaded"
      class="clickable digital-profile-button"
      @click="goTo('admissions')"
      @keydown="goTo('admissions')"
    >
      {{ $t("dashboard.admissions.button") }}
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'AdmissionsSection',
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    mobile() {
      return this.$vuetify.breakpoint.width < 670;
    },
  },
  methods: {
    goTo(page) {
      this.$router.push(page);
      trackMixPanel('click_dashboard_module_admissions');
    },
  },
};
</script>
