<template>
  <div
    class="state-chip"
    :class="{
      'state-chip--fit-content': fitContent,
      'state-chip--disabled': chipState.type === 0 || chipState.type === 'disabled',
      'state-chip--waiting': chipState.type === 1 || chipState.type === 'waiting',
      'state-chip--in_process': chipState.type === 2 || chipState.type === 'in_process',
      'state-chip--blocked': chipState.type === 3 || chipState.type === 'blocked',
    }"
  >
    <p
      v-t="chipState.name"
      class="state-chip--content"
      :class="{
        'state-chip--content--disabled': chipState.type === 0 || chipState.type === 'disabled',
        'state-chip--content--waiting': chipState.type === 1 || chipState.type === 'waiting',
        'state-chip--content--in_process': chipState.type === 2 || chipState.type === 'in_process',
        'state-chip--content--blocked': chipState.type === 3 || chipState.type === 'blocked',
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'StateChip',
  props: {
    /* state type for css controls
      1 = waiting
      2 = in_process
      3 = blocked
    */
    chipState: {
      type: Object,
      default: () => ({
        name: '',
        type: 0,
      }),
    },
    fitContent: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
