var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-container relative",style:({
    '--width': `${_vm.width}px`,
    '--height': `${_vm.height}px`,
  })},[(_vm.title != '')?_c('div',{staticClass:"absolute-bottom-right p-2 text-left",staticStyle:{"z-index":"200","color":"#5627ff","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(!_vm.muted)?_c('v-icon',{staticStyle:{"z-index":"201","position":"absolute","right":"5px","top":"5px"},attrs:{"color":"red"},on:{"click":_vm.toggleMuted}},[_vm._v(" "+_vm._s(_vm.userMuted ? 'mdi-volume-off' : 'mdi-volume-high')+" ")]):_vm._e(),(_vm.videoURL)?_c('vimeo-player',{ref:"player",staticClass:"video-player",style:({
      '--width': `${_vm.width}px`,
      '--height': `${_vm.height}px`,
      '--vertical-offset': _vm.verticalOffset,
      '--horizontal-offset': _vm.horizontalOffset,
    }),attrs:{"video-url":_vm.videoURL ? _vm.videoURL : null,"video-id":_vm.videoURL ? null : _vm.videoID,"autoplay":"","player-height":`${_vm.actualHeight}px`,"player-width":`${_vm.actualWidth}px`,"options":_vm.options},on:{"timeupdate":_vm.log,"playing":_vm.onPlaying}}):_vm._e(),(_vm.videoGIF)?_c('img',{staticClass:"gif-player",attrs:{"src":_vm.videoGIF,"alt":"GIF"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }