<template>
  <div class="notifications loader-dashboard">
    <v-skeleton-loader
      v-if="!loadedMessages || !loaded"
      max-width="auto"
      max-height="220"
      type="list-item-three-line, list-item-two-line"
    />
    <div v-if="loadedMessages && loaded" class="text-left">
      <span class="messages-text-big">{{ totalMessages }}</span>
      {{ $t("dashboard.notifications.headmaster-total") }}
    </div>
    <br />
    <div v-if="loadedMessages && loaded" class="text-left">
      <span class="messages-text-big">{{ myMessages }}</span>
      {{ $t("dashboard.notifications.headmaster-school") }}
    </div>
    <div
      v-if="myMessages > 0"
      class="clickable digital-profile-button"
      @click="goTo('notifications')"
      @keydown="goTo('notifications')"
    >
      {{ $t("dashboard.notifications.button") }}
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'MessagesHeadMaster',
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadedMessages: false,
      totalMessages: 0,
      myMessages: 0,
      messageByCategory: Object(),
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
  },
  mounted() {
    this.getMessagesCount().then((response) => {
      this.totalMessages = response.data;
    });
    this.getGroupedMessages({
      groupBy: 'message_title',
      index: 'school_uuid',
      uuid: this.campusDetail.uuid,
    }).then((response) => {
      if (response.status === 404) {
        this.messageByCategory = Object();
      } else {
        this.myMessages = Object.values(this.messageByCategory).reduce((a, b) => a + b);
      }
      this.loadedMessages = true;
    });
  },
  methods: {
    ...mapActions({
      getMessagesCount: 'messaging/getMessagesCount',
      getGroupedMessages: 'messaging/getGroupedMessages',
    }),
    goTo(page) {
      this.$router.push(page);
      if (page === 'notifications') {
        trackMixPanel('click_dashboard_submodule_notifications', {
          campus_code: this.campusDetail.campus_code,
          institution_code: this.campusDetail.institution_code,
        });
      }
    },
  },
};
</script>
