<template>
  <div>
    <div class="d-flex justify-between mb-8">
      <div class="d-flex">
        <SVGIcon
          icon="drag-drop.svg"
          alt-text="drag"
          size="13"
          name="dragger"
          class="clickable"
        />
        <span
          v-if="contact.label_id === 0"
          v-t="{
            path: 'dashboard.digital_profile.contacts.title_index',
            args: { index: index + 1 },
          }"
          class="ml-10 contacts-dashboard__title"
        />
        <TextAtom
          v-else
          :value="getContactLabelName"
          tag="label"
          font="poppins"
          color="primary-700"
          weight="600"
          size="subtitle"
          class="ml-10 contacts-dashboard__title"
        />
      </div>
      <v-menu start>
        <template #activator="{ on, attrs }">
          <v-icon
            color="#646464"
            v-bind="attrs"
            v-on="on"
          >
            mdi-dots-horizontal
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            class="clickable"
            @click="$emit('deleteContact', index)"
          >
            <v-list-item-title style="color: #CCCCCC;">
              {{ $t('dashboard.profile.delete') }}
            </v-list-item-title>
            <v-icon
              class="ml-2"
              color="#CCCCCC"
            >
              mdi-delete
            </v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="contacts-dashboard__grid">
      <EditableGroup
        :fields="[
          {
            type: 'dropdown',
            varName: 'label_id',
            dense: true,
            input: getContactLabel(index),
            items: [...getContactOptions],
            openModal: true,
            dropdownColor: '#F5F5F5',
            place: $t('dashboard.digital_profile.contacts.contact_label'),
          },
        ]"
        @setObject="setBody"
      />
      <EditableGroup
        :fields="[
          {
            type: 'text',
            varName: 'names',
            dense: true,
            input: getContactName(index),
            textColor: '#F5F5F5',
            prependIcon: 'mdi-account',
            title: $t('dashboard.digital_profile.contacts.name'),
            titleText: false,
            backgroundColor: '#F5F5F5',
          },
        ]"
        @setObject="setBody"
      />
      <EditableGroup
        :fields="[
          {
            type: 'number',
            varName: 'cellphone',
            dense: true,
            input: getContactCell(index),
            textColor: '#F5F5F5',
            prependIcon: 'mdi-cellphone',
            title: $t('dashboard.digital_profile.contacts.mobile'),
            titleText: false,
            backgroundColor: '#F5F5F5',
          },
        ]"
        @setObject="setBody"
      />
      <EditableGroup
        :fields="[
          {
            type: 'number',
            varName: 'phone',
            dense: true,
            input: getContactPhone(index),
            textColor: '#F5F5F5',
            prependIcon: 'mdi-cellphone',
            title: $t('dashboard.digital_profile.contacts.phone'),
            titleText: false,
            backgroundColor: '#F5F5F5',
          },
        ]"
        @setObject="setBody"
      />
      <EditableGroup
        :fields="[
          {
            type: 'text',
            varName: 'email',
            dense: true,
            input: getContactEmail(index),
            textColor: '#F5F5F5',
            prependIcon: 'mdi-email-outline',
            title: $t('dashboard.digital_profile.contacts.email'),
            titleText: false,
            backgroundColor: '#F5F5F5',
          },
        ]"
        @setObject="setBody"
      />
    </div>
    <EditableGroup
      class="mt-7"
      :fields="[
        {
          type: 'checkbox',
          dense: true,
          title: $t('dashboard.digital_profile.contacts.verified_question'),
          input: isVerified,
          varName: 'verified',
          items: verifiedType,
          elementsRow: 1,
          disabled: !canBeVerified,
        },
      ]"
      @setVariable="setBody"
    />
  </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import { mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';

export default {
  name: 'ContactSingle',
  components: {
    EditableGroup,
    SVGIcon,
    TextAtom,
  },
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    contactsArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      body: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      contactsLabels: 'options/contactLabels',
    }),
    canBeVerified() {
      const {
        // eslint-disable-next-line camelcase
        label_id, name, cellphone, phone, email,
      } = this.body;
      // eslint-disable-next-line camelcase
      return label_id && (name || cellphone || phone || email);
    },
    verifiedType() {
      return [
        {
          value: 1,
          label: this.$t('dashboard.digital_profile.contacts.verified'),
        },
      ];
    },
    isVerified() {
      if (this.contact?.verified) {
        return [1];
      }
      return [];
    },
    getContactOptions() {
      const existingLabels = this.contactsArray.filter((contact) => contact.label_id)
        .map((contact) => contact.label_id);

      const newLabels = this.contactsLabels.filter((label) => !existingLabels.some((id) => id === label.id));

      if (this.contact.label_id !== 0) {
        const nameCurrentContact = this.getContactLabelName;
        newLabels.push({
          id: this.contact.label_id,
          contact_name: nameCurrentContact,
        });
      }
      return newLabels
        .filter((label) => [1, 3, 8, 2, 9].includes(label.id))
        .map((label) => ({
          value: label.id,
          label: label.contact_name,
        }));
    },
    getContactLabelName() {
      return this.contactsLabels.find(
        (label) => label.id === this.contact.label_id,
      ).contact_name;
    },
  },
  mounted() {
    this.body = this.contact;
  },
  methods: {
    setBody(body) {
      const [key, value] = Object.entries(body)[0];
      this.body[key] = value;
      if (key === 'names') {
        this.$emit('setKeyValue', 'name', value);
      } else {
        this.$emit('setKeyValue', key, value);
      }
      if (key === 'verified') {
        this.$emit('setKeyValue', key, value.length > 0);
      }
    },
    getContactLabel(index) {
      return this.contactsArray[index].label_id;
    },
    getContactName(index) {
      return this.contactsArray[index].name;
    },
    getContactCell(index) {
      return this.contactsArray[index].cellphone;
    },
    getContactPhone(index) {
      return this.contactsArray[index].phone;
    },
    getContactEmail(index) {
      return this.contactsArray[index].email;
    },
  },
};
</script>
