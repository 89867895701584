<template>
  <SpinningLoader v-if="loading" :active="loading" />
</template>

<script>
import SpinningLoader from '@/components/organisms/general/SpinningLoader.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LinkBankWidget',
  components: {
    SpinningLoader,
  },
  props: {
    interface: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    widgetReady: false,
    widget: null,
  }),
  computed: {
    ...mapGetters({
      loading: 'payments/loading',
    }),
  },
  watch: {
    widgetReady(value) {
      if (value) {
        // Open the Widget that has been mounted
        this.interface.openWidget(this.widget);
      }
    },
  },
  async created() {
    // Upon creation of the component, setup the corresponding script and start the chain of events
    this.setupProvider();
  },
  destroyed() {
    if (this.widget) {
      this.widgetReady = false;
      this.interface.destroyWidget(this.widget);
    }
  },
  methods: {
    successfulPayment(response) {
      this.$emit('success', response);
    },
    closedWidget() {
      this.$emit('exit');
    },
    setupProvider() {
      const callbacks = {
        loadedScript: () => {
          this.loadWidget();
        },
      };
      this.interface.setup({ callbacks });
    },
    async loadWidget() {
      const callbacks = {
        success: this.successfulPayment,
        exit: this.closedWidget,
      };
      const config = this.interface.createConfig({ callbacks });
      if (this.widget) {
        this.interface.destroyWidget(this.widget);
      }
      this.widget = this.interface.widgetInvoker({ config });
      this.widgetReady = true;
    },
  },

};
</script>
