<template>
  <div class="costs-input-box">
    <TextAtom
      class="pt-4 pl-4"
      value="$"
      tag="p"
      font="poppins"
      color="primary-dark"
      weight="500"
      size="normal"
    />
    <TextAtom
      class="costs-input-box-separator"
      value="|"
      tag="p"
      font="poppins"
      weight="500"
      size="big"
    />
    <EditableGroup
      :fields="[
        {
          type: 'number',
          varName: 'var',
          input: quantity,
          extraClasses: 'costs-input-box-input',
          autofocus: false,
          backgroundColor: '#F5F5F5',
        },
      ]"
      @setObject="emitValue"
    />
  </div>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import EditableGroup from '@/components/dashboard/utils/EditableGroup.vue';

export default {
  name: 'CostInputBox',
  components: { TextAtom, EditableGroup },
  props: {
    quantity: {
      type: String,
      required: true,
    },
  },
  methods: {
    emitValue(quantity) {
      this.$emit('setValue', quantity);
    },
  },
};
</script>
