<template>
  <div>
    <Subcontent
      title-class="editable-title"
      box-shadow="0"
      :padding="inList ? '15px 15px 15px 15px' : dense ? '0px 0px 0px 0px' : '19px 19px 0px 16px'"
      :extra-classes="extraClasses ? extraClasses : 'm-0'"
      :title="inList || !titleText ? '' : title"
      :icon="icon"
    >
      <div v-if="!dense && title != '' && inList" class="editable-text text-left mb-2">
        {{ title }}
      </div>
      <!-- text -->
      <div v-if="type == 'text' || type == 'number'" class="text-input" :style="{ '--color': textColor }">
        <v-text-field
          v-model="myInput"
          class="editable-text"
          solo
          :autofocus="autofocus"
          :background-color="backgroundColor"
          :label="dense ? title : ''"
          :type="type"
          dense
          :disabled="disabled"
          :prepend-inner-icon="prependIcon ? prependIcon : ''"
          hide-details="auto"
          v-bind="fieldProps"
          @input="setInput()"
        />
      </div>
      <!-- password -->
      <div v-if="type == 'password' ">
        <div v-if="!myShowEye" class="enter-token">
          {{ $t('dashboard.digital_profile.socials.token_title') }}
          <div class="enter-token--value" @click="changeToken()" @keydown="changeToken()">
            {{ $t('dashboard.digital_profile.socials.token_title2') }}
          </div>
        </div>
        <form v-else class="editable-text" autocomplete="off">
          <v-text-field
            v-model="myInput"
            class="editable-text"
            aria-label="token"
            solo
            :placeholder="dense ? title : ''"
            :type="setInputType()"
            dense
            color="#828282 !important"
            hide-details
            v-bind="fieldProps"
            background-color="#E3E9FE"
            @input="setInput()"
          />
          <v-icon
            class="editable-eye"
            @click="showPassword ? (showPassword = false) : (showPassword = true)"
          >
            {{ showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}
          </v-icon>
        </form>
      </div>
      <!-- end password -->
      <!-- end text -->
      <!-- number white -->
      <div v-if="type == 'numberField'" class="text-input">
        <v-text-field
          v-model="myInput"
          :label="$t('dashboard.digital_profile.number')"
          solo
          color="#2B5BFF"
          type="number"
          dense
          width="100%"
          :disabled="disabled"
          hide-details
          v-bind="fieldProps"
          @input="setInput()"
        />
      </div>
      <!-- end number white -->
      <!-- text area -->
      <div v-else-if="type == 'textarea'">
        <v-textarea
          v-model="myInput"
          class="editable-text rounded-lg"
          autofocus
          solo
          :disabled="disabled"
          hide-details="auto"
          flat
          color="#FFFFFF"
          :background-color="colorTextArea"
          v-bind="fieldProps"
          @input="setInput()"
        >
          <template #label>
            <div class="editable-text">
              {{ fieldProps.label ? fieldProps.label : '' }}
            </div>
          </template>
        </v-textarea>
      </div>
      <!-- end text area -->
      <!-- date -->
      <div v-else-if="type == 'date'">
        <v-date-picker
          v-if="(modal && myEditing) || !openModal"
          v-model="myInput"
          full-width
          :disabled="disabled"
          :show-current="true"
          selected-items-text="rango seleccionado"
          locale="es-ES"
          color="#2B5BFF"
          v-bind="fieldProps"
          lang="es-ES"
          range
          @input="inList ? setInput() : () => {}"
        />
        <!-- :disabled="!myEditing && !inList" -->
        <div v-else class="dashboard-grid" :style="{ '--n-elements-per-row': 2 }">
          <div
            :class="disabled ? '' : 'clickable'"
            class="dashboard-grid-element editable-date-info m-2 rounded"
            :style="{ '--height': '60px' }"
            @click="modal && !disabled ? toggleEditing() : () => {}"
            @keydown="modal && !disabled ? toggleEditing() : () => {}"
          >
            <div>{{ getDay(0) }}</div>
            <div>{{ getMonth(0) }}</div>
          </div>
          <div
            :class="disabled ? '' : 'clickable'"
            class="dashboard-grid-element editable-date-info m-2 rounded"
            :style="{ '--height': '60px' }"
            @click="modal && !disabled ? toggleEditing() : () => {}"
            @keydown="modal && !disabled ? toggleEditing() : () => {}"
          >
            <div>
              {{ getDay(1) }}
            </div>
            <div>
              {{ getMonth(1) }}
            </div>
          </div>
        </div>
        <div v-if="!modal" />
      </div>
      <!-- end date -->
      <!-- dropdown -->
      <div v-else-if="type == 'dropdown'" class="dropdown-dashboard">
        <v-select
          v-if="type == 'dropdown'"
          v-model="myInput"
          :items="items"
          item-text="label"
          item-value="value"
          hide-details
          elevation-0
          v-bind="fieldProps"
          :color="dropdownColor"
          :disabled="(!myEditing && openModal) || disabled"
          :append-icon="!myEditing && openModal ? '' : 'mdi-chevron-down'"
          solo
          :placeholder="dense ? place : ''"
          :background-color="dropdownColor"
          @click="openModal ? toggleEditing() : toggleEditing()"
          @change="setInput()"
        />
      </div>
      <!-- end dropdown -->
      <!-- image -->
      <div v-else-if="type == 'image'" style="max-width: 100%; overflow: hidden">
        <v-file-input
          id="fileInputId"
          v-model="imageInput"
          class="editable-field"
          background-color="#2B5BFF"
          :rules="ruleSingle"
          v-bind="fieldProps"
          prepend-icon=""
          prepend-inner-icon="mdi-upload"
          truncate-length="15"
          solo
          accept="image/jpeg, image/png"
          @click:prepend-inner="openFileInput()"
          @change="onImageUpload"
        />
        <v-row class="mt-3">
          <v-img :height="imageUrl ? 'auto' : '0'" :src="imageUrl" />
        </v-row>
      </div>
      <!-- end image -->
      <!-- file -->
      <div v-else-if="type == 'file'" style="max-width: 100%; overflow: hidden">
        <v-file-input
          v-model="myInput"
          background-color="#2B5BFF"
          color="#2B5BFF"
          height="142px"
          v-bind="fieldProps"
          prepend-icon=""
          truncate-length="15"
          solo
          @click.stop
        >
          <template #label>
            <v-icon color="white">
              mdi-upload
            </v-icon>
          </template>
        </v-file-input>
      </div>
      <!-- end file -->

      <!-- checkbox -->
      <v-container
        v-if="type == 'checkbox'"
        class="grid-dashboard grid-dashboard--checkbox"
        :style="{ '--elements-per-row': mobile ? 2 : elementsRow }"
        fluid
      >
        <v-checkbox
          v-for="(item, index) in items"
          v-bind="fieldProps"
          :key="index"
          v-model="myInput"
          dense
          hide-details
          :disabled="disabled"
          class="editable-text"
          color="#5627FF"
          :value="item.value"
          @change="setInput()"
        >
          <template #label>
            <span class="editable-text">{{ item.label }}</span>
          </template>
        </v-checkbox>
      </v-container>
      <!-- end checkbox -->
      <!-- checkbox with input -->
      <v-container
        v-if="type == 'checkInput'"
        fluid
        class="checkInput-container"
      >
        <v-checkbox
          v-for="(item, index) in items"
          v-bind="fieldProps"
          :key="index"
          v-model="myInput"
          dense
          hide-details
          color="#5627FF"
          :value="item.value"
          @change="setInputCheck('check')"
        >
          <template #label>
            <span class="editable-text">{{ item.label }}</span>
          </template>
        </v-checkbox>
        <v-text-field
          v-model="otherInput"
          :disabled="myInput === null || myInput === 1"
          color="#5627FF"
          solo
          @input="setInputCheck('input')"
        />
      </v-container>
      <!-- end checkbox -->
      <!-- radio button -->
      <v-container v-if="type == 'radiobutton'" class="px-0" fluid>
        <v-radio-group v-model="myInput">
          <v-radio
            v-for="(item, index) in items"
            :key="index"
            class="editable-text"
            color="#5627FF"
            :value="item.value"
            @click="setInput()"
          >
            <template #label>
              <span class="editable-text">{{ item.label }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-container>
      <!-- end radio button -->
      <!-- radio button -->
      <v-container v-if="type == 'radio'" class="px-0 py-0" fluid>
        <v-radio-group v-model="myInput">
          <v-radio
            v-for="(item, index) in items"
            :key="index"
            class="editable-text--radio"
            color="#5627FF"
            :value="item.value"
            @click="setInput()"
          >
            <template #label>
              <TextAtom
                class="mb-2 mt-4"
                :value="item.label"
                tag="label"
                font="inter"
                color="neutral-850"
                weight="500"
                size="subtitle"
              />
              <TextAtom
                :value="item.subtitle"
                tag="label"
                font="inter"
                color="text-neutral-600"
                weight="400"
                size="subtitle"
              />
            </template>
          </v-radio>
        </v-radio-group>
      </v-container>
      <!-- end radio button -->
      <div
        v-if="(modal && myEditing) || (type == 'date' && !openModal)"
        class="editable-button-container"
      >
        <div class="dashboard-button editable-button" @click="setInput" @keydown="setInput">
          {{ $t('dashboard.utils.editable_fields') }}
        </div>
      </div>
    </Subcontent>
  </div>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import Subcontent from './Subcontent.vue';

export default {
  name: 'EditableFields',
  components: { Subcontent, TextAtom },
  props: {
    /* if dense, title will be shown in placeholder */
    dense: {
      required: false,
      default: false,
      type: Boolean,
    },
    /* extra classes for subcontent if need be */
    extraClasses: {
      required: false,
      type: String,
      default: null,
    },
    /* wether field is in group in list or not */
    inList: {
      required: false,
      default: false,
      type: Boolean,
    },
    /* wether field is being edited by default */
    editing: {
      required: false,
      default: false,
      type: Boolean,
    },
    /* icon shown */
    icon: {
      required: true,
      type: String,
    },
    /* background color */
    backgroundColor: {
      required: false,
      type: String,
      default: 'white',
    },
    /* autofocus */
    autofocus: {
      required: false,
      type: Boolean,
      default: true,
    },
    /* items for dropdown */
    items: {
      required: false,
      default: () => [],
      type: Array,
    },
    /* title shown */
    title: {
      required: true,
      type: String,
    },
    /* either text, textarea, date, dropdown */
    type: {
      required: false,
      default: 'text',
      type: String,
    },
    /* if disabled, can never be edited */
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    /* starting text */
    input: {
      required: false,
      type: [String, Array, Number, Object],
      default: '',
    },
    /* wether field is in a modal or not */
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* wether field opens a modal or not */
    openModal: {
      type: Boolean,
      default: false,
      required: false,
    },
    /* rules if needed */
    fieldProps: {
      type: Object,
      default: () => Object(),
      required: false,
    },
    showEye: {
      type: Boolean,
      default: false,
      required: false,
    },
    place: {
      type: String,
      default: '',
      required: false,
    },
    infoOther: {
      type: String,
      default: '',
      required: false,
    },
    elementsRow: {
      type: Number,
      default: 3,
      required: false,
    },
    dropdownColor: {
      type: String,
      default: 'white',
      required: false,
    },
    textColor: {
      type: String,
      default: 'red',
      required: false,
    },
    prependIcon: {
      type: String,
      default: '',
      required: false,
    },
    titleText: {
      type: Boolean,
      default: true,
      required: false,
    },
    colorTextArea: {
      type: String,
      default: 'white',
      required: false,
    },
  },
  data() {
    return {
      imageInput: null,
      imageUrl: null,
      myEditing: false,
      myInput: [],
      language: [],
      level: [],
      showPassword: false,
      myShowEye: false,
      ruleSingle: [
        (file) => !file || file.size < 3150000 || file.size === undefined || 'La imagen debe ser menor de 3MB!',
      ],
      otherInput: '',
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    input(newVal) {
      this.myInput = newVal;
    },
    showEye(newVal) {
      this.myShowEye = newVal;
    },
    infoOther(newVal) {
      this.otherInput = newVal;
    },
  },
  mounted() {
    if (this.type === 'image' && this.input !== '') {
      this.imageUrl = this.input;
      this.imageInput = new FileReader();
    }
    if (this.type === 'date' && this.input[0] === null && this.input[1] === null) {
      this.myInput = [];
    } else {
      this.myInput = this.input;
      this.myEditing = this.editing;
      this.myShowEye = this.showEye;
    }
    if (this.type === 'checkInput') {
      this.otherInput = this.infoOther;
    }
  },
  methods: {
    openFileInput() {
      const fileInput = document.getElementById('fileInputId');
      if (fileInput != null) {
        fileInput.click();
      }
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
        this.$emit('setInput', this.imageUrl);
      };
      reader.readAsDataURL(file);
    },
    setInputType() {
      if (!this.showPassword) {
        return 'password';
      }
      return 'text';
    },
    onImageUpload(file) {
      if (!file || file.size > 3150000 || file.size === undefined) {
        this.imageUrl = null;
        this.$emit('setInput', this.imageUrl);
        return;
      }
      this.createImage(file);
    },
    getDay(index) {
      if (Array.isArray(this.myInput)) {
        if (this.myInput[index] == null) {
          return '●';
        }
        const day = this.myInput[index].split('-')[2];
        return day;
      }
      return null;
    },
    getMonth(index) {
      if (Array.isArray(this.myInput)) {
        if (this.myInput[index] == null) {
          return '';
        }
        return new Date(this.myInput[index]).toLocaleString('es-ES', { month: 'short' });
      }
      return null;
    },
    setInput() {
      if (this.type === 'date') {
        if (new Date(this.myInput[0]) > new Date(this.myInput[1])) {
          // swap
          const swapped = [this.myInput[1], this.myInput[0]];
          this.myInput = swapped;
        }
      }
      this.$emit('setInput', this.language);
      this.$emit('setInput', this.level);
      const variable = {};
      variable[this.varName] = this.myInput;
      this.$emit('setVariable', variable);
      this.$emit('setInput', this.myInput);
      this.$emit('setModal');
    },

    setInputCheck(info) {
      if (info === 'check') {
        if (this.myInput === null) {
          this.otherInput = '';
        }
      } else if (info === 'input') {
        if (this.otherInput === '') {
          this.myInput = null;
        }
      }
      this.$emit('setOtherInfo', this.otherInput);
    },
    toggleEditing() {
      if (this.openModal) {
        this.$emit('setModal', true);
        if (this.myEditing) {
          this.$emit('setVariable', this.myInput);
        }
        return;
      }
      if (this.modal) {
        this.$emit('setModal', true);
        return;
      }
      if (this.disabled) {
        this.myEditing = false;
        return;
      }
      if (this.myEditing) {
        this.$emit('setInput', this.myInput);
      }
      this.myEditing = !this.myEditing;
    },
    changeToken() {
      this.$emit('changeToken');
    },
  },
};
</script>
