<template>
  <div v-if="TENANT_CONFIGURATION.SETTINGS.LINK_BANK_ACCOUNT.ENABLED">
    <TextAtom
      class="des-sections__location mt-3 pt-3 d-flex left-text ml-4"
      :value="$t('dashboard.digital_profile.prime.bank_info.subsection_title')"
      tag="label"
      font="inter"
      color="neutral-800"
      weight="400"
      size="normal"
    />
    <div class="title-sections__bank">
      <div class="full-width d-flex flex-row align-center justify-between">
        <TextAtom
          class="title-sections__waiting--list"
          :value="$t('dashboard.digital_profile.prime.bank_info.title')"
          tag="label"
          font="inter"
          color="primary-dark"
          weight="500"
          size="title"
        />
        <SvgIcon :icon="getBankInfoIcon()" :size="'25'" style="margin-left: 16px" />
      </div>
      <TextAtom
        class="title-sections__waiting--list"
        :value="$t('dashboard.digital_profile.prime.bank_info.subtitle')"
        tag="label"
        font="inter"
        color="neutral-700"
        weight="400"
        size="normal"
      />
      <AccountTypeSelect
        v-if="preFlowCheck"
        :options="accountOptions()"
        @confirm="confirmAccount($event)"
        @cancel="cancelAccount()"
      />
      <LinkIntent :visible="widgetVisible" :account-type="accountType" @finished="widgetFinished" />
      <div v-if="bankAccount" class="full-width d-flex flex-row align-center mt-4">
        <TextAtom
          class="title-sections__waiting--list mr-2"
          :value="'Cuenta vinculada:'"
          tag="label"
          font="inter"
          color="neutral-700"
          weight="600"
          size="subtitle"
        />
        <TextAtom
          class="title-sections__waiting--list"
          :value="`${bankAccount.name} - ${bankAccount.number}`"
          tag="label"
          font="inter"
          color="neutral-700"
          weight="400"
          size="normal"
        />
      </div>
      <TextLink
        :link-text="getLinkBankButtonText()"
        style="margin-top: 15px;text-align: left; display: flex;"
        @toLink="startFlow()"
      />
    </div>
  </div>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import { mapActions, mapGetters } from 'vuex';
import AccountTypeSelect from '@/components/organisms/payments/modals/AccountTypeSelect.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import TextLink from '@/components/atoms/buttons/TextLink.vue';
import LinkIntent from '@/components/organisms/dashboard/LinkIntent.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';

export default {
  name: 'BankInfo',
  components: {
    TextAtom,
    TextLink,
    SvgIcon,
    LinkIntent,
    AccountTypeSelect,
  },
  data() {
    return {
      TENANT_CONFIGURATION,
      widgetVisible: false,
      preFlowCheck: false,
      accountType: 'business',
    };
  },
  computed: {
    ...mapGetters({
      currentProvider: 'payments/currentProvider',
      bankAccount: 'payments/bankAccount',
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
  },
  mounted() {
    if (
      !this.currentProvider
      || this.currentProvider.provider_code !== this.TENANT_CONFIGURATION.SETTINGS.LINK_BANK_ACCOUNT.DEFAULT_PROVIDER
    ) {
      this.getProvider({ providerCode: this.TENANT_CONFIGURATION.SETTINGS.LINK_BANK_ACCOUNT.DEFAULT_PROVIDER });
    }
    if (!this.bankAccount || this.bankAccount.entity_code !== this.campusDetail.campus_code) {
      this.getBankAccount({ entityCode: this.campusDetail.campus_code });
    }
  },
  methods: {
    ...mapActions({
      getBankAccount: 'payments/getBankAccount',
      getProvider: 'payments/getProvider',
    }),
    getBankInfoIcon() {
      if (this.bankAccount) {
        return 'green-check.svg';
      }
      return 'grey-check.svg';
    },
    getLinkBankButtonText() {
      if (this.bankAccount) {
        return this.$t('dashboard.digital_profile.prime.bank_info.button_text.change');
      }
      return this.$t('dashboard.digital_profile.prime.bank_info.button_text.connect');
    },
    accountOptions() {
      const options = [
        {
          title: this.$t('payments.link_bank.dialog.account_type.business'),
          code: 'business',
        },
        {
          title: this.$t('payments.link_bank.dialog.account_type.individual'),
          code: 'individual',
        },
      ];
      return options;
    },
    startFlow() {
      this.preFlowCheck = true;
    },
    confirmAccount(accountTypeIndex) {
      this.accountType = this.accountOptions()[accountTypeIndex].code;
      if (this.accountType) {
        this.widgetVisible = true;
        this.preFlowCheck = false;
      }
    },
    cancelAccount() {
      this.preFlowCheck = false;
    },
    widgetFinished() {
      this.widgetVisible = false;
    },
  },
};
</script>
