<template>
  <v-dialog
    v-model="showModal"
    max-width="900"
    max-height="800"
    overlay-color="black"
    :fullscreen="mobile"
    @click:outside="toggleModal(null)"
  >
    <!-- main modal -->
    <div
      v-if="currentModal == 'information'"
      class="relative d-flex"
      :class="mobile ? 'flex-column' : 'flex-row'"
    >
      <!-- x button -->
      <img
        v-show="videoLoaded"
        :class="mobile ? 'absolute-top-left m-2' : 'absolute-top-right m-2'"
        style="z-index: 101; right: 22px; top: 10px"
        src="@/assets/dashboard/icons/close_cross.svg"
        alt="close"
        @click="toggleModal(null)"
        @keydown="toggleModal(null)"
      />
      <!-- end x button -->
      <!-- video -->
      <CenteredVideo
        v-show="videoLoaded"
        :width="mobile ? getWindowWidth() : 290"
        :height="mobile ? 240 : 515"
        :muted="false"
        video-i-d="735300750"
        @onPlaying="onPlaying"
      />
      <!-- end video -->
      <!-- -->
      <!-- content -->
      <div
        v-if="videoLoaded"
        style="background-color: #eef5ff"
        :style="mobile ? 'width:100%;height:calc(100vh - 240px)' : 'height:515px;width:315px'"
        class="relative"
      >
        <img
          v-if="!mobile"
          class="absolute"
          style="top: 40px; right: 50px; height: 60px"
          src="@/assets/dashboard/icons/dfm-modal.svg"
          alt="dfm"
        />
        <!-- information -->
        <div style="padding: 30px; margin-top: 0px" class="left information-container">
          <br v-if="!mobile" />
          <br v-if="!mobile" />
          <p class="dfm-text big" style="font-size: 20px">
            {{ $t('dashboard.dfm.objective') }}
          </p>
          <br />
          <p class="dfm-text" style="font-weight: normal">
            {{ $t('dashboard.dfm.title_text') }}
          </p>
          <br />
          <p class="dfm-text bold">
            {{ $t('dashboard.dfm.title_info') }}
          </p>
        </div>
        <!-- end information -->
        <!-- button -->
        <div class="dfm-open-button" @click="toggleModal('ambassadors')" @keydown="toggleModal('ambassadors')">
          {{ $t('dashboard.dfm.meeting') }}
        </div>
        <!-- end button -->
      </div>
      <!-- end content -->
    </div>
    <!-- end main modal -->
    <!-- selection modal -->
    <div
      v-if="currentModal == 'ambassadors'"
      :class="mobile ? '' : 'calendly-div-container'"
      @click="toggleModal(null)"
      @keydown="toggleModal(null)"
    >
      <!-- x button -->
      <img
        v-show="videoLoaded"
        :class="mobile ? 'absolute-top-left m-2' : 'absolute-top-right'"
        style="z-index: 101; right: 60px; top: 10px"
        src="@/assets/dashboard/icons/close.svg"
        alt="close"
        @click="toggleModal(null)"
        @keydown="toggleModal(null)"
      />
      <!-- end x button -->
      <div :class="mobile ? 'calendly-container-mobile' : 'calendly-container'">
        <iframe
          title="calendly"
          :style="mobile ? '' : 'margin-left:-50px;margin-top:-66px;overflow-y:hidden'"
          :width="mobile ? getWindowWidth() : 1000"
          :height="mobile ? getWindowHeight() : 700"
          :scrolling="mobile ? 'yes' : 'no'"
          frameborder="0"
          src="https://calendly.com/decidiendofuturomejor/presentacion-decidiendo-para-un-futuro-mejor"
          alt="Calendly Dudas y consultas DFM"
          loading="lazy"
        />
      </div>
    </div>
    <!-- end selection modal -->
  </v-dialog>
</template>
<script>
import trackMixPanel from '@/utils/mixpanel';
import 'vue2-datepicker/index.css';
import { mapGetters } from 'vuex';
import CenteredVideo from './utils/CenteredVideo.vue';

export default {
  name: 'DFMModal',
  components: {
    CenteredVideo,
  },
  props: {
    currentModal: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      videoLoaded: false,
    };
  },
  computed: {
    showModal() {
      return this.currentModal !== null;
    },
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    document.addEventListener('keydown', () => {
      this.$emit('setModal', null);
    });
  },
  methods: {
    getWindowHeight() {
      return window.innerHeight;
    },
    getWindowWidth() {
      return window.innerWidth;
    },
    toggleModal(modal) {
      if (modal === null) {
        this.videoLoaded = false;
      }
      this.$emit('setModal', modal);
      if (modal === 'ambassadors') {
        trackMixPanel('click_dashboard_dfm_ambassador', {
          campus_code: this.campusDetail.campus_code,
          institution_code: this.campusDetail.institution_code,
        });
      }
    },
    onPlaying() {
      if (this.videoLoaded === false) {
        this.videoLoaded = true;
      }
    },
  },
};
</script>
