<template>
  <div class="dashboard-section-content">
    <div class="dashboard-section-subcontent">
      <div class="dashboard-text text-left">
        {{ title }}
      </div>
      <div class="dashboard-subtext text-left">
        {{ $t('dashboard.teaching.teaching-des') }}
      </div>
      <div class="dashboard-grid" :style="{ '--n-elements-per-row': 1 }">
        <div
          :class="getSwitchClass()"
          class="dashboard-switch dashboard-grid-element"
          :style="{ '--element-height': '40px' }"
          @click="toggleSwitch()"
          @keydown="toggleSwitch()"
        >
          {{ $t('dashboard.teaching.searching-warning') }}
        </div>
      </div>
      <v-expand-transition>
        <div v-if="switchOn">
          <br />
          <div class="dashboard-subtext text-left">
            {{ $t('dashboard.teaching.soon-warning') }}
          </div>
          <div
            class="d-flex flex-wrap flex-row w-100 mw-100"
            :style="{ '--n-elements-per-row': 3 }"
          >
            <div
              v-for="(text, index) in subjectsList"
              :key="index"
              :style="{ '--element-height': '30px' }"
              class="dashboard-switch dashboard-switch-disabled dashboard-grid-element"
            >
              {{ text }}
            </div>
          </div>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchingTeachers',
  props: {
    title: {
      required: false,
      default: '',
      type: String,
    },
  },
  data() {
    return {
      switchOn: false,
      subjectsList: [
        'Lenguaje',
        'Matemáticas',
        'Historia',
        'Biología',
        'Física',
        'Química',
        'Arte',
      ],
    };
  },
  methods: {
    getSwitchClass() {
      if (this.switchOn === 1) {
        return 'dashboard-switch-on';
      }
      return 'dashboard-switch-off';
    },
    toggleSwitch() {
      this.switchOn = !this.switchOn;
    },
  },
};
</script>
