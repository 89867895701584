<template>
  <div>
    <EditableGroup
      class="mb-5"
      :fields="[
        {
          type: 'textarea',
          title: '',
          varName: 'sports_text',
          input: getSportsText,
          dense: true,
          fieldProps: {
            label: $t('dashboard.digital_profile.sports.add-description'),
          },
        },
      ]"
      @setVariable="setTextBody"
    />
    <div
      class="dashboard-grid"
      style="background-color: white"
      :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr' }"
    >
      <SportsPanel
        class="padding height-digital-profile"
        :sports="campusDetail.sport_campus"
        :canonical-link="canonicalLink"
      />
      <div>
        <EditableGroup
          :fields="[
            {
              type: 'checkbox',
              title: $t('dashboard.digital_profile.sports.edit'),
              input: submittedCampusSportIds,
              varName: 'sport_campus',
              items: sportsTypes,
              elementsRow: 1,
            },
          ]"
          @setVariable="setBody"
        />
        <EditableGroup
          v-if="otherType.length > 0"
          :fields="[
            {
              type: 'checkInput',
              title: '',
              dense: true,
              input: getOtherInfo(),
              varName: 'sport_other',
              items: otherType,
              infoOther: otherSportsInput,
            },
          ]"
          @setVariable="setBody"
        />
      </div>

      <SaveButton :loading="saving" @save="save" />
    </div>
  </div>
</template>
<script>
import SportsPanel from '@/components/explorer/general/campus_detail/panels/Sports.vue';
import utils from '@/utils/';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'SportsSection',
  components: { EditableGroup, SaveButton, SportsPanel },
  props: {
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      body: Object(),
      textBody: {},
      otherBody: Object(),
      otherSportsInput: null,
      canonicalLink: utils.getCanonicalLink(this.$route.path),
      otherInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      sportsLabels: 'options/sportsLabels',
    }),
    getSportsText() {
      return this.campusDetail.institutiontext_campus[0]?.sports_text ?? '';
    },
    submittedCampusSportIds() {
      // eslint-disable-next-line camelcase
      return this.campusDetail.sport_campus.filter(({ sport_label_id }) => sport_label_id !== 99)
        .map(({ sport_label_id: id }) => id) ?? [];
    },
    updatedCampusSportsIds() {
      return this.body.sport_campus ?? null;
    },
    sportsTypes() {
      const sports = [];
      this.sportsLabels.forEach((sport) => {
        if (sport.id !== 99) {
          sports.push({ label: sport.sport_name, value: sport.id });
        }
      });
      sports.sort((a, b) => a.label.localeCompare(b.label));
      return sports;
    },
    otherType() {
      const otherType = [];
      this.sportsLabels.forEach((sport) => {
        if (sport.id === 99) {
          otherType.push({ label: sport.sport_name, value: sport.id });
        }
      });
      return otherType;
    },
  },
  watch: {
    updatedCampusSportsIds: {
      handler(newIds) {
        const hasOthers = newIds.includes(99);
        this.setInputDisabledValue({ disabled: !hasOthers });
        if (!hasOthers) {
          this.otherSportsInput = null;
        }
      },
    },
  },
  async mounted() {
    this.loaded = true;

    this.body = {
      sport_campus: [...this.submittedCampusSportIds],
    };
    // eslint-disable-next-line camelcase
    if (this.campusDetail.sport_campus.some(({ sport_label_id }) => sport_label_id === 99)) {
      const otherSports = this.campusDetail.sport_campus.find(({ sport_label_id: id }) => id === 99);
      this.otherSportsInput = otherSports.descrip;
      this.otherInfo = true;
    }
  },
  methods: {
    ...mapActions({
      campusSportsPatch: 'institutions/campusSportsPatch',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),
    setTextBody(body) {
      this.textBody = body;
    },
    setBody(body) {
      if (body.sport_other !== undefined) {
        this.otherBody = {
          label_id: 99,
          descrip: body.sport_other,
        };
      } else {
        this.body = body;
      }
    },
    save() {
      this.saving = true;

      const campusData = this.updatedCampusSportsIds.map((id) => ({
        label_id: id,
      }));
      if (this.otherBody.descrip) {
        campusData.push(this.otherBody);
      }
      this.campusSectionTextPatch({ campusUuid: this.campusDetail.uuid, campusData: this.textBody });
      this.campusSportsPatch({ campusUuid: this.campusDetail.uuid, campusData }).finally(
        () => {
          this.saving = false;
        },
      );
      trackMixPanel('click_dashboard_digital_profile_sports_save', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
    },
    getOtherInfo() {
      return this.otherInfo === true ? 99 : 1;
    },
  },
};
</script>
