var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title-sections__location"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.general_info.title'))+" ")]),_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"institution-name institution-name--first"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.general_info.institution-name'))+" ")]),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'text',
          title: _vm.$t('dashboard.digital_profile.general_info.institution-long-name'),
          varName: 'institution_name',
          dense: true,
          input: _vm.getInstitutionName(),
          fieldProps: {
            maxLength: 100,
            counter: 100,
          },
        },
      ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'text',
          title: _vm.$t('dashboard.digital_profile.general_info.institution-short-name'),
          varName: 'institution_short_name',
          dense: true,
          input: _vm.getInstitutionShortName(),
          fieldProps: {
            maxLength: 30,
            counter: 30,
          },
        },
      ]},on:{"setObject":_vm.setBody}})],1),_c('div',{staticClass:"mb-5"},[_c('span',{staticClass:"institution-name"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.general_info.campus'))+" ")]),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'text',
          title: _vm.$t('dashboard.digital_profile.general_info.campus-name'),
          dense: true,
          varName: 'campus_name',
          input: _vm.getCampusName(),
          fieldProps: {
            maxLength: 100,
            counter: 100,
          },
        },
      ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'text',
          title: _vm.$t('dashboard.digital_profile.general_info.campus-short-name'),
          dense: true,
          varName: 'campus_short_name',
          input: _vm.getCampusShortName(),
          fieldProps: {
            maxLength: 30,
            counter: 30,
          },
        },
      ]},on:{"setObject":_vm.setBody}})],1),_c('SaveButton',{attrs:{"loading":_vm.saving,"is-disabled":_vm.isActive},on:{"save":_vm.save}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }