<template>
  <div class="kit-admin" :style="{ '--n-rows': 2 }">
    <Subsection
      bg-color="transparent"
      class="kit-admin--social"
      flex="column"
      clickable
      @onClick="goTo('social_network_kit')"
    >
      <div class="kit-admin--text">
        <TextAtom
          :value="$t('dashboard.kit-admin.title')"
          tag="label"
          font="inter"
          color="secondary-light"
          weight="500"
          size="default"
        />
        <TextAtom
          :value="$t('dashboard.kit-admin.social_subtitle')"
          tag="label"
          font="inter"
          color="secondary-light"
          weight="500"
          size="normal"
        />
      </div>
      <ImageAtom
        class="kit-admin__image"
        :source="require('@/assets/dashboard/widgets/kit-social.svg')"
      />
    </Subsection>
    <Subsection
      bg-color="transparent"
      class="kit-admin--banner"
      flex="column"
      clickable
      @onClick="goTo('webpage_banner_kit')"
    >
      <div class="kit-admin--text">
        <TextAtom
          :value="$t('dashboard.kit-admin.title')"
          tag="label"
          font="inter"
          color="secondary-light"
          weight="500"
          size="default"
        />
        <TextAtom
          :value="$t('dashboard.kit-admin.banner_subtitle')"
          tag="label"
          font="inter"
          color="primary-medium"
          weight="500"
          size="normal"
        />
      </div>
      <ImageAtom
        class="kit-admin--banner__image"
        :source="require('@/assets/dashboard/widgets/kit-banner.svg')"
      />
    </Subsection>
    <Subsection
      bg-color="transparent"
      class="kit-admin--elements"
      flex="column"
      clickable
      @onClick="goTo('graphic_elements_kit')"
    >
      <div class="kit-admin--text">
        <TextAtom
          :value="$t('dashboard.kit-admin.title')"
          tag="label"
          font="inter"
          color="secondary-light"
          weight="500"
          size="default"
        />
        <TextAtom
          :value="$t('dashboard.kit-admin.elements_subtitle')"
          tag="label"
          font="inter"
          color="primary-medium"
          weight="500"
          size="normal"
        />
      </div>
      <ImageAtom
        class="kit-admin__image"
        :source="require('@/assets/dashboard/widgets/kit-elements.svg')"
      />
    </Subsection>
    <Subsection
      bg-color="transparent"
      class="kit-admin--manual"
      flex="column"
      clickable
      @onClick="goTo('documentation_kit')"
    >
      <div class="kit-admin--text">
        <TextAtom
          :value="$t('dashboard.kit-admin.title')"
          tag="label"
          font="inter"
          color="secondary-light"
          weight="500"
          size="default"
        />
        <TextAtom
          :value="$t('dashboard.kit-admin.manual_subtitle')"
          tag="label"
          font="inter"
          color="secondary-light"
          weight="500"
          size="normal"
        />
      </div>
      <ImageAtom
        class="kit-admin__image"
        :source="require('@/assets/dashboard/widgets/kit-manual.svg')"
      />
    </Subsection>
  </div>
</template>

<script>
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import { mapGetters } from 'vuex';
import Subsection from './Subsection.vue';

export default {
  name: 'KitAdmin',
  components: { Subsection, TextAtom, ImageAtom },
  computed: {
    ...mapGetters({
      widgetsDashboardLink: 'widgets/widgetsDashboardLink',
    }),
  },
  methods: {
    goTo(widget) {
      const { link } = this.widgetsDashboardLink.find((w) => w.label === widget);
      window.open(link, '_blank').focus();
    },
  },
};
</script>
