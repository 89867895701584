<template>
  <div
    class="label-molecule"
    :class="{
      'label-molecule--italic': italic,
    }"
    :style="{
      fontWeight,
    }"
  >
    <MDIIcon v-if="icon" :icon="icon" :color="color" class="mr-2" small />
    <div
      v-t="text"
      :style="{
        color,
      }"
    />
  </div>
</template>
<script>
import MDIIcon from '@/components/atoms/icons/MdiIcon.vue';

export default {
  name: 'ColoredIconText',
  components: {
    MDIIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#5627FF',
    },
    italic: {
      type: Boolean,
      default: false,
    },
    fontWeight: {
      type: (String || Number),
      default: 400,
    },
  },
};
</script>
