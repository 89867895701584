<template>
  <DGEApplication v-if="dgeSelectedApplication" />
  <DGEApplicationsDataTable v-else />
</template>

<script>
import DGEApplicationsDataTable from '@/components/organisms/digital_enrollment/ApplicationsDataTable.vue';
import DGEApplication from '@/components/organisms/digital_enrollment/ApplicationInfo.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DGEApplicationsPanel',
  components: { DGEApplicationsDataTable, DGEApplication },
  computed: {
    ...mapGetters({
      dgeSelectedApplication: 'digitalEnrollment/dgeSelectedApplication',
    }),
  },
};
</script>
