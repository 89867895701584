<template>
  <div class="subsection-container" :style="{ '--n-rows': 2 }">
    <div>
      <v-skeleton-loader
        v-if="!loaded"
        max-width="auto"
        max-height="160"
        type="image"
      />
      <Subsection
        v-else
        bg-color="transparent"
        :style="{
          'background-image':
            'url(' + require('@/assets/dashboard/widgets/explorer-widget.svg') + ')',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'border-radius': '5px',
          'background-position': 'center',
        }"
        flex="column"
        clickable
        @onClick="goTo('map')"
      >
        <div class="widget-tool">
          {{ $t('dashboard.tools.explorer') }}
        </div>
      </Subsection>
    </div>
    <!-- -------- CHILE SAE-------- -->

    <!-- <div v-if="isAdmission">
      <v-skeleton-loader
        v-if="!loaded"
        max-width="auto"
        max-height="160"
        type="image"
      />
      <Subsection
        v-if="isAdmission && loaded"
        target="popup"
        :style="{
          'background-image': 'url(' + require('@/assets/dashboard/widgets/sae-widget.svg') + ')',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'border-radius': '5px',
          'background-position': 'center',
        }"
        bg-color="transparent"
        flex="column"
        clickable
        @onClick="changeModal('sae')"
      >
        <div class="widget-tool">
          {{ $t('dashboard.tools.SAE') }}
        </div>
        <div class="widget-tool--des">
          {{ $t('dashboard.tools.SAE-des') }}
        </div>
      </Subsection>
    </div> -->
    <!-- <v-dialog
      v-model="showSae"
      :value="overlay"
      max-width="360"
      max-height="750"
      @click:outside="changeModal('sae')"
    >
      <iframe
        :title="$t('dashboard.tools.SAE')"
        :style="'overflow-y:hidden'"
        :width=" 360"
        :height=" 750"
        :scrolling="'yes'"
        frameborder="0"
        :src="reportsLink(0)"
        alt="reporte"
      />
    </v-dialog> -->

    <Subsection
      v-if="loaded && hasBrandBook"
      target="popup"
      bg-color="#F5F5F5"
      flex="column"
      clickable
      align="left"
      @onClick="goToDrive()"
    >
      <div class="widget-tool__brandbook">
        <TextAtom
          :value="$t('dashboard.tools.brandbook_title')"
          tag="label"
          font="poppins"
          color="primary-dark"
          weight="500"
          size="title"
        />
        <TextAtom
          :value="$t('dashboard.tools.brandbook_subtitle')"
          tag="label"
          font="inter"
          color="primary-600"
          weight="400"
          size="subtitle"
        />
      </div>
      <ImageAtom
        class="widget-tool__brandbook--image"
        :source="require('@/assets/dashboard/widgets/brandfolder-widget.svg')"
      />
    </Subsection>

    <!-- -------- PALMIRA POSTULACION PARCIAL-------- -->

    <!-- <div v-if="isAdmission">
      <v-skeleton-loader
        v-if="!loaded"
        max-width="auto"
        max-height="160"
        type="image"
      />
      <Subsection
        v-if="isAdmission && loaded"
        target="popup"
        bg-color="#F5F5F5"
        flex="column"
        clickable
        align="right"
        @onClick="changeModal('postulacion')"
      >
        <div class="widget-tool">
          {{ $t('dashboard.tools.postulacion') }}
        </div>
        <div class="widget-tool--des">
          {{ $t('dashboard.tools.postulacion-des') }}
        </div>
        <img
          style="
          position: absolute;
          bottom: 8px;
          right: 15px;
          width: 85%;
          height: 50%;
        "
          src="@/assets/dashboard/widgets/graph-app-widget.svg"
          alt="graph"
        />
      </Subsection>
    </div>
    <v-dialog
      v-model="showPostulacion"
      max-width="360"
      max-height="750"
      @click:outside="changeModal('postulacion')"
    >
      <iframe
        title="calendly"
        :style="'overflow-y:hidden'"
        :width=" 360"
        :height=" 750"
        :scrolling="'yes'"
        frameborder="0"
        :src="reportsLink(1)"
        alt="reporte"
        loading="lazy"
      />
    </v-dialog> -->

    <!-- -------- PALMIRA POSTULACION -------- -->

    <!-- <div v-if="isAdmission">
      <v-skeleton-loader
        v-if="!loaded"
        max-width="auto"
        max-height="160"
        type="image"
      />
      <Subsection
        v-if="isAdmission && loaded"
        target="popup"
        bg-color="#F5F5F5"
        flex="column"
        clickable
        @onClick="changeModal('postulacionResultados')"
      >
        <div class="widget-tool">
          {{ $t('dashboard.tools.postulacion-result') }}
        </div>
        <div class="widget-tool--des">
          {{ $t('dashboard.tools.postulacion-result-des') }}
        </div>
        <img
          style="position: absolute; bottom: 10px; left: 15px; width: 85%"
          src="@/assets/dashboard/widgets/graph2-widget.svg"
          alt="graph"
        />
      </Subsection>
    </div>
    <v-dialog
      v-model="showPostulacionResultados"
      max-width="360"
      max-height="750"
      @click:outside="changeModal('postulacionResultados')"
    >
      <iframe
        title="calendly"
        :style="'overflow-y:hidden'"
        :width=" 360"
        :height=" 750"
        :scrolling="'yes'"
        frameborder="0"
        :src="reportsLink(2)"
        alt="reporte"
        loading="lazy"
      />
    </v-dialog> -->

    <!-- -------- VISITAS USUARIOS -------- -->

    <!-- <div v-if="isAdmission">
      <v-skeleton-loader
        v-if="!loaded"
        max-width="auto"
        max-height="160"
        type="image"
      />
      <Subsection
        v-if="isAdmission && loaded"
        target="popup"
        bg-color="#F5F5F5"
        flex="column"
        align="right"
        clickable
        @onClick="changeModal('visitas')"
      >
        <div class="widget-tool">
          {{ $t('dashboard.tools.users_visits') }}
        </div>
        <div class="widget-tool--des">
          {{ $t('dashboard.tools.users_visits_des') }}
        </div>
        <img
          style="position: absolute; bottom: 10px; right: 15px; width: 85%"
          src="@/assets/dashboard/widgets/graph-users-widget.svg"
          alt="graph"
        />
      </Subsection>
    </div>
    <v-dialog
      v-model="showVisitas"
      max-width="1000"
      max-height="750"
      @click:outside="changeModal('visitas')"
    >
      <iframe
        title="calendly"
        :style="'overflow-y:hidden'"
        :width="1000"
        :height=" 750"
        :scrolling="'yes'"
        frameborder="0"
        :src="reportsLink(4)"
        alt="reporte"
        loading="lazy"
      />
    </v-dialog> -->

    <!-- Tools Stats -->
    <div
      @click="goTo('notifications')"
      @keydown="goTo('notifications')"
    >
      <v-skeleton-loader
        v-if="!loaded"
        max-width="auto"
        max-height="160"
        type="image"
      />
      <Subsection
        v-if="loaded"
        target="popup"
        bg-color="#F5F5F5"
        flex="column"
        clickable
        align="left"
      >
        <div class="widget-tool__messages">
          <div class="widget-tool__messages--title">
            {{ $t('dashboard.tools.messages') }}
          </div>
          <div class="widget-tool__messages--des color-admissions">
            {{ $t('dashboard.tools.messages-des') }}
          </div>
        </div>
        <img
          style="
          position: absolute;
          top: 15px;
          right: 15px;
          transform: translate(10%,0);
          width: 45%;
        "
          src="@/assets/dashboard/widgets/messages-widget.svg"
          alt="graph"
        />
      </Subsection>
    </div>
    <div v-for="(tool, index) in tools" :key="index">
      <Subsection bg-color="#F5F5F5" flex="column" class="section-tool">
        <img
          style="position: absolute; top: 0px; right: 0px"
          :src="require('@/assets/dashboard/widgets/' + tool.icon)"
          alt="tool"
        />
        <div :class="mobile ? 'tool-data top-tool loader-dashboard' : 'tool-data loader-dashboard'">
          <v-skeleton-loader
            v-if="!loaded"
            max-width="auto"
            max-height="220"
            type="button"
          />
          <div v-else>
            {{ tool.data }}
          </div>
        </div>
        <div class="tool-title">
          {{ tool.title }}
        </div>
      </Subsection>
    </div>
  </div>
</template>

<script>
// import CONFIG from '@/config';
import trackMixPanel from '@/utils/mixpanel';
import { mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import ImageAtom from '@/components/atoms/images/Image.vue';
import Subsection from './Subsection.vue';

export default {
  name: 'ToolsHeadmaster',
  components: { Subsection, TextAtom, ImageAtom },
  metaInfo: {
    meta: [],
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // showPostulacion: false,
      // showPostulacionResultados: false,
      // showVisitas: false,
      // showSae: false,
      views: 0,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    // isAdmission() {
    //   const campusAdmissionSystem = this.campusDetail?.campus_admission_system[0];
    //   return (campusAdmissionSystem?.admission_system_label.id === 1 && CONFIG.tenant !== 'dom');
    // },
    hasBrandBook() {
      const configCampus = this.campusDetail?.config_campus[0];
      return (configCampus?.campus_brandfolder ?? false)
      && configCampus?.campus_tether_pack;
    },
    tools() {
      const tools = [
        {
          data: this.views,
          title: this.$t('dashboard.tools.visit_profile'),
          icon: 'visits-stats.svg',
        },
      ];
      return tools;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.views = this.setViewsNum();
  },
  methods: {
    // reportsLink(i) {
    //   const reports = [
    //     `https://reports-testing-v2.s3.amazonaws.com/CHL-01/reports/new/${this.campusDetail?.institution_code}/Reporte+${this.campusDetail?.institution_code}.html`,
    //     `https://reports-testing-v2.s3.amazonaws.com/COL-01/postulaciones+parciales+2022/${this.campusDetail?.campus_code}/Reporte+${this.campusDetail?.campus_code}.html`,
    //     `https://reports-testing-v2.s3.amazonaws.com/COL-01/resultado+postulaciones+2021/Reporte+${this.campusDetail?.campus_code}.html`,
    //     `https://reports-testing-v2.s3.amazonaws.com/PAL-01/reports/Reporte+${this.campusDetail?.campus_code}.html`,
    //     `https://reports-testing-v2.s3.amazonaws.com/CHL-02/reports/Reporte+${this.campusDetail.campus_code}.html`,
    //   ];
    //   return reports[i];
    // },
    // changeModal(modal) {
    //   if (modal === 'postulacion') {
    //     this.showPostulacion = !this.showPostulacion;
    //   }
    //   if (modal === 'postulacionResultados') {
    //     this.showPostulacionResultados = !this.showPostulacionResultados;
    //   }
    //   if (modal === 'visitas') {
    //     this.showVisitas = !this.showVisitas;
    //   }
    //   if (modal === 'sae') {
    //     this.showSae = !this.showSae;
    //   }
    // },
    setViewsNum() {
      const listOfEvents = this.campusDetail?.num_general_views ?? [];
      const totalViews = listOfEvents?.agrupado?.filter(({ event }) => event === 'click_campus_card' || event === 'open_digital_profile_from_url')
        ?.reduce((acc, { total }) => acc + total, 0) ?? 0;

      return totalViews;
    },
    goTo(page) {
      this.$router.push(page);
      if (page === 'digital-profile') {
        trackMixPanel('click_dashboard_submodule_school_community');
      }
      if (page === 'gallery') {
        trackMixPanel('click_dashboard_submodule_gallery');
      }
    },
    goToDrive() {
      const drive = this.campusDetail?.config_campus[0]?.campus_brandfolder;
      window.open(drive, '_blank').focus();
    },
  },
};
</script>
