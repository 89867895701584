<template>
  <div
    class="dge-application"
    :class="{
      'dge-application--mobile': mobile,
    }"
  >
    <MainHeader
      :header-content="['mainTitle']"
      :main-title-text="'digital_enrollment.application.titles.main'"
      class="dge-application__header"
    />
    <div
      class="dge-application__content"
      :class="{
        'dge-application__content--mobile': mobile,
      }"
    >
      <div
        class="
          dge-application__content__column
          dge-application__content__column--small
          dge-application__content__column--with-background
        "
        :class="{
          'dge-application__content__column--mobile': mobile,
        }"
      >
        <ResumedAdmissionState
          :admission="admission"
          column
          hide-address-icon
          hide-status
        />
      </div>
      <div
        class="
          dge-application__content__column
          dge-application__content__column--large
        "
        :class="{
          'dge-application__content__column--mobile': mobile,
        }"
      >
        <!-- TODO: Figure out how to atomize the case of steps with actions -->
        <MainTitle
          :text="'digital_enrollment.application.titles.current_state'"
          left-align
          small
        />
        <ApplicationStep
          :key="`${currentStateKey}-${admission.id}`"
          :step="currentStateKey"
          :notifications="getNotifications(currentStateKey)"
          :action="currentState.action"
          :subtitle-args="currentState.subtitleArgs"
          :step-provider-data="currentState.provider"
          :loading="currentState.loading"
        />
        <ApplicationStepsGroup
          v-if="pendingProcesses.length > 0"
          :title-text="'digital_enrollment.application.titles.pending_steps'"
          :steps="pendingProcesses"
          :counter-icon="'mdi-clock-outline'"
          :counter-type="'error'"
        />
        <ApplicationStepsGroup
          v-if="completedProcesses.length > 0"
          :title-text="'digital_enrollment.application.titles.completed_steps'"
          :steps="completedProcesses"
          :counter-icon="'mdi-check-circle-outline'"
          :counter-type="'primary'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import MainHeader from '@/components/molecules/sections/MainHeader.vue';
import ApplicationStep from '@/components/organisms/digital_enrollment/ApplicationStep.vue';
import ApplicationStepsGroup from '@/components/organisms/digital_enrollment/ApplicationStepsGroup.vue';
import ResumedAdmissionState from '@/components/organisms/digital_enrollment/ResumedAdmissionState.vue';
import CONFIG from '@/config';
import { redirectToDGE } from '@/utils/redirects';
import { ADMISSION_STATUS } from '@/constants/digitalEnrollment';

export default {
  name: 'DGEApplicationInfo',
  components: {
    ApplicationStep, ApplicationStepsGroup, MainHeader, MainTitle, ResumedAdmissionState,
  },
  computed: {
    ...mapGetters({
      admission: 'digitalEnrollment/dgeSelectedApplication',
      lastSuccessfulPayment: 'digitalEnrollment/applicationPaymentTime',
      sessionUUID: 'authentication/sessionUUID',
      legalGuardian: 'authentication/legalGuardian',
      applicationPaymentStatus: 'digitalEnrollment/applicationPaymentStatus',
      applicationMetadata: 'digitalEnrollment/applicationMetadata',
    }),
    metadataLoaded() {
      return this.applicationMetadata && this.applicationMetadata.id === this.admission.id;
    },
    steps() {
      return [
        { name: ADMISSION_STATUS.CREATED, milestone: true },
        { name: ADMISSION_STATUS.CANCELLED },
        {
          name: ADMISSION_STATUS.NON_SCHEDULED,
          next: ADMISSION_STATUS.SCHEDULED,
          action: () => this.triggerAdmissionStepRedirect('schedule'),
          provider: { key: 'calendly', type: 'scheduling', hasLogo: true },
        },
        {
          name: ADMISSION_STATUS.SCHEDULED,
          next: ADMISSION_STATUS.SCHEDULED,
          action: this.interviewAction,
          provider: this.meetingProvider,
          subtitleArgs: this.applicationMetadata?.interview?.date,
        },
        {
          name: ADMISSION_STATUS.INTERVIEWING,
          milestone: true,
          next: ADMISSION_STATUS.WAITING_CONFIRMATION,
          action: this.interviewAction,
          provider: this.meetingProvider,
          subtitleArgs: this.applicationMetadata?.interview?.date,
        },
        {
          name: ADMISSION_STATUS.WAITING_CONFIRMATION,
          next: ADMISSION_STATUS.CONFIRMED,
          action: () => this.triggerAdmissionStepRedirect('confirmation', ADMISSION_STATUS.WAITING_SIGNATURE),
        },
        { name: ADMISSION_STATUS.CONFIRMED, milestone: true },
        {
          name: ADMISSION_STATUS.WAITING_SIGNATURE,
          next: ADMISSION_STATUS.CONTRACT_SIGNED,
          action: this.contractAction,
          provider: { key: 'pandadoc', type: 'contract', hasLogo: this.metadataLoaded },
        },
        { name: ADMISSION_STATUS.CONTRACT_SIGNED, milestone: true },
        {
          name: ADMISSION_STATUS.WAITING_PAYMENT,
          next: ADMISSION_STATUS.PAID,
          action: this.paymentAction,
          loading: this.waitingPaymentConfirmation(),
        },
        { name: ADMISSION_STATUS.PAID, milestone: true },
        {
          name: ADMISSION_STATUS.FILLING_ENROLLMENT_FORM,
          next: ADMISSION_STATUS.ENROLLMENT_FORM_FILLED,
          action: this.triggerEnrollmentForm,
        },
        { name: ADMISSION_STATUS.ENROLLMENT_FORM_FILLED, milestone: true },
        { name: ADMISSION_STATUS.COMPLETED, milestone: true },
        {
          name: ADMISSION_STATUS.WAITING_FULL_PAYMENT,
          next: ADMISSION_STATUS.FULLY_PAID,
          action: this.paymentAction,
          loading: this.waitingPaymentConfirmation(),
        },
        { name: ADMISSION_STATUS.FULLY_PAID, milestone: true },
      ];
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    paymentAction() {
      return this.triggerPayment;
    },
    contractAction() {
      const { contract: { link } = {} } = this.applicationMetadata || {};

      if (link) {
        return () => {
          window.open(link, '_blank');
          this.pollApplicationStatus({
            applicationId: this.admission.id,
            expectedStatus: ADMISSION_STATUS.WAITING_PAYMENT,
          });
        };
      }
      return null;
    },
    meetingProvider() {
      const providers = {
        google_conference: {
          key: 'google_conference',
          type: 'meetings',
          remote: true,
          hasLogo: this.metadataLoaded,
        },
        zoom_conference: {
          key: 'zoom_conference',
          type: 'meetings',
          remote: true,
          hasLogo: this.metadataLoaded,
        },
        on_site: {
          key: 'on_site',
          type: 'meetings',
          remote: false,
          hasLogo: false,
        },
      };

      return providers[this.applicationMetadata?.interview?.location?.type] || providers.on_site;
    },
    interviewAction() {
      const meetingURL = this.applicationMetadata?.interview?.location?.join_url;
      if (this.meetingProvider.remote && meetingURL) {
        return () => {
          window.open(meetingURL, '_blank');
          this.pollApplicationStatus({ applicationId: this.admission.id, intervalTime: 10000 });
        };
      }
      return undefined;
    },
    hasPendingPayment() {
      return this.applicationPaymentStatus && this.applicationPaymentStatus.status !== ADMISSION_STATUS.PAID;
    },
    currentStateKey() {
      const current = this.admission.status;
      if (current === ADMISSION_STATUS.COMPLETED && this.hasPendingPayment) {
        return ADMISSION_STATUS.WAITING_FULL_PAYMENT;
      }
      return current;
    },
    currentStateIndex() {
      return this.steps.findIndex(
        ({ name }) => name === this.currentStateKey,
      );
    },
    currentState() {
      return this.steps[this.currentStateIndex] || {};
    },
    completedProcesses() {
    // FIXME: In an ideal world, this should come from the API
    // return this.admission.completedProcesses;

      return this.steps.slice(0, this.currentStateIndex + 1).reduce((acc, step) => {
        if (step.milestone) {
          return [...acc, step];
        }
        return acc;
      }, []);
    },
    pendingProcesses() {
    // FIXME: In an ideal world, this should come CORRECTLY from the API (the following logic is very hardcoded)
      const nextPendingProcess = this.currentState.next;

      const hardcodedExtraMilestones = this.hasPendingPayment ? [ADMISSION_STATUS.FULLY_PAID] : [];
      return this.steps.slice(this.currentStateIndex + 1, this.steps.length + 1).reduce((acc, step) => {
        if (
          step.milestone
          && step.name !== nextPendingProcess
          && (this.admission.pendingProcesses.includes(step.name) || hardcodedExtraMilestones.includes(step.name))
        ) {
          return [...acc, step];
        }
        return acc;
      }, []);
    },
  },
  created() {
    this.setBreadcrumbs({
      breadcrumbs: [
        {
          text: 'dashboard.breadcrumbs.panel_selected',
          route: '/dashboard',
        },
        {
          text: 'dashboard.breadcrumbs.applications',
          route: '/digital-enrollment',
        },
        {
          text: 'digital_enrollment.application.titles.main',
          route: this.$route.path,
        },
      ],
    });
  },
  beforeDestroy() {
    this.endStatusPolling();
  },
  methods: {
    ...mapActions({
      setBreadcrumbs: 'utils/setBreadcrumbs',
      activateModal: 'utils/activateModal',
      pollApplicationStatus: 'digitalEnrollment/pollApplicationStatus',
      endStatusPolling: 'digitalEnrollment/endStatusPolling',
    }),
    getNotifications(process) { // eslint-disable-line no-unused-vars
      // TODO: NOTIFICATIONS (payment, signature, etc.)
      return null;
    },
    waitingPaymentConfirmation() {
      return !!(this.lastSuccessfulPayment && moment(this.lastSuccessfulPayment).add(5, 'minutes') > moment());
    },
    async triggerPayment() {
      const paymentId = this.applicationMetadata?.payment.transactionId;
      if (!paymentId) {
        return;
      }
      // 5 minutes since last attempt
      const hasSuccessfulAttempt = this.currentState.loading;
      const callbacks = {
        onConfirm: () => this.$router.push({ name: 'Payment', params: { id: paymentId } }),
      };
      this.activateModal({ name: 'PaymentConfirmation', data: { paymentId, hasSuccessfulAttempt }, callbacks });
    },
    async triggerEnrollmentForm() {
      const { formId } = this.applicationMetadata?.survey || {};

      if (!formId) {
        return;
      }

      const rootFormURL = `https://tethereducation.qualtrics.com/jfe/form/${formId}`;

      const {
        id: admissionId,
        applicant: { name: applicantName },
      } = this.admission;

      const urlParams = new URLSearchParams();
      urlParams.append('externalDataReference', admissionId);
      urlParams.append('admissionId', admissionId);
      urlParams.append('name_applicant', applicantName);
      urlParams.append('country', CONFIG.tenant);
      urlParams.append('user', this.sessionUUID);
      urlParams.append('name_user', this.legalGuardian.firstName);

      const paramsString = urlParams.toString();
      const toURL = `${rootFormURL}${paramsString ? `?${paramsString}` : ''}`;
      window.open(toURL, '_blank');
      this.pollApplicationStatus({ applicationId: admissionId });
    },
    async triggerAdmissionStepRedirect(step, nextExpected = null) {
      // /admission/:admissionId/:step

      redirectToDGE({
        path: `admission/${this.admission.id}/${step}`,
        query: { tenantId: this.admission.campus.code },
        newTab: true,
      });
      this.pollApplicationStatus({ applicationId: this.admission.id, expectedStatus: nextExpected });
    },
  },
};
</script>
