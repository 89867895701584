<template>
  <v-chip
    label
    class="student-chip"
    :class="{
      'student-chip--elevated': elevated,
      'student-chip--clickable': clickable,
      clickable: clickable,
    }"
    :color="color"
    :ripple="clickable"
    @click="onInteraction"
    @keydown.enter="onInteraction"
  >
    <!-- Should this be a molecule, so we use out icon-loading component? -->
    <v-img
      class="student-chip__icon"
      width="24"
      :src="require('@/assets/dashboard/icons/student-icon.svg')"
      alt="Student Icon"
    />
    <span
      class="student-chip__name"
      :style="{
        'font-weight': elevated ? 500 : 'normal',
      }"
    >
      {{ studentName }}
    </span>
    <slot name="menu" />
  </v-chip>
</template>

<script>
export default {
  name: 'StudentChip',
  props: {
    studentName: {
      type: String,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
    elevated: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onInteraction({ type: event }) {
      if (this.clickable) {
        this.$emit(event);
      }
    },
  },
};
</script>
