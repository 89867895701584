<template>
  <div :class="mobile ? 'dashboard-mobile' : 'dashboard'">
    <BaseSnackbar />
    <!-- side bar -->
    <div v-if="!mobile" class="dashboard__sidebar">
      <CustomMenu in-dashboard />
    </div>
    <v-dialog
      v-if="mobile"
      v-model="showNavigationBar"
      max-width="150"
      transition="slide-x-transition"
      content-class="main-container__row__dialog"
    >
      <CustomMenu in-dashboard @closeMenu="setMenuVisibility(false)" />
    </v-dialog>
    <!-- end side bar -->
    <MobileExplorerHeader
      v-if="mobile"
      :show-campus-search="!isLegalGuardian"
      :logo="'inline-tether-logo.svg'"
      @toggleMenu="setMenuVisibility(true)"
    />
    <DashboardNavbar
      v-else
      @menuClick="setMenuVisibility(true)"
      @setLoading="setLoading"
      @setChange="setHeadMasterCurrentSchool"
    />
    <div
      :key="loaded"
      :class="
        mobile ? 'dashboard-section-group-mobile' : 'dashboard-section-group'
      "
      :style="{
        '--grid-area-component': gridAreaComponent,
        '--grid-rows-component': gridRows,
      }"
    >
      <Sections
        v-for="(section, index) in sections"
        v-show="section.show === undefined || section.show"
        v-bind="section"
        :key="section.key || index"
        :loaded="loaded"
        v-on="$listeners"
        @show-explorer-meeting-modal="showExplorerMeetingModal = true;"
      />
    </div>
    <v-dialog
      v-model="headMasterFirstLogin"
      content-class="elevation-0 mt-0"
      max-width="360"
      @click:outside="closeAlertModal()"
    >
      <Alert
        :title="$t('views.login.alert.title')"
        :text="$t('views.login.alert.text')"
        :btn1="false"
        :btn2="$t('views.login.alert.btnText')"
        :btn-path="'resetPassword'"
        @close="closeAlertModal()"
      />
    </v-dialog>
    <v-dialog
      v-model="showExplorerMeetingModal"
      :content-class="mobile ? 'user-dialog-mobile' : 'user-dialog'"
      transition="dialog-bottom-transition"
      :max-width="'480'"
    >
      <DashboardCalendar
        out-of-register
        style="padding: 32px 28px 32px 28px;"
        :calendar-url="explorerMeetingUrl"
        @closeModal="showExplorerMeetingModal = false;"
      />
    </v-dialog>
  </div>
</template>
<script>
import Alert from '@/components/dashboard/utils/Alert.vue';
import BaseSnackbar from '@/components/explorer/base/BaseSnackbar.vue';
import DashboardNavbar from '@/components/organisms/dashboard/DashboardNavbar.vue';
import DashboardCalendar from '@/components/organisms/register/CalendarModal.vue';
import MobileExplorerHeader from '@/layouts/MobileExplorerHeader.vue';
import CONFIG from '@/config';
import { mapActions, mapGetters } from 'vuex';
import CustomMenu from '../../layouts/CustomMenu.vue';
import Sections from './Sections.vue';

export default {
  name: 'DashboardTemplate',
  components: {
    CustomMenu,
    Sections,
    BaseSnackbar,
    Alert,
    DashboardCalendar,
    DashboardNavbar,
    MobileExplorerHeader,
  },
  props: {
    sections: {
      /* sections to be displayed in the sections part of the template. */
      required: true,
      type: Array,
    },
    gridAreaComponent: {
      /* grid area of the component. */
      required: true,
      type: String,
    },
    gridRows: {
      /* grid rows of the component. */
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      loaded: true,
      showNavigationBar: false,
      showExplorerMeetingModal: false,
      explorerMeetingUrl: `${CONFIG.ambassadorCalendly}?month=2022-07?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=1a0c4c&primary_color=1a0c4c" style="min-width:360px;height:630px;`,
    };
  },
  computed: {
    ...mapGetters({
      headMasterFirstLogin: 'roles/headMasterFirstLogin',
      isHeadMaster: 'roles/isHeadMaster',
      isAmbassador: 'roles/isAmbassador',
      isLegalGuardian: 'roles/isLegalGuardian',
      currentHeadMasterSchools: 'roles/currentHeadMasterSchools',
      currentHeadMasterMainSchool: 'roles/currentHeadMasterMainSchool',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    schools() {
      if (!this.currentHeadMasterSchools) {
        return [];
      }
      return this.currentHeadMasterSchools.map((item, index) => ({
        label: `${item.campus_name}`,
        campus_uuid: item.uuid,
        value: index,
      }));
    },
  },
  methods: {
    ...mapActions({
      setHeadMasterMainSchool: 'roles/setHeadMasterMainSchool',
      setHeadMasterFirstLogin: 'roles/setHeadMasterFirstLogin',
      retrieveCampusDetails: 'institutions/retrieveCampusDetails',
    }),
    setLoading(bool) {
      this.loaded = bool;
    },
    setHeadMasterCurrentSchool(index) {
      if (index !== null) {
        this.loaded = false;
        const campusUUID = this.schools[index].campus_uuid;
        if (!('id' in this.currentHeadMasterSchools[index])) {
          this.retrieveCampusDetails({ searchCode: campusUUID }).then((campusDetails) => {
            this.currentHeadMasterSchools[index] = campusDetails;
            this.setHeadMasterMainSchool({ mainSchool: this.currentHeadMasterSchools[index] }).then(
              () => {
                this.loaded = true;
              },
            );
          });
        } else {
          this.setHeadMasterMainSchool({ mainSchool: this.currentHeadMasterSchools[index] }).then(
            () => {
              this.loaded = true;
            },
          );
        }
      }
    },
    closeAlertModal() {
      this.setHeadMasterFirstLogin({ bool: false });
    },
    /* sets menu visibility */
    setMenuVisibility(visibility) {
      this.showNavigationBar = visibility;
    },
  },
};
</script>
