const INTEGRATION_METHODS = Object.freeze({
  WIDGET: 'embedded_widget',
  REDIRECTION: 'redirection',
});

const PAYMENT_STATES = Object.freeze({
  CREATED: 'created',
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  CANCELED: 'canceled',
});

export { INTEGRATION_METHODS, PAYMENT_STATES };
