<template>
  <div class="dashboard-section-content">
    <span class="dashboard-instruction-text" style="width: calc(100% - 2px)">
      {{ $t('dashboard.digital_profile.faq.title') }}
      <v-icon color="#0D22A8" name="dragger"> mdi-drag </v-icon>.
    </span>
    <EditableGroupList
      :form-array="array"
      :fields="fields"
      title-var-name="question"
      :loading="btnLoading"
      @confirm="setBody"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import EditableGroupList from '../../utils/EditableGroupList.vue';

export default {
  name: 'AdmissionFrequentlyAskedQuestions',
  components: { EditableGroupList },
  data() {
    return {
      array: [],
      btnLoading: false,
      fields: [
        {
          type: 'text',
          title: this.$t('dashboard.digital_profile.faq.question'),
          varName: 'question',
          input: '',
          fieldProps: {
            maxLength: 600,
            counter: 600,
          },
        },
        {
          type: 'text',
          title: this.$t('dashboard.digital_profile.faq.answer'),
          varName: 'answer',
          input: '',
          fieldProps: {
            maxLength: 600,
            counter: 600,
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      currentHeadMasterSchools: 'roles/currentHeadMasterSchools',
    }),
  },
  watch: {
    campusDetail: {
      deep: true,
      handler() {
        this.setFaqsArray();
      },
    },
  },
  mounted() {
    this.setFaqsArray();
  },
  methods: {
    ...mapActions({
      campusFaqsPatch: 'institutions/campusFaqsPatch',
    }),
    setBody(patchData) {
      this.btnLoading = true;
      const faqsData = [];
      patchData.forEach((faq) => {
        faqsData.push({
          id: faq.id ?? null,
          answer: faq.answer,
          order: faq.order,
          question: faq.question,
        });
      });
      this.campusFaqsPatch({ campusUuid: this.campusDetail.uuid, campusData: faqsData })
        .then(() => {
          this.btnLoading = false;
        });
    },
    setFaqsArray() {
      if (this.campusDetail.frequently_asked_questions) {
        this.array = this.campusDetail.frequently_asked_questions;
      } else {
        this.array = [];
      }
    },
  },
};
</script>
