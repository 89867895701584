<template>
  <div>
    <TextAtom
      class="title-sections__location"
      :value="$t('dashboard.digital_profile.testimonials.title')"
      tag="label"
      font="poppins"
      color="primary-dark"
      weight="600"
      size="title"
    />
    <TextAtom
      class="des-sections__location mb-5 d-flex left-text"
      :value="$t('dashboard.digital_profile.testimonials.info')"
      tag="label"
      font="inter"
      color="neutral-800"
      weight="400"
      size="subtitle"
    />
    <Draggable
      v-model="testimonialsArray"
      handle="[name=dragger]"
      force-callback
    >
      <div v-for="(testimonial, index) in testimonialsArray" :key="testimonial.label_id">
        <div class="contacts-dashboard">
          <TestimonialSingle
            :key="index"
            :testimonial="testimonial"
            :testimonials-array="testimonialsArray"
            :index="index"
            @deleteTestimonial="deleteTestimonial"
            @setKeyValue="setKeyValueAtPosition(index, ...arguments)"
          />
        </div>
      </div>
    </Draggable>
    <div
      v-if="testimonialsArray.length <= testimonialsLabel.length"
      class="d-flex left-text clickable"
      style=""
      @click="add"
      @keydown="add"
    >
      <SVGIcon
        icon="more-border.svg"
        size="20"
      />
      <TextAtom
        class="ml-3 clickable"
        :value="$t('dashboard.digital_profile.testimonials.add_extra')"
        tag="label"
        font="inter"
        color="primary-dark"
        weight="400"
        size="normal"
      />
    </div>
    <SaveButton :loading="loading" @save="submitTestimonials" />
  </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import { mapActions, mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import SaveButton from '../../utils/SaveButton.vue';
import TestimonialSingle from './TestimonialSingle.vue';

export default {
  name: 'TestimonialsSection',
  components: {
    TextAtom,
    SaveButton,
    Draggable,
    TestimonialSingle,
    SVGIcon,
  },
  data() {
    return {
      loading: false,
      testimonialsArray: [],
      testimonialsId: [],
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      testimonialsLabel: 'options/testimonialsLabel',
    }),
  },
  mounted() {
    const testimonialesOrdered = this.campusDetail.audiovisual_campus
      .sort((a, b) => a.audiovisual_order - b.audiovisual_order);
    this.setTestimonialsArray(testimonialesOrdered);
  },
  methods: {
    ...mapActions({
      campusAudiovisualsPatch: 'institutions/campusAudiovisualsPatch',
    }),
    setTestimonialsArray(testimonials) {
      this.testimonialsId = this.testimonialsLabel.map((testimonial) => testimonial.id);
      // if testimonials.audiovisual_label.id is equal to some of the labels in testimonialsLabel
      this.testimonialsArray = testimonials
        .filter((testimonial) => this.testimonialsId.includes(testimonial.audiovisual_label.id))
        .map((testimonial) => ({
          label_id: testimonial.audiovisual_label.id,
          name: testimonial.name,
          audiovisual_link: testimonial.audiovisual_link,
          audiovisual_order: testimonial.audiovisual_order,
        }));
    },
    add() {
      this.testimonialsArray.push({
        label_id: 0,
        name: '',
        audiovisual_link: '',
        audiovisual_order: 0,
      });
    },
    deleteTestimonial(index) {
      this.testimonialsArray.splice(index, 1);
    },
    setKeyValueAtPosition(index, key, value) {
      this.testimonialsArray[index][key] = value;
    },
    submitTestimonials() {
      this.loading = true;
      const testimonials = this.testimonialsArray.filter((testimonial) => testimonial.label_id !== 0)
        .map((testimonial, index) => ({
          label_id: testimonial.label_id,
          name: testimonial.name,
          audiovisual_link: testimonial.audiovisual_link,
          audiovisual_order: index,
        }));

      const order = {};
      const updatedData = testimonials.map((testimonial) => {
        const newItem = testimonial;
        const labelId = testimonial.label_id;
        if (!(labelId in order)) {
          order[labelId] = 0;
        }
        newItem.audiovisual_order = order[labelId];
        order[labelId] += 1;
        return newItem;
      });

      this.campusDetail.audiovisual_campus?.forEach((audiovisual) => {
        if (!this.testimonialsId.includes(audiovisual.audiovisual_label.id)) {
          updatedData.push(
            {
              label_id: audiovisual.audiovisual_label.id,
              audiovisual_link: audiovisual.audiovisual_link,
              name: audiovisual.name,
              audiovisual_order: audiovisual.audiovisual_order,
            },
          );
        }
      });
      this.campusAudiovisualsPatch({ campusUuid: this.campusDetail.uuid, campusData: updatedData }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
