<template>
  <v-container>
    <v-row no-gutters class="alert-modal" justify="center">
      <img
        style="z-index: 500; cursor: pointer"
        class="alert-modal-background alert-modal-background--close"
        src="@/assets/iconsChile/backgrounds/close.svg"
        alt="close"
        @click="closeModal"
        @keydown="closeModal"
      />
      <div>
        <img
          class="alert-modal-background alert-modal-background--top"
          :src="require('@/assets/iconsChile/backgrounds/list.svg')"
          alt="list"
        />
        <img
          class="alert-modal-background alert-modal-background--bottom"
          :src="require('@/assets/iconsChile/backgrounds/list2.svg')"
          alt="list"
        />
      </div>
      <div class="alert-modal__div ma-4">
        <div v-t="title" class="alert-modal__div__title" />
        <div v-t="text" class="alert-modal__div__text mt-5" />
        <div class="alert-modal__btn_container pt-5">
          <BaseButton v-if="btn1" v-t="btn1" class="btn-continue mr-5" @click="mainBtnClick" />
          <BaseButton v-t="btn2" class="btn-continue" @click="altBtnClick" />
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import BaseButton from '../../explorer/base/BaseButton.vue';

export default {
  name: 'WarningModal',
  components: {
    BaseButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    btn1: {
      type: [String, Boolean],
      default: false,
    },
    btn2: {
      type: [String, Boolean],
      default: false,
    },
    btnPath: {
      type: String,
      default: '',
    },
  },
  computed: {
  },
  methods: {
    ...mapActions({
      resetHeadMasterPassword: 'authentication/resetHeadMasterPassword',
    }),
    closeModal() {
      this.$emit('close');
    },
    altBtnClick() {
      if (this.btnPath === 'resetPassword') {
        this.resetHeadMasterPassword().then(() => {
          this.$router.replace({ name: 'Reset Password' });
        });
      }
    },
    mainBtnClick() {},
  },
};
</script>
