<template>
  <div
    class="dashboard-section-content admissions-container d-flex flex-row"
  >
    <img
      src="@/assets/dashboard/widgets/analytics-widget.svg"
      class="analytics-widget"
      alt="graph"
    />
    <div class="text-left analytics-widget--text">
      {{ $t("dashboard.analytics.description") }}
    </div>
    <v-btn
      :loading="!loaded"
      class="clickable digital-profile-button"
      @click="goTo('analytics')"
      @keydown="goTo('analytics')"
    >
      {{ $t("dashboard.analytics.enter") }}
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'AnalyticsSection',
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goTo(page) {
      this.$router.push(page);
    },
  },
};
</script>
