<template>
  <div>
    <div class="title-sections__location">
      {{ $t('dashboard.digital_profile.general_info.title') }}
    </div>
    <div class="mb-2">
      <span class="institution-name institution-name--first">
        {{ $t('dashboard.digital_profile.general_info.institution-name') }}
      </span>
      <EditableGroup
        :fields="[
          {
            type: 'text',
            title: $t('dashboard.digital_profile.general_info.institution-long-name'),
            varName: 'institution_name',
            dense: true,
            input: getInstitutionName(),
            fieldProps: {
              maxLength: 100,
              counter: 100,
            },
          },
        ]"
        @setObject="setBody"
      />
      <EditableGroup
        :fields="[
          {
            type: 'text',
            title: $t('dashboard.digital_profile.general_info.institution-short-name'),
            varName: 'institution_short_name',
            dense: true,
            input: getInstitutionShortName(),
            fieldProps: {
              maxLength: 30,
              counter: 30,
            },
          },
        ]"
        @setObject="setBody"
      />
    </div>
    <div class="mb-5">
      <span class="institution-name">
        {{ $t('dashboard.digital_profile.general_info.campus') }}
      </span>
      <EditableGroup
        :fields="[
          {
            type: 'text',
            title: $t('dashboard.digital_profile.general_info.campus-name'),
            dense: true,
            varName: 'campus_name',
            input: getCampusName(),
            fieldProps: {
              maxLength: 100,
              counter: 100,
            },
          },
        ]"
        @setObject="setBody"
      />
      <EditableGroup
        :fields="[
          {
            type: 'text',
            title: $t('dashboard.digital_profile.general_info.campus-short-name'),
            dense: true,
            varName: 'campus_short_name',
            input: getCampusShortName(),
            fieldProps: {
              maxLength: 30,
              counter: 30,
            },
          },
        ]"
        @setObject="setBody"
      />
    </div>
    <SaveButton :loading="saving" :is-disabled="isActive" @save="save" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'GeneralInfo',
  components: { EditableGroup, SaveButton },
  data() {
    return {
      saving: false,
      body: Object(),
      isActive: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      currentHeadMasterSchools: 'roles/currentHeadMasterSchools',
    }),
  },
  async mounted() {
    await this.retrieveOptions({ reset: false });
    this.body.campus_name = this.campusDetail.campus_name;
  },
  methods: {
    ...mapActions({
      campusGeneralInformationPatch: 'institutions/campusGeneralInformationPatch',
      retrieveOptions: 'options/retrieveOptions',
    }),
    getInstitutionName() {
      return this.campusDetail?.institution_name ?? '';
    },
    getInstitutionShortName() {
      return this.campusDetail?.institution_short_name ?? '';
    },
    getCampusName() {
      return this.campusDetail?.campus_name ?? '';
    },
    getCampusShortName() {
      return this.campusDetail?.campus_short_name ?? '';
    },
    setBody(body) {
      const index = Object.keys(body)[0];
      const indexInfo = Object.values(body)[0];
      this.body[index] = indexInfo;
      this.isActive = this.body.institution_name === '' || this.body.campus_name === '';
    },
    async save() {
      this.saving = true;
      await this.campusGeneralInformationPatch({ campusUuid: this.campusDetail.uuid, campusData: this.body });
      this.saving = false;
    },
  },
};
</script>
