<template>
  <div class="dashboard-section-content">
    <DigitalProfileModal :current-modal="currentModal" @setModal="setModal" />
    <!-- module -->
    <div
      ref="digitalProfile"
      style="height: calc(100% - 50px); max-width: 100%; width: 100%"
    >
      <!-- gallery -->
      <CenteredVideo
        v-if="videoWidth && videoHeight"
        class="half-video"
        :width="videoWidth"
        :height="videoHeight"
        :video-i-d="null"
        video-u-r-l="https://vimeo.com/674421261/c504c5ee57"
        :time-range="[14, 19]"
        :extra-options="{ muted: true }"
        :title="$t('dashboard.digital_profile.marketing_message1')"
      />
      <!-- infrastructure -->
      <CenteredVideo
        v-if="videoWidth && videoHeight"
        class="half-video-right"
        :width="videoWidth"
        :height="videoHeight"
        :video-i-d="null"
        video-g-i-f="https://explorer-public-assets.s3.amazonaws.com/chile/explorer-assets/dashboard/video_proyecto_educativo.gif"
        :extra-options="{ muted: true }"
        :time-range="[0.4, 6]"
        :title="$t('dashboard.digital_profile.marketing_message2')"
      />
    </div>
    <v-btn
      class="clickable"
      :class="
        mobile ? 'digital-profile-button__mobile' : 'digital-profile-button'
      "
      :loading="!loaded"
      @click="showCalendar()"
      @keydown="showCalendar()"
    >
      {{ $t("dashboard.digital_profile.more-profile") }}
    </v-btn>
    <!-- end module -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CenteredVideo from '@/components/dashboard/modals/utils/CenteredVideo.vue';
import DigitalProfileModal from '@/components/dashboard/modals/DigitalProfileModal.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'DigitalProfile',
  components: {
    CenteredVideo,
    DigitalProfileModal,
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoWidth: false,
      videoHeight: false,
      currentModal: null,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    mobile() {
      return this.$vuetify.breakpoint.width < 670;
    },
  },
  mounted() {
    this.getVideoWidth();
    this.getVideoHeight();
  },
  methods: {
    setModal(modal) {
      this.currentModal = modal;
      if (modal === 'information') {
        trackMixPanel('click_dashboard_module_digital_profile');
      }
    },
    getVideoWidth() {
      this.$nextTick(() => {
        this.videoWidth = this.$refs.digitalProfile.clientWidth / 2;
      });
    },
    getVideoHeight() {
      this.$nextTick(() => {
        this.videoHeight = this.$refs.digitalProfile.clientHeight;
      });
    },
    showCalendar() {
      trackMixPanel('click_dashboard_module_digital_profile');
      this.$emit('show-explorer-meeting-modal');
    },
  },
};
</script>
