<template>
  <SelectionDialog
    :title="`payments.link_bank.dialog.account_type.title`"
    :subtitle="`payments.link_bank.dialog.account_type.subtitle`"
    :options="options"
    :confirm-button-text="`payments.link_bank.dialog.continue`"
    :cancel-button-text="`payments.link_bank.dialog.cancel`"
    value
    @confirm="confirm($event)"
    @cancel="cancel"
  />
</template>

<script>
import SelectionDialog from '@/components/molecules/dialogs/SelectionDialog.vue';

export default {
  name: 'AccountTypeSelectModal',
  components: {
    SelectionDialog,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    confirm(selected) {
      this.$emit('confirm', selected);
    },
    cancel() {
      this.$emit('cancel');
    },
  },

};
</script>
