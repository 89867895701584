<template>
  <div
    :style="{ width: '100%', display: 'block', '--n-columns': mobile ? 1 : 3 }"
    class="subsection-container"
  >
    <Subsection
      v-for="(section, index) in sections"
      :key="index"
      height="48%"
      bg-color="white"
      padding="0px"
      style="margin-bottom: 20px"
    >
      <AnalyticsSectionTile
        :chart-data="section.main_graph_json"
        :section-title="section.name"
        @selectSection="goTo(section)"
      />
    </Subsection>
  </div>
</template>

<script>
import Subsection from '../Subsection.vue';
import AnalyticsSectionTile from './subsections/SectionTile.vue';

export default {
  name: 'AnalyticsSectionChoice',
  components: { Subsection, AnalyticsSectionTile },
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    goTo(page) {
      this.$router.push({ name: 'AnalyticsSection', params: { section: `${page.id}` } }).catch();
    },
  },
};
</script>
