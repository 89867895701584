<template>
  <v-row
    :style="{
      height: mobile && detailed ? '40%' : '80%',
      marginTop: marginTop,
    }"
    class="mx-0 pa-0"
    align="center"
    justify="center"
    no-gutters
  >
    <v-col class="px-0 pb-0" style="height: 90%">
      <canvas v-if="loaded" ref="chart" />
    </v-col>
  </v-row>
</template>

<script>
import { Chart as ChartJS, registerables } from 'chart.js';

// Lazy way to register everything from the chart.js library
ChartJS.register(...registerables);

export default {
  name: 'AnalyticsGraphRenderer',
  props: {
    graphJson: {
      type: Object,
      required: true,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    isSection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Use to force re-mounting the graph
      windowHeight: window.innerHeight,
      loaded: true,

      // contains the chartjs object
      chart: null,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    marginTop() {
      if (this.isSection) {
        return '5%';
      }
      if (this.mobile && this.detailed) {
        return '20%';
      }
      if (!this.detailed) {
        return '10%';
      }
      return null;
    },
  },
  watch: {
    windowHeight() {
      this.mountChart();
    },
    graphJson() {
      this.mountChart();
    },
    detailed() {
      this.mountChart();
    },
    mobile() {
      this.mountChart();
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    this.mountChart();
  },
  methods: {
    onResize() {
      this.mountChart();
    },
    mountChart() {
      if (this.loaded) {
        this.loaded = false;
        setTimeout(() => {
          this.loaded = true;
          if (this.chart) {
            this.chart.destroy();
          }
          this.$nextTick(() => {
            this.chart = new ChartJS(this.$refs.chart, this.graphJson);
          });
        }, 10);
      }
    },
  },
};
</script>
