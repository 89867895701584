<template>
  <section>
    <h1 v-t="'dashboard.digital_profile.vacancies.title_field'" class="title-sections__location" />
    <p v-t="'dashboard.digital_profile.vacancies.description'" class="des-sections__location mb-5" />
    <EditableGroup
      class="mb-5"
      :fields="[
        {
          type: 'textarea',
          title: '',
          varName: 'vacancies_text',
          input: committedText,
          dense: true,
          fieldProps: {
            label: $t('dashboard.digital_profile.vacancies.add-description'),
          },
        },
      ]"
      @setVariable="setBody"
    />
    <!-- list of vacancies -->
    <v-card
      class="programs-card pb-20"
      color="#FFFFFF00"
    >
      <Tabs
        v-model="tab"
        :current-year="currentYear"
        :second-year="secondYear"
        :third-year="thirdYear"
        :disabled="tabDisabled"
      />
      <LoaderCircular
        v-if="loading || committing"
        :show="loading || committing"
        class="mx-auto my-8"
        :size="60"
        color="#461FD2"
        :width="8"
      />
      <template v-else>
        <div
          class="flex flex-row justify-end"
        >
          <TextAtom
            value="dashboard.digital_profile.vacancies.toggle_header"
            tag="label"
            size="small"
          />
        </div>

        <div
          v-for="(grade) in sortedGradesList"
          :key="`grade-${grade.id}-${grade.showVacancies}`"
        >
          <div
            class="mt-2 d-flex flex-column full-width bg-gray-section rounded-lg px-4"
          >
            <div class="full-width d-flex flex-row align-center justify-between">
              <h3 class="title-sections__location body-1 font-weight-bold">
                {{ grade.label }}
              </h3>
              <v-switch
                :input-value="(gradeVisibilityChanges[grade.id] ?? grade.showVacancies ?? -1)"
                :true-value="1"
                :false-value="0"
                :indeterminate="(gradeVisibilityChanges[grade.id] ?? grade.showVacancies) === -1"
                small
                color="#461FD2"
                inset
                @change="(value) => setChangeOfVisibility(grade.id, value)"
              />
            </div>
          </div>
        </div>
      </template>
    </v-card>

    <SaveButton v-if="!loading" :loading="committing" @save="commitChanges" />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Tabs from '@/components/molecules/tabs/Tabs.vue';
import { getLocaleProperty } from '@/utils/locale';
import institutionsService from '@/services/institutions.services';
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SaveButton from '../../utils/SaveButton.vue';
import EditableGroup from '../../utils/EditableGroup.vue';

export default {
  name: 'VacanciesDashboard',
  components: {
    SaveButton,
    Tabs,
    EditableGroup,
    LoaderCircular,
    TextAtom,
  },
  data() {
    return {
      loading: false,
      committing: false,
      vacancies: {},
      body: {},
      gradeVisibilityChanges: {},
      committedText: '',
      tab: 0,
      currentYear: new Date().getFullYear(),
      secondYear: new Date().getFullYear() + 1,
      thirdYear: new Date().getFullYear() + 2,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      textNotification: 'institutions/textNotification',
    }),
    tabDisabled() {
      return [
        false,
        true,
        true,
      ];
    },
    yearVacanciesToggle() {
      const yearData = {};
      const currentYear = new Date().getFullYear() + this.tab;
      const yearPrograms = (this.campusDetail?.program_campus || []).filter(({ year }) => year === currentYear);
      yearPrograms.forEach((program) => {
        const { id } = program;
        const gradeObj = program?.gradetrack?.grade_label;
        const gradeId = gradeObj?.id;
        const order = gradeObj?.order;
        const gradeLabel = getLocaleProperty(gradeObj, 'grade_name');
        if (!yearData[gradeId]) {
          yearData[gradeId] = {
            id: gradeId,
            label: gradeLabel,
            showVacancies: -1,
            order,
            programs: [],
          };
        }
        const freshValue = this.vacancies[program.id];
        yearData[gradeId].programs.push(id);
        yearData[gradeId].showVacancies = Math.max(
          yearData[gradeId].showVacancies,
          freshValue ?? program?.show_vacancies,
        );
      });
      return yearData;
    },
    sortedGradesList() {
      return Object.values(this.yearVacanciesToggle).sort((a, b) => a.order - b.order);
    },
  },
  watch: {
    tab: {
      handler() {
        this.resetStagedChanges();
      },
      immediate: true,
    },
    campusDetail: {
      handler(value) {
        if (value) {
          this.getProgramVisibilities();
          this.committedText = value.institutiontext_campus[0]?.vacancies_text ?? '';
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      error: 'utils/error',
      info: 'utils/info',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),
    resetStagedChanges() {
      this.gradeVisibilityChanges = {};
    },
    getProgramVisibilities() {
      this.loading = true;
      institutionsService.getProgramVacanciesVisibility({ campusCode: this.campusDetail.campus_code })
        .then((response) => {
          this.vacancies = response.data;
        }).finally(() => {
          this.loading = false;
        });
    },
    setChangeOfVisibility(gradeId, value) {
      const committedValue = value ? 1 : 0;
      if (this.yearVacanciesToggle[gradeId].showVacancies === committedValue) {
        delete this.gradeVisibilityChanges[gradeId];
      } else {
        this.gradeVisibilityChanges[gradeId] = committedValue;
      }
    },
    setBody(body) {
      this.body = body;
    },
    // Parallelize patch of all programs involved
    async commitChanges() {
      const payloads = Object.entries(this.gradeVisibilityChanges).flatMap(([gradeId, showVacancies]) => {
        const programIds = this.yearVacanciesToggle[gradeId].programs;
        return programIds.map((programId) => ({
          programId,
          showVacancies,
        }));
      });

      const vacanciesPromises = payloads.map(institutionsService.patchProgramVacanciesVisibility);
      const promises = [...vacanciesPromises];
      if (this.body.vacancies_text !== undefined && this.body.vacancies_text !== this.committedText) {
        promises.push(this.campusSectionTextPatch({
          campusUuid: this.campusDetail.uuid,
          campusData: this.body,
        }).then(() => {
          this.committedText = this.body.vacancies_text;
        }));
      }
      if (promises.length === 0) {
        this.info(this.textNotification.nothing_to_save);
        return;
      }
      this.committing = true;

      Promise.all(promises)
        .catch(() => {
          this.error(this.textNotification.error);
        })
        .finally(() => {
          if (vacanciesPromises.length > 0) this.getProgramVisibilities();
          this.committing = false;
          this.gradeVisibilityChanges = {};
        });
    },
  },
};
</script>
