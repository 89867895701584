var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-section-content"},[_c('div',{staticClass:"dashboard-grid",style:({ '--n-elements-per-row': _vm.mobile ? 1 : 2, '--element-size': '1fr', '--gap': '15px' })},[(false)?_c('div',{staticClass:"campus-detail"},[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":`grey lighten-4`}},[_c('v-skeleton-loader',{attrs:{"type":"table-heading, image, list-item-three-line,\n                list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line,\n                list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"}})],1)],1):_c('div',[_c('span',{directives:[{name:"t",rawName:"v-t",value:('dashboard.admissions.admission_process.description'),expression:"'dashboard.admissions.admission_process.description'"}],staticClass:"dashboard-instruction-text"}),_c('EditableGroup',{attrs:{"fields":[
          {
            icon: 'mdi-calendar',
            varName: 'application_period',
            title: _vm.$t('dashboard.admissions.admission_process.process_time'),
            disabled: _vm.isSAE,
            type: 'date',
            input: _vm.getDataAdmissionDate,
            modal: true,
            openModal: true,
            editing: false,
            extraClasses: 'm-2',
          },
        ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
          {
            varName: 'application_in_process_link',
            title: _vm.$t('dashboard.admissions.admission_process.web_link_regular'),
            placeHolder: true,
            textHolder: 'https://www.colegioejemplo.com',
            disabled: _vm.isSAE,
            input: _vm.getAdmissionSystemInProcessLink(),
            extraClasses: 'm-2',
          },
        ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
          {
            varName: 'application_out_process_link',
            title: _vm.$t('dashboard.admissions.admission_process.web_link_no_regular'),
            placeHolder: true,
            textHolder: 'https://www.colegioejemplo.com',
            input: _vm.getAdmissionSystemOutProcessLink(),
            extraClasses: 'm-2',
          },
        ]},on:{"setObject":_vm.setBody}}),_c('div',{staticClass:"dashboard-grid mt-3",style:({ '--n-elements-per-row': 2, '--element-size': '1fr' })},[_c('EditableGroup',{attrs:{"fields":[
            {
              icon: 'mdi-face-man',
              varName: 'first_course_entry',
              title: _vm.$t('dashboard.admissions.admission_process.entry_course'),
              input: _vm.getFirstCourseEntry(),
              extraClasses: 'm-2',
              disabled: true,
            },
          ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
            {
              icon: 'mdi-seat',
              varName: 'vacancies_entry_level',
              title: _vm.$t('dashboard.admissions.admission_process.vacancies_number'),
              input: _vm.getVacanciesEntryLevel(),
              extraClasses: 'm-2',
              disabled: _vm.isSAE,
            },
          ],"disabled":""},on:{"setObject":_vm.setBody}})],1)],1),(false)?_c('div',{staticClass:"campus-detail"},[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":`grey lighten-4`}},[_c('v-skeleton-loader',{attrs:{"type":"table-heading, image, list-item-three-line,\n                list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line,\n                list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"}})],1)],1):_c('div',[_c('Subcontent',{attrs:{"title-class":"editable-title","title":_vm.$t('dashboard.admissions.admission_process.test-title')}},[_c('EditableGroupList',{attrs:{"expandable":false,"external-save":"","form-array":_vm.initialRequiredTests,"fields":[
            {
              title: _vm.$t('dashboard.admissions.admission_process.test'),
              icon: 'mdi-paper',
              input: '',
              varName: 'description',
              extraClasses: 'm-0 p-0',
              dense: true,
              fieldProps: {
                maxLength: 600,
                counter: 600,
              },
            },
          ]},on:{"setArray":function($event){return _vm.setArray('required_tests', ...arguments)}}})],1),_c('Subcontent',{attrs:{"title-class":"editable-title","title":_vm.$t('dashboard.admissions.admission_process.document-title')}},[_c('EditableGroupList',{attrs:{"expandable":false,"external-save":"","form-array":_vm.initialRequiredDocuments,"fields":[
            {
              title: _vm.$t('dashboard.admissions.admission_process.document'),
              input: '',
              varName: 'description',
              extraClasses: 'm-0 p-0',
              dense: true,
              fieldProps: {
                maxLength: 1800,
                counter: 1800,
              },
            },
          ]},on:{"setArray":function($event){return _vm.setArray('required_documents', ...arguments)}}})],1)],1),_c('SaveButton',{attrs:{"bottom":"20px","loading":_vm.saving},on:{"save":_vm.save}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }