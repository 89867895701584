<template>
  <div class="dashboard-section-content" style="width: 100%; max-width: 100%">
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      max-width="300"
      type="card"
    />
    <GmapMap
      v-else
      ref="dashboardMapRef"
      :center="homeCoordinates ? homeCoordinates : defaultCoordinates"
      :zoom="zoom"
      :class="
        $vuetify.breakpoint.mobile
          ? 'vue-map-container-mobile'
          : 'vue-map-container'
      "
      :options="{
        disableDefaultUI: true,
        clickableIcons: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        styles: mapStyle,
        gestureHandling: 'greedy',
      }"
      style="width: 100%; height: 100%"
    >
      <div v-if="favoriteSchoolsDetails.length > 0">
        <GmapCustomMarker
          v-for="(school, i) in favoriteSchoolsDetails"
          :id="`id-markermap-${school.uuid}`"
          :key="i"
          :marker="{
            lat: school.location.lat,
            lng: school.location.lon,
          }"
          alignment="top"
        >
          <img src="@/assets/dashboard/icons/like.svg" alt="like" />
        </GmapCustomMarker>
      </div>
      <GmapCustomMarker
        :marker="homeCoordinates ? homeCoordinates : defaultCoordinates"
        alignment="top"
      >
        <img src="@/assets/dashboard/icons/home.svg" alt="home" />
      </GmapCustomMarker>
    </GmapMap>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import mapStyle from '@/assets/mapStyle.json';
import { TENANT_CONFIGURATION } from '@/constants/tenant';

export default {
  name: 'DashboardMap',
  components: {
    GmapCustomMarker,
  },
  props: {
    isGuest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zoom: 14,
      mapStyle,
      homeCoordinates: null,
      defaultCoordinates: TENANT_CONFIGURATION.DEFAULTS.LOCATION.coordinates,
    };
  },
  computed: {
    ...mapGetters({
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
      loading: 'favorites/loadingSchoolsDetails',
    }),
  },
  async mounted() {
    if (!this.isGuest && this.legalGuardianAddress) {
      this.homeCoordinates = {
        lat: this.legalGuardianAddress[0].address_lat,
        lng: this.legalGuardianAddress[0].address_lon,
      };
      return this.homeCoordinates;
    }
    return null;
  },
};
</script>
