<template>
  <section class="partnership-widget">
    <div>
      <img
        src="@/assets/logos/partnerships/ensena-cl-full-logo.svg"
        alt="ensena chile"
        width="100"
      />
      <p v-t="'partnerships.ens-cl.description_widget'" />
    </div>
    <div class="partnership-widget__actions">
      <button v-t="'partnerships.meet_us'" type="button" @click="toPage()" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'EnsenaCl',

  methods: {
    toPage() {
      window.open('https://www.ensenachile.cl/', '_blank');
    },
  },
};
</script>
