<template>
  <section
    class="clickable public-register--admin"
  >
    <div class="pt-5">
      <ImageAtom
        :src="require(`@/assets/dashboard/widgets/public-admin-icon.svg`)"
        :alt="'public-icon'"
        :display-option="'inline-flex'"
        width="50%"
      />
    </div>
    <div class="public-register--field">
      <TextAtom
        class="mt-2"
        :value="`dashboard.digital_profile.public-register-admin.title`"
        :color="'primary-dark'"
        font="poppins"
        weight="400"
        size="title"
      />
    </div>
    <div
      class="public-register--button"
      @click="externalGoTo()"
      @keydown="externalGoTo()"
    >
      <CheckButton
        :text="'dashboard.digital_profile.public-register-admin.button'"
      />
    </div>
  </section>
</template>

<script>
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CheckButton from '@/components/atoms/buttons/CheckButton.vue';
import CONFIG from '@/config';

export default {
  name: 'PublicRegisterAdmin',
  components: {
    ImageAtom,
    TextAtom,
    CheckButton,
  },
  methods: {
    externalGoTo() {
      const env = CONFIG.environment === 'staging' ? 'staging.' : '';
      const page = `https://${env}crm.tether.education/`;
      window.open(page, '_blank').focus();
    },
  },
};
</script>
