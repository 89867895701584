<template>
  <div class="fill-width programs-shifts--full">
    <div
      v-for="(shift, index) in shiftsToAdd"
      :key="shift.id"
      class="programs-shifts--container mb-5"
    >
      <EditableGroup
        class="programs-shifts"
        :fields="[
          {
            type: 'dropdown',
            varName: 'shift_id',
            dense: true,
            input: getShiftId(index),
            items: [...getShiftOptions],
            openModal: true,
            dropdownColor: '#F5F5F5',
            place: $t('dashboard.digital_profile.programs.programs_flow.shift'),
          },
        ]"
        @setObject="setBody(index, ...arguments)"
      />
      <div class="programs-shifts--container__menu">
        <v-menu bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              color="#646464"
              v-bind="attrs"
              v-on="on"
            >
              mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              class="clickable"
              @click="deleteShift(index)"
            >
              <v-list-item-title style="color: #CCCCCC;">
                {{ $t('dashboard.profile.delete') }}
              </v-list-item-title>
              <v-icon
                class="ml-2"
                color="#CCCCCC"
              >
                mdi-delete
              </v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div
      class="d-flex left-text clickable mt-5"
      @click="add"
      @keydown="add"
    >
      <SVGIcon
        icon="more-border-gray.svg"
        size="17"
      />
      <TextAtom
        class="ml-2 clickable"
        :value="$t('dashboard.digital_profile.programs.programs_flow.add_shift')"
        tag="label"
        font="inter"
        color="neutral-800"
        weight="400"
        size="normal"
      />
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CONFIG from '@/config';
import { mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';

export default {
  name: 'ShiftsSection',
  components: {
    EditableGroup,
    SVGIcon,
    TextAtom,
  },
  data() {
    return {
      shiftsToAdd: [],
    };
  },
  computed: {
    ...mapGetters({
      shifts: 'options/shiftsLabels',
    }),
    getShiftOptions() {
      return this.shifts
        .map((shift) => ({
          value: shift.id,
          label: shift.shift_name,
        }));
    },
    getShiftId() {
      return (index) => {
        const shift = this.shiftsToAdd[index];
        return shift.id;
      };
    },
  },
  mounted() {
    this.shiftsToAdd.push({
      id: this.getShiftLabel(),
    });
    this.$emit('setShifts', this.shiftsToAdd);
  },
  methods: {
    getShiftLabel() {
      let shiftId = 6; // colombia
      const { tenant } = CONFIG;
      if (tenant === 'chile') {
        shiftId = 3;
      } else if (tenant === 'newhaven') {
        shiftId = 1;
      }
      return shiftId;
    },
    setBody(index, body) {
      this.shiftsToAdd = this.shiftsToAdd.map((shift, i) => {
        if (i === index) {
          return {
            id: body.shift_id,
          };
        }
        return shift;
      });
      this.$emit('setShifts', this.shiftsToAdd);
    },
    add() {
      this.shiftsToAdd.push({
        id: 0,
      });
    },
    deleteShift(index) {
      this.shiftsToAdd.splice(index, 1);
    },
  },
};
</script>
