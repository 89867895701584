var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"state-chip",class:{
    'state-chip--fit-content': _vm.fitContent,
    'state-chip--disabled': _vm.chipState.type === 0 || _vm.chipState.type === 'disabled',
    'state-chip--waiting': _vm.chipState.type === 1 || _vm.chipState.type === 'waiting',
    'state-chip--in_process': _vm.chipState.type === 2 || _vm.chipState.type === 'in_process',
    'state-chip--blocked': _vm.chipState.type === 3 || _vm.chipState.type === 'blocked',
  }},[_c('p',{directives:[{name:"t",rawName:"v-t",value:(_vm.chipState.name),expression:"chipState.name"}],staticClass:"state-chip--content",class:{
      'state-chip--content--disabled': _vm.chipState.type === 0 || _vm.chipState.type === 'disabled',
      'state-chip--content--waiting': _vm.chipState.type === 1 || _vm.chipState.type === 'waiting',
      'state-chip--content--in_process': _vm.chipState.type === 2 || _vm.chipState.type === 'in_process',
      'state-chip--content--blocked': _vm.chipState.type === 3 || _vm.chipState.type === 'blocked',
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }