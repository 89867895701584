var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',[_c('div',{staticClass:"dashboard-grid",style:({ '--n-elements-per-row': _vm.mobile ? 1 : 2, '--element-size': '1fr' })},[_c('div',[_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'checkbox',
            title: _vm.$t('dashboard.digital_profile.pride_points.pride_point_type'),
            varName: 'pridepoint_label_id',
            input: _vm.pridepoint.pridepoint_label_id,
            items: _vm.pridePointTypes,
            elementsRow: 1,
          },
        ]},on:{"setVariable":_vm.setBody}})],1),_c('div',[_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'text',
            title: '',
            varName: 'pridepoint_title',
            input: _vm.pridepoint.pridepoint_title,
            fieldProps: {
              maxLength: 100,
              counter: 100,
              label: _vm.$t('dashboard.digital_profile.pride_points.title_pride_points'),
            },
          },
          {
            type: 'textarea',
            title: '',
            varName: 'pridepoint_description',
            input: _vm.pridepoint.pridepoint_description,
            fieldProps: {
              maxLength: 1800,
              counter: 1800,
              label: _vm.$t('dashboard.digital_profile.pride_points.description'),
            },
            colorTextArea: '#E3E9FE',
          },
          {
            type: 'image',
            title: '',
            varName: 'imagebase64',
            input: _vm.getPridePointImage(),
          },
        ]},on:{"setVariable":_vm.setBody}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }