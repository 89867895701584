<template>
  <div>
    <div class="d-flex justify-between mb-8">
      <div class="d-flex">
        <SVGIcon
          icon="drag-drop.svg"
          alt-text="drag"
          size="13"
          name="dragger"
          class="clickable"
        />
        <span
          v-t="{
            path: 'dashboard.digital_profile.testimonials.title_index',
            args: { index: index + 1 },
          }"
          class="ml-10 contacts-dashboard__title"
        />
      </div>
      <v-menu start>
        <template #activator="{ on, attrs }">
          <v-icon
            color="#646464"
            v-bind="attrs"
            v-on="on"
          >
            mdi-dots-horizontal
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            class="clickable"
            @click="$emit('deleteTestimonial', index)"
          >
            <v-list-item-title style="color: #CCCCCC;">
              {{ $t('dashboard.profile.delete') }}
            </v-list-item-title>
            <v-icon
              class="ml-2"
              color="#CCCCCC"
            >
              mdi-delete
            </v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="d-flex flex-column gap-8">
      <EditableGroup
        :fields="[
          {
            type: 'text',
            varName: 'audiovisual_link',
            dense: true,
            input: getTestimonialLink(index),
            textColor: '#F5F5F5',
            title: $t('dashboard.digital_profile.testimonials.title_input_link'),
            titleText: true,
            backgroundColor: '#F5F5F5',
          },
        ]"
        @setObject="setBody"
      />
      <EditableGroup
        :fields="[
          {
            type: 'text',
            varName: 'names',
            dense: true,
            input: getTestimonialName(index),
            textColor: '#F5F5F5',
            title: $t('dashboard.digital_profile.testimonials.title_input_name'),
            titleText: true,
            backgroundColor: '#F5F5F5',
          },
        ]"
        @setObject="setBody"
      />
      <EditableGroup
        :fields="[
          {
            type: 'dropdown',
            varName: 'label_id',
            dense: true,
            input: getTestimonialLabel(index),
            items: [...getTestimonialOptions],
            openModal: true,
            title: $t('dashboard.digital_profile.testimonials.title_input_position'),
            titleText: true,
            dropdownColor: '#F5F5F5',
            place: $t('dashboard.digital_profile.testimonials.title'),
          },
        ]"
        @setObject="setBody"
      />
    </div>
  </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import { mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';

export default {
  name: 'TestimonialSingle',
  components: {
    EditableGroup,
    SVGIcon,
  },
  props: {
    testimonial: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    testimonialsArray: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      testimonialsLabel: 'options/testimonialsLabel',
    }),
    getTestimonialOptions() {
      const newLabels = [...this.testimonialsLabel];
      if (this.testimonial.label_id !== 0) {
        const nameCurrentTestimonial = this.getTestimonialLabelName;
        newLabels.push({
          id: this.testimonial.label_id,
          audiovisual_name: nameCurrentTestimonial,
        });
      }
      const testimonialsId = this.testimonialsLabel.map((testimonial) => testimonial.id);

      return newLabels
        .filter((label) => testimonialsId.includes(label.id))
        .map((label) => ({
          value: label.id,
          label: label.audiovisual_name,
        }));
    },
    getTestimonialLabelName() {
      return this.testimonialsLabel.find(
        (label) => label.id === this.testimonial.label_id,
      ).audiovisual_name;
    },
  },
  methods: {
    setBody(body) {
      const [key, value] = Object.entries(body)[0];
      if (key === 'names') {
        this.$emit('setKeyValue', 'name', value);
      } else {
        this.$emit('setKeyValue', key, value);
      }
    },
    getTestimonialLabel(index) {
      return this.testimonialsArray[index].label_id;
    },
    getTestimonialName(index) {
      return this.testimonialsArray[index].name;
    },
    getTestimonialLink(index) {
      return this.testimonialsArray[index].audiovisual_link;
    },
  },
};
</script>
