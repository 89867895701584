<template>
  <div
    class="video-container relative"
    :style="{
      '--width': `${width}px`,
      '--height': `${height}px`,
    }"
  >
    <div
      v-if="title != ''"
      class="absolute-bottom-right p-2 text-left"
      style="z-index: 200; color: #5627ff; font-weight: 500"
    >
      {{ title }}
    </div>
    <v-icon
      v-if="!muted"
      color="red"
      style="z-index: 201; position: absolute; right: 5px; top: 5px"
      @click="toggleMuted"
    >
      {{ userMuted ? 'mdi-volume-off' : 'mdi-volume-high' }}
    </v-icon>
    <vimeo-player
      v-if="videoURL"
      ref="player"
      :video-url="videoURL ? videoURL : null"
      class="video-player"
      :video-id="videoURL ? null : videoID"
      autoplay
      :style="{
        '--width': `${width}px`,
        '--height': `${height}px`,
        '--vertical-offset': verticalOffset,
        '--horizontal-offset': horizontalOffset,
      }"
      :player-height="`${actualHeight}px`"
      :player-width="`${actualWidth}px`"
      :options="options"
      @timeupdate="log"
      @playing="onPlaying"
    />
    <img v-if="videoGIF" :src="videoGIF" alt="GIF" class="gif-player" />
  </div>
</template>
<script>
export default {
  name: 'CenteredVideo',
  props: {
    muted: {
      required: false,
      type: Boolean,
      default: true,
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
    timeRange: {
      required: false,
      type: [Array, String],
      default: null,
    },
    height: {
      required: true,
      type: Number,
    },
    width: {
      required: true,
      type: Number,
    },
    videoURL: {
      required: false,
      type: String,
      default: null,
    },
    videoGIF: {
      required: false,
      type: String,
      default: null,
    },
    videoID: {
      required: false,
      type: [Number, String],
      default: null,
    },
    extraOptions: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      userMuted: false,
      options: {
        transparent: 0,
        controls: 0,
        portrait: true,
        muted: true,
        background: true,
        volume: 0,
      },
    };
  },
  computed: {
    actualHeight() {
      if ((this.width * 9) / 16 < this.height) {
        return this.height;
      }
      return (this.width * 9) / 16;
    },
    actualWidth() {
      if ((this.height * 16) / 9 < this.width) {
        return this.width;
      }
      return (this.height * 16) / 9;
    },
    horizontalOffset() {
      return `${-Math.abs(this.width - this.actualWidth) / 2}px`;
    },
    verticalOffset() {
      return `${-Math.abs(this.height - this.actualHeight) / 2}px`;
    },
  },
  mounted() {
    if (this.timeRange) {
      this.$refs.player.player.setCurrentTime(this.timeRange[0]);
    }
    this.options = {
      muted: this.muted,
      ...this.options,
      width: `${this.actualWidth}px`,
      ...this.extraOptions,
    };
  },
  methods: {
    toggleMuted() {
      if (this.userMuted) {
        this.$refs.player.player.setVolume(1);
        this.userMuted = false;
      } else {
        this.$refs.player.player.setVolume(0);
        this.userMuted = true;
      }
    },
    log(data) {
      if (this.timeRange) {
        if (data.seconds > this.timeRange[1]) {
          this.$refs.player.player.setCurrentTime(this.timeRange[0]);
        }
      }
    },
    onPlaying() {
      if (!this.muted) {
        this.$refs.player.player.setVolume(1);
      }

      this.$emit('onPlaying');
    },
  },
};
</script>
