<template>
  <div>
    <div>
      <EditableGroup
        :fields="[
          {
            type: 'textarea',
            title: $t('dashboard.digital_profile.about.description_title'),
            varName: 'profile_summary',
            input: getProfileSummary(),
            fieldProps: {
              maxLength: 1800,
              counter: 1800,
              label: $t('dashboard.digital_profile.about.description'),
            },
          },
        ]"
        @setVariable="setBody"
      />
      <PlacardGeneratedByAi v-if="profileSummaryIsGenerateByAi" />
      <div class="mt-3">
        <EditableGroup
          :fields="[
            {
              type: 'text',
              title: $t('dashboard.digital_profile.about.continuity_title'),
              varName: 'campus_continuity',
              input: campusDetail.institutiontext_campus[0]?.campus_continuity,
            },
          ]"
          @setVariable="setBody"
        />
      </div>

      <div class="dashboard-grid" :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr' }">
        <EditableGroup
          :fields="[
            {
              type: 'radiobutton',
              title: $t('dashboard.digital_profile.about.sector_title'),
              input: getInstitutionSector,
              varName: 'sector_label_id',
              items: sectorTypes,

            },
          ]"
          @setVariable="setBody"
        />
        <div class="mt-5">
          <EditableGroup
            :fields="[
              {
                type: 'checkbox',
                dense: true,
                title: $t('dashboard.digital_profile.about.agreements_title'),
                input: getInstitutionAgreements,
                varName: 'agreements',
                items: agreementsTypes,
                elementsRow: 1,
              },
            ]"
            @setVariable="setBody"
          />
          <EditableGroup
            v-if="isOther"
            :fields="[
              {
                type: 'checkInput',
                title: '',
                dense: true,
                input: getOtherInfo(),
                varName: 'agreement_other',
                items: otherType,
                infoOther: otherDescription,
              },
            ]"
            @setVariable="setBody"
          />
        </div>
      </div>
    </div>
    <SaveButton :loading="saving" @save="save" />
  </div>
</template>
<script>
import PlacardGeneratedByAi from '@/components/molecules/placard/PlacardGeneratedByAi.vue';
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'AboutSection',
  components: { EditableGroup, SaveButton, PlacardGeneratedByAi },
  props: {
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      body: Object(),
      agreementsCampus: [],
      otherDescription: '',
      otherBody: Object(),
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      sectorLabels: 'options/sectorLabels',
      agreementsLabels: 'options/agreementsLabels',
    }),
    profileSummaryIsGenerateByAi() {
      return this.campusDetail?.institutiontext_campus[0]?.profile_summary_aigen ?? false;
    },
    getInstitutionSector() {
      try {
        return this.campusDetail.sector_label.id;
      } catch {
        return 1;
      }
    },
    getInstitutionAgreements() {
      try {
        return this.agreementsCampus;
      } catch {
        return 1;
      }
    },
    agreementsTypes() {
      const agreementsTypes = [];
      this.agreementsLabels.forEach((agreement) => {
        if (agreement.id !== 99) {
          agreementsTypes.push({ label: agreement.agreement_name, value: agreement.id });
        }
      });
      agreementsTypes.sort((a, b) => a.label.localeCompare(b.label));
      return agreementsTypes;
    },
    isOther() {
      return this.agreementsLabels.some((agreement) => agreement.id === 99);
    },
    otherType() {
      const otherType = [];
      this.agreementsLabels.forEach((agreement) => {
        if (agreement.id === 99) {
          otherType.push({ label: agreement.agreement_name, value: agreement.id });
        }
      });
      return otherType;
    },
    sectorTypes() {
      const sectorTypes = [];
      this.sectorLabels.forEach((sector) => {
        sectorTypes.push({ label: sector.sector_name, value: sector.id });
      });
      sectorTypes.sort((a, b) => a.label.localeCompare(b.label));
      return sectorTypes;
    },
  },
  async mounted() {
    await this.retrieveOptions({ reset: false });
    this.loaded = true;
    this.body.agreements = [];
    this.campusDetail.institutionagreement_campus.forEach((agreement) => {
      this.agreementsCampus.push(agreement.agreement_label.id);
      if (agreement.agreement_label.id !== 99) {
        this.body.agreements.push({ label_id: agreement.agreement_label.id });
      } else {
        this.otherDescription = agreement.descrip;
        this.otherInfo = true;
      }
    });
  },
  methods: {
    ...mapActions({
      retrieveOptions: 'options/retrieveOptions',
      campusAboutEstablishmentPatch: 'institutions/campusAboutEstablishmentPatch',
    }),
    getProfileSummary() {
      if (this.campusDetail) {
        if (this.campusDetail.institutiontext_campus.length > 0) {
          return this.campusDetail.institutiontext_campus[0].profile_summary;
        }
      }
      return '';
    },
    getOtherInfo() {
      return this.otherInfo === true ? 99 : 1;
    },
    setBody(body) {
      const infoBody = Object.entries(body)[0][1];
      const varName = Object.entries(body)[0][0];
      if (body.agreement_other !== undefined) {
        this.otherBody = {
          label_id: 99,
          descrip: body.agreement_other,
        };
      } if (varName === 'agreements') {
        this.body.agreements = body.agreements.map((x) => ({
          label_id: x,
        }));
      } else {
        this.body[varName] = infoBody;
      }
    },
    save() {
      this.saving = true;
      if (this.otherBody.descrip) {
        this.body.agreements.push(this.otherBody);
      }
      this.campusAboutEstablishmentPatch({ campusUuid: this.campusDetail.uuid, campusData: this.body })
        .then(() => {
          this.saving = false;
        });
    },
  },
};
</script>
