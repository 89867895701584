var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Subsection',{style:({
    'background-image':
      'url('
      + require('@/assets/dashboard/widgets/location-widget.svg')
      + ')',
    'background-size': 'cover',
    'border-radius': '5px',
    'background-position': 'center',
  }),attrs:{"bg-color":"transparent","flex":"column","clickable":""},on:{"onClick":function($event){return _vm.toggleModal()}}},[_c('div',{staticClass:"widget-tool--location"},[_c('p',{directives:[{name:"t",rawName:"v-t",value:('dashboard.tools.location'),expression:"'dashboard.tools.location'"}],staticClass:"widget-tool--location--title"}),_c('p',{staticClass:"widget-tool--location--address"},[_vm._v(" "+_vm._s(_vm.address)+" ")]),_c('img',{staticClass:"widget-tool--location--icon",attrs:{"src":require("@/assets/dashboard/widgets/location-pin-widget.svg"),"alt":"graph"}})]),_c('v-dialog',{staticStyle:{"height":"100vh !important"},attrs:{"content-class":!_vm.mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'},on:{"click:outside":function($event){return _vm.toggleModal()}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('LocationFlow',{attrs:{"select-step":1,"in-dashboard":""},on:{"closeModal":function($event){return _vm.toggleModal()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }