<template>
  <StudentChip
    :student-name="studentName"
    :editable="editable"
    :class="{ 'student-chip--editable': editable }"
  >
    <template #menu>
      <v-menu
        bottom
        :offset-x="true"
        class="student-chip__menu"
      >
        <template #activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            color="#646464"
            style="position:absolute; right: 7%;"
            v-on="on"
          >
            mdi-dots-horizontal
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            class="clickable"
            @click="onInteraction({ type: item.event.toLowerCase() })"
          >
            <v-list-item-title style="text-align: left; color: #CCCCCC;">
              {{ item.title }}
            </v-list-item-title>
            <v-icon
              class="ml-7"
              color="#CCCCCC"
            >
              {{ item.icon }}
            </v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </StudentChip>
</template>

<script>
import StudentChip from '@/components/atoms/chips/StudentChip.vue';

export default {
  name: 'EditableStudentChip',
  components: {
    StudentChip,
  },
  props: {
    studentName: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          title: this.$t('dashboard.profile.edit'),
          icon: 'mdi-pencil',
          event: 'edit',
        },
        {
          title: this.$t('dashboard.profile.delete'),
          icon: 'mdi-delete',
          event: 'delete',
        },
      ],
    };
  },
  methods: {
    onInteraction({ type: event }) {
      if (this.clickable || this.editable) {
        this.$emit(event);
      }
    },
  },
};
</script>
