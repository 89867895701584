<template>
  <div>
    <div>
      <div>
        <div class="title-sections__location">
          {{ $t('dashboard.digital_profile.students.title') }}
        </div>
        <div class="des-sections">
          {{ $t('dashboard.digital_profile.students.info') }}
        </div>
        <EditableGroup
          class="mb-5 m-4"
          :fields="[
            {
              type: 'textarea',
              title: '',
              varName: 'students_text',
              input: getStudentsText,
              dense: true,
              fieldProps: {
                label: $t('dashboard.digital_profile.students.add-description'),
              },
            },
          ]"
          @setVariable="setTextBody"
        />
        <div
          class="grid-number"
          :style="{ '--col': columns }"
        >
          <div v-for="(stage, index) in getDataStudents" :key="index">
            <EditableGroup
              :fields="[
                {
                  type: 'numberField',
                  varName: stage.id.toString(),
                  title: getTitleStage(index),
                  extraClasses: 'size-field',
                  input: getDataStage(stage),
                },
              ]"
              @setObject="setBody"
            />
          </div>
        </div>
      </div>

      <SaveButton :loading="saving" @save="save" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'StudentsSection',
  components: { EditableGroup, SaveButton },
  data() {
    return {
      saving: false,
      body: Object(),
      textBody: {},
      columns: 2,
    };
  },
  computed: {
    ...mapGetters({
      currentHeadMasterMainSchool: 'roles/currentHeadMasterMainSchool',
      stageOptions: 'options/stages',
    }),
    getDataStudents() {
      const total = {};
      this.stageOptions.forEach((stage) => {
        const memberInyect = this.currentHeadMasterMainSchool?.institutionmember_campus?.filter(
          (member) => {
            if (member.stage_label?.id === stage?.id && member.year === this.getMembersLastYear) {
              return true;
            }
            return false;
          },
        );
        if (memberInyect.length > 0) {
          total[stage.stage_name] = { count: memberInyect[0].total_members ?? '', year: this.getMembersLastYear, id: stage.id };
        } else {
          total[stage.stage_name] = { count: '', year: this.getMembersLastYear, id: stage.id };
        }
      });
      return total;
    },
    getMembersLastYear() {
      let lastYear = 0;
      this.currentHeadMasterMainSchool.institutionmember_campus.forEach((members) => {
        if (members.year > lastYear) {
          lastYear = members.year;
        }
      });
      return lastYear;
    },
    getStudentsText() {
      return this.currentHeadMasterMainSchool.institutiontext_campus[0]?.students_text ?? '';
    },
  },
  methods: {
    ...mapActions({
      campusStudentsPatch: 'institutions/campusStudentsPatch',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),
    isMemberInBody(body) {
      const memberInfo = this.body.institutionmember_campus?.find(
        (member) => member.stage_label_id === parseInt(Object.keys(body)[0], 10),
      );
      return !!memberInfo;
    },
    setTextBody(body) {
      this.textBody = body;
    },
    setBody(body) {
      if (!this.body.institutionmember_campus) {
        this.body.institutionmember_campus = [];
      }
      // add if the stage is not in the array
      const lastYear = this.getMembersLastYear;
      if (!this.isMemberInBody(body)) {
        this.body.institutionmember_campus.push({
          member_label_id: 1,
          stage_label_id: parseInt(Object.keys(body)[0], 10),
          total_members: Object.values(body)[0] ? parseInt(Object.values(body)[0], 10) : 0,
          year: lastYear,
        });
      } else {
        // update the stage
        const index = this.body.institutionmember_campus.findIndex(
          (stage) => stage.stage_label_id === parseInt(Object.keys(body)[0], 10),
        );
        this.body.institutionmember_campus[index] = {
          member_label_id: 1,
          stage_label_id: parseInt(Object.keys(body)[0], 10),
          total_members: Object.values(body)[0] ? parseInt(Object.values(body)[0], 10) : 0,
          year: lastYear,
        };
      }
    },
    async save() {
      this.saving = true;
      this.campusSectionTextPatch({ campusUuid: this.currentHeadMasterMainSchool.uuid, campusData: this.textBody });
      await this.campusStudentsPatch(
        { campusUuid: this.currentHeadMasterMainSchool.uuid, campusData: this.body },
      );
      this.saving = false;
    },
    getTitleStage(index) {
      return this.$t('dashboard.digital_profile.students.edit') + index;
    },
    getDataStage(stage) {
      return stage.count;
    },
  },
};
</script>
