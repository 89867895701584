var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label-molecule",class:{
    'label-molecule--italic': _vm.italic,
  },style:({
    fontWeight: _vm.fontWeight,
  })},[(_vm.icon)?_c('MDIIcon',{staticClass:"mr-2",attrs:{"icon":_vm.icon,"color":_vm.color,"small":""}}):_vm._e(),_c('div',{directives:[{name:"t",rawName:"v-t",value:(_vm.text),expression:"text"}],style:({
      color: _vm.color,
    })})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }