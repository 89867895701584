import { INTEGRATION_METHODS } from '@/constants/payments';
import store from '@/store';
// TODO: Connect this to the backend NationalIdentification model
const DOCUMENT_TYPES = Object.freeze({
  CHILE: [
    // RUT, PP
    {
      id: 1, name: 'RUT - Rol Único Tributario', code: 'RUT', validation: '^[0-9]{6,10}$',
    },
    {
      id: 2, name: 'PP - Pasaporte', code: 'PP', validation: '^[0-9]{6,10}$',
    },
  ],
  COLOMBIA: [
    // CC, NIT, PP, CE, TI
    {
      id: 1, name: 'CC - Cédula de Ciudadanía', code: 'CC', validation: '^[0-9]{6,10}$',
    },
    {
      id: 2, name: 'NIT - Número de Identificación Tributaria', code: 'NIT', validation: '^[0-9]{6,10}$',
    },
    {
      id: 3, name: 'PP - Pasaporte', code: 'PP', validation: '^[0-9]{6,10}$',
    },
    {
      id: 4, name: 'CE - Cédula de extranjería', code: 'CE', validation: '^[0-9]{6,10}$',
    },
    {
      id: 5, name: 'TI - Tarjeta de identidad', code: 'TI', validation: '^[0-9]{6,10}$',
    },
  ],
});

/**
 * Generic widget interface.
 * @returns {Object} - Object with generic widget interface methods
 * @property {String} method - Integration method (widget)
 * @property {Function} setup - Setup function
 * @property {Function} createConfig - Create config function
 * @property {Function} invoke - Invoke function
 * @property {Function} openWidget - Open widget function
 * @property {Function} destroyWidget - Destroy widget function
 * @property {Function} translateStatus - Translate status function (from provider string to internal payment status)
 * @property {Function} buildFlowSubmissionData - Build flow submission data function
 * @property {Function} submitFlow - Submit flow function (defaults to submitting flow to payments service)
 */
const genericWidgetInterface = () => ({
  method: INTEGRATION_METHODS.WIDGET,
  setup: () => {},
  createConfig: () => {},
  invoke: () => {},
  openWidget: () => {},
  destroyWidget: () => {},
  translateStatus: () => {},
  buildFlowSubmissionData: () => {},
  submitFlow: async ({ flowSubmissionData }) => store.dispatch('payments/submitPaymentFlow', { flowSubmissionData }, { root: true }),
  settings: {},
});

const genericRedirectionInterface = ({ flowInitializationData }) => {
  const { provider_redirect_url: toLink } = flowInitializationData;
  return {
    method: INTEGRATION_METHODS.REDIRECTION,
    setup: () => {},
    invoke: () => window.open(toLink, '_blank'),
    submitFlow: async ({ flowSubmissionData }) => store.dispatch('payments/submitPaymentFlow', { flowSubmissionData }, { root: true }),
    settings: {},
  };
};

/**
 * Parse provider config params into a single object with key-value pairs.
 *
 * The payments service stores provider config with the following structure:
 *
 * | provider_key_name |  var_value |
 * |-------------------|------------|
 * | PROVIDER_CDN      | https://...|
 * | PROVIDER_KEY      | 1234567890 |
 *
 * This function parses the config into a single object:
 *
 * {
 *  PROVIDER_CDN: 'https://...',
 *  PROVIDER_KEY: '1234567890',
 * }
 *
 * @param {*} param0 - Object with provider config params
 * @param {Array} param0.providerConfig - Array of objects with provider config params
 * @returns {Object} - Object with provider config params
 *
 * @example
 * const providerConfig = [
 *  { provider_key_name: 'PROVIDER_CDN', var_value: 'https://...' },
 *  { provider_key_name: 'PROVIDER_KEY', var_value: '1234567890' },
 * ];
 *
 * const config = parseConfig({ providerConfig });
 *
 * // config = {
 * //  PROVIDER_CDN: 'https://...',
 * //  PROVIDER_KEY: '1234567890',
 * // }
 */
const parseConfig = ({ providerConfig }) => {
  const config = {};
  providerConfig.forEach((configItem) => {
    const { provider_key_name: key, value, raw_value: raw } = configItem;
    config[key] = value || raw;
  });
  return config;
};

const defaultScriptCallbacks = {
  loadedScript: () => {},
};

const defaultWidgetCallbacks = {
  success: () => {},
  exit: () => {},
  flowEvent: () => {},
};

export {
  genericWidgetInterface,
  genericRedirectionInterface,
  parseConfig,
  defaultScriptCallbacks,
  defaultWidgetCallbacks,
  DOCUMENT_TYPES,
};
