<template>
  <div
    class="subsection"
    :style="{
      '--clickable': clickable ? 'pointer' : 'auto',
      '--height': height,
    }"
    @click="onClick"
    @keydown="onClick"
  >
    <div
      class="subsection-content"
      :style="{
        '--bg-color': bgColor,
        '--padding': padding,
        '--color': color,
        '--flex': flex,
        '--align': align,
      }"
    >
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'SubSection',
  props: {
    height: {
      required: false,
      default: '',
      type: String,
    },
    bgColor: {
      required: false,
      default: '#426CFB',
      type: String,
    },
    color: {
      required: false,
      default: 'white',
      type: String,
    },
    padding: {
      required: false,
      default: '10px',
      type: String,
    },
    clickable: {
      required: false,
      default: false,
      type: Boolean,
    },
    flex: {
      required: false,
      default: 'row',
      type: String,
    },
    align: {
      required: false,
      default: 'left',
      type: String,
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>
