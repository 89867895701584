<template>
  <section
    class="p-2 flex risk-widget--content clickable"
    @click="goTo()"
    @keydown="goTo()"
  >
    <img
      :src="favoriteSchoolsDetails.length > 0 ? require('@/assets/dashboard/widgets/chart-risk-widget.svg')
        : require('@/assets/dashboard/widgets/chart-risk-empty-widget.svg')"
      alt="check"
    />
    <div class="risk-widget">
      <h3 v-t="'dashboard.digital_profile.risk.title'" class="risk-widget--title" />
      <p
        v-t="favoriteSchoolsDetails.length > 0 ? 'dashboard.digital_profile.risk.title_field'
          : 'dashboard.digital_profile.risk.title_field_empty'"
        class="risk-widget--subtitle"
      />
    </div>
  </section>
</template>

<script>
import '@/styles/dashboard/simulation.scss';
import { mapGetters } from 'vuex';

export default {
  name: 'SimulationSection',
  computed: {
    ...mapGetters({
      loading: 'favorites/loadingSchoolsDetails',
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
    }),
  },
  methods: {
    goTo() {
      return this.$router.push('/simulate');
    },
  },
};
</script>
