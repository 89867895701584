<template>
  <div class="digital-full-container">
    <div class="profile-container">
      <img
        class="profile-container__image"
        :src="
          profileIndex < 100
            ? require('@/assets/dashboard/widgets/no-complete-profile.svg')
            : require('@/assets/dashboard/widgets/complete-profile.svg')
        "
        alt="complete-profile"
      />
      <span class="profile-container__name">{{ userUsername }}</span>
      <v-icon
        v-if="!showProfile"
        class="profile-container__icon clickable"
        @click="toggleProfile"
      >
        mdi-chevron-down
      </v-icon>
      <v-icon
        v-if="showProfile"
        class="profile-container__icon clickable"
        @click="toggleProfile"
      >
        mdi-chevron-up
      </v-icon>
    </div>
    <Profile v-if="showProfile" class="profile-container" />
    <div
      class="digital-profile-container loader-dashboard"
    >
      <v-skeleton-loader
        v-if="!loaded"
        max-width="auto"
        max-height="220"
        type="list-item-avatar-two-line"
      />
      <div v-else>
        <div v-if="isAmbassador && !currentHeadMasterMainSchool" class="d-flex flex-column fill-width">
          <h3
            v-t="'dashboard.profile.profile_edit.title'"
            class="dashboard-text dashboard-section-title pa-0"
          />
          <p
            v-t="'dashboard.profile.profile_edit.instructions'"
            class="profile-instructions"
          />
        </div>
        <div v-if="currentHeadMasterMainSchool" class="digital-profile-container__info">
          <v-avatar width="100px" height="100%" tile class="digital-profile-container__info--avatar">
            <v-skeleton-loader
              v-if="campusImage === ''"
              max-width="auto"
              max-height="auto"
              type="avatar"
            />
            <v-img
              else
              class="image-profile"
              :src="
                campusImage !== null
                  ? campusImage
                  : require('@/assets/icons/school.svg')
              "
            />
          </v-avatar>
          <div class="digital-profile-container__info--title">
            <div class="profile-institution">
              {{ institutionName }}
            </div>
            <div class="profile-text">
              {{ institutionInfo }}
            </div>
            <div class="see-profile__container">
              <v-btn
                v-if="profileIndex < 100"
                :loading="!loaded"
                fab
                small
                outlined
                color="#E0E0E0"
                elevation="0"
                class="mr-2 clickable see-profile"
                @click="exploreCampusDetail(campusDetail)"
                @keydown.enter="exploreCampusDetail(campusDetail)"
              >
                <v-icon color="#5627FF">
                  mdi-eye-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-if="profileIndex < 100"
                :loading="!loaded"
                color="#E0E0E0"
                elevation="0"
                class="clickable see-profile complete-profile"
                @click="goTo('digital-profile')"
                @keydown="goTo('digital-profile')"
              >
                {{ $t("dashboard.digital_profile.see-profile") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="percent-info">
        <img
          class="percent-image"
          :src="
            profileIndex < 100
              ? require('@/assets/dashboard/widgets/no-complete-profile.svg')
              : require('@/assets/dashboard/widgets/complete-profile.svg')
          "
          alt="complete-profile"
        />
        <div class="percent-info__text loader-dashboard">
          <v-skeleton-loader
            v-if="!loaded"
            max-width="auto"
            max-height="220"
            type="list-item"
          />
          <div v-else>
            {{ profileIndex }}%
            <span class="percent-info__text--small">{{
              $t("dashboard.digital_profile.index-profile")
            }}</span>
          </div>
        </div> -->
      <!-- <v-btn
          v-if="profileIndex < 100"
          :loading="!loaded"
          color="#5627FF"
          class="clickable percent-button white--text"
          @click="goTo('digital-profile')"
          @keydown="goTo('digital-profile')"
        >
          {{ $t("dashboard.digital_profile.completar-button") }}
        </v-btn>
        <v-btn
          v-else
          :loading="!loaded"
          color="#5627FF"
          class="clickable percent-button white--text"
          @click="goTo('digital-profile')"
          @keydown="goTo('digital-profile')"
        >
          {{ $t("dashboard.digital_profile.editar-button") }}
        </v-btn> -->
      <!-- </div> -->
      <!-- <div class="progress-profile loader-dashboard">
        <v-skeleton-loader
          v-if="!loaded"
          max-width="auto"
          max-height="220"
          type="heading"
        />
        <v-progress-linear
          v-else
          background-color="#D6D6D6"
          color="#5627FF"
          :value="profileIndex"
        />
      </div> -->
    </div>
    <v-dialog
      v-model="campusDetailModal"
      transition="dialog-bottom-transition"
      max-width="800px"
      content-class="favorites__dialog"
    >
      <CampusDetail
        v-if="campusDetailModal"
        style="z-index: 1000"
        in-dashboard
        @close="closeCampusDetail"
      />
    </v-dialog>
  </div>
</template>

<script>
import Profile from '@/components/dashboard/sections/Profile.vue';
import CampusDetail from '@/components/explorer/general/campus_detail/CampusDetail.vue';
import CONFIG from '@/config';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DigitalProfileEdit',
  components: { Profile, CampusDetail },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      institutionName: '',
      campusImage: '',
      institutionInfo: '',
      showProfile: false,
      profileIndex: 60,
      campusDetailModal: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      userUsername: 'authentication/userUsername',
      isAmbassador: 'roles/isAmbassador',
      currentHeadMasterMainSchool: 'roles/currentHeadMasterMainSchool',
    }),
    image() {
      if (this.campusDetail.campus_images_thumbnail !== null
      && this.campusDetail.campus_images_thumbnail !== undefined) {
        return `${this.campusDetail.campus_images_thumbnail[0].image_link}?${Date.now()}`;
      }
      return null;
    },
  },
  watch: {
    campusDetail() {
      this.institutionName = this.campusDetail.campus_short_name && this.campusDetail.campus_short_name !== '' ? this.campusDetail.campus_short_name : this.campusDetail.campus_name;
      this.campusImage = this.image;
      this.institutionInfo = this.getInstitutionInfo();
    },
  },
  mounted() {
    if (this.campusDetail) {
      this.institutionName = this.campusDetail.campus_short_name && this.campusDetail.campus_short_name !== '' ? this.campusDetail.campus_short_name : this.campusDetail.campus_name;
      this.campusImage = this.image;
      this.institutionInfo = this.getInstitutionInfo();
    }
  },
  methods: {
    ...mapActions({
      retrieveCampusDetails: 'institutions/retrieveCampusDetails',
    }),
    toggleProfile() {
      this.showProfile = !this.showProfile;
    },
    getInstitutionInfo() {
      if (CONFIG.tenant === 'colombia') {
        return this.campusDetail.campus_name;
      }
      if (CONFIG.tenant === 'chile') {
        return this.campusDetail?.primary_national_code ? `RBD ${this.campusDetail.primary_national_code}` : '';
      }
      if (
        CONFIG.tenant === 'newhaven'
        && this.campusDetail.sector_label
      ) {
        return this.campusDetail.sector_label.sector_name;
      }
      return this.campusDetail.campus_name;
    },
    exploreCampusDetail(school) {
      trackMixPanel('click_dashboard_see-institution-profile_screen', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      this.retrieveCampusDetails({ searchCode: school.uuid });
      this.campusDetailModal = true;
    },
    closeCampusDetail() {
      this.campusDetailModal = false;
    },
    goTo(page) {
      this.$router.push(page);
      trackMixPanel('click_dashboard_edit-institution-profile_screen', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
    },
  },
};
</script>
