<template>
  <div>
    <div v-if="sdkInterface" class="title-sections__bank">
      <div class="full-width d-flex flex-row align-center justify-between">
        <TextAtom
          class="title-sections__waiting--list"
          :value="$t(`dashboard.digital_profile.socials.connect_insta.${alreadyLinked}.title`)"
          tag="label"
          font="inter"
          color="primary-dark"
          weight="500"
          size="title"
        />
        <SvgIcon :icon="getIcon()" :size="'25'" style="margin-left: 16px" />
      </div>
      <TextAtom
        class="title-sections__waiting--list text-left pr-12"
        :value="$t(`dashboard.digital_profile.socials.connect_insta.${alreadyLinked}.subtitle`)"
        tag="label"
        font="inter"
        color="neutral-700"
        weight="400"
        size="normal"
      />
      <div class="flex items-center gap-x-4">
        <LoadingSpinner
          v-if="authenticating"
          show
          :size="20"
          class="mt-3"
        />
        <button type="button" class="facebook-button" @click="login()" @keydown="login()">
          <ImageAtom
            style="width: 20px; margin-right: 5px;"
            :source="require('@/assets/logos/providers/facebook_logo.png')"
            :alt-text="'facebook-logo'"
          />
          <TextAtom
            :value="$t(`dashboard.digital_profile.socials.connect_insta.${alreadyLinked}.connect`)"
            color="white"
            weight="600"
            size="small"
          />
        </button>
      </div>
    </div>

    <div
      class="dashboard-grid grid-rows-1"
      :style="{ '--element-size': '1fr' }"
    >
      <EditableGroup :fields="fields" :autofocus="false" @setObject="setBody" />
    </div>
    <SaveButton :loading="saving" @save="save" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';
import services from '@/services';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import ImageAtom from '@/components/atoms/images/Image.vue';
import { setupMetaSDK } from '@/utils/metaIntegrations';
import LoadingSpinner from '@/components/atoms/loaders/LoaderCircular.vue';
import SaveButton from '../../utils/SaveButton.vue';
import EditableGroup from '../../utils/EditableGroup.vue';

export default {
  name: 'SocialsSection',
  components: {
    EditableGroup,
    SaveButton,
    TextAtom,
    SvgIcon,
    LoadingSpinner,
    ImageAtom,
  },
  props: { },
  data() {
    return {
      saving: false,
      sdkInterface: null,
      body: Object(),
      tokenBody: Object(),
      authenticating: false,
      selectedFacebookPage: {},
      facebookTokenPatched: false,
      showEye: true,
      token: '',
      fields: [
        {
          icon: 'mdi-web',
          type: 'text',
          title: this.$t('dashboard.digital_profile.socials.web.title'),
          fieldProps: {
            maxLength: 100,
            counter: 100,
            label: this.$t('dashboard.digital_profile.socials.web.label'),
          },
          varName: '1',
          input: '',
        },
        {
          icon: 'mdi-facebook',
          type: 'text',
          fieldProps: {
            maxLength: 100,
            counter: 100,
            label: this.$t('dashboard.digital_profile.socials.facebook.label'),
          },
          title: this.$t('dashboard.digital_profile.socials.facebook.title'),
          varName: '4',
          input: '',
        },
        {
          icon: 'mdi-instagram',
          type: 'text',
          fieldProps: {
            maxLength: 100,
            counter: 100,
            label: this.$t('dashboard.digital_profile.socials.instagram.label'),
          },
          title: this.$t('dashboard.digital_profile.socials.instagram.title'),
          varName: '5',
          input: '',
        },
        {
          icon: 'mdi-youtube',
          type: 'text',
          title: this.$t('dashboard.digital_profile.socials.youtube.title'),
          fieldProps: {
            maxLength: 100,
            counter: 100,
            label: this.$t('dashboard.digital_profile.socials.youtube.label'),
          },
          varName: '6',
          input: '',
        },
        {
          icon: 'mdi-twitter',
          type: 'text',
          fieldProps: {
            maxLength: 100,
            counter: 100,
            label: this.$t('dashboard.digital_profile.socials.twitter.label'),
          },
          title: this.$t('dashboard.digital_profile.socials.twitter.title'),
          varName: '7',
          input: '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      contactLabels: 'options/contactLabels',
    }),
    alreadyLinked() {
      const tokenInfo = this.campusDetail.token_campus.find((token) => token.token_label.name === 'Instagram Business');
      if (tokenInfo) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.sdkInterface = setupMetaSDK().setup();
  },
  beforeDestroy() {
    setupMetaSDK().removeSDK(this.sdkInterface);
  },
  mounted() {
    this.getSocials();
    if (this.token !== null) {
      this.showEye = false;
    }
  },
  methods: {
    ...mapActions({
      campusContactsPatch: 'institutions/campusContactsPatch',
      campusSocialTokenPatch: 'institutions/campusSocialTokenPatch',
      activateModal: 'utils/activateModal',
      raiseError: 'utils/error',
    }),
    getSocials() {
      if (this.campusDetail.institutioncontact_campus.length > 0) {
        this.fields.map((field) => {
          const contact = this.campusDetail.institutioncontact_campus.find(
            (contactLabel) => contactLabel.contact_label.id === parseInt(field.varName, 10),
          );
          if (contact?.webpage) {
            Object.assign(field, { input: contact.webpage });
          }
          const contactId = field.varName;
          this.body[contactId] = contact.webpage;
          return field;
        });
      }
    },
    save() {
      trackMixPanel('click_dashboard_digital_profile_socials_save', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      this.saving = true;
      if (Object.keys(this.body).length > 0) {
        const socialMediaData = [];
        Object.keys(this.body).forEach((contactId) => {
          if (contactId) {
            const webpage = this.body[contactId];
            const contact = this.campusDetail.institutioncontact_campus.find(
              (contactLabel) => contactLabel.contact_label.id === parseInt(contactId, 10),
            );
            socialMediaData.push({
              label_id: contact ? contact.contact_label.id : contactId,
              webpage,
              name: contact ? contact.contact_name : this.getContactName(contactId),
            });
          }
        });
        if (socialMediaData.length > 0) {
          this.campusContactsPatch({
            campusUuid: this.campusDetail.uuid,
            campusData: socialMediaData,
          }).then(() => {
            this.saving = false;
          });
        } else {
          this.saving = false;
        }
      }
      if (Object.keys(this.tokenBody).length > 0) {
        this.campusSocialTokenPatch({
          campusUuid: this.campusDetail.uuid,
          campusData: this.tokenBody,
        }).then(() => {
          this.saving = false;
        });
      }
    },
    setBody(body) {
      this.body = body;
    },
    getContactName(contactId) {
      const newContact = this.contactLabels.find((contact) => contact.id === parseInt(contactId, 10));
      return newContact.contact_name;
    },
    saveFacebookToken() {
      this.saving = true;
      services.facebookAccountsService.getBusinessAccountId(this.selectedFacebookPage.id).then((businessAccId) => {
        this.campusSocialTokenPatch({
          campusUuid: this.campusDetail.uuid,
          campusData: [{
            label_id: 2,
            token_value: this.selectedFacebookPage.access_token,
            expired_in_seconds: null,
            account_id: businessAccId,
          }],
        }).then(() => {
          this.facebookTokenPatched = true;
          this.saving = false;
          this.authenticating = false;
        });
      }).catch(() => {
        this.authenticating = false;
        this.raiseError(this.$t('dashboard.digital_profile.socials.connect_insta.error_getting_instagram'));
      });
    },
    async login() {
      this.authenticating = true;

      services.facebookAccountsService.login().then((response) => {
        const { data: accounts } = response;
        if (accounts.length > 0) {
          if (accounts.length > 1) {
            this.activateModal({
              name: 'SelectFacebookPage',
              data: {
                accounts,
              },
              callbacks: {
                onConfirm: (event) => {
                  this.selectedFacebookPage = event;
                  this.saveFacebookToken();
                },
                onCancel: () => {
                  this.authenticating = false;
                  this.raiseError(this.$t('dashboard.digital_profile.socials.connect_insta.cancelled'));
                },
              },
            });
          } else {
            [this.selectedFacebookPage] = accounts;
            this.saveFacebookToken();
          }
        }
      }).catch(() => {
        this.authenticating = false;
        this.raiseError(this.$t('dashboard.digital_profile.socials.connect_insta.login_error'));
      });
    },
    getIcon() {
      if (this.alreadyLinked || this.facebookTokenPatched) {
        return 'green-check.svg';
      }
      return 'grey-check.svg';
    },
  },
};
</script>
<style scoped>
.facebook-button {
  background-color: #1877F2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 6px;
  margin-top: 10px
}

</style>
