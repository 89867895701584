<template>
  <v-btn
    class="costs-category-box"
    :style="{
      'background-color': backgroundcolor + ' !important',
      height: '50px !important',
    }"
    :disabled="disabled"
    @click="emitValue(paymentlabel)"
  >
    <img
      style="padding-right:10px;"
      alt="down arrow"
      :src="require('@/assets/dashboard/icons/' + icon)"
    />
    <TextAtom
      class="text-left"
      :value="label"
      tag="p"
      font="inter"
      weight="400"
      :color="color"
      size="normal"
    />
    <v-spacer />
  </v-btn>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'CostsCategoryButton',
  components: { TextAtom },
  props: {
    backgroundcolor: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    paymentlabel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitValue(paymentlabel) {
      this.$emit('setValue', { paymentlabelValue: paymentlabel.value, paymentOrder: paymentlabel.order });
    },
  },
};
</script>
