<template>
  <div style="height: 95%">
    <section v-if="dgeLoader">
      <SkeletonLoader :skeleton-type-array="['twoCols']" />
    </section>
    <section v-if="!dgeLoader" class="dgeInfo" @click="widgetClick()" @keydown.enter="widgetClick()">
      <InfoBox
        :info-content="['smallTitle', 'counterChip', 'multiStyleText', 'LabelInfoBox']"
        :title-text="$t('dashboard.dge_headmaster_info.title')"
        :counter-chip-number="interviewNumber"
        :multi-style-text="multiStyleText"
        :info-box-content="infoBoxContent"
        style="margin-right: 20px"
      />
      <div class="fill-width d-flex flex-column justify-space-between" style="height: 100%">
        <InfoBox
          :info-content="['smallTitle', 'counterChipTitle']"
          :title-text="$t('dashboard.dge_headmaster_info.title2')"
          :counter-chip-number="inAdmissionNumber"
          style="height: 47%"
        />
        <InfoBox
          :info-content="['smallTitle', 'counterChipTitle']"
          :title-text="$t('dashboard.dge_headmaster_info.title3')"
          :counter-chip-number="waitingListNumber"
          style="height: 47%"
        />
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SkeletonLoader from '@/components/atoms/loaders/SkeletonLoader.vue';
import InfoBox from '@/components/molecules/sections/InfoBox.vue';
import { redirectToDGE } from '@/utils/redirects';

import trackMixPanel from '@/utils/mixpanel';
import { ADMISSION_STATUS } from '@/constants/digitalEnrollment';

export default {
  name: 'DgeHeadmasterInfo',
  metaInfo: {
    meta: [],
  },
  components: {
    InfoBox,
    SkeletonLoader,
  },
  data() {
    return {
      monthNames: ['De Enero', 'De Febrero', 'De Marzo', 'De Abril', 'De Mayo', 'De Junio',
        'De Julio', 'De Agosto', 'De Septiembre', 'De Octubre', 'De Noviembre', 'De Diciembre'],
      interviewNumber: '0',
      waitingListNumber: '0',
      inAdmissionNumber: '0',
      dgeStudents: null,
      dgeLoader: true,
    };
  },
  computed: {
    ...mapGetters({
      dgeHeadmasterInfo: 'digitalEnrollment/dgeHeadmasterInfo',
    }),
    multiStyleText() {
      const today = new Date();
      const todayString = `${String(today.getDate())} ${this.monthNames[today.getMonth()]}`;
      const todayYear = `${String(today.getFullYear())}`;
      const textArray = [
        {
          text: 'Hoy',
          text2: todayString,
          text3: todayYear,
          tag: 'click_dge-widget_title_date',
          bolder: [2, 3],
          secondary: [1, 2, 3],
          spaced: [2],
          tiny: [1, 2, 3],
        },
      ];
      return textArray;
    },
    infoBoxContent() {
      const infoArray = [];
      if (this.dgeStudents) {
        this.dgeStudents.forEach((student) => {
          infoArray.push({
            name: student,
            icon: 'small-face.svg',
          });
        });
      }
      return infoArray;
    },
  },
  watch: {
    dgeHeadmasterInfo: {
      handler(newVal) {
        let waitingList = 0;
        let inAdmissionProcess = 0;
        let interviewNum = 0;
        const students = [];
        // FIXME: The api is not returning the correct data (no admissions)
        if (newVal.admissions) {
          newVal.admissions.forEach((admission) => {
            if (
              !students.includes(admission.applicant.fullName)
              && (admission.status === ADMISSION_STATUS.SCHEDULED
              || admission.status === ADMISSION_STATUS.INTERVIEWING)
            ) {
              students.push(admission.applicant.fullName);
            }
            if (admission.status === 'WAITING_LIST') {
              waitingList += 1;
            }
            if (
              admission.status === ADMISSION_STATUS.WAITING_CONFIRMATION
              || admission.status === ADMISSION_STATUS.CONFIRMED
              || admission.status === ADMISSION_STATUS.WAITING_SIGNATURE
              || admission.status === ADMISSION_STATUS.CONTRACT_SIGNED
              || admission.status === ADMISSION_STATUS.WAITING_PAYMENT
              || admission.status === ADMISSION_STATUS.PAID
              || admission.status === ADMISSION_STATUS.FILLING_ENROLLMENT_FORM
              || admission.status === ADMISSION_STATUS.ENROLLMENT_FORM_FILLED
              || admission.status === ADMISSION_STATUS.COMPLETED
            ) {
              inAdmissionProcess += 1;
            }
            if (admission.status === ADMISSION_STATUS.SCHEDULED || admission.status === ADMISSION_STATUS.INTERVIEWING) {
              interviewNum += 1;
            }
          });
          this.dgeStudents = students.slice(0, 3);
          this.waitingListNumber = waitingList.toString();
          this.inAdmissionNumber = inAdmissionProcess.toString();
          this.interviewNumber = interviewNum.toString();
        } else if (newVal.summary) {
          const {
            // CANCELLED = 0,
            COMPLETED = 0,
            // CREATED = 0,
            INTERVIEWING = 0,
            SCHEDULED = 0,
            WAITING_LIST = 0,
            WAITING_PAYMENT = 0,
            WAITING_SIGNATURE = 0,
            WAITING_CONFIRMATION = 0,
            CONFIRMED = 0,
            CONTRACT_SIGNED = 0,
            PAID = 0,
            FILLING_ENROLLMENT_FORM = 0,
            ENROLLMENT_FORM_FILLED = 0,
          } = newVal.summary;

          this.dgeStudents = [];
          this.waitingListNumber = (WAITING_LIST).toString();
          this.inAdmissionNumber = (
            WAITING_CONFIRMATION
            + CONFIRMED
            + WAITING_SIGNATURE
            + CONTRACT_SIGNED
            + WAITING_PAYMENT
            + PAID
            + FILLING_ENROLLMENT_FORM
            + ENROLLMENT_FORM_FILLED
            + COMPLETED
          ).toString();
          this.interviewNumber = (SCHEDULED + INTERVIEWING).toString();
        }
        this.dgeLoader = false;
      },
    },
  },
  mounted() {
    this.getDgeHeadmasterResume();
  },
  methods: {
    ...mapActions({
      getDgeHeadmasterResume: 'digitalEnrollment/getDgeHeadmasterResume',
    }),
    widgetClick() {
      trackMixPanel('click_dashboard_digitalenrollment_screen');
      redirectToDGE();
    },
  },
};
</script>
