<template>
  <button
    class="check-button"
    :class="{ 'check-button--dark': main }"
    type="button"
  >
    <p
      v-t="text"
      class="check-button--text"
      @keydown.enter="clickDisabled ? null : changeIcon()"
      @click="clickDisabled ? null : changeIcon()"
    />
    <SvgIcon v-if="icon" :icon="'close-white.svg'" :size="'13'" style="margin-left: 16px" />
  </button>
</template>

<script>
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';

export default {
  name: 'CheckButton',
  components: {
    SvgIcon,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    clickDisabled: {
      type: Boolean,
      default: false,
    },
    main: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeIcon() {
    },
  },
};
</script>
