<template>
  <div>
    <div>
      <div class="title-sections__location">
        {{ $t('dashboard.digital_profile.infrastructure.title') }}
      </div>
      <div class="des-sections des-sections-space">
        {{ $t('dashboard.digital_profile.infrastructure.edit') }}
      </div>
      <EditableGroup
        class="mb-5"
        :fields="[
          {
            type: 'textarea',
            title: '',
            varName: 'infrastructure_text',
            input: getInfrastructureText,
            dense: true,
            fieldProps: {
              label: $t('dashboard.digital_profile.infrastructure.add-description'),
            },
          },
        ]"
        @setVariable="setTextBody"
      />
      <div>
        <EditableGroup
          v-if="infrastructuresTypes.length > 0"
          :fields="[
            {
              type: 'checkbox',
              dense: true,
              title: $t('dashboard.digital_profile.infrastructure.choose'),
              input: getInfrastructure(),
              varName: 'infraestructure_campus',
              items: infrastructuresTypes,
            },
          ]"
          @setVariable="setBody"
        />
        <EditableGroup
          v-if="infrastructuresTypes.length > 0"
          :fields="[
            {
              type: 'checkInput',
              title: '',
              dense: true,
              input: getOtherInfo(),
              varName: 'infraestructure_other',
              items: otherInfraTypes,
              infoOther: otherDescrip,
            },
          ]"
          @setVariable="setBody"
        />
      </div>
      <br />
      <div>
        <EditableGroup
          v-if="safetyTypes.length > 0"
          :fields="[
            {
              type: 'checkbox',
              title: $t('dashboard.digital_profile.infrastructure.accesibility'),
              input: getSafety(),
              varName: 'safety',
              dense: true,
              items: safetyTypes,
            },
          ]"
          @setVariable="setBody"
        />
        <EditableGroup
          v-if="infrastructuresTypes.length > 0"
          :fields="[
            {
              type: 'checkInput',
              title: '',
              dense: true,
              input: getOtherInfoInfra(),
              varName: 'safety_other',
              items: otherSafetyTypes,
              infoOther: otherDescripInfra,
            },
          ]"
          @setVariable="setBody"
        />
      </div>
      <SaveButton :loading="saving" @save="save" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'InfrastructureSection',
  components: { EditableGroup, SaveButton },

  data() {
    return {
      saving: false,
      body: {
        infraestructure_campus: [],
        infraestructure_other: [],
        safety: [],
        safety_other: [],
      },
      infrastructuresCampus: [],
      otherDescrip: '',
      otherInfo: false,
      otherInfoSafety: false,
      otherDescripInfra: '',
      safetyCampus: [],
      infraFind: null,
      safetyFind: null,
      otherInfraBody: Object(),
      otherSafetyBody: Object(),
      textBody: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      infrastructureLabels: 'options/infrastructureLabels',
      safetyLabels: 'options/safetyLabels',
    }),
    getInfrastructureText() {
      return this.campusDetail.institutiontext_campus[0]?.infrastructure_text ?? '';
    },
    infrastructuresTypes() {
      const infrastructuresTypes = [];
      this.infrastructureLabels.forEach((i) => {
        if (i.id !== 99) {
          infrastructuresTypes.push({ label: i.infra_name, value: i.id });
        }
      });
      infrastructuresTypes.sort((a, b) => a.label.localeCompare(b.label));
      return infrastructuresTypes;
    },
    otherInfraTypes() {
      const otherInfraTypes = [];
      this.infrastructureLabels.forEach((i) => {
        if (i.id === 99) {
          otherInfraTypes.push({ label: i.infra_name, value: i.id });
        }
      });
      return otherInfraTypes;
    },
    safetyTypes() {
      const safetyTypes = [];
      this.safetyLabels.forEach((i) => {
        if (i.id !== 99) {
          safetyTypes.push({ label: i.name, value: i.id });
        }
      });
      safetyTypes.sort((a, b) => a.label.localeCompare(b.label));
      return safetyTypes;
    },
    otherSafetyTypes() {
      const otherSafetyTypes = [];
      this.safetyLabels.forEach((i) => {
        if (i.id === 99) {
          otherSafetyTypes.push({ label: i.name, value: i.id });
        }
      });
      return otherSafetyTypes;
    },
  },
  async mounted() {
    await this.retrieveOptions({ reset: false });
    if (this.campusDetail.infraestructure_campus) {
      this.campusDetail.infraestructure_campus.forEach((i) => {
        this.infraFind = this.infrastructureLabels.find(
          (infra) => infra.id === i.infra_label.id,
        ).id;
        if (this.infraFind !== 99) {
          this.infrastructuresCampus.push(this.infraFind);
          this.body.infraestructure_campus.push({
            label_id: this.infraFind,
            descrip: i.descrip,
          });
        } else {
          this.otherInfo = true;
          this.otherDescrip = i.descrip;
          this.otherInfraBody = {
            label_id: 99,
            descrip: i.descrip,
          };
        }
      });
    }
    if (this.campusDetail.safety_campus) {
      this.campusDetail.safety_campus.forEach((i) => {
        this.safetyFind = this.safetyLabels.find(
          (safety) => safety.name === i.safety_label.name,
        ).id;
        if (this.safetyFind !== 99) {
          this.safetyCampus.push(this.safetyFind);
          this.body.safety.push({
            label_id: this.safetyFind,
            descrip: i.descrip,
          });
        } else {
          this.otherInfoSafety = true;
          this.otherDescripInfra = i.descrip;
          this.otherSafetyBody = {
            label_id: 99,
            descrip: i.descrip,
          };
        }
      });
    }
  },
  methods: {
    ...mapActions({
      patchCampus: 'institutions/patchCampus',
      campusSafetyPatch: 'institutions/campusSafetyPatch',
      campusInfrastructurePatch: 'institutions/campusInfrastructurePatch',
      retrieveOptions: 'options/retrieveOptions',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),
    setTextBody(body) {
      this.textBody = body;
    },
    setBody(body) {
      if (body.safety) {
        this.body.safety = body.safety.map((x) => ({
          label_id: x,
          descrip: null,
        }));
      }
      if (body.infraestructure_campus) {
        this.body.infraestructure_campus = body.infraestructure_campus.map((x) => ({
          label_id: x,
          descrip: null,
        }));
      }
      if (body.infraestructure_other !== undefined) {
        this.otherInfraBody = {
          label_id: 99,
          descrip: body.infraestructure_other,
        };
      }
      if (body.safety_other !== undefined) {
        this.otherSafetyBody = {
          label_id: 99,
          descrip: body.safety_other,
        };
      }
    },
    async save() {
      this.saving = true;
      if (this.otherInfraBody.descrip) {
        this.body.infraestructure_campus.push(this.otherInfraBody);
      }
      if (this.otherSafetyBody.descrip) {
        this.body.safety.push(this.otherSafetyBody);
      }
      this.campusSectionTextPatch({ campusUuid: this.campusDetail.uuid, campusData: this.textBody });

      if (this.body.safety) {
        await this.campusSafetyPatch({ campusUuid: this.campusDetail.uuid, campusData: this.body.safety })
          .then(() => {
            if (this.body.infraestructure_campus) {
              this.campusInfrastructurePatch(
                {
                  campusUuid: this.campusDetail.uuid,
                  campusData: this.body.infraestructure_campus,
                },
              ).then(() => {
                this.saving = false;
              })
                .catch(() => {
                  this.saving = false;
                });
            }
          })
          .catch(() => {
            this.saving = false;
          });
      }
    },
    getInfrastructure() {
      return this.infrastructuresCampus;
    },
    getSafety() {
      return this.safetyCampus;
    },
    getOtherInfo() {
      return this.otherInfo === true ? 99 : 1;
    },
    getOtherInfoInfra() {
      return this.otherInfoSafety === true ? 99 : 1;
    },
  },
};
</script>
