var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'textarea',
          title: _vm.$t('dashboard.digital_profile.about.description_title'),
          varName: 'profile_summary',
          input: _vm.getProfileSummary(),
          fieldProps: {
            maxLength: 1800,
            counter: 1800,
            label: _vm.$t('dashboard.digital_profile.about.description'),
          },
        },
      ]},on:{"setVariable":_vm.setBody}}),(_vm.profileSummaryIsGenerateByAi)?_c('PlacardGeneratedByAi'):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'text',
            title: _vm.$t('dashboard.digital_profile.about.continuity_title'),
            varName: 'campus_continuity',
            input: _vm.campusDetail.institutiontext_campus[0]?.campus_continuity,
          },
        ]},on:{"setVariable":_vm.setBody}})],1),_c('div',{staticClass:"dashboard-grid",style:({ '--n-elements-per-row': _vm.mobile ? 1 : 2, '--element-size': '1fr' })},[_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'radiobutton',
            title: _vm.$t('dashboard.digital_profile.about.sector_title'),
            input: _vm.getInstitutionSector,
            varName: 'sector_label_id',
            items: _vm.sectorTypes,

          },
        ]},on:{"setVariable":_vm.setBody}}),_c('div',{staticClass:"mt-5"},[_c('EditableGroup',{attrs:{"fields":[
            {
              type: 'checkbox',
              dense: true,
              title: _vm.$t('dashboard.digital_profile.about.agreements_title'),
              input: _vm.getInstitutionAgreements,
              varName: 'agreements',
              items: _vm.agreementsTypes,
              elementsRow: 1,
            },
          ]},on:{"setVariable":_vm.setBody}}),(_vm.isOther)?_c('EditableGroup',{attrs:{"fields":[
            {
              type: 'checkInput',
              title: '',
              dense: true,
              input: _vm.getOtherInfo(),
              varName: 'agreement_other',
              items: _vm.otherType,
              infoOther: _vm.otherDescription,
            },
          ]},on:{"setVariable":_vm.setBody}}):_vm._e()],1)],1)],1),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }