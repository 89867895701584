<template>
  <v-row
    align="center"
    :style="{
      height: height,
    }"
  >
    <v-col justify="center">
      {{ message }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AnalyticsGraphNoDataMessage',
  props: {
    message: {
      required: true,
      type: String,
    },
    detailed: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    height() {
      if (this.mobile && this.detailed) {
        return '40%';
      }
      if (this.detailed) {
        return '88%';
      }
      return '100%';
    },
  },
};
</script>
