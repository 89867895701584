var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2"},[_c('EditableGroup',{staticClass:"mb-5",attrs:{"fields":[
      {
        type: 'textarea',
        title: '',
        varName: 'postulation_text',
        input: _vm.getAdmissionText,
        dense: true,
        fieldProps: {
          label: _vm.$t('dashboard.admissions.add-description'),
        },
      },
    ]},on:{"setVariable":_vm.setBody}}),_c('a',{on:{"click":_vm.clickChangeAdmissions,"keydown":_vm.clickChangeAdmissions}},[_c('div',{staticClass:"sige-button",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"sige-button-text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.admissions.edit_profile_button'))+" ")]),_c('img',{staticStyle:{"position":"absolute","right":"10%"},attrs:{"src":require("@/assets/dashboard/icons/link.svg"),"alt":"link"}})])]),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }