<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'selectionButton']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
        :subtitle-text="'user_register.priority.subtitle'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :selection-button-info="selectionButtonInfo"
        @setSelectionButtonValue="setButtonsValue"
      />
    </div>
    <CallToActions
      style="margin-top: 40px"
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'PreSchoolPriority',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    inputValues: {
      type: Object,
      default() {
        return {};
      },
    },
    preFill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectionButtonInfo: [
        { label: 'user_register.priority_question.yes', isActive: false },
        { label: 'user_register.priority_question.no', isActive: false },
      ],
      isActive: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
    }),
  },
  watch: {
    inputValues: {
      handler(newVal) {
        if (this.prefill) {
          if (newVal && Object.keys(newVal).includes('icbf') && newVal.icbf !== null) {
            if (newVal.icbf) {
              this.setButtonsValue(0);
            } else {
              this.setButtonsValue(1);
            }
          }
        }
      },
    },
  },
  mounted() {
    if (this.preFill) {
      if (
        this.inputValues
        && Object.keys(this.inputValues).includes('icbf')
        && this.inputValues.icbf !== null
      ) {
        if (this.inputValues.icbf) {
          this.setButtonsValue(0);
        } else {
          this.setButtonsValue(1);
        }
      }
    }
  },
  methods: {
    ...mapActions({ setIcbfBoolean: 'newStudentRegister/setIcbfBoolean' }),
    nextStep() {
      if (!this.isActive) {
        return null;
      }
      let icbfBool = true;
      if (this.selectionButtonInfo[1].isActive) {
        icbfBool = false;
      }
      const icbfInfo = {
        uuid: this.currentStudent.uuid,
        hasIcbf: icbfBool,
      };
      this.setIcbfBoolean({ icbfData: icbfInfo }).then(() => {
        this.$emit('nextStep');
      });
      return null;
    },
    setButtonsValue(i) {
      if (i === 0) {
        this.currentSchoolToSet = true;
        this.selectionButtonInfo[0].isActive = true;
        this.selectionButtonInfo[1].isActive = false;
      } else {
        this.currentSchoolToSet = false;
        this.selectionButtonInfo[0].isActive = false;
        this.selectionButtonInfo[1].isActive = true;
      }
      this.isActive = true;
      return i;
    },
  },
};
</script>
