<template>
  <div class="program-single">
    <div class="program-single--container mb-5">
      <EditableGroup
        class="programs-shifts"
        :fields="[
          {
            type: 'dropdown',
            varName: 'grade_id',
            dense: true,
            input: getShiftId,
            items: [...getShiftOptions],
            openModal: true,
            padding: '0px',
            dropdownColor: '#F5F5F5',
            place: $t('dashboard.digital_profile.programs.programs_flow.shift'),
          },
        ]"
        @setObject="setShift"
      />
      <div class="programs-shifts--container__menu">
        <v-menu bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              color="#646464"
              v-bind="attrs"
              v-on="on"
            >
              mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              class="clickable"
              @click="deleteProgram()"
            >
              <v-list-item-title style="color: #CCCCCC;">
                {{ $t('dashboard.profile.delete') }}
              </v-list-item-title>
              <v-icon
                class="ml-2"
                color="#CCCCCC"
              >
                mdi-delete
              </v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="mb-5">
      <div class="mr-15 program-switch">
        <div class="full-width d-flex flex-row align-center justify-between">
          <div class="d-flex flex-column align-start">
            <TextAtom
              class="mt-5"
              :value="$t('dashboard.digital_profile.programs.gender.title')"
              tag="label"
              font="inter"
              color="neutral-850"
              weight="500"
              size="subtitle"
            />
            <TextAtom
              :value="$t('dashboard.digital_profile.programs.gender.subtitle')"
              tag="label"
              font="inter"
              color="neutral-800"
              weight="400"
              size="subtitle"
            />
          </div>
          <v-switch
            v-model="genderSwitch"
            small
            color="#461FD2"
            inset
          />
        </div>

        <v-expand-transition>
          <section v-show="genderSwitch" class="mt-8">
            <div class="pr-6">
              <Gender
                class="mt-5"
                :prefill="getGenderId"
                @setGender="setGenderSelected"
              />
            </div>
          </section>
        </v-expand-transition>
      </div>
    </div>
    <div class="mb-5">
      <div class="mr-15 program-switch">
        <div class="full-width d-flex flex-row align-center justify-between">
          <div class="d-flex flex-column align-start">
            <TextAtom
              class="mt-5"
              :value="$t('dashboard.digital_profile.programs.modality.title')"
              tag="label"
              font="inter"
              color="neutral-850"
              weight="500"
              size="subtitle"
            />
            <TextAtom
              :value="$t('dashboard.digital_profile.programs.modality.subtitle')"
              tag="label"
              font="inter"
              color="neutral-800"
              weight="400"
              size="subtitle"
            />
          </div>
          <v-switch
            v-model="modalitiesSwitch"
            small
            color="#461FD2"
            inset
          />
        </div>

        <v-expand-transition>
          <section v-show="modalitiesSwitch" class="mt-8">
            <div class="pr-6">
              <EditableGroup
                class="programs-shifts"
                :fields="[
                  {
                    type: 'dropdown',
                    varName: 'modality_id',
                    dense: true,
                    input: getModalityId,
                    items: modalitiesGrade,
                    openModal: true,
                    dropdownColor: '#F5F5F5',
                    place: $t('dashboard.digital_profile.programs.modality.title'),
                  },
                ]"
                @setObject="setModality"
              />
            </div>
          </section>
        </v-expand-transition>
      </div>
    </div>
    <div class="mb-5">
      <div class="mr-15 program-switch">
        <div class="full-width d-flex flex-row align-center justify-between">
          <div class="d-flex flex-column align-start">
            <TextAtom
              class="mt-5"
              :value="$t('dashboard.digital_profile.programs.specialty.title')"
              tag="label"
              font="inter"
              color="neutral-850"
              weight="500"
              size="subtitle"
            />
            <TextAtom
              :value="$t('dashboard.digital_profile.programs.specialty.subtitle')"
              tag="label"
              font="inter"
              color="neutral-800"
              weight="400"
              size="subtitle"
            />
          </div>
          <v-switch
            v-model="specialtySwitch"
            small
            color="#461FD2"
            inset
          />
        </div>

        <v-expand-transition>
          <section v-show="specialtySwitch" class="mt-8">
            <div class="pr-6">
              <EditableGroup
                class="programs-shifts"
                :fields="[
                  {
                    type: 'dropdown',
                    varName: 'specialty_id',
                    dense: true,
                    input: getSpecialtyId,
                    items: specialtiesGrade,
                    openModal: true,
                    dropdownColor: '#F5F5F5',
                    place: $t('dashboard.digital_profile.programs.specialty.title'),
                  },
                ]"
                @setObject="setSpecialty"
              />
            </div>
          </section>
        </v-expand-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CONFIG from '@/config';
import { LABELS } from '@/constants/explorer/labels';
import EditableGroup from '../../utils/EditableGroup.vue';
import Gender from './Gender.vue';

export default {
  name: 'ProgramSingle',
  components: {
    EditableGroup,
    TextAtom,
    Gender,
  },
  props: {
    grade: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      genderSwitch: false,
      modalitiesSwitch: false,
      specialtySwitch: false,
      modalitiesGrade: [],
      specialtiesGrade: [],
      modalityId: null,
    };
  },
  computed: {
    ...mapGetters({
      labelsByKey: 'explorer/labelsByKey',
    }),
    getShiftOptions() {
      return this.labelsByKey(LABELS.SHIFTS)
        .map((shift) => ({
          value: shift.id,
          label: shift.shift_name,
        }));
    },
    getShiftId() {
      return this.grade?.shift_label_id;
    },
    getModalityId() {
      return this.grade?.gradetrack?.modality_label_id;
    },
    getSpecialtyId() {
      return this.grade?.gradetrack?.specialty_label_id;
    },
    getGenderId() {
      return this.grade?.gender_label_id;
    },
  },
  watch: {
    genderSwitch: {
      handler(value) {
        if (!value) {
          const id = this.getDefaultGenderId();
          this.setGenderSelected(id);
        }
      },
    },
    modalitiesSwitch: {
      handler(value) {
        if (!value) {
          const id = this.getDefaultModalityId();
          this.setModality(id);
        }
      },
    },
    specialtySwitch: {
      handler(value) {
        if (!value) {
          const id = this.getDefaultSpecialtyId();
          this.setSpecialty(id);
        }
      },
    },
    grade: {
      handler() {
        this.specialtiesGrade = [];
        this.setModalities();
      },
    },
    modalityId: {
      handler() {
        this.setSpecialties();
      },
    },
  },
  mounted() {
    this.genderSwitch = this.grade?.gender_label_id ?? false;
    this.modalitiesSwitch = this.grade?.gradetrack?.modality_label_id ?? false;
    this.modalityId = this.grade?.gradetrack?.modality_label_id ?? null;
    this.specialtySwitch = this.grade?.gradetrack?.specialty_label_id ?? false;
    this.setModalities();
  },
  methods: {
    ...mapActions({
      modalitiesByGrade: 'options/getModalitiesByGrade',
      specialtiesByGrade: 'options/getSpecialtiesByGrade',
    }),
    setShift(shift) {
      this.$emit('setShift', shift);
    },
    setModality(modality) {
      this.modalityId = modality.modality_id;
      this.$emit('setModality', modality);
    },
    setSpecialty(specialty) {
      this.$emit('setSpecialty', specialty);
    },
    deleteProgram() {
      this.$emit('deleteProgram');
    },
    setGenderSelected(gender) {
      this.$emit('setGenderSelected', gender);
    },
    getDefaultGenderId() {
      const { tenant } = CONFIG;
      if (tenant === 'chile' || tenant === 'colombia') {
        return 3;
      } if (tenant === 'newhaven') {
        return 1;
      }
      return 1;
    },
    getDefaultModalityId() {
      return { modality_id: 1 };
    },
    getDefaultSpecialtyId() {
      return { specialty_id: 1 };
    },
    async setModalities() {
      const gradeId = this.grade?.gradetrack?.grade_label_id;
      if (gradeId) {
        const modalities = await this.modalitiesByGrade({
          gradeId: this.grade?.gradetrack.grade_label_id,
          stageId: this.grade?.gradetrack.stage_label_id,
        });
        // eslint-disable-next-line camelcase
        this.modalitiesGrade = modalities.map(({ id, modality_name }) => ({ value: id, label: modality_name }));
      }
    },
    async setSpecialties() {
      const gradeId = this.grade?.gradetrack?.grade_label_id;
      if (gradeId && this.modalityId !== null) {
        const specialties = await this.specialtiesByGrade({
          gradeId: this.grade?.gradetrack.grade_label_id,
          stageId: this.grade?.gradetrack.stage_label_id,
          modalityId: this.modalityId,
        });
        // eslint-disable-next-line camelcase
        this.specialtiesGrade = specialties.map(({ id, specialty_name }) => ({ value: id, label: specialty_name }));
      }
    },
  },
};
</script>
