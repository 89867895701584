var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-between mb-8"},[_c('div',{staticClass:"d-flex"},[_c('SVGIcon',{staticClass:"clickable",attrs:{"icon":"drag-drop.svg","alt-text":"drag","size":"13","name":"dragger"}}),(_vm.contact.label_id === 0)?_c('span',{directives:[{name:"t",rawName:"v-t",value:({
          path: 'dashboard.digital_profile.contacts.title_index',
          args: { index: _vm.index + 1 },
        }),expression:"{\n          path: 'dashboard.digital_profile.contacts.title_index',\n          args: { index: index + 1 },\n        }"}],staticClass:"ml-10 contacts-dashboard__title"}):_c('TextAtom',{staticClass:"ml-10 contacts-dashboard__title",attrs:{"value":_vm.getContactLabelName,"tag":"label","font":"poppins","color":"primary-700","weight":"600","size":"subtitle"}})],1),_c('v-menu',{attrs:{"start":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#646464"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"clickable",on:{"click":function($event){return _vm.$emit('deleteContact', _vm.index)}}},[_c('v-list-item-title',{staticStyle:{"color":"#CCCCCC"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.profile.delete'))+" ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"#CCCCCC"}},[_vm._v(" mdi-delete ")])],1)],1)],1)],1),_c('div',{staticClass:"contacts-dashboard__grid"},[_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'dropdown',
          varName: 'label_id',
          dense: true,
          input: _vm.getContactLabel(_vm.index),
          items: [..._vm.getContactOptions],
          openModal: true,
          dropdownColor: '#F5F5F5',
          place: _vm.$t('dashboard.digital_profile.contacts.contact_label'),
        },
      ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'text',
          varName: 'names',
          dense: true,
          input: _vm.getContactName(_vm.index),
          textColor: '#F5F5F5',
          prependIcon: 'mdi-account',
          title: _vm.$t('dashboard.digital_profile.contacts.name'),
          titleText: false,
          backgroundColor: '#F5F5F5',
        },
      ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'number',
          varName: 'cellphone',
          dense: true,
          input: _vm.getContactCell(_vm.index),
          textColor: '#F5F5F5',
          prependIcon: 'mdi-cellphone',
          title: _vm.$t('dashboard.digital_profile.contacts.mobile'),
          titleText: false,
          backgroundColor: '#F5F5F5',
        },
      ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'number',
          varName: 'phone',
          dense: true,
          input: _vm.getContactPhone(_vm.index),
          textColor: '#F5F5F5',
          prependIcon: 'mdi-cellphone',
          title: _vm.$t('dashboard.digital_profile.contacts.phone'),
          titleText: false,
          backgroundColor: '#F5F5F5',
        },
      ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'text',
          varName: 'email',
          dense: true,
          input: _vm.getContactEmail(_vm.index),
          textColor: '#F5F5F5',
          prependIcon: 'mdi-email-outline',
          title: _vm.$t('dashboard.digital_profile.contacts.email'),
          titleText: false,
          backgroundColor: '#F5F5F5',
        },
      ]},on:{"setObject":_vm.setBody}})],1),_c('EditableGroup',{staticClass:"mt-7",attrs:{"fields":[
      {
        type: 'checkbox',
        dense: true,
        title: _vm.$t('dashboard.digital_profile.contacts.verified_question'),
        input: _vm.isVerified,
        varName: 'verified',
        items: _vm.verifiedType,
        elementsRow: 1,
        disabled: !_vm.canBeVerified,
      },
    ]},on:{"setVariable":_vm.setBody}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }