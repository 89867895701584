<template>
  <div style="position: relative">
    <div v-for="(field, index) in fields" :key="index">
      <Editable
        v-bind="field"
        :in-list="inList"
        :first-in-list="index == 0"
        :autofocus="autofocus"
        @setVariable="setVariable(index, ...arguments)"
        @setInput="setInput"
        @changeToken="changeToken"
      />
    </div>
  </div>
</template>
<script>
import Editable from './Editable.vue';

export default {
  name: 'EditableGroup',
  components: { Editable },
  props: {
    inList: {
      required: false,
      default: false,
      type: Boolean,
    },
    fields: {
      required: true,
      type: Array,
    },
    autofocus: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      body: () => {},
    };
  },
  methods: {
    setInput(input) {
      this.$emit('setInput', input);
    },
    setVariable(index, varName, value) {
      if (value !== undefined) {
        this.body[varName] = value;
        // FIXME: Disabled for now. IDK if this component is used anywhere
        this.fields[index].input = value; // eslint-disable-line
        this.$emit('setInput', varName, value);
        this.$emit('setVariable', { [varName]: value });
        this.constructPatch();
      }
    },
    constructPatch() {
      const object = {};
      this.fields.forEach((field) => {
        object[field.varName] = field.input;
      });
      this.$emit('setObject', object);
    },
    changeToken() {
      this.$emit('changeToken');
    },
  },
};
</script>
