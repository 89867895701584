<template>
  <section
    class="analytics-selector analytics-selector--instagram clickable"
    @click="goTo()"
    @keydown="goTo()"
  >
    <ImageAtom
      class="mb-6"
      :src="require('@/assets/dashboard/widgets/instagram-selector.svg')"
      :alt="'instagram-selector'"
      :object-fit="'fill'"
      :display-option="'inline-flex'"
      :width="'55%'"
    />
    <TextAtom
      :value="$t('dashboard.analytics.selector.title_instagram')"
      font="poppins"
      color="primary-dark"
      weight="500"
      size="title"
    />
    <TextAtom
      class="mt-3"
      :value="$t('dashboard.analytics.selector.subtitle_instagram')"
      font="inter"
      color="neutral-800"
      weight="400"
      size="subtitle"
    />
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';

export default {
  name: 'AnalyticsSelectorInstagram',
  components: {
    TextAtom,
    ImageAtom,
  },
  methods: {
    goTo() {
      this.$router.push({ name: 'AnalyticsInstagram' });
    },
  },
};
</script>
