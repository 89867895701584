<template>
  <section
    class="info-box"
    :class="{
      'info-box--left-title': infoContent.includes('counterChipTitle'),
      'info-box--gray-background': grayBackground,
    }"
  >
    <CounterChip
      v-if="infoContent.includes('counterChipTitle')"
      :chip-number="counterChipNumber"
      style="margin-bottom: 20px"
    />
    <div class="d-flex fill-width align-center">
      <SmallTitle
        v-if="infoContent.includes('smallTitle')"
        :text="titleText"
        primary
        center-left
        no-margin
        :style="infoContent.includes('counterChip') ? 'margin-right: 14px !important' : ''"
      />
      <CounterChip v-if="infoContent.includes('counterChip')" :chip-number="counterChipNumber" />
    </div>
    <div
      v-if="infoContent.includes('multiStyleText')"
      :style="multiTextFillHeight ? 'height: 100%' : 'height: auto'"
    >
      <section
        v-for="section in multiStyleText"
        :key="section.text"
        style="margin-top: 8px; height: 100%"
        class="d-flex justify-center align-center"
      >
        <MultiStyleText
          :bold="section.bold"
          :bolder="section.bolder"
          :link="section.link"
          :underline="section.underline"
          :disabled="section.disabledValue"
          :secondary="section.secondary"
          :spaced="section.spaced"
          :small="section.small"
          :center="section.center"
          :text="section.text"
          :args="section.args"
          :text2="section.text2"
          :text3="section.text3"
          :text4="section.text4"
          @linkClick="$emit('linkClick')"
          @underlineClick="$emit('underlineClick')"
        />
      </section>
    </div>
    <div v-if="infoContent.includes('LabelInfoBox')">
      <section v-for="info, index in infoBoxContent" :key="info.name">
        <LabelInfoBox
          style="margin-bottom: 8px !important"
          :icon="info.icon"
          small-icon
          :text="info.name"
          fill-width
          align-left
          gray-background
          no-margin
          :index="index"
        />
      </section>
    </div>
  </section>
</template>
<script>
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import CounterChip from '@/components/atoms/chips/CounterChip.vue';
import MultiStyleText from '@/components/atoms/labels/MultiStyleText.vue';
import LabelInfoBox from '@/components/atoms/labels/InfoBox.vue';

export default {
  name: 'InfoBox',
  metaInfo: {
    meta: [],
  },
  components: {
    SmallTitle,
    CounterChip,
    MultiStyleText,
    LabelInfoBox,
  },
  props: {
    infoContent: {
      type: Array,
      default() {
        return [];
      },
    },
    titleText: {
      type: String,
      default: '',
    },
    counterChipNumber: {
      type: String,
      default: '',
    },
    multiStyleText: {
      type: Array,
      default() {
        return [];
      },
    },
    infoBoxContent: {
      type: Array,
      default() {
        return [];
      },
    },
    grayBackground: {
      type: Boolean,
      default: false,
    },
    multiTextFillHeight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
