<template>
  <v-container fluid style="height: 100%" @click="onClick()">
    <AnalyticsGraphRenderer :graph-json="chartData" is-section />

    <div class="graph-section-tile-label">
      <div style="width: 100%; height: 100%; position: absolute">
        <span style="font-size: 10px">
          {{ $t('dashboard.analytics.sections.label') }}
        </span>
        <br />
        <span style="position: absolute; bottom: 40px; max-width: 119px; width: 119px">
          {{ sectionTitle }}
        </span>
        <br />
        <span
          style="text-decoration: underline; position: absolute; bottom: 20px"
          @click="onClick()"
          @keydown="onClick()"
        >
          {{ $t('dashboard.analytics.sections.enter') }}
        </span>
      </div>
    </div>
  </v-container>
</template>

<script>
import { Chart as ChartJS, registerables } from 'chart.js';
import AnalyticsGraphRenderer from '../graph/Renderer.vue';

// Lazy way to register everything from the chart.js library
ChartJS.register(...registerables);

export default {
  name: 'AnalyticsSectionTile',
  components: { AnalyticsGraphRenderer },
  props: {
    chartData: {
      required: true,
      type: Object,
    },
    sectionTitle: {
      required: false,
      default: '',
      type: String,
    },
    height: {
      required: false,
      default: '100%',
      type: String,
    },
    id: {
      required: false,
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      windowHeight: window.innerHeight,
      chart: null,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    windowHeight() {
      this.mountChart();
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.mountChart();
  },
  methods: {
    onResize() {
      this.mountChart();
    },
    onClick() {
      this.$emit('selectSection', this.id);
    },
    mountChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new ChartJS(this.$refs.chart, this.chartData);
    },
    maxHeight() {
      return this.height;
    },
  },
};
</script>
