<template>
  <div
    class="chips"
    :class="{
      'chips--disabled': chipNumber === '0',
      'chips--icon': icon !== null,
      [`chips--${type}`]: type !== null,
    }"
  >
    <v-icon v-if="icon">
      {{ icon }}
    </v-icon>
    <p
      v-t="chipNumber"
      class="chips--content"
    />
  </div>
</template>

<script>
export default {
  name: 'CounterChip',
  props: {
    chipNumber: {
      type: String,
      default: '0',
    },
    icon: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'primary',
    },
  },
};
</script>
