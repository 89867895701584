<template>
  <Partnership :partnership="partnership" display="full" />
</template>

<script>

import Partnership from '@/components/organisms/campus/Partnership.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'PartnershipContainer',
  components: {
    Partnership,
  },
  computed: {
    ...mapGetters({
      listPartnership: 'options/partnershipLabels',
    }),
    partnership() {
      return this.listPartnership.find(({ id }) => id === this.idPartnership);
    },
    // Getting the id of the partnership from the route.
    idPartnership() {
      return Number(this.$route.params.id);
    },
  },
};
</script>
