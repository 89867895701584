var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"costs-input-box"},[_c('TextAtom',{staticClass:"pt-4 pl-4",attrs:{"value":"$","tag":"p","font":"poppins","color":"primary-dark","weight":"500","size":"normal"}}),_c('TextAtom',{staticClass:"costs-input-box-separator",attrs:{"value":"|","tag":"p","font":"poppins","weight":"500","size":"big"}}),_c('EditableGroup',{attrs:{"fields":[
      {
        type: 'number',
        varName: 'var',
        input: _vm.quantity,
        extraClasses: 'costs-input-box-input',
        autofocus: false,
        backgroundColor: '#F5F5F5',
      },
    ]},on:{"setObject":_vm.emitValue}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }