<template>
  <v-tooltip bottom :disabled="!loading && !actionable">
    <template #activator="{ on, attrs }">
      <div
        class="dge-application-step"
        :class="{ 'dge-application-step--clickable': actionable }"

        v-bind="attrs"
        title=""
        v-on="on"
        @click="callAction"
        @keydown.enter="callAction"
      >
        <div class="d-flex flex-column flex-grow-1">
          <div class="d-flex flex-row text-left">
            <SVGLogo
              v-if="stepProviderData.hasLogo"
              :logo="provider.logo"
              :alt="provider.name"
              svg-width="40"
              no-margin
              style="width: 44px !important;"
              class="mr-4"
            />
            <MDIIcon
              v-else-if="stepIcon"
              :icon="stepIcon"
              avatar
              class="mr-4"
            />
            <div class="d-flex flex-column text-left">
              <SmallTitle
                :text="getTranslationKey('title').path"
                :args="titleArgs"
                primary
                center-left
                no-margin
              />
              <PrimaryText
                :text="getTranslationKey('subtitle', { ...subtitleArgs, provider: provider.name })"
                center-left
                no-margin
                neutral800
              />
            </div>
          </div>
          <div v-if="notifications?.length" class="mt-2">
            <ColoredIconText
              v-for="(notification, index) in notifications"
              :key="index"
              :icon="notificationIcon(notification) ?? 'mdi-alert-circle-outline'"
              :text="notification.text"
              color="#5627FF"
              italic
              :font-weight="500"
              style="font-size: 16px; line-height: 18px; margin-top: 4px;"
            />
          </div>
        </div>
        <LoaderCircular v-if="loading" show :size="40" :width="5" />
        <ChevronRightButton v-else-if="actionable" />
      </div>
    </template>
    <!-- Tooltip Description -->
    <span v-t="actionDescription" />
  </v-tooltip>
</template>

<script>
import ChevronRightButton from '@/components/atoms/buttons/ChevronRightButton.vue';
import MDIIcon from '@/components/atoms/icons/MdiIcon.vue';
import PrimaryText from '@/components/atoms/labels/PrimaryText.vue';
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import SVGLogo from '@/components/atoms/logos/SvgLogo.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import ColoredIconText from '@/components/molecules/labels/ColoredIconText.vue';

export default {
  name: 'ApplicationStep',
  components: {
    ChevronRightButton,
    ColoredIconText,
    LoaderCircular,
    MDIIcon,
    PrimaryText,
    SVGLogo,
    SmallTitle,
  },
  props: {
    step: {
      type: String,
      required: true,
    },
    notifications: {
      type: Array,
      default: () => [],
    },
    action: {
      type: Function,
      default: null,
    },
    titleArgs: {
      type: Object,
      default: () => ({}),
    },
    subtitleArgs: {
      type: Object,
      default: () => ({}),
    },
    stepProviderData: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actionable() {
      return this.action !== null && !this.loading;
    },
    stepIcon() {
      return this.getTranslationKey('icon', {}, true);
    },
    provider() {
      const name = this.$t(`digital_enrollment.providers.${this.stepProviderData.type}.${this.stepProviderData.key}`);
      const logo = `providers/${this.stepProviderData.type}/${this.stepProviderData.key}.svg`;
      return { name, logo };
    },
    actionDescription() {
      return this.getTranslationKey(this.loading ? 'loading' : 'action', { provider: this.provider.name }, true);
    },
  },
  methods: {
    getTranslationKey(key, args = {}, getValue = false) {
      const i18nKey = `digital_enrollment.application.steps.${this.step}.${key}`;
      if (getValue) {
        const value = this.$t(i18nKey, args);
        if (value !== i18nKey && value !== '') {
          return value;
        }
        return null;
      }
      return { path: i18nKey, args };
    },
    notificationIcon(notification) {
      return notification.icon ?? 'mdi-alert-octagon-outline';
    },
    callAction() {
      if (this.action && typeof this.action === 'function') {
        this.action();
      }
    },
  },
};
</script>
