<template>
  <div>
    <div v-if="loaded" class="gallery-buttons d-flex justify-end flex-column">
      <div>
        <!-- thumbnail -->
        <div class="gallery-button-thumb text-left p-1 mb-0 d-flex flex-row" style="height: 50px">
          <span v-t="'dashboard.gallery.gallery-edit.title'" class="mt-2 mr-2 mb-0" />
          <div style="width: 180px">
            <div class="gallery-label">
              <v-file-input
                v-model="rawUploadedThumbnail"
                :rules="ruleSingle"
                accept="image/jpeg, image/png"
                :placeholder="$t('dashboard.gallery.gallery-edit.add_images')"
                prepend-icon=""
                :label="$t('dashboard.gallery.gallery-edit.add_images')"
                solo
                dense
                min-height="10"
                max-height="10"
                @change="onThumbnailChange"
                @click.stop
              >
                <template v-if="rawUploadedThumbnail" #prepend-inner>
                  <div v-t="'dashboard.gallery.gallery-edit.add_images'" class="label-images" />
                </template>
              </v-file-input>
            </div>
          </div>
        </div>

        <div
          class="relative"
        >
          <div
            v-if="currentThumbnail !== null && (currentThumbnail.image_link || currentThumbnail.imagebase64)"
            class="image-container"
          >
            <button type="button" class="close-image" @click="removeThumbnail">
              <span>&times;</span>
            </button>
            <v-img
              class="cell"
              max-height="150"
              max-width="170"
              :src="currentThumbnail.image_link ?? currentThumbnail.imagebase64"
            />
          </div>
          <div v-else v-t="'dashboard.gallery.gallery-edit.no_image'" class="dashboard-instruction-text w-100" />
        </div>
        <!-- end thumbnail -->
      </div>
      <v-divider class="mt-2 mb-2" />
      <!-- gallery  -->
      <div>
        <!-- images to upload -->
        <div :class="mobile ? 'd-flex flex-column' : 'd-flex flex-row'">
          <div class="text-left p-1">
            {{ galleryImagesToUpload.length > 0
              ? 'Imágenes a subir' : $t('dashboard.gallery.gallery-edit.your_images') }}
          </div>
          <div v-if="mobile" class="mt-1 w-100" />
          <div class="mr-auto" />
          <div class="gallery-buttons__add-images gallery-label" style="position: relative">
            <v-file-input
              v-if="!showSelect"
              v-model="rawGalleryUploads"
              multiple
              :rules="rules"
              accept="image/jpeg, image/png"
              :placeholder="$t('dashboard.gallery.gallery-edit.add_images')"
              prepend-icon=""
              :label="$t('dashboard.gallery.gallery-edit.add_images')"
              solo
              dense
              min-height="10"
              max-height="10"
              clear-icon=""
              @change="onImagesUpload"
              @click.stop
            >
              <template v-if="rawGalleryUploads.length > 0" #prepend-inner>
                <div v-t="'dashboard.gallery.gallery-edit.add_images'" class="label-images" />
              </template>
            </v-file-input>
          </div>
          <div v-if="mobile" class="mt-1 w-100" />
          <v-btn
            v-if="galleryImagesToUpload.length > 0"
            v-t="'dashboard.gallery.gallery-edit.confirm'"
            class="dashboard-button"
            color="#2B5BFF"
            @click="addImages"
          />
          <v-btn
            v-if="showSelect && hasSelected"
            class="dashboard-button-secondary delete-button"
            color="#2B5BFF"
            @click="deleteImages"
          >
            {{ $t('dashboard.gallery.gallery-edit.delete') }}
          </v-btn>
          <v-btn
            v-if="galleryImagesToUpload.length == 0"
            class="dashboard-button-secondary button-right gallery-buttons__select-images"
            @click="toggleSelect"
          >
            {{ showSelect
              ? $t('dashboard.gallery.gallery-edit.unselect') : $t('dashboard.gallery.gallery-edit.select') }}
          </v-btn>
        </div>
        <draggable
          v-if="galleryImagesToUpload.length > 0"
          v-model="galleryImagesToUpload"
          :options="{ disabled: true }"
        >
          <transition-group tag="div" class="grid" name="grid">
            <div v-for="(item, index) in galleryImagesToUpload" :key="index">
              <div class="image-multiple-contain">
                <button type="button" class="close-image__multiple" @click="removeImageList(index)">
                  <span>&times;</span>
                </button>
                <v-img
                  class="cell cell-dragable"
                  max-height="218"
                  max-width="309"
                  :src="item.imagebase64"
                />
              </div>
            </div>
          </transition-group>
        </draggable>
        <!-- end images to upload -->
        <!-- uploaded images -->
        <div class="draggable-container dashboard-scrollbar">
          <!-- buttons -->
          <div class="d-flex flex-row">
            <div class="text-left p-1 mr-auto">
              {{ galleryImagesToUpload.length > 0 ? $t('dashboard.gallery.gallery-edit.your_images') : '' }}
            </div>
          </div>
          <!-- end buttons -->
        </div>
        <!-- end uploaded images -->
        <!-- Gallery -->
        <draggable v-model="galleryImages" class="gallery-draggable">
          <transition-group tag="div" class="grid" name="grid">
            <section
              v-for="(item, index) in galleryImages"
              :key="index"
            >
              <v-img
                class="cell cell-draggable"
                max-height="218"
                max-width="309"
                :src="item.imageUrl ?? item.imagebase64"
              >
                <div class="gallery-draggable__index">
                  {{ index + 1 }}
                </div>
                <v-checkbox
                  v-if="showSelect"
                  v-model="item.selected"
                  color="#2B5BFF"
                  class="gallery-draggable__checkbox-selected"
                />
              </v-img>
            </section>
          </transition-group>
        </draggable>
      </div>
      <!-- end gallery -->
    </div>
    <!-- save button -->

    <div
      class="gallery-buttons__saving"
      :style="
        mobile
          ? 'position: fixed; bottom: 10px; right: 10px'
          : 'position: fixed; bottom: 20px; right: 20px'
      "
    >
      <v-btn class="dashboard-button" color="#2B5BFF" :disabled="uploading" @click="confirm">
        {{ uploading ? $t('dashboard.gallery.gallery-edit.saving') : $t('dashboard.gallery.gallery-edit.save') }}
      </v-btn>
    </div>
    <!-- end save button -->
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ImageUploader',
  components: {
    draggable,
  },
  data() {
    return {
      // Thumbnail related data
      thumbnailHasBeenCleared: false, // flag to know if the thumbnail was cleared
      thumbnailHasBeenUploaded: false, // flag to know if a new thumbnail was uploaded
      rawUploadedThumbnail: null,
      uploadedThumbnail: null,

      // Gallery related data
      rawGalleryUploads: [], // raw image inputs
      galleryImagesToUpload: [], // processed images ready to upload to the server
      galleryImages: [], // images already uploaded to the server

      // Internal flags
      uploading: false,
      loaded: false,
      showSelect: false,

      // Rules for the file input
      rules: [
        (files) => !files || !files.some((file) => file.size > 3150000) || 'Las imágenes debe ser menor de 3MB!',
      ],
      ruleSingle: [
        (file) => !file || file.size < 3150000 || 'La imagen debe ser menor de 3MB!',
      ],
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      currentHeadMasterSchools: 'roles/currentHeadMasterSchools',
    }),
    campusThumbnail() {
      if (this.campusDetail.campus_images_thumbnail !== null && this.campusDetail.campus_images_thumbnail.length > 0) {
        const [thumbnail] = this.campusDetail.campus_images_thumbnail;
        return thumbnail;
      }
      return null;
    },
    currentThumbnail() {
      if (this.thumbnailHasBeenUploaded) {
        return this.uploadedThumbnail;
      }
      return this.thumbnailHasBeenCleared ? null : this.campusThumbnail;
    },
    hasSelected() {
      return this.galleryImages.filter((image) => image.selected).length > 0;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    campusDetail: {
      handler() {
        this.thumbnailHasBeenCleared = false;
        this.thumbnailHasBeenUploaded = false;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.getImagesFromList(this.campusDetail.campus_images_gallery);
  },
  methods: {
    ...mapActions({
      campusImagesPatch: 'institutions/campusImagesPatch',
      error: 'utils/error',
    }),
    removeThumbnail() {
      this.thumbnailHasBeenCleared = true;
      this.thumbnailHasBeenUploaded = false;
    },
    removeImageList(i) {
      this.galleryImagesToUpload.splice(i, 1);
    },
    toggleSelect() {
      this.showSelect = !this.showSelect;
      if (!this.showSelect) {
        for (let i = 0; i < this.galleryImages.length; i += 1) {
          this.galleryImages[i].selected = false;
        }
      }
    },
    confirm() {
      trackMixPanel('click_dashboard_gallery_save', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      this.uploading = true;
      const campusData = [...this.galleryImages];
      if (this.currentThumbnail) {
        campusData.push(this.currentThumbnail);
      }
      const campusImagesPatchData = [];
      campusData.forEach((image, index) => {
        campusImagesPatchData.push(
          {
            id: image.id,
            imageUrl: image.imageUrl,
            order: index,
            image_name: image.image_name ?? '',
            image_description: image.image_description ?? '',

          },
        );
        if (image.imagebase64) {
          campusImagesPatchData[index].imagebase64 = image.imagebase64;
        }
      });
      this.campusImagesPatch({
        campusUuid: this.campusDetail.uuid,
        campusData: campusImagesPatchData,
      }).then(() => {
        this.uploading = false;
      }).catch(() => {
        this.uploading = false;
      });
    },

    getImagesFromList(galleryImages) {
      if (galleryImages === null) {
        this.galleryImages = [];
        this.loaded = true;
      } else {
        this.galleryImages = galleryImages.map((image, index) => ({
          selected: false,
          imageUrl: image.image_link,
          id: image.id,
          order: index,
        }));
        this.loaded = true;
      }
    },
    onThumbnailChange() {
      if (this.rawUploadedThumbnail.size < 3150000) {
        this.createThumbnailImage(this.rawUploadedThumbnail);
      }
    },
    createThumbnailImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedThumbnail = {
          image_name: 'Thumbnail',
          imagebase64: e.target.result,
          order: 0,
        };
      };
      reader.readAsDataURL(file);
      this.thumbnailHasBeenUploaded = true;
    },
    createGalleryImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (file.name.toLowerCase() !== 'thumbnail') {
          this.galleryImagesToUpload.push({
            image_name: file.name,
            imagebase64: e.target.result,
            selected: false,
            order: this.galleryImages.length,
          });
        }
      };
      reader.readAsDataURL(file);
    },
    onImagesUpload() {
      for (let i = 0; i < this.rawGalleryUploads.length; i += 1) {
        if (this.rawGalleryUploads[i].size < 3150000) {
          this.createGalleryImage(this.rawGalleryUploads[i]);
        }
      }
      this.rawGalleryUploads = [];
    },
    addImages() {
      this.galleryImages = [...this.galleryImages, ...this.galleryImagesToUpload];
      this.galleryImagesToUpload = [];
    },
    deleteImages() {
      this.galleryImages = this.galleryImages.filter((el) => el.selected === false);
      this.toggleSelect();
    },
  },
};
</script>
