<template>
  <div class="notifications">
    <!-- <MessagesModal
      :groups="messageByCategory"
      :show-modal="showModal"
      @toggleModal="toggleModal"
    /> -->
    <div
      v-for="({ type, text }, i) in myNotifications.general"
      :key="i"
      class="notifications__container"
    >
      <div class="notifications__container_img">
        <img
          v-if="type === 0"
          src="@/assets/dashboard/icons/red-point.svg"
          style="margin-right: 5px"
          alt="red-point"
        />
        <img
          v-else
          src="@/assets/dashboard/icons/green-point.svg"
          style="margin-right: 5px"
          alt="green-point"
        />
      </div>
      <div class="notifications__container_text">
        <span>
          {{ text }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// import MessagesModal from '../modals/MessagesModal.vue';

export default {
  name: 'MessagesSection',
  metaInfo: {
    meta: [],
  },
  // components: { MessagesModal },
  data() {
    return {
      // showModal: false,
      loaded: false,
      myMessages: 0,
      messageByCategory: Object(),
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    myNotifications() {
      const myNotifications = {
        general: [
          {
            text: this.$t('dashboard.notifications.text1'),
            type: 0,
          },
          {
            text: this.$t('dashboard.notifications.text2'),
            type: 1,
          },
          {
            text: this.$t('dashboard.notifications.text3'),
            type: 2,
          },
        ],
      };
      return myNotifications;
    },
  },
  mounted() {
    if (this.campusDetail) {
      this.getGroupedMessages({
        groupBy: 'message_title',
        index: 'school_uuid',
        uuid: this.campusDetail.uuid,
      }).then((response) => {
        if (response.status === 404) {
          this.messageByCategory = Object();
        } else {
          this.messageByCategory = response.data;
          this.myMessages = Object.values(this.messageByCategory).reduce((a, b) => a + b);
        }
        this.loaded = true;
      });
    }
  },
  methods: {
    ...mapActions({
      getGroupedMessages: 'contactForm/getGroupedMessages',
    }),
  },
};
</script>
