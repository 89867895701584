<template>
  <div class="graph-bottom-right-button-container">
    <v-btn depressed text color="primary" @click="$emit('click')">
      {{ $t('dashboard.analytics.graph.enter') }}
      <v-icon right size="22px">
        mdi-fullscreen
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsGraphEnterButton',
};
</script>
