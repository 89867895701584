<template>
  <div class="auto-schools">
    <v-select
      :class="
        mobile
          ? 'dashboard-navbar__dropdown-mobile '
          : 'dashboard-navbar__dropdown-desktop'
      "
      item-value="value"
      :items="items"
      item-text="label"
      hide-details
      max-width="30"
      width="30"
      dense
      solo
      :placeholder="placeholder"
      @change="changeState"
    />
  </div>
</template>

<script>
export default {
  name: 'NavbarSelect',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    if (this.selectValue) {
      this.value = this.selectValue;
    }
  },
  methods: {
    changeState(index) {
      this.$emit('setChange', index);
    },
  },
};
</script>
