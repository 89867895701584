<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'autoComplete']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
        :subtitle-text="'user_register.sisben_priority.subtitle'"
        :auto-complete-placeholder="'user_register.sisben_priority.placeholder'"
        :auto-complete-items="setGroupList()"
        :autocomplete-no-info-error="noInfoError && !group"
        :autocomplete-initial-value="defaultCategory"
        @setAutoComplete="setGroup"
      />
    </div>
    <CallToActions
      style="margin-top: 40px"
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      small
      :loader="sisbenLoader"
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'SisbenPriority',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    inputValues: {
      type: Object,
      default() {
        return {};
      },
    },
    preFill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noInfoError: false,
      noSisbenPriority: null,
      group: null,
      sisbenLoader: false,
      defaultCategory: '',
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      sisbenGroups: 'options/sisbenGroups',
      hasIcbf: 'newStudentRegister/hasIcbf',
      modalInfoFlow: 'userRegister/modalInfoFlow',
    }),
    isActive() {
      if (this.group || this.noSisbenPriority) {
        return true;
      }
      return false;
    },
    /* WHEN SYSTEM MODAL ITS ENABLED
    setModalUuid() {
      if (this.modalInfoFlow && this.modalInfoFlow.length > 0) {
        const infoModal = this.modalInfoFlow.find((modal) => {
          if (modal.modal_code.indexOf('w') > -1) {
            return modal;
          }
          return null;
        });
        if (infoModal) {
          return infoModal.uuid;
        }
      }
      return null;
    },
    currentRoute() {
      return this.$route.name;
    },
    */
  },
  watch: {
    inputValues: {
      handler(newVal) {
        if (this.preFill) {
          if (newVal && Object.keys(newVal).includes('sisben')) {
            this.group = newVal.sisben;
            this.defaultCategory = this.sisbenGroups.find(
              (group) => group.id === newVal.sisben,
            ).sisben_category_name;
          }
        }
      },
    },
  },
  mounted() {
    if (this.preFill) {
      if (this.inputValues && Object.keys(this.inputValues).includes('sisben')) {
        this.group = this.inputValues.sisben;
        this.defaultCategory = this.sisbenGroups.find(
          (group) => group.id === this.inputValues.sisben,
        ).sisben_category_name;
      }
    }
  },
  methods: {
    ...mapActions({
      setStudentSisbenfGroup: 'newStudentRegister/setStudentSisbenfGroup',
      getApplicantCheckIcbf: 'newStudentRegister/getApplicantCheckIcbf',
      setModalFlowInfo: 'userRegister/setModalFlowInfo',
    }),
    setGroupList() {
      const sisbenLabels = [];
      this.sisbenGroups.forEach((group) => {
        sisbenLabels.push(group.sisben_category_name);
      });
      return sisbenLabels;
    },
    setGroup(value) {
      if (value) {
        const groupId = this.sisbenGroups.find((group) => group.sisben_category_name === value).id;
        this.group = groupId;
      }
      return this.group;
    },
    nextStep() {
      this.sisbenLoader = true;
      if (!this.isActive) {
        return null;
      }
      if (this.noSisbenPriority) {
        this.sisbenLoader = false;
        return this.$emit('nextStep');
      }
      const icbfData = {
        uuid: this.currentStudent.uuid,
        hasSisben: true,
        category: this.group,
      };
      /*
      if (this.currentRoute === 'Dashboard') {
        const modalInfo = {
          user: '',
          modal_code: '6M.w',
          modal_response: true,
          action_completed: true,
        };
        const modalUuid = this.setModalUuid;
        this.setModalFlowInfo({ modalInfo, modalUuid });
      }
      */
      this.setStudentSisbenfGroup({ icbfData }).then(() => {
        this.getApplicantCheckIcbf().then(() => {
          this.sisbenLoader = false;
          if (this.hasIcbf.response) {
            this.$emit('toIcbfInfo');
          } else {
            this.$emit('nextStep');
          }
        });
      });

      return null;
    },
  },
};
</script>
