var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dashboard-grid",style:({ '--n-elements-per-row': _vm.mobile ? 1 : 1, '--element-size': '1fr' })},[_c('div',[_c('div',{directives:[{name:"t",rawName:"v-t",value:('dashboard.digital_profile.audiovisual_material.title'),expression:"'dashboard.digital_profile.audiovisual_material.title'"}],staticClass:"title-sections"}),_c('div',{staticClass:"des-sections",staticStyle:{"margin-bottom":"16px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.audiovisual_material.info'))+" ")]),_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'text',
            title: _vm.$t('dashboard.digital_profile.audiovisual_material.title_input_tour'),
            varName: 'tour_link',
            input: _vm.getAudiovisualLink(2),
          },
          {
            type: 'text',
            title: _vm.$t('dashboard.digital_profile.audiovisual_material.title_input_overflight'),
            varName: 'overflight_link',
            input: _vm.getAudiovisualLink(1),
          },
        ]},on:{"setObject":_vm.setBody}})],1),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }