var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subsection",style:({
    '--clickable': _vm.clickable ? 'pointer' : 'auto',
    '--height': _vm.height,
  }),on:{"click":_vm.onClick,"keydown":_vm.onClick}},[_c('div',{staticClass:"subsection-content",style:({
      '--bg-color': _vm.bgColor,
      '--padding': _vm.padding,
      '--color': _vm.color,
      '--flex': _vm.flex,
      '--align': _vm.align,
    })},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }