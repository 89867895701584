var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',{directives:[{name:"t",rawName:"v-t",value:('dashboard.digital_profile.vacancies.title_field'),expression:"'dashboard.digital_profile.vacancies.title_field'"}],staticClass:"title-sections__location"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('dashboard.digital_profile.vacancies.description'),expression:"'dashboard.digital_profile.vacancies.description'"}],staticClass:"des-sections__location mb-5"}),_c('EditableGroup',{staticClass:"mb-5",attrs:{"fields":[
      {
        type: 'textarea',
        title: '',
        varName: 'vacancies_text',
        input: _vm.committedText,
        dense: true,
        fieldProps: {
          label: _vm.$t('dashboard.digital_profile.vacancies.add-description'),
        },
      },
    ]},on:{"setVariable":_vm.setBody}}),_c('v-card',{staticClass:"programs-card pb-20",attrs:{"color":"#FFFFFF00"}},[_c('Tabs',{attrs:{"current-year":_vm.currentYear,"second-year":_vm.secondYear,"third-year":_vm.thirdYear,"disabled":_vm.tabDisabled},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}),(_vm.loading || _vm.committing)?_c('LoaderCircular',{staticClass:"mx-auto my-8",attrs:{"show":_vm.loading || _vm.committing,"size":60,"color":"#461FD2","width":8}}):[_c('div',{staticClass:"flex flex-row justify-end"},[_c('TextAtom',{attrs:{"value":"dashboard.digital_profile.vacancies.toggle_header","tag":"label","size":"small"}})],1),_vm._l((_vm.sortedGradesList),function(grade){return _c('div',{key:`grade-${grade.id}-${grade.showVacancies}`},[_c('div',{staticClass:"mt-2 d-flex flex-column full-width bg-gray-section rounded-lg px-4"},[_c('div',{staticClass:"full-width d-flex flex-row align-center justify-between"},[_c('h3',{staticClass:"title-sections__location body-1 font-weight-bold"},[_vm._v(" "+_vm._s(grade.label)+" ")]),_c('v-switch',{attrs:{"input-value":(_vm.gradeVisibilityChanges[grade.id] ?? grade.showVacancies ?? -1),"true-value":1,"false-value":0,"indeterminate":(_vm.gradeVisibilityChanges[grade.id] ?? grade.showVacancies) === -1,"small":"","color":"#461FD2","inset":""},on:{"change":(value) => _vm.setChangeOfVisibility(grade.id, value)}})],1)])])})]],2),(!_vm.loading)?_c('SaveButton',{attrs:{"loading":_vm.committing},on:{"save":_vm.commitChanges}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }