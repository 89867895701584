<template>
  <StateChip :chip-state="state" :fit-content="fitContent" />
</template>

<script>
import StateChip from '@/components/atoms/chips/StateChip.vue';

export default {
  name: 'ApplicationStatusChip',
  components: { StateChip },
  props: {
    admissionStatus: {
      type: String,
      required: true,
    },
    fitContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    state() {
      return {
        name: `digital_enrollment.application.steps.${this.admissionStatus}.status.text`,
        type: this.$t(`digital_enrollment.application.steps.${this.admissionStatus}.status.type`),
      };
    },
  },

};
</script>
