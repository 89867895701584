<template>
  <div>
    <div
      class="dashboard-grid"
      :style="{ '--n-elements-per-row': mobile ? 1 : 1, '--element-size': '1fr' }"
    >
      <div>
        <div v-t="'dashboard.digital_profile.audiovisual_material.title'" class="title-sections" />
        <div class="des-sections" style="margin-bottom: 16px">
          {{ $t('dashboard.digital_profile.audiovisual_material.info') }}
        </div>
        <EditableGroup
          :fields="[
            {
              type: 'text',
              title: $t('dashboard.digital_profile.audiovisual_material.title_input_tour'),
              varName: 'tour_link',
              input: getAudiovisualLink(2),
            },
            {
              type: 'text',
              title: $t('dashboard.digital_profile.audiovisual_material.title_input_overflight'),
              varName: 'overflight_link',
              input: getAudiovisualLink(1),
            },
          ]"
          @setObject="setBody"
        />
      </div>

      <SaveButton :loading="saving" @save="save" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'AudiovisualMaterial',
  components: { EditableGroup, SaveButton },
  props: {
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      mainInfo: Object(),
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      monthlyPayments: 'options/monthlyPayments',
      currentHeadMasterSchools: 'roles/currentHeadMasterSchools',
    }),
  },
  async mounted() {
    await this.retrieveOptions({ reset: false });
    this.loaded = true;
  },
  methods: {
    ...mapActions({
      campusAudiovisualsPatch: 'institutions/campusAudiovisualsPatch',
      retrieveOptions: 'options/retrieveOptions',
    }),

    setBody(body) {
      this.mainInfo = body;
    },
    save() {
      this.saving = true;
      const campusData = [];
      if (this.mainInfo) {
        if (this.mainInfo.overflight_link || this.mainInfo.overflight_link !== '') {
          const overFlightObj = {
            label_id: 1,
            audiovisual_link: Object.keys(this.mainInfo).length === 0
              ? this.getAudiovisualLink(1) : this.mainInfo.overflight_link,
            name: null,
            audiovisual_order: 1,
          };
          campusData.push(overFlightObj);
        }
        if (this.mainInfo.tour_link || this.mainInfo.tour_link !== '') {
          const tourLinkObj = {
            label_id: 2,
            audiovisual_link: Object.keys(this.mainInfo).length === 0
              ? this.getAudiovisualLink(2) : this.mainInfo.tour_link,
            name: null,
            audiovisual_order: 1,
          };
          campusData.push(tourLinkObj);
        }
      }
      this.campusDetail.audiovisual_campus?.forEach((audiovisual) => {
        if (audiovisual.audiovisual_label.id !== 1 && audiovisual.audiovisual_label.id !== 2) {
          campusData.push(
            {
              label_id: audiovisual.audiovisual_label.id,
              audiovisual_link: audiovisual.audiovisual_link,
              name: audiovisual.name,
              audiovisual_order: audiovisual.audiovisual_order,
            },
          );
        }
      });
      this.campusAudiovisualsPatch({ campusUuid: this.campusDetail.uuid, campusData }).then(() => {
        this.saving = false;
      });
    },
    getAudiovisualLink(id) {
      // id = audiovisual label id
      if (this.campusDetail.audiovisual_campus.length > 0) {
        const tour = this.campusDetail.audiovisual_campus.find(
          (audiovisual) => audiovisual.audiovisual_label.id === id,
        );
        if (tour) {
          return tour.audiovisual_link;
        }
      }
      return null;
    },
  },
};
</script>
