<template>
  <div style="padding-top: 1.2rem; padding-left: 1rem">
    <div class="title-sections__location">
      {{ $t('dashboard.digital_profile.location.title') }}
    </div>
    <div class="des-sections__location">
      {{ $t('dashboard.digital_profile.location.title_field') }}
    </div>
    <div class="style-location">
      <LocationModal :from-submodule="'location'" />
    </div>
  </div>
</template>
<script>
import LocationModal from '../../utils/LocationModal.vue';

export default {
  name: 'LocationSection',
  components: { LocationModal },
};
</script>
