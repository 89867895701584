<template>
  <div class="dashboard-section-content">
    <div
      class="dashboard-grid"
      :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr', '--gap': '15px' }"
    >
      <!-- left column -->
      <div v-if="false" class="campus-detail">
        <v-sheet :color="`grey lighten-4`" class="pa-3">
          <v-skeleton-loader
            type="table-heading, image, list-item-three-line,
                  list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line,
                  list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
          />
        </v-sheet>
      </div>
      <div v-else>
        <span v-t="'dashboard.admissions.admission_process.description'" class="dashboard-instruction-text" />
        <EditableGroup
          :fields="[
            {
              icon: 'mdi-calendar',
              varName: 'application_period',
              title: $t('dashboard.admissions.admission_process.process_time'),
              disabled: isSAE,
              type: 'date',
              input: getDataAdmissionDate,
              modal: true,
              openModal: true,
              editing: false,
              extraClasses: 'm-2',
            },
          ]"
          @setObject="setBody"
        />
        <EditableGroup
          :fields="[
            {
              varName: 'application_in_process_link',
              title: $t('dashboard.admissions.admission_process.web_link_regular'),
              placeHolder: true,
              textHolder: 'https://www.colegioejemplo.com',
              disabled: isSAE,
              input: getAdmissionSystemInProcessLink(),
              extraClasses: 'm-2',
            },
          ]"
          @setObject="setBody"
        />
        <EditableGroup
          :fields="[
            {
              varName: 'application_out_process_link',
              title: $t('dashboard.admissions.admission_process.web_link_no_regular'),
              placeHolder: true,
              textHolder: 'https://www.colegioejemplo.com',
              input: getAdmissionSystemOutProcessLink(),
              extraClasses: 'm-2',
            },
          ]"
          @setObject="setBody"
        />
        <div
          class="dashboard-grid mt-3"
          :style="{ '--n-elements-per-row': 2, '--element-size': '1fr' }"
        >
          <EditableGroup
            :fields="[
              {
                icon: 'mdi-face-man',
                varName: 'first_course_entry',
                title: $t('dashboard.admissions.admission_process.entry_course'),
                input: getFirstCourseEntry(),
                extraClasses: 'm-2',
                disabled: true,
              },
            ]"
            @setObject="setBody"
          />
          <EditableGroup
            :fields="[
              {
                icon: 'mdi-seat',
                varName: 'vacancies_entry_level',
                title: $t('dashboard.admissions.admission_process.vacancies_number'),
                input: getVacanciesEntryLevel(),
                extraClasses: 'm-2',
                disabled: isSAE,
              },
            ]"
            disabled
            @setObject="setBody"
          />
        </div>
      </div>
      <!-- end left column -->
      <!-- right column -->
      <div v-if="false" class="campus-detail">
        <v-sheet :color="`grey lighten-4`" class="pa-3">
          <v-skeleton-loader
            type="table-heading, image, list-item-three-line,
                  list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line,
                  list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
          />
        </v-sheet>
      </div>
      <div v-else>
        <Subcontent title-class="editable-title" :title="$t('dashboard.admissions.admission_process.test-title')">
          <EditableGroupList
            :expandable="false"
            external-save
            :form-array="initialRequiredTests"
            :fields="[
              {
                title: $t('dashboard.admissions.admission_process.test'),
                icon: 'mdi-paper',
                input: '',
                varName: 'description',
                extraClasses: 'm-0 p-0',
                dense: true,
                fieldProps: {
                  maxLength: 600,
                  counter: 600,
                },
              },
            ]"
            @setArray="setArray('required_tests', ...arguments)"
          />
        </Subcontent>
        <Subcontent title-class="editable-title" :title="$t('dashboard.admissions.admission_process.document-title')">
          <EditableGroupList
            :expandable="false"
            external-save
            :form-array="initialRequiredDocuments"
            :fields="[
              {
                title: $t('dashboard.admissions.admission_process.document'),
                input: '',
                varName: 'description',
                extraClasses: 'm-0 p-0',
                dense: true,
                fieldProps: {
                  maxLength: 1800,
                  counter: 1800,
                },
              },
            ]"
            @setArray="setArray('required_documents', ...arguments)"
          />
        </Subcontent>
      </div>
      <!-- end right column -->
      <!-- save button -->
      <SaveButton bottom="20px" :loading="saving" @save="save" />
    </div>
  </div>
</template>
<script>
import trackMixPanel from '@/utils/mixpanel';
import { deepCopy } from '@/utils/objects';
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import EditableGroupList from '../../utils/EditableGroupList.vue';
import SaveButton from '../../utils/SaveButton.vue';
import Subcontent from '../../utils/Subcontent.vue';

export default {
  name: 'AdmissionProcess',
  components: {
    Subcontent,
    EditableGroup,
    EditableGroupList,
    SaveButton,
  },
  data() {
    return {
      saving: false,
      body: Object(),
      initialRequiredTests: [],
      initialRequiredDocuments: [],
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    getDataAdmissionDate() {
      return [this.getAdmissionStartDate(), this.getAdmissionEndDate()] ?? [];
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isSAE() {
      const campusAdmissionSystem = this.campusDetail?.campus_admission_system[0];
      return campusAdmissionSystem?.admission_system_name === 'SAE';
    },
  },
  watch: {
    campusDetail: {
      handler() {
        this.setInitialDocuments();
        this.setInitialTests();
      },
      deep: true,
    },
  },
  mounted() {
    this.setInitialDocuments();
    this.setInitialTests();
  },

  methods: {
    ...mapActions({
      patchAdmissionProcess: 'institutions/campusAdmissionProcessPatch',
    }),
    setInitialDocuments() {
      this.initialRequiredDocuments = deepCopy(this.campusDetail?.required_documents ?? []);
      if (this.initialRequiredDocuments.length > 0) {
        this.body.required_documents = this.initialRequiredDocuments;
      }
    },
    setInitialTests() {
      this.initialRequiredTests = deepCopy(this.campusDetail?.required_tests ?? []);
      if (this.initialRequiredTests.length > 0) {
        this.body.required_tests = this.initialRequiredTests;
      }
    },
    getGradeByGradeMin(gradeMin) {
      const dictionary = {
        '1°': '1ro',
        '2°': '2do',
        '3°': '3ro',
        '4°': '4to',
        '5°': '5to',
        '6°': '6to',
        '7°': '7mo',
        '8°': '8vo',
      };
      if (gradeMin in dictionary) {
        return dictionary[gradeMin];
      }
      return gradeMin;
    },
    save() {
      trackMixPanel('click_dashboard_admissions_process_save', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      this.saving = true;
      const {
        application_period: applicationPeriod,
        required_documents: requiredDocuments,
        required_tests: requiredTests,
        ...admissionSystemData
      } = this.body;
      const [startDate, endDate] = applicationPeriod ?? [undefined, undefined];
      const admissionSystemPatchData = {
        application_date_start: startDate,
        application_date_end: endDate,
        ...admissionSystemData,
      };
      const campusData = {
        required_documents: requiredDocuments,
        required_tests: requiredTests,
        admission_system: Object.values(admissionSystemPatchData).some((x) => x) ? admissionSystemPatchData : undefined,
      };
      this.patchAdmissionProcess({ campusUuid: this.campusDetail.uuid, campusData }).finally(
        () => {
          this.saving = false;
        },
      );
    },
    setArray(varName, array) {
      const newArray = array.filter((item) => item.description);
      this.body[varName] = newArray;
    },
    setBody(newValues) {
      const [key, value] = Object.entries(newValues)[0];
      if (newValues.application_period) {
        const date = newValues;
        date.application_period = [
          newValues.application_period[0].replaceAll('-', '/'),
          newValues.application_period[1].replaceAll('-', '/'),
        ];
      }
      this.body[key] = value;
    },
    getFirstCourseEntry() {
      const firstCourseEntry = this.campusDetail?.grade_min_label?.grade_name;
      return firstCourseEntry ?? '';
    },
    getVacanciesEntryLevel() {
      if (this.campusDetail?.campus_admission_system[0]?.vacancies_entry_level) {
        return this.campusDetail?.campus_admission_system[0]?.vacancies_entry_level;
      }
      const includesResult = this.getGradeByGradeMin(this.getFirstCourseEntry().split(' ')[0]);
      const campusVacancies = this.campusDetail?.vacancies;
      const vacanciesFirstCourse = campusVacancies?.find((v) => v.grade_name.includes(includesResult))?.regular_vacancies ?? '';
      return vacanciesFirstCourse;
    },
    getAdmissionStartDate() {
      const startDate = this.campusDetail?.campus_admission_system[0]?.application_date_start;
      const date = startDate ? startDate.split(' ')[0] : '';
      if (date === '') {
        return null;
      }
      return date ?? null;
    },
    getAdmissionEndDate() {
      const endDate = this.campusDetail?.campus_admission_system[0]?.application_date_end;
      const date = endDate ? endDate.split(' ')[0] : '';
      if (date === '') {
        return null;
      }
      return date ?? null;
    },
    getAdmissionSystemInProcessLink() {
      const campusAdmissionSystem = this.campusDetail?.campus_admission_system[0];
      const admissionSystemInProcessLink = campusAdmissionSystem?.application_in_process_link;
      return admissionSystemInProcessLink ?? '';
    },
    getAdmissionSystemOutProcessLink() {
      const campusAdmissionSystem = this.campusDetail?.campus_admission_system[0];
      const admissionSystemOutProcessLink = campusAdmissionSystem?.application_out_process_link;
      return admissionSystemOutProcessLink ?? '';
    },
  },
};
</script>
