<template>
  <div style="height: 100%" @click="widgetClick()" @keydown.enter="widgetClick()">
    <section class="lgApplications">
      <InfoBox
        style="hight: 20%"
        :info-content="['smallTitle', 'counterChip']"
        :title-text="$t('dashboard.dge_headmaster_info.title')"
        :counter-chip-number="applicationsNumber"
        gray-background
      />
      <SkeletonLoader
        v-if="dgeLoader"
        style="height: 80%; padding: 0 10px 0 10px"
        :skeleton-type-array="['image']"
      />
      <InfoBox
        v-if="noInfo && !dgeLoader"
        style="height: 80%"
        :info-content="['multiStyleText']"
        :multi-style-text="multiStyleText"
        multi-text-fill-height
      />
      <div v-if="!noInfo && !dgeLoader" @click.stop>
        <ResumedAdmissionState
          v-for="admission in digitalEnrollmentApplications"
          :key="admission.id"
          style="height: 80%"
          :admission="admission"
          selectable
          hide-address
          hide-student
          hide-program
          @click="admissionClick(admission)"
          @keydown.enter="admissionClick(admission)"
        />
      </div>
    </section>
  </div>
</template>
<script>
import SkeletonLoader from '@/components/atoms/loaders/SkeletonLoader.vue';
import InfoBox from '@/components/molecules/sections/InfoBox.vue';
import ResumedAdmissionState from '@/components/organisms/digital_enrollment/ResumedAdmissionState.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LegalGuardianApplications',
  metaInfo: {
    meta: [],
  },
  components: {
    InfoBox,
    SkeletonLoader,
    ResumedAdmissionState,
  },
  data() {
    return {
      applicationsNumber: '0',
      dgeLoader: true,
      noInfo: false,
    };
  },
  computed: {
    ...mapGetters({
      digitalEnrollmentApplications: 'digitalEnrollment/digitalEnrollmentApplications',
    }),
    multiStyleText() {
      const textArray = [
        {
          text: 'dashboard.dge_lg_applications.no_info',
          tag: 'click_dge-no-info-widget',
          disabledValue: true,
          center: [1],
        },
      ];
      return textArray;
    },
  },
  watch: {
    digitalEnrollmentApplications: {
      handler(newApplications) {
        this.applicationsNumber = newApplications.length.toString();
        this.noInfo = newApplications.length === 0;
        this.dgeLoader = false;
      },
    },
  },
  mounted() {
    this.getDgeResumeLegalGuardian();
  },
  methods: {
    ...mapActions({
      getDgeResumeLegalGuardian: 'digitalEnrollment/getDgeResumeLegalGuardian',
      setDgeSelectedApplication: 'digitalEnrollment/setDgeSelectedApplication',
    }),
    widgetClick() {
      if (!this.noInfo) {
        this.$router.push({
          name: 'DigitalEnrollment',
        });
      }
    },
    admissionClick(admission) {
      this.$router.push({
        name: 'DigitalEnrollmentApplication',
        params: { id: admission.id },
      });
    },
  },
};
</script>
