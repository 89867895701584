var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"title-sections__location"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.infrastructure.title'))+" ")]),_c('div',{staticClass:"des-sections des-sections-space"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.infrastructure.edit'))+" ")]),_c('EditableGroup',{staticClass:"mb-5",attrs:{"fields":[
        {
          type: 'textarea',
          title: '',
          varName: 'infrastructure_text',
          input: _vm.getInfrastructureText,
          dense: true,
          fieldProps: {
            label: _vm.$t('dashboard.digital_profile.infrastructure.add-description'),
          },
        },
      ]},on:{"setVariable":_vm.setTextBody}}),_c('div',[(_vm.infrastructuresTypes.length > 0)?_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'checkbox',
            dense: true,
            title: _vm.$t('dashboard.digital_profile.infrastructure.choose'),
            input: _vm.getInfrastructure(),
            varName: 'infraestructure_campus',
            items: _vm.infrastructuresTypes,
          },
        ]},on:{"setVariable":_vm.setBody}}):_vm._e(),(_vm.infrastructuresTypes.length > 0)?_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'checkInput',
            title: '',
            dense: true,
            input: _vm.getOtherInfo(),
            varName: 'infraestructure_other',
            items: _vm.otherInfraTypes,
            infoOther: _vm.otherDescrip,
          },
        ]},on:{"setVariable":_vm.setBody}}):_vm._e()],1),_c('br'),_c('div',[(_vm.safetyTypes.length > 0)?_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'checkbox',
            title: _vm.$t('dashboard.digital_profile.infrastructure.accesibility'),
            input: _vm.getSafety(),
            varName: 'safety',
            dense: true,
            items: _vm.safetyTypes,
          },
        ]},on:{"setVariable":_vm.setBody}}):_vm._e(),(_vm.infrastructuresTypes.length > 0)?_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'checkInput',
            title: '',
            dense: true,
            input: _vm.getOtherInfoInfra(),
            varName: 'safety_other',
            items: _vm.otherSafetyTypes,
            infoOther: _vm.otherDescripInfra,
          },
        ]},on:{"setVariable":_vm.setBody}}):_vm._e()],1),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }