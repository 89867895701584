<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    max-height="515"
    overlay-color="black"
    :fullscreen="mobile"
    @click:outside="toggleModal(null)"
  >
    <!-- main modal -->
    <div
      v-if="currentModal == 'information'"
      class="relative d-flex"
      :class="mobile ? 'flex-column' : 'flex-row'"
    >
      <!-- x button -->
      <img
        v-show="videoLoaded"
        :class="mobile ? 'absolute-top-left m-2' : 'absolute-top-right m-2'"
        style="z-index: 101"
        src="@/assets/dashboard/icons/close.svg"
        alt="close"
        @click="toggleModal(null)"
        @keydown="toggleModal(null)"
      />
      <!-- end x button -->
      <!-- video -->
      <CenteredVideo
        v-show="videoLoaded"
        :width="mobile ? getWindowWidth() : 405"
        :height="mobile ? 240 : 515"
        video-u-r-l="https://player.vimeo.com/video/668078547?h=6923f4da5d"
        :time-range="[29, 33]"
        :extra-options="{ quality: '1080p' }"
        :video-i-d="null"
        @onPlaying="onPlaying"
      />
      <!-- end video -->
      <!-- content -->
      <div
        v-if="videoLoaded"
        style="background-color: #eef5ff"
        :style="
          mobile
            ? 'width:100%;height:calc(100vh - 240px)'
            : 'height:515px;width:315px'
        "
        class="relative"
      >
        <!-- information -->
        <div
          style="padding: 30px"
          class="left dfm-text"
          :class="mobile ? '' : 'information-container'"
        >
          <div class="d-flex flex-row mt-2 mb-2">
            <img class="mr-1" src="@/assets/icons/campus-properties/multimedia/drone.svg" alt="drone" />
            <img class="" src="@/assets/icons/campus-properties/multimedia/tour360.svg" alt="360video" />
          </div>
          <p class="dfm-text big" style="font-size: 20px">
            Agregar material audiovisual
          </p>
          <br />
          <p class="dfm-text" style="font-weight: normal">
            El establecimiento puede complementar la información en su perfil
            digital si agrega material audiovisual. Puedes reunirte con nuestro
            equipo para saber más.
          </p>
          <br />
        </div>
        <!-- end information -->
        <!-- button -->
        <div class="dfm-open-button" @click="toggleModal('ambassadors')" @keydown="toggleModal('ambassadors')">
          Quiero reunirme con un embajador
        </div>
        <!-- end button -->
      </div>
      <!-- end content -->
    </div>
    <!-- end main modal -->
    <!-- selection modal -->
    <div
      v-if="currentModal == 'ambassadors'"
      :class="mobile ? '' : 'calendly-div-container'"
      @click="toggleModal(null)"
      @keydown="toggleModal(null)"
    >
      <!-- x button -->
      <img
        v-show="videoLoaded"
        :class="mobile ? 'absolute-top-left m-2' : 'absolute-top-right'"
        style="z-index: 101; right: 60px; top: 10px"
        src="@/assets/dashboard/icons/close.svg"
        alt="close"
        @click="toggleModal(null)"
        @keydown="toggleModal(null)"
      />
      <!-- end x button -->
      <div :class="mobile ? 'calendly-container-mobile' : 'calendly-container'">
        <iframe
          title="calendly"
          :style="
            mobile ? '' : 'margin-left:-50px;margin-top:-66px;overflow-y:hidden'
          "
          :width="mobile ? getWindowWidth() : 1000"
          :height="mobile ? getWindowHeight() : 700"
          :scrolling="mobile ? 'yes' : 'no'"
          src="https://calendly.com/natalia-tether"
          alt="https://calendly.com/natalia-tether"
          frameborder="0"
          loading="lazy"
        />
      </div>
    </div>
    <!-- end selection modal -->
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import 'vue2-datepicker/index.css';
import trackMixPanel from '@/utils/mixpanel';
import CenteredVideo from './utils/CenteredVideo.vue';

export default {
  name: 'DigitalProfile',
  components: {
    CenteredVideo,
  },
  props: {
    currentModal: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      videoLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    showModal() {
      return this.currentModal != null;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    document.addEventListener('keydown', () => {
      this.$emit('setModal', null);
    });
  },
  methods: {
    getWindowWidth() {
      return window.innerWidth;
    },
    getWindowHeight() {
      return window.innerHeight;
    },
    toggleModal(modal) {
      if (modal === null) {
        this.videoLoaded = false;
      }
      this.$emit('setModal', modal);
      if (modal === 'ambassadors') {
        trackMixPanel('click_dashboard_digital_profile_ambassador', {
          institution_code: this.campusDetail.institution_code,
          campus_code: this.campusDetail.campus_code,
        });
      }
    },
    onPlaying() {
      if (this.videoLoaded === false) {
        this.videoLoaded = true;
      }
    },
  },
};
</script>
