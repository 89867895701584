<template>
  <div class="p-2">
    <EditableGroup
      class="mb-5"
      :fields="[
        {
          type: 'textarea',
          title: '',
          varName: 'postulation_text',
          input: getAdmissionText,
          dense: true,
          fieldProps: {
            label: $t('dashboard.admissions.add-description'),
          },
        },
      ]"
      @setVariable="setBody"
    />
    <a @click="clickChangeAdmissions" @keydown="clickChangeAdmissions">
      <div class="sige-button" style="width: 100%">
        <span class="sige-button-text"> {{ $t('dashboard.admissions.edit_profile_button') }} </span>
        <img style="position: absolute; right: 10%" src="@/assets/dashboard/icons/link.svg" alt="link" />
      </div>
    </a>
    <SaveButton :loading="saving" @save="save" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'AdmissionsSection',
  components: {
    EditableGroup,
    SaveButton,
  },
  data() {
    return {
      saving: false,
      body: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    getAdmissionText() {
      return this.campusDetail.institutiontext_campus[0]?.postulation_text ?? '';
    },
  },
  methods: {
    ...mapActions({
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),
    clickChangeAdmissions() {
      trackMixPanel('click_dashboard_digital_profile_change_admission', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      this.$router.push('admissions');
    },
    setBody(body) {
      this.body = body;
    },
    save() {
      this.saving = true;
      this.campusSectionTextPatch({ campusUuid: this.campusDetail.uuid, campusData: this.body }).then(() => {
        this.saving = false;
      });
    },
  },
};
</script>
