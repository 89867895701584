var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-width programs-shifts--full"},[_vm._l((_vm.shiftsToAdd),function(shift,index){return _c('div',{key:shift.id,staticClass:"programs-shifts--container mb-5"},[_c('EditableGroup',{staticClass:"programs-shifts",attrs:{"fields":[
        {
          type: 'dropdown',
          varName: 'shift_id',
          dense: true,
          input: _vm.getShiftId(index),
          items: [..._vm.getShiftOptions],
          openModal: true,
          dropdownColor: '#F5F5F5',
          place: _vm.$t('dashboard.digital_profile.programs.programs_flow.shift'),
        },
      ]},on:{"setObject":function($event){return _vm.setBody(index, ...arguments)}}}),_c('div',{staticClass:"programs-shifts--container__menu"},[_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#646464"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"clickable",on:{"click":function($event){return _vm.deleteShift(index)}}},[_c('v-list-item-title',{staticStyle:{"color":"#CCCCCC"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.profile.delete'))+" ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"#CCCCCC"}},[_vm._v(" mdi-delete ")])],1)],1)],1)],1)],1)}),_c('div',{staticClass:"d-flex left-text clickable mt-5",on:{"click":_vm.add,"keydown":_vm.add}},[_c('SVGIcon',{attrs:{"icon":"more-border-gray.svg","size":"17"}}),_c('TextAtom',{staticClass:"ml-2 clickable",attrs:{"value":_vm.$t('dashboard.digital_profile.programs.programs_flow.add_shift'),"tag":"label","font":"inter","color":"neutral-800","weight":"400","size":"normal"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }