<template>
  <div v-if="loaded">
    <span class="dashboard-instruction-text">
      {{ $t('dashboard.digital_profile.pride_points.info') }}
      <v-icon color="#0D22A8" name="dragger"> mdi-drag </v-icon>
      {{ $t('dashboard.digital_profile.pride_points.info2') }}
    </span>
    <draggable
      v-model="array"
      handle="[name=dragger]"
      force-callback
    >
      <!-- FIXME: The ordering is not actually working, we're just rendering the order from the backend -->
      <div v-for="(pridepoint, idx) in array" :key="pridepoint.id" :order="pridepoint.pridepoint_order">
        <v-expansion-panels v-model="pridepoint.show" accordion>
          <v-expansion-panel class="expand-pride" hide-details expand>
            <v-expansion-panel-header style="text-align: left; padding-left: 5px">
              <template v-if="true">
                <div style="min-width: 55px; max-width: 55px">
                  <v-icon color="#2B5BFF" name="dragger">
                    mdi-drag
                  </v-icon>
                  <v-icon color="#2B5BFF" @click="removeAt(idx)">
                    mdi-delete
                  </v-icon>
                </div>
              </template>
              <template #actions>
                <v-icon color="#2B5BFF">
                  $expand
                </v-icon>
              </template>
              <span style="text-align: left; color: #2b5bff">{{ getTitle(idx) }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <PridePointSingle
                :mobile="mobile"
                :pridepoint="pridepoint"
                @setKeyValue="setKeyValueAtPosition(idx, ...arguments)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div style="height: 5px" />
      </div>
    </draggable>
    <div class="d-flex flex-row w-100">
      <div
        v-if="array.length < 3"
        class="dashboard-button editable-button"
        style="margin-right: auto"
        @click="add"
        @keydown="add"
      >
        {{ $t('dashboard.digital_profile.pride_points.add') }}
      </div>
    </div>
    <SaveButton :loading="saving" @save="save" />
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';
import { LABELS } from '@/constants/explorer/labels';
import SaveButton from '../../utils/SaveButton.vue';
import PridePointSingle from './PridePointSingle.vue';

export default {
  name: 'EditPridePoints',
  components: { PridePointSingle, draggable, SaveButton },
  props: {
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      array: [],
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
  },
  watch: {
    campusDetail() {
      this.setArray(this.campusDetail.pridepoint_campus);
    },
  },
  async mounted() {
    await this.checkLabelInmediatly({ keyLabel: LABELS.PRIDE_POINTS });
    this.loaded = true;
    this.setArray(this.campusDetail.pridepoint_campus);
  },
  methods: {
    ...mapActions({
      campusPridePointsPatch: 'institutions/campusPridePointsPatch',
      error: 'utils/error',
      checkLabelInmediatly: 'explorer/checkLabelInmediatly',
    }),
    setArray(array) {
      this.array = [...array];
    },
    save() {
      this.setPridepointsOrder();
      trackMixPanel('click_dashboard_digital_profile_pride_points_save', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      this.saving = true;
      this.campusPridePointsPatch({
        campusUuid: this.campusDetail.uuid,
        campusData: this.array,
      })
        .then((newPridePoints) => {
          this.array = newPridePoints;
          this.saving = false;
        })
        .catch((err) => {
          this.error(err);
          this.saving = false;
        });
    },
    getTitle(idx) {
      const title = this.array[idx].pridepoint_title;
      return title ? `${this.array[idx].pridepoint_title}` : '';
    },
    add() {
      this.array.push({
        pridepoint_title: '',
        pridepoint_description: '',
        pridepoint_label_id: 1,
        show: 0,
      });
    },
    removeAt(idx) {
      this.array.splice(idx, 1);
    },
    setKeyValueAtPosition(index, key, value) {
      this.array[index][key] = value;
    },
    setPridepointsOrder() {
      /* eslint-disable-next-line no-plusplus */
      for (let i = 0; i < this.array.length; i++) {
        this.array[i].pridepoint_order = i;
      }
    },
  },
};
</script>
