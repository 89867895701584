var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('EditableGroup',{staticClass:"mb-5",attrs:{"fields":[
      {
        type: 'textarea',
        title: '',
        varName: 'extraactivities_text',
        input: _vm.getExtraactivitiesText,
        dense: true,
        fieldProps: {
          label: _vm.$t('dashboard.digital_profile.extracurriculars.add-description'),
        },
      },
    ]},on:{"setVariable":_vm.setTextBody}}),_c('div',{staticClass:"dashboard-grid",staticStyle:{"background-color":"white"},style:({ '--n-elements-per-row': _vm.mobile ? 1 : 2, '--element-size': '1fr' })},[_c('ExtracurricularsPanel',{staticClass:"padding height-digital-profile",attrs:{"extracurriculars":_vm.campusDetail.extraactivity_campus,"canonical-link":_vm.canonicalLink}}),_c('div',[_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'checkbox',
            title: _vm.$t('dashboard.digital_profile.extracurriculars.info'),
            input: _vm.getExtra,
            varName: 'extraactivity_campus',
            items: _vm.extraTypes,
            elementsRow: 1,
          },
        ]},on:{"setVariable":_vm.setBody}}),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.otherActivity),expression:"otherActivity"}],staticClass:"input-otro",attrs:{"id":"yourText","type":"text","disabled":!_vm.extraCampus.includes(99)},domProps:{"value":(_vm.otherActivity)},on:{"input":function($event){if($event.target.composing)return;_vm.otherActivity=$event.target.value}}})])],1),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }