<template>
  <div
    :class="[
      mobile ? 'dashboard-save-button-absolute--mobile' : 'dashboard-save-button-absolute',
      (loading || isDisabled) ? 'dashboard-save-button-absolute--disabled' : '',
    ]"
    :disabled="loading || isDisabled"
    :style="{ '--bottom': bottom }"
    @click="save"
    @keydown="save"
  >
    {{ loading ? $t('dashboard.digital_profile.save_button_loading') : $t('dashboard.digital_profile.save_button') }}
  </div>
</template>

<script>
export default {
  name: 'SaveButton',
  props: {
    bottom: {
      type: String,
      default: null,
      required: false,
    },
    loading: {
      required: true,
      type: Boolean,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    save() {
      if (!this.loading && !this.isDisabled) {
        this.$emit('save');
      }
    },
  },
};
</script>
