<template>
  <v-container style="height: 100%">
    <v-row no-gutters align="center" style="height: 100%">
      <v-col cols="12">
        <v-skeleton-loader type="card" class="p-3" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'SkeletonSection',
};
</script>
