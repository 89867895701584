<template>
  <div class="d-flex align-center justify-center">
    <v-btn
      icon
      plain
    >
      <v-icon
        x-large
      >
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ChevronRightButton',
};
</script>
