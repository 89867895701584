var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.mobile ? 'dashboard-section-mobile' : 'dashboard-section',
    _vm.paddingSection ? 'padding-section-dashboard' : '',
  ],style:({
    '--row': _vm.row,
    '--col': _vm.col,
    '--background-color': _vm.background,
    '--mobile-height': _vm.mobileHeight,
    '--padding': _vm.paddingSection,
    '--grid-area': _vm.gridArea,
    ..._vm.styles,
  }),on:{"click":function($event){return _vm.onClick()},"keydown":function($event){return _vm.onClick()}}},[(_vm.showTitle)?_c('div',{staticClass:"dashboard-text",class:_vm.fullSpace
        ? 'dashboard-section-title-fullspace dashboard-section-title'
        : 'dashboard-section-title'},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"dashboard-scrollbar",class:_vm.getBodyClass,style:({
      '--mobile-height': _vm.mobileHeight,
      '--radius': _vm.showTitle ? '0px 0px 5px 5px' : '5px 5px 5px 5px',
      '--body-height': _vm.bodyHeight,
      '--paddingMobile': _vm.paddingMobile,
    })},[_c(_vm.name,_vm._g(_vm._b({tag:"div",on:{"show-explorer-meeting-modal":function($event){return _vm.$emit('show-explorer-meeting-modal')}}},'div',{ ..._vm.sectionProps, ..._vm.$props },false),_vm.$listeners))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }