<template>
  <v-container
    fluid
    class="pa-0"
    style="height: 100%"
    :style="{ overflow: detailed && showDetails ? null : 'hidden' }"
  >
    <!-- Absolute-positioned title over the graph -->
    <AnalyticsGraphTitle v-if="finalDetails" :title="finalDetails.title" />
    <!-- Absolute-positioned back button if detailed -->
    <AnalyticsGraphBackButton v-if="detailed" @click="onClick()" />
    <!-- Chart.js rendering component -->
    <AnalyticsGraphRenderer
      v-if="fetched && finalData !== null"
      :graph-json="finalData"
      :detailed="detailed"
    />
    <!-- Error message related to missing data in the backend -->
    <AnalyticsGraphNoDataMessage
      v-else-if="fetched && finalDetails"
      :message="finalDetails.no_data_message"
      :detailed="detailed"
    />
    <!-- Error message for other cases -->
    <v-row v-else-if="fetched" align="center" style="height: 100%">
      <v-col justify="center">
        {{ $t('dashboard.analytics.graph.noData') }}
      </v-col>
    </v-row>
    <!-- Loading skeleton -->
    <Skeleton v-else />
    <!-- Enter button to go into the detailed graph -->
    <AnalyticsGraphEnterButton v-if="!detailed" @click="onClick()" />
    <!-- Graph Details -->
    <AnalyticsGraphDetails
      v-if="detailed"
      :details="finalDetails"
      :show-details="showDetails"
      @toggleDetails="toggleDetails"
    />
  </v-container>
</template>

<script>
import { deepCopy } from '@/utils/objects';
import { mapActions, mapGetters } from 'vuex';

import Skeleton from '../Skeleton.vue';
import AnalyticsGraphBackButton from './graph/BackButton.vue';
import AnalyticsGraphDetails from './graph/Details.vue';
import AnalyticsGraphEnterButton from './graph/EnterButton.vue';
import AnalyticsGraphNoDataMessage from './graph/NoDataMessage.vue';
import AnalyticsGraphRenderer from './graph/Renderer.vue';
import AnalyticsGraphTitle from './graph/Title.vue';

export default {
  name: 'AnalyticsGraph',
  components: {
    AnalyticsGraphRenderer,
    AnalyticsGraphTitle,
    AnalyticsGraphDetails,
    AnalyticsGraphBackButton,
    AnalyticsGraphEnterButton,
    AnalyticsGraphNoDataMessage,
    Skeleton,
  },
  props: {
    id: {
      required: true,
      type: Number,
    },
    detailed: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      // Toggle detailed description
      showDetails: false,

      // Data fetched from analytics
      fetchedData: null,
      fetchedDetails: null,
      fetched: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    finalData() {
      if (this.fetchedData !== null) {
        const dataCopy = deepCopy(this.fetchedData);
        // Disable the legend in the non-detailed view
        dataCopy.options.legend = { ...dataCopy.options.legend, display: this.detailed };
        // Show in tooltip only non-null values
        dataCopy.options.plugins.tooltip = {
          ...dataCopy.options.plugins.tooltip,
          filter: (element) => element.raw.y !== null,
        };
        return dataCopy;
      }
      return null;
    },
    finalDetails() {
      return this.fetchedDetails;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    if (this.mobile) {
      this.showDetails = true;
    }

    const payload = {
      graphId: this.id,
      campusCode: this.campusDetail.campus_code,
    };
    this.getCampusGraph(payload).then((response) => {
      this.fetchedData = response.data.graph_json;
      this.fetchedDetails = response.data.graph_detail;
      this.fetched = true;
    });
  },
  methods: {
    ...mapActions({
      getCampusGraph: 'roles/getAnalyticsGraph',
    }),
    onClick() {
      this.$emit('selectGraph', this.id, this.finalDetails.title);
    },
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
  },
};
</script>
