<template>
  <section>
    <div class="d-flex justify-space-between align-center pr-4 mb-6 mt-4">
      <MainTitle
        :text="titleText"
        left-align
        small
        no-margin
      />
      <CounterChip
        v-if="steps.length > 0"
        :icon="counterIcon"
        :type="counterType"
        :chip-number="steps.length.toString()"
        class="mt-1"
      />
    </div>
    <ApplicationStep
      v-for="(step, index) in steps"
      :key="`${step.name}_${index}`"
      :notifications="getNotifications(step.name)"
      :step="step.name"
      :step-provider-data="step.provider"
    />
  </section>
</template>

<script>
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import ApplicationStep from '@/components/organisms/digital_enrollment/ApplicationStep.vue';
import CounterChip from '@/components/atoms/chips/CounterChip.vue';

export default {
  name: 'ApplicationStepsGroup',
  components: {
    MainTitle,
    ApplicationStep,
    CounterChip,
  },
  props: {
    titleText: {
      type: String,
      default: '',
    },
    steps: {
      type: Array,
      default: () => ([]),
    },
    counterIcon: {
      type: String,
      default: null,
    },
    counterType: {
      type: String,
      default: 'primary',
    },
  },
  methods: {
    getNotifications(process) { // eslint-disable-line no-unused-vars
      // TODO: NOTIFICATIONS (payment, signature, etc.)
      // return [{ text: 'Notificación de prueba 1' }, { text: 'Notificación de prueba 2' }];
      return null;
    },
  },
};
</script>
