<template>
  <div class="dashboard-section-content admissions-container d-flex flex-row">
    <div v-if="!loaded" class="img-section__loader">
      <v-skeleton-loader
        max-width="auto"
        max-height="220"
        type="button"
      />
    </div>
    <div v-else class="img-section__length">
      + {{ size }}
    </div>
    <div v-if="!loaded" class="d-flex flex-row img-section__container">
      <div v-for="(i, index) in 5" :key="index">
        <v-avatar size="80" tile class="img-section">
          <v-skeleton-loader
            max-width="auto"
            max-height="220"
            type="button"
          />
        </v-avatar>
      </div>
    </div>
    <div v-else class="d-flex flex-row img-section__container">
      <div v-for="(img, index) in images1" :key="index">
        <v-avatar size="80" tile class="img-section">
          <v-img
            :src="
              img !== null
                ? img.image_link
                : require('@/assets/dashboard/gallery-add.svg')
            "
          />
        </v-avatar>
      </div>
    </div>
    <div v-if="!loaded" class="d-flex flex-row img-section__container__second">
      <div v-for="(i, index) in 5" :key="index">
        <v-avatar size="80" tile class="img-section">
          <v-skeleton-loader
            max-width="auto"
            max-height="220"
            type="button"
          />
        </v-avatar>
      </div>
    </div>
    <div v-else class="d-flex flex-row img-section__container__second">
      <div v-for="(img, index) in images2" :key="index">
        <v-avatar size="80" tile class="img-section">
          <v-img
            :src="
              img !== null
                ? img.image_link
                : require('@/assets/dashboard/gallery-add.svg')
            "
          />
        </v-avatar>
      </div>
    </div>
    <v-btn
      :loading="!loaded"
      class="clickable digital-profile-button"
      @click="goTo('gallery')"
      @keydown="goTo('gallery')"
    >
      {{ $t("dashboard.gallery.button") }}
    </v-btn>
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';
import { mapGetters } from 'vuex';

export default {
  name: 'ImageGallery',
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [null, null, null, null, null, null, null, null, null, null],
      images1: [],
      images2: [],
      size: 0,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
  },
  mounted() {
    this.images.fill(null, 0, 10);
    this.campusDetail?.campus_images.forEach((img, index) => {
      if (index < 10) {
        if (img.image_link !== null) {
          this.images[index] = img;
        }
      }
    });
    this.images1 = this.images.slice(0, 5);
    this.images2 = this.images.slice(5, 10);
    this.size = this.campusDetail?.campus_images.length ?? 0;
  },
  methods: {
    goTo(page) {
      this.$router.push(page);
      trackMixPanel('click_dashboard_module_gallery');
    },
  },
};
</script>
