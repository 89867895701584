<template>
  <v-container fluid class="graph-description-container mt-2 dashboard-scrollbar">
    <v-row
      class="ma-1 pa-0"
      align="center"
      justify="center"
      style="border: 2px solid white; border-radius: 5px; text-align: end"
      @click="toggleDetails()"
      @keydown="toggleDetails()"
    >
      <v-col style="text-align: left">
        {{ title || details.title }}
      </v-col>
      <v-col> {{ $t('dashboard.analytics.graph.detailed.last_update', { lastUpdate }) }} </v-col>
      <v-col class="mr-4">
        <span>
          <v-icon left large color="white">
            {{ showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
          {{ showDetails ? 'Esconder detalles' : 'Ver detalles' }}
        </span>
      </v-col>
    </v-row>
    <v-row v-if="showDetails">
      <v-col :cols="cols" class="px-6">
        <div class="py-2 graph-description-section-title">
          {{ $t('dashboard.analytics.graph.detailed.description') }}
        </div>
        <!--General description of graph-->
        <vue-mathjax id="mathjax" :formula="details.description" />
      </v-col>

      <v-col
        :cols="cols"
        class="px-6"
        :style="{
          backgroundColor: 'black',
          border: '20px solid #1e2d4c',
        }"
        align="center"
      >
        {{ $t('dashboard.analytics.graph.detailed.no_video') }}
        <!--Video - for now -->

        <!--
          No hay video explicativo disponible para este gráfico.
          <CenteredVideo
            height="240"
            videoID="688520716"
            :muted="true"
            v-show="videoLoaded"
            @onPlaying="onPlaying"
          />-->
      </v-col>
      <v-col :cols="cols" class="px-6 graph-description-section-title">
        {{ $t('dashboard.analytics.graph.detailed.sources') }}
        <v-list-item v-for="(source, index) in details.datasource" :key="index">
          <v-list-item-content>
            <v-list-item-title>
              {{ source }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col :cols="cols" class="px-6">
        <!-- Technical aspects of graph-->
        <div class="py-2 graph-description-section-title">
          {{ $t('dashboard.analytics.graph.detailed.technical_details') }}
        </div>
        <vue-mathjax id="mathjax" :formula="details.technical_detail" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import CenteredVideo from '@/components/dashboard/modals/utils/CenteredVideo.vue';

export default {
  name: 'AnalyticsGraphDescription',
  // components: { CenteredVideo },
  props: {
    details: {
      required: true,
      type: Object,
    },
    showDetails: {
      required: true,
      type: Boolean,
    },
    title: {
      required: false,
      default: '',
      type: String,
    },
    lastUpdate: {
      required: false,
      default: 'xx/xx/xxxx hh:mm',
      type: String,
    },
  },
  data() {
    return {
      videoLoaded: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    cols() {
      return this.mobile ? 12 : 6;
    },
  },
  methods: {
    onPlaying() {
      if (this.videoLoaded === false) {
        this.videoLoaded = true;
      }
    },
    toggleDetails() {
      this.$emit('toggleDetails');
    },
  },
};
</script>
