var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',[_c('div',{staticClass:"title-sections__location"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.teachers.edit'))+" ")]),_c('div',{staticClass:"des-sections"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.teachers.info'))+" ")]),_c('EditableGroup',{staticClass:"mb-5",attrs:{"fields":[
          {
            type: 'textarea',
            title: '',
            varName: 'teacher_text',
            input: _vm.getTeachersText,
            dense: true,
            fieldProps: {
              label: _vm.$t('dashboard.digital_profile.teachers.add-description'),
            },
          },
        ]},on:{"setVariable":_vm.setTextBody}}),_c('div',[_c('div',{staticClass:"grid-number",style:({ '--col': _vm.columns })},_vm._l((_vm.getDataTeachers),function(member,index){return _c('div',{key:index},[_c('EditableGroup',{attrs:{"fields":[
                {
                  type: 'numberField',
                  varName: member.id.toString(),
                  title: index,
                  extraClasses: 'size-field',
                  input: _vm.getDataMember(member),
                },
              ]},on:{"setObject":_vm.setBody}})],1)}),0),_c('div',{staticClass:"support-padding"},[_c('EditableGroup',{attrs:{"fields":[
              {
                type: 'checkbox',
                title: _vm.$t('dashboard.digital_profile.teachers.support_team'),
                dense: true,
                input: _vm.getInstitutionSupport(),
                varName: 'support_campus',
                items: _vm.supportTypes,
                elementsRow: 2,
              },
            ]},on:{"setVariable":_vm.setBody}}),(_vm.supportTypes.length > 0)?_c('EditableGroup',{attrs:{"fields":[
              {
                type: 'checkInput',
                title: '',
                dense: true,
                input: _vm.getOtherInfo(),
                varName: 'support_other',
                items: _vm.otherType,
                infoOther: _vm.otherDescription,
              },
            ]},on:{"setVariable":_vm.setBody}}):_vm._e()],1)])],1),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }