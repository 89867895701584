<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
      />
      <div class="d-flex flex-wrap">
        <ModalContent
          style="margin-right: 4px"
          inline-content
          :content-sections="['primaryText']"
          :primary-text-label="'user_register.IcbfInfoModal.text1'"
          bold-primary-text
        />
      </div>
    </div>
    <CallToActions
      style="margin-top: 40px"
      :types="['main']"
      active-value
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'IcbfInfoModal',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
    }),
  },
  methods: {
    nextStep() {
      this.$emit('nextStep');
      return null;
    },
    /* Funct to styles text pending prop control
    setPrimaryText(index) {
      Params for styling text idea
      :strongPrimaryText="index === 1 || index === 3 ? true : false"
      :secondaryPrimaryText="index === 2 ? true : false"
      :underlinePrimaryText="index === 5 ? true : false"

      if (index === 1) {
        return 'user_register.IcbfInfoModal.text1';
      }
      if (index === 2) {
        return 'user_register.IcbfInfoModal.text2';
      }
      if (index === 3) {
        return 'user_register.IcbfInfoModal.text3';
      }
      if (index === 4) {
        return 'user_register.IcbfInfoModal.text4';
      }
      if (index === 5) {
        return 'user_register.IcbfInfoModal.text5';
      }
      return null;
    },
    */
  },
};
</script>
