<template>
  <div
    class="'register-main register-main--spaced'"
    :class="{ 'roles--center': getLocationLoader, 'register-main': mobile }"
  >
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <ModalContent
        :content-sections="['smallTitle']"
        :small-title-text="'dashboard.profile.edit_student_title'"
        small-title-left
        small-title-primary-color
      />
    </div>
    <CallToActions
      :types="['info']"
      :info-buttons-section="sectionInfo"
      :active-value="selectedOption.length > 0"
      :main-button-text="'user_register.continue'"
      info-button-without-title
      @infoNextStep="nextStep"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';

export default {
  name: 'EditStudentSelection',
  components: {
    ModalHeader,
    ModalContent,
    CallToActions,
  },
  data() {
    return {
      selectedOption: [],
      address: '',
      commune: '',
      getLocationLoader: false,
      otherString: '',
      sectionInfo: [
        [
          {
            img: 'person-primary.svg',
            text: 'dashboard.profile.edit_student_identification',
          },
        ],
        [
          {
            img: 'grade-primary.svg',
            text: 'dashboard.profile.edit_student_grade',
          },
        ],
        [
          {
            img: 'pin.svg',
            text: 'dashboard.profile.edit_student_location',
          },
        ],
        /*
        [
          {
            img: 'edit.svg',
            text: 'dashboard.profile.edit_student_additional_info',
          },
        ],
        */
      ],
      inputContent: [
        {
          value: null,
          noInfoError: false,
          placeholder: 'user_register.register_role.other_placeholer',
          show: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      municipalityLabels: 'options/municipalityLabels',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      identificationLgStep1: 'authentication/identificationLgStep1',
    }),
    nextStep(index) {
      return this.$emit('nextStep', index);
    },
  },
};
</script>
