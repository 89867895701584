<template>
  <div>
    <div class="title-sections">
      {{ $t('dashboard.digital_profile.languages.edit') }}
      <v-tooltip style="margin-left: 1rem" bottom content-class="custom-tooltip">
        <template #activator="{ on, attrs }">
          <v-btn
            style="margin-left: 1rem; margin-top: 0.1rem"
            v-bind="attrs"
            icon
            dark
            x-small
            color="#1E2D4C"
            v-on="on"
          >
            <v-icon dark>
              mdi-information
            </v-icon>
          </v-btn>
        </template>
        <span> {{ $t('dashboard.digital_profile.languages.info') }} </span>
      </v-tooltip>
    </div>
    <EditableGroup
      class="mb-5"
      :fields="[
        {
          type: 'textarea',
          title: '',
          varName: 'languages_text',
          input: getLanguagesText,
          dense: true,
          fieldProps: {
            label: $t('dashboard.digital_profile.languages.add-description'),
          },
        },
      ]"
      @setVariable="setTextBody"
    />
    <div
      class="dashboard-grid"
      style="background-color: white"
      :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr' }"
    >
      <div v-if="campusDetail.language_campus.length > 0">
        <LanguagePanel class="padding__language height-digital-profile" :languages="getLanguages" />
      </div>
      <div>
        <div class="des-sections mt-8">
          {{ $t('dashboard.digital_profile.languages.choose') }}
        </div>
        <div class="des-sections">
          {{ $t('dashboard.digital_profile.languages.delete') }}
        </div>
        <div v-for="(language, index) in languageTypes" :key="index">
          <EditableGroup
            v-if="languageTypes.length > 0"
            :fields="[
              {
                type: 'checkbox',
                title: language.label,
                input: getLevel(language),
                varName: `${language.value}`,
                items: levelTypes,
                elementsRow: 1,
              },
            ]"
            @setVariable="setBody"
          />
        </div>
      </div>
      <SaveButton :loading="saving" @save="save" />
    </div>
  </div>
</template>
<script>
import LanguagePanel from '@/components/explorer/general/campus_detail/panels/Language.vue';
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'LanguagesSection',
  components: { EditableGroup, SaveButton, LanguagePanel },
  props: {
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      body: {
        languages: [],
      },
      textBody: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      languageLabels: 'options/languageLabels',
      institutionCampusDetail: 'institutions/campusDetails',
      languageLevelsLabels: 'options/languageLevelsLabels',
    }),
    getLanguagesText() {
      return this.campusDetail.institutiontext_campus[0]?.languages_text ?? '';
    },
    getLanguages() {
      const listLanguages = [];
      const languages = this.campusDetail?.language_campus.map((language) => ({
        language: language.language_label,
        level: language.language_level_label,
      })) ?? [];
      // filter languages
      const setLanguages = new Set(languages.map(JSON.stringify));
      const languagesFilter = Array.from(setLanguages).map(JSON.parse);
      // formatting languages
      languagesFilter.forEach((language) => {
        const hasLanguage = listLanguages.findIndex(
          (listLanguage) => listLanguage.language.id === language.language.id,
        );
        if (hasLanguage === -1) {
          if (language.level === null || language.level === undefined) {
            const newLanguage = {
              language: language.language,
              level: [0],
            };
            listLanguages.push(newLanguage);
          } else {
            const newLanguage = {
              language: language.language,
              level: [language.level],
            };
            listLanguages.push(newLanguage);
          }
        } else if (language.level === null || language.level === undefined) {
          listLanguages[hasLanguage].level.push(0);
        } else {
          listLanguages[hasLanguage].level.push(language.level);
        }
      });
      return listLanguages ?? [];
    },
    levelTypes() {
      const levelTypes = [];
      this.languageLevelsLabels.forEach((i) => {
        levelTypes.push({ label: i.language_level_name, value: i.id });
      });
      return levelTypes;
    },
    languageTypes() {
      const sortedLangLabel = this.languageLabels;
      const languageTypes = [];
      sortedLangLabel.sort((a, b) => a.id - b.id).forEach((language) => {
        languageTypes.push(
          {
            label: this.$i18n.locale === 'en' ? language.language_name_en : language.language_name,
            value: language.id,
          },
        );
      });
      return languageTypes;
    },
  },
  mounted() {
    this.retrieveOptions({ reset: false });
    this.loaded = true;
    this.campusDetail.language_campus.forEach((language) => {
      this.body.languages.push({
        language_label_id: language.language_label.id,
        language_level_label_id: language.language_level_label.id,
      });
    });
  },
  methods: {
    ...mapActions({
      campusLanguagesPatch: 'institutions/campusLanguagesPatch',
      retrieveOptions: 'options/retrieveOptions',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),
    setTextBody(body) {
      this.textBody = body;
    },
    setBody(body) {
      if (Object.entries(body)[0][1] === null) {
        const index = this.body.languages.findIndex(
          (language) => language.language_label_id
            === parseInt(Object.entries(body)[0][0], 10),
        );
        this.body.languages.splice(index, 1);
      } else if (typeof Object.entries(body)[0][1] === 'number') {
        if (!this.body.languages?.find((language) => language.language_label_id
        === parseInt(Object.entries(body)[0][0], 10))) {
          this.body.languages.push({
            language_label_id: parseInt(Object.entries(body)[0][0], 10),
            language_level_label_id: parseInt(Object.entries(body)[0][1], 10),
          });
        } else {
          const index = this.body.languages.findIndex(
            (language) => language.language_label_id
            === parseInt(Object.entries(body)[0][0], 10),
          );
          this.body.languages[index].language_level_label_id = parseInt(Object.entries(body)[0][1], 10);
        }
      }
    },
    async save() {
      this.saving = true;
      this.campusSectionTextPatch({ campusUuid: this.campusDetail.uuid, campusData: this.textBody });
      await this.campusLanguagesPatch({ campusUuid: this.campusDetail.uuid, campusData: this.body.languages });
      this.saving = false;
    },
    getLevel(language) {
      const selectedLevel = this.body.languages.find((lang) => lang.language_label_id === language.value);

      if (selectedLevel && selectedLevel.language_level_label_id) {
        return selectedLevel.language_level_label_id;
      }
      return null;
    },
  },
};
</script>
