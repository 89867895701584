<template>
  <div class="graph-top-right-button-container">
    <v-btn depressed text color="primary" @click="$emit('click')">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      {{ $t('dashboard.analytics.graph.back') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsGraphBackButton',
};
</script>
