var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('EditableGroup',{staticClass:"mb-5 p-4",attrs:{"fields":[
      {
        type: 'textarea',
        title: '',
        varName: 'performance_text',
        input: _vm.getPerformanceText,
        dense: true,
        fieldProps: {
          label: _vm.$t('dashboard.digital_profile.performance.add-description'),
        },
      },
    ]},on:{"setVariable":_vm.setTextBody}}),_c('div',{staticClass:"dashboard-grid",style:({ '--n-elements-per-row': _vm.mobile ? 1 : 2, '--element-size': '1fr' })},[_c('div',[_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'textarea',
            title: _vm.$t('dashboard.digital_profile.performance.outstanding_achievement.title'),
            varName: 'first_achievement',
            input: _vm.getDescription(0),
            fieldProps: {
              maxLength: 600,
              counter: 600,
              label: _vm.$t('dashboard.digital_profile.performance.outstanding_achievement.label'),
            },
          },
          {
            type: 'textarea',
            title: _vm.$t('dashboard.digital_profile.performance.first_academic_achievement.title'),
            varName: 'second_achievement',
            input: _vm.getDescription(1),
            fieldProps: {
              maxLength: 600,
              counter: 600,
              label: _vm.$t('dashboard.digital_profile.performance.first_academic_achievement.label'),
            },
          },
        ]},on:{"setVariable":_vm.setBody}})],1),_c('div',[_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'textarea',
            title: _vm.$t('dashboard.digital_profile.performance.second_academic_achievement.title'),
            varName: 'third_achievement',
            input: _vm.getDescription(2),
            fieldProps: {
              maxLength: 600,
              counter: 600,
              label: _vm.$t('dashboard.digital_profile.performance.second_academic_achievement.label'),
            },
          },
          {
            type: 'textarea',
            title: _vm.$t('dashboard.digital_profile.performance.third_academic_achievement.title'),
            varName: 'fourth_achievement',
            input: _vm.getDescription(3),
            fieldProps: {
              maxLength: 600,
              counter: 600,
              label: _vm.$t('dashboard.digital_profile.performance.third_academic_achievement.label'),
            },
          },
        ]},on:{"setVariable":_vm.setBody}})],1)]),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }