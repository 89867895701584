var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Subsection',{style:({
    'background-image': 'url(' + require('@/assets/dashboard/widgets/sae-widget.svg') + ')',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'border-radius': '5px',
    'background-position': 'center',
  }),attrs:{"bg-color":"transparent","flex":"column","clickable":""},on:{"onClick":_vm.displayReport}},[_c('div',{staticClass:"mt-1 ml-1"},[_c('div',{staticClass:"widget-tool"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.analytics.reports.sae_results_title_21'))+" ")]),_c('div',{staticClass:"widget-tool--des"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.analytics.reports.sae_results_desc'))+" ")])]),_c('v-dialog',{attrs:{"width":"70%"},on:{"click:outside":function($event){return _vm.closeModal()}},model:{value:(_vm.launchModal),callback:function ($$v) {_vm.launchModal=$$v},expression:"launchModal"}},[_c('iframe',{staticClass:"sae-results-report-frame",attrs:{"title":"results-report","src":_vm.getSourceUrl(),"alt":"reporte","loading":"lazy"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }