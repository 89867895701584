const setupMetaSDK = () => {
  const setup = () => {
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/es_LA/sdk.js';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.onload = () => {
      window.FB.init({
        appId: process.env.VUE_APP_META_INTEGRATIONS_APP_ID,
        xfbml: true,
        version: 'v19.0',
      });
    };
    return document.body.appendChild(script);
  };

  const removeSDK = (script) => {
    document.body.removeChild(script);
  };

  return {
    setup, removeSDK,
  };
};

export {
  setupMetaSDK,
};
