var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-chip',{staticClass:"student-chip",class:{
    'student-chip--elevated': _vm.elevated,
    'student-chip--clickable': _vm.clickable,
    clickable: _vm.clickable,
  },attrs:{"label":"","color":_vm.color,"ripple":_vm.clickable},on:{"click":_vm.onInteraction,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onInteraction.apply(null, arguments)}}},[_c('v-img',{staticClass:"student-chip__icon",attrs:{"width":"24","src":require('@/assets/dashboard/icons/student-icon.svg'),"alt":"Student Icon"}}),_c('span',{staticClass:"student-chip__name",style:({
      'font-weight': _vm.elevated ? 500 : 'normal',
    })},[_vm._v(" "+_vm._s(_vm.studentName)+" ")]),_vm._t("menu")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }