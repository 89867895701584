<template>
  <div>
    <div>
      <div>
        <div class="title-sections__location">
          {{ $t('dashboard.digital_profile.teachers.edit') }}
        </div>
        <div class="des-sections">
          {{ $t('dashboard.digital_profile.teachers.info') }}
        </div>
        <EditableGroup
          class="mb-5"
          :fields="[
            {
              type: 'textarea',
              title: '',
              varName: 'teacher_text',
              input: getTeachersText,
              dense: true,
              fieldProps: {
                label: $t('dashboard.digital_profile.teachers.add-description'),
              },
            },
          ]"
          @setVariable="setTextBody"
        />
        <div>
          <div
            class="grid-number"
            :style="{ '--col': columns }"
          >
            <div v-for="(member, index) in getDataTeachers" :key="index">
              <EditableGroup
                :fields="[
                  {
                    type: 'numberField',
                    varName: member.id.toString(),
                    title: index,
                    extraClasses: 'size-field',
                    input: getDataMember(member),
                  },
                ]"
                @setObject="setBody"
              />
            </div>
          </div>

          <div class="support-padding">
            <EditableGroup
              :fields="[
                {
                  type: 'checkbox',
                  title: $t('dashboard.digital_profile.teachers.support_team'),
                  dense: true,
                  input: getInstitutionSupport(),
                  varName: 'support_campus',
                  items: supportTypes,
                  elementsRow: 2,
                },
              ]"
              @setVariable="setBody"
            />
            <EditableGroup
              v-if="supportTypes.length > 0"
              :fields="[
                {
                  type: 'checkInput',
                  title: '',
                  dense: true,
                  input: getOtherInfo(),
                  varName: 'support_other',
                  items: otherType,
                  infoOther: otherDescription,
                },
              ]"
              @setVariable="setBody"
            />
          </div>
        </div>
      </div>

      <SaveButton :loading="saving" @save="save" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'TeachersSection',
  components: { EditableGroup, SaveButton },
  data() {
    return {
      saving: false,
      body: {
        institutionmember_campus: [],
        support_campus: [],
      },
      supportCampus: [],
      otherInfo: false,
      otherDescription: '',
      otherBody: Object(),
      columns: 2,
      textBody: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      supportLabels: 'options/supportLabels',
      membersLabels: 'options/membersLabels',
    }),
    getMembersLastYear() {
      let lastYear = 0;
      this.campusDetail.institutionmember_campus.forEach((members) => {
        if (members.year > lastYear) {
          lastYear = members.year;
        }
      });
      return lastYear;
    },
    getMembers() {
      return this.membersLabels?.filter((m) => m.id !== 1);
    },
    getDataTeachers() {
      const total = {};
      const copyMembers = this.getMembers;
      copyMembers.forEach((member) => {
        const hasMember = this.campusDetail?.institutionmember_campus
          .filter((m) => m.member_label.id === member.id)
          .map((m) => ({
            count: m.total_members, name: member.member_name, year: m.year, id: member.id,
          }))
          .sort((a, b) => b.year - a.year) ?? [];

        if (hasMember.length > 0) {
          total[member.member_name] = { ...hasMember[0] };
        } else {
          total[member.member_name] = {
            count: '', name: member.member_name, year: this.getMembersLastYear, id: member.id,
          };
        }
      });
      return total;
    },
    supportTypes() {
      const supportTypes = [];
      this.supportLabels.forEach((support) => {
        if (support.id !== 99) {
          supportTypes.push({ label: support.support_name, value: support.id });
        }
      });
      supportTypes.sort((a, b) => a.label.localeCompare(b.label));
      return supportTypes;
    },
    otherType() {
      const otherType = [];
      this.supportLabels.forEach((support) => {
        if (support.id === 99) {
          otherType.push({ label: support.support_name, value: support.id });
        }
      });
      return otherType;
    },
    getTeachersText() {
      return this.campusDetail.institutiontext_campus[0]?.teacher_text ?? '';
    },
  },
  async mounted() {
    await this.retrieveOptions({ reset: false });
    const lastYear = this.getMembersLastYear;
    this.campusDetail.support_campus.forEach((support) => {
      if (support.support_label.id !== 99) {
        this.supportCampus.push(support.support_label.id);
        this.body.support_campus.push({
          label_id: support.support_label.id,
          descrip: support.descrip,
          quantity: null,
        });
      } else {
        this.otherInfo = true;
        this.otherDescription = support.descrip;
        this.otherBody = {
          label_id: 99,
          descrip: support.descrip,
          quantity: null,
        };
      }
    });
    this.campusDetail.institutionmember_campus.forEach((member) => {
      if (member.member_label.id !== 1 && member.year === lastYear) {
        this.body.institutionmember_campus.push({
          member_label_id: member.member_label.id,
          stage_label_id: null,
          total_members: member.total_members,
          year: lastYear,
        });
      }
    });
  },
  methods: {
    ...mapActions({
      retrieveOptions: 'options/retrieveOptions',
      campusStaffPatch: 'institutions/campusStaffPatch',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),

    setBody(body) {
      const lastYear = this.getMembersLastYear;
      if (Object.keys(body)[0] === 'support_campus') {
        this.body.support_campus = body.support_campus.map((x) => ({
          label_id: x,
          descrip: null,
          quantity: null,
        }));
      }

      if (
        !this.isMemberInBody(body)
        && Object.keys(body)[0] !== 'support_campus'
        && Object.keys(body)[0] !== 'support_other'
      ) {
        this.body.institutionmember_campus.push({
          member_label_id: parseInt(Object.keys(body)[0], 10),
          stage_label_id: null,
          total_members: parseInt(Object.values(body)[0], 10) ?? 0,
          year: lastYear || new Date().getFullYear(),
        });
      } else if (Object.keys(body)[0] !== 'support_campus' && Object.keys(body)[0] !== 'support_other') {
        // update the stage
        const index = this.body.institutionmember_campus.findIndex(
          (member) => member.member_label_id === parseInt(Object.keys(body)[0], 10),
        );
        this.body.institutionmember_campus[index] = {
          member_label_id: parseInt(Object.keys(body)[0], 10),
          stage_label_id: null,
          total_members: parseInt(Object.values(body)[0], 10) ?? 0,
          year: lastYear || new Date().getFullYear(),
        };
      }
      if (body.support_other !== undefined) {
        this.otherBody = {
          label_id: 99,
          descrip: body.support_other,
          quantity: null,
        };
      }
    },
    setTextBody(body) {
      this.textBody = body;
    },
    save() {
      this.saving = true;

      if (this.otherBody.descrip) {
        this.body.support_campus.push(this.otherBody);
      }
      this.campusSectionTextPatch({ campusUuid: this.campusDetail.uuid, campusData: this.textBody });
      this.campusStaffPatch({ campusUuid: this.campusDetail.uuid, campusData: this.body }).then(() => {
        this.insideBody = false;
        this.saving = false;
      });
    },
    getInstitutionSupport() {
      try {
        return this.supportCampus;
      } catch {
        return 1;
      }
    },
    getDataMember(member) {
      return member.count;
    },
    getOtherInfo() {
      return this.otherInfo === true ? 99 : 1;
    },
    isMemberInBody(body) {
      const memberInfo = this.body.institutionmember_campus?.find(
        (member) => member.member_label_id === parseInt(Object.keys(body)[0], 10),
      );
      return !!memberInfo;
    },
  },
};
</script>
