<template>
  <div>
    <TextAtom
      class="title-sections__location"
      :value="$t('dashboard.digital_profile.contacts.title')"
      tag="label"
      font="poppins"
      color="primary-dark"
      weight="600"
      size="title"
    />
    <TextAtom
      class="des-sections__location mb-5 d-flex left-text"
      :value="$t('dashboard.digital_profile.contacts.title_field')"
      tag="label"
      font="inter"
      color="neutral-800"
      weight="400"
      size="subtitle"
    />
    <EditableGroup
      class="mb-5"
      :fields="[
        {
          type: 'textarea',
          title: '',
          varName: 'direction_text',
          input: getContactsText,
          dense: true,
          fieldProps: {
            label: $t('dashboard.digital_profile.contacts.add-description'),
          },
        },
      ]"
      @setVariable="setBody"
    />
    <Draggable
      v-model="contactsArray"
      handle="[name=dragger]"
      force-callback
    >
      <div v-for="(contact, index) in contactsArray" :key="contact.label_id">
        <div class="contacts-dashboard">
          <ContactSingle
            :key="contact.label"
            :contact="contact"
            :contacts-array="contactsArray"
            :index="index"
            @deleteContact="deleteContact"
            @setKeyValue="setKeyValueAtPosition(index, ...arguments)"
          />
        </div>
      </div>
    </Draggable>
    <div
      v-if="contactsArray.length < 5"
      class="d-flex left-text clickable"
      style=""
      @click="add"
      @keydown="add"
    >
      <SVGIcon
        icon="more-border.svg"
        size="20"
      />
      <TextAtom
        class="ml-3 clickable"
        :value="$t('dashboard.digital_profile.contacts.add')"
        tag="label"
        font="inter"
        color="primary-dark"
        weight="400"
        size="normal"
      />
    </div>
    <SaveButton :loading="loading" @save="submitContacts" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SaveButton from '../../utils/SaveButton.vue';
import ContactSingle from './ContactSingle.vue';
import EditableGroup from '../../utils/EditableGroup.vue';

export default {
  name: 'ContactsSection',
  components: {
    TextAtom,
    SaveButton,
    Draggable,
    SVGIcon,
    ContactSingle,
    EditableGroup,
  },
  data() {
    return {
      loading: false,
      contactsArray: [],
      body: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      contactsLabels: 'options/contactLabels',
    }),
    getContactsText() {
      return this.campusDetail.institutiontext_campus[0]?.direction_text ?? '';
    },
  },
  watch: {
    campusDetail() {
      this.setArray(this.campusDetail.institutioncontact_campus);
    },
  },
  mounted() {
    this.setArray(this.campusDetail.institutioncontact_campus);
  },
  methods: {
    ...mapActions({
      campusGlobalContactsPatch: 'institutions/campusGlobalContactsPatch',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),
    setArray(array) {
      this.contactsArray = array.filter((contact) => [1, 3, 8, 2, 9].includes(contact.contact_label.id))
        .map((contact) => ({
          id: contact.id,
          label_id: contact.contact_label.id,
          label: contact.contact_label.contact_name,
          email: contact.email,
          cellphone: contact.cellphone,
          phone: contact.phone,
          name: contact.name,
          verified: contact?.verified,
        }));
    },
    add() {
      this.contactsArray.push({
        label: '',
        label_id: 0,
        name: '',
        cellphone: '',
        phone: '',
        email: '',
        verified: false,
      });
    },
    deleteContact(index) {
      this.contactsArray.splice(index, 1);
    },
    setKeyValueAtPosition(index, key, value) {
      this.contactsArray[index][key] = value;
    },
    setBody(body) {
      this.body = body;
    },
    submitContacts() {
      this.loading = true;
      this.campusSectionTextPatch({ campusUuid: this.campusDetail.uuid, campusData: this.body });
      this.campusGlobalContactsPatch({ campusUuid: this.campusDetail.uuid, campusData: this.contactsArray })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
