<template>
  <v-btn
    class="extra-costs-add-button"
    @click="emitValue"
  >
    <div>
      <img
        style="padding-right:10px;"
        alt="down arrow"
        :src="require('@/assets/dashboard/icons/extra-costs-add.svg')"
      />
    </div>
    <TextAtom
      :value="$t('dashboard.digital_profile.price.add_extra_cost')"
      tag="p"
      font="poppins"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />
  </v-btn>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'AddExtraCosts',
  components: {
    TextAtom,
  },
  props: { },
  methods: {
    emitValue() {
      this.$emit('click');
    },
  },
};
</script>
