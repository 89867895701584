<template>
  <v-dialog
    max-height="auto"
    height="auto"
    width="700"
    max-width="700"
    overlay-color="black"
    :value="showModal"
    @click:outside="toggleModal()"
  >
    <v-card class="mx-auto">
      <v-app-bar class="border-title" flat color="rgba(0, 0, 0, 0)">
        <img
          class="arrow-bar"
          src="@/assets/dashboard/icons/messages/back-arrow.svg"
          alt="back"
          @click="toggleModal()"
          @keydown="toggleModal()"
        />

        <v-spacer />

        <img class="destacar-img" src="@/assets/dashboard/icons/messages/destacar-message.svg" alt="destacar" />
        <!--  -->
      </v-app-bar>
      <v-card-text class="card-message">
        <div class="date-message">
          {{ dateSplit }}
        </div>
        <div class="profile-message">
          <div class="i-circle">
            {{ firstLetter }}
          </div>
          <div class="name-message">
            {{ name }}
          </div>
        </div>

        <div class="title-message">
          {{ title }}
        </div>
        <div class="content-message">
          {{ content }}
        </div>
        <v-card-actions>
          <v-flex class="text-right">
            <div class="contact-message">
              {{ $t('dashboard.messaging.info_email') }} {{ mail }}
            </div>
          </v-flex>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'MessageSingle',
  props: {
    showModal: {
      required: true,
      type: Boolean,
    },
    message: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      mail: null,
      date: null,
      dateSplit: null,
      name: null,
      title: null,
      content: null,
      firstLetter: '',
    };
  },

  watch: {
    message(val) {
      this.mail = val.mail_from;
      this.date = val.timestamp;
      [this.dateSplit] = this.date.split(' ');
      this.name = val.username;
      this.title = val.message_title;
      this.content = val.message;
      this.firstLetter = this.name[0].toUpperCase();
    },
  },
  methods: {
    toggleModal() {
      this.$emit('toggleModal');
    },
  },
};
</script>
