<template>
  <div>
    <EditableGroup
      class="mb-5 p-4"
      :fields="[
        {
          type: 'textarea',
          title: '',
          varName: 'performance_text',
          input: getPerformanceText,
          dense: true,
          fieldProps: {
            label: $t('dashboard.digital_profile.performance.add-description'),
          },
        },
      ]"
      @setVariable="setTextBody"
    />
    <div
      class="dashboard-grid"
      :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr' }"
    >
      <div>
        <EditableGroup
          :fields="[
            {
              type: 'textarea',
              title: $t('dashboard.digital_profile.performance.outstanding_achievement.title'),
              varName: 'first_achievement',
              input: getDescription(0),
              fieldProps: {
                maxLength: 600,
                counter: 600,
                label: $t('dashboard.digital_profile.performance.outstanding_achievement.label'),
              },
            },
            {
              type: 'textarea',
              title: $t('dashboard.digital_profile.performance.first_academic_achievement.title'),
              varName: 'second_achievement',
              input: getDescription(1),
              fieldProps: {
                maxLength: 600,
                counter: 600,
                label: $t('dashboard.digital_profile.performance.first_academic_achievement.label'),
              },
            },
          ]"
          @setVariable="setBody"
        />
      </div>
      <div>
        <EditableGroup
          :fields="[
            {
              type: 'textarea',
              title: $t('dashboard.digital_profile.performance.second_academic_achievement.title'),
              varName: 'third_achievement',
              input: getDescription(2),
              fieldProps: {
                maxLength: 600,
                counter: 600,
                label: $t('dashboard.digital_profile.performance.second_academic_achievement.label'),
              },
            },
            {
              type: 'textarea',
              title: $t('dashboard.digital_profile.performance.third_academic_achievement.title'),
              varName: 'fourth_achievement',
              input: getDescription(3),
              fieldProps: {
                maxLength: 600,
                counter: 600,
                label: $t('dashboard.digital_profile.performance.third_academic_achievement.label'),
              },
            },
          ]"
          @setVariable="setBody"
        />
      </div>
    </div>
    <SaveButton :loading="saving" @save="save" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'PerformanceSection',
  components: {
    EditableGroup,
    SaveButton,
  },
  props: {
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      body: {},
      textBody: {},
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
    getPerformanceText() {
      return this.campusDetail.institutiontext_campus[0]?.performance_text ?? '';
    },
  },
  mounted() {
    this.mountBody();
  },
  methods: {
    ...mapActions({
      campusAchievementsPatch: 'institutions/campusAchievementsPatch',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),
    mountBody() {
      this.body = {
        first_achievement: { id: this.getId(0), description: this.getDescription(0), order: 0 },
        second_achievement: { id: this.getId(1), description: this.getDescription(1), order: 1 },
        third_achievement: { id: this.getId(2), description: this.getDescription(2), order: 2 },
        fourth_achievement: { id: this.getId(3), description: this.getDescription(3), order: 3 },
      };
      return this.body;
    },
    getDescription(index) {
      if (!this.campusDetail.achievement_campus) {
        return null;
      }
      const description = this.campusDetail?.achievement_campus[index]?.description;
      return description ?? null;
    },
    getId(index) {
      if (!this.campusDetail.achievement_campus) {
        return undefined;
      }
      const id = this.campusDetail?.achievement_campus[index]?.id;
      return id ?? undefined;
    },
    setBody(body) {
      const value = Object.values(body)[0];
      this.body[Object.keys(body)[0]].description = value;
    },
    setTextBody(body) {
      this.textBody = body;
    },
    save() {
      this.saving = true;
      trackMixPanel('click_dashboard_digital_profile_performance_save', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      const achievements = [
        this.body.first_achievement,
        this.body.second_achievement,
        this.body.third_achievement,
        this.body.fourth_achievement,
      ];
      this.campusSectionTextPatch({ campusUuid: this.campusDetail.uuid, campusData: this.textBody });
      this.campusAchievementsPatch({
        campusUuid: this.campusDetail.uuid,
        campusData: achievements,
      }).then(() => {
        this.saving = false;
      });
    },
  },
};
</script>
