<template>
  <div>
    <div
      class="dashboard-grid"
      :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr' }"
    >
      <div>
        <div v-t="'dashboard.digital_profile.educational_proyect.title'" class="title-sections" />
        <div class="des-sections" style="margin-bottom: 16px">
          {{ $t('dashboard.digital_profile.educational_proyect.info') }}
        </div>
        <EditableGroup
          :fields="[
            {
              type: 'text',
              title: $t('dashboard.digital_profile.educational_proyect.title_field_pdf'),
              varName: 'pei_pdf',
              input: currentHeadMasterMainSchool.pei_pdf,
            },
            {
              type: 'text',
              title: $t('dashboard.digital_profile.educational_proyect.title_field_video'),
              varName: 'educational_project_video',
              input: videoLink,
            },
          ]"
          @setVariable="setBody"
        />
      </div>
      <SaveButton :loading="saving" @save="save" />
    </div>
  </div>
</template>
<script>
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'EducationalProject',
  components: { EditableGroup, SaveButton },
  props: {
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      body: Object(),
      saving: false,
    };
  },
  computed: {
    ...mapGetters({
      currentHeadMasterMainSchool: 'roles/currentHeadMasterMainSchool',
    }),
    videoLink() {
      const video = this.currentHeadMasterMainSchool.audiovisual_campus.find(
        (audiovisual) => audiovisual.audiovisual_label.id === 3,
      );
      if (video) {
        return video.audiovisual_link;
      }
      return '';
    },
  },
  methods: {
    ...mapActions({
      campusEducationalProjectPatch: 'institutions/campusEducationalProjectPatch',
    }),
    save() {
      trackMixPanel('click_dashboard_digital_profile_educational_project_save', {
        campus_code: this.currentHeadMasterMainSchool.campus_code,
        institution_code: this.currentHeadMasterMainSchool.institution_code,
      });
      this.saving = true;
      this.campusEducationalProjectPatch({ campusUuid: this.currentHeadMasterMainSchool.uuid, campusData: this.body })
        .then(() => {
          this.saving = false;
        });
    },
    setBody(body) {
      const value = Object.values(body)[0];
      this.body[Object.keys(body)[0]] = value;
    },
  },
};
</script>
