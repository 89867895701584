<template>
  <div v-if="loaded">
    <div
      class="dashboard-grid"
      :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr' }"
    >
      <!-- left column -->
      <div>
        <EditableGroup
          :fields="[
            {
              type: 'checkbox',
              title: $t('dashboard.digital_profile.pride_points.pride_point_type'),
              varName: 'pridepoint_label_id',
              input: pridepoint.pridepoint_label_id,
              items: pridePointTypes,
              elementsRow: 1,
            },
          ]"
          @setVariable="setBody"
        />
      </div>
      <!-- right column -->
      <div>
        <EditableGroup
          :fields="[
            {
              type: 'text',
              title: '',
              varName: 'pridepoint_title',
              input: pridepoint.pridepoint_title,
              fieldProps: {
                maxLength: 100,
                counter: 100,
                label: $t('dashboard.digital_profile.pride_points.title_pride_points'),
              },
            },
            {
              type: 'textarea',
              title: '',
              varName: 'pridepoint_description',
              input: pridepoint.pridepoint_description,
              fieldProps: {
                maxLength: 1800,
                counter: 1800,
                label: $t('dashboard.digital_profile.pride_points.description'),
              },
              colorTextArea: '#E3E9FE',
            },
            {
              type: 'image',
              title: '',
              varName: 'imagebase64',
              input: getPridePointImage(),
            },
          ]"
          @setVariable="setBody"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { LABELS } from '@/constants/explorer/labels';
import EditableGroup from '../../utils/EditableGroup.vue';

export default {
  name: 'PridePointSingle',
  components: { EditableGroup },
  props: {
    pridepoint: {
      required: true,
      type: Object,
    },
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return { loaded: false };
  },
  computed: {
    ...mapGetters({
      labelsByKey: 'explorer/labelsByKey',
    }),
    pridePointTypes() {
      const pridePointTypes = [];
      this.labelsByKey(LABELS.PRIDE_POINTS).forEach((pridepoint) => {
        pridePointTypes.push({ label: pridepoint.pridepoint_name, value: pridepoint.id });
      });
      return pridePointTypes;
    },
  },
  mounted() {
    this.loaded = true;
  },
  methods: {

    getPridePointImage() {
      if (this.pridepoint.images_pridepoint && this.pridepoint.images_pridepoint.length > 0) {
        // This hack forces the image to be reloaded when the user changes it. Because the image is stored in the
        // database with the same name, the browser caches the url and doesn't reload it
        return `${this.pridepoint.images_pridepoint[0].image}?${new Date().getTime()}`;
      }
      return '';
    },
    setBody(body) {
      const [key, value] = Object.entries(body)[0];
      this.$emit('setKeyValue', key, value);
      // TODO: Remove the following line when we update to the new patch.
      // The issue comes from the fact that we're sending the whole payload back to the backend, and the backend
      // uses pridepoint_label instead of pridepoint_label_id on update, but pridepoint_label_id on creation.
      // This is a temporary fix to make it work.
      if (key === 'pridepoint_label_id') {
        this.$emit('setKeyValue', 'pridepoint_label', value);
      }
    },
  },
};
</script>
