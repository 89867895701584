<template>
  <div
    id="internito"
    style="{'--mobile-height':'100%'}"
  >
    <LoaderCircular
      v-if="!widgetLoaded"
      :size="44"
      show
      style="position: absolute; left: calc(50% - 22px); top: calc(65% - 22px);"
    />
    <div v-if="!unavailable">
      <!-- eslint-disable-next-line vuejs-accessibility/iframe-has-title -->
      <iframe id="vacancies_graph" class="vacancies-graph-frame" src="" loading="lazy" />
      <!-- eslint-disable-next-line vuejs-accessibility/iframe-has-title -->
      <iframe id="vacancies_table" class="vacancies-table-frame" src="" loading="lazy" />
    </div>

    <div v-else>
      <img
        class="vacancies-unavailable-image"
        src="@/assets/dashboard/widgets/vacancies-widgets-unavailable.png"
        alt="graph"
      />
      <div class="vacancies-unavailable-message-container">
        <img
          class="vacancies-unavailable-message-icon"
          src="@/assets/icons/lock.svg"
          alt="graph"
        />
        <h1 class="vacancies-unavailable-message-title">
          {{ $t('dashboard.analytics.widgets.no_data') }}
        </h1>
        <p
          class="vacancies-unavailable-message-text"
          style="
            padding-top: 13%;
            padding-bottom: 5%;
            "
        >
          {{ $t('dashboard.analytics.widgets.contact_us_pre_link') }}
          <u>
            <a
              style="color:#737373"
              class="vacancies-unavailable-message-text"
              href="https://meetings.hubspot.com/javiera-atala"
            >
              {{ $t('dashboard.analytics.widgets.contact_us_link') }}
            </a>
          </u>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VacanciesWidget',
  components: { LoaderCircular },
  data() {
    return {
      unavailable: false,
      widgetLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      widgetsVacanciesGraphURL: 'widgets/widgetsVacanciesGraphURL',
      widgetsVacanciesTableURL: 'widgets/widgetsVacanciesTableURL',
    }),
  },
  mounted() {
    this.loadWidgets();
  },
  methods: {
    ...mapActions({
      getWidgetsVacanciesGraph: 'widgets/getWidgetsVacanciesGraph',
      getWidgetsVacanciesTable: 'widgets/getWidgetsVacanciesTable',
    }),

    displayNoData() {
      this.unavailable = true;
    },

    async loadWidgets() {
      this.widgetLoaded = false;
      const params = {
        campusCode: this.campusDetail.campus_code,
      };
      await this.getWidgetsVacanciesGraph(params);
      if (this.widgetsVacanciesGraphURL) {
        const graph = document.getElementById('vacancies_graph');
        graph.src = this.widgetsVacanciesGraphURL;
      } else {
        this.displayNoData();
      }
      await this.getWidgetsVacanciesTable(params);
      if (this.widgetsVacanciesTableURL) {
        const graph = document.getElementById('vacancies_table');
        graph.src = this.widgetsVacanciesTableURL;
        this.widgetLoaded = true;
      } else {
        this.widgetLoaded = true;
        this.displayNoData();
      }
    },
  },
};
</script>
