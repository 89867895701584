<template>
  <div v-if="loaded">
    <draggable
      v-model="array"
      handle="[name=dragger]"
      force-callback
      @start="startDrag()"
      @end="endDrag()"
    >
      <div v-for="(element, idx) in array" :key="element.id" :order="idx">
        <v-expansion-panels v-if="expandable" v-model="element.show" accordion>
          <v-expansion-panel hide-details expand>
            <v-expansion-panel-header style="text-align: left; padding-left: 5px">
              <template v-if="true">
                <div style="min-width: 55px; max-width: 55px">
                  <v-icon color="#2B5BFF" name="dragger">
                    mdi-drag
                  </v-icon>
                  <v-icon color="#2B5BFF" @click="removeAt(idx)">
                    mdi-delete
                  </v-icon>
                </div>
              </template>
              <template #actions>
                <v-icon color="#2B5BFF">
                  $expand
                </v-icon>
              </template>
              <span style="text-align: left">
                {{ getTitle(idx) }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="expandable">
              <EditableGroup
                class="m-2"
                in-list
                :fields="element.fields"
                in-group
                @setInput="setInput(idx, ...arguments)"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else style="position: relative" class="relative">
          <!-- icons if not expandable -->
          <div style="position: absolute; top: 5px; right: 15px; z-index: 2">
            <!--<v-icon color="#2B5BFF" name="dragger">mdi-drag</v-icon>-->
            <v-icon color="#2B5BFF" size="20" @click="removeAt(idx)">
              mdi-delete
            </v-icon>
          </div>
          <!-- end icons if not expandable -->
          <EditableGroup
            class="m-2"
            in-list
            :fields="element.fields"
            in-group
            @setInput="setInput(idx, ...arguments)"
          />
        </div>
        <div style="height: 5px" />
      </div>
    </draggable>
    <div class="w-100">
      <div class="editable-button-container">
        <v-btn
          v-if="(!loading && !externalSave && (deletedOne || array.length > 0))"
          color="#2b5bff"
          dark
          :disabled="disabled"
          @click="confirm"
          @keydown="confirm"
        >
          {{ $t('dashboard.utils.editable_group_list.save') }}
        </v-btn>
        <v-btn
          v-if="loading"
          class="editable-button-container--disabled"
        >
          {{ $t('dashboard.digital_profile.save_button_loading') }}
        </v-btn>
        <div class="dashboard-button editable-button" style="margin-right: auto" @click="add" @keydown="add">
          {{ $t('dashboard.utils.editable_group_list.add') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import trackMixPanel from '@/utils/mixpanel';
import { deepCopy } from '@/utils/objects';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import EditableGroup from './EditableGroup.vue';

export default {
  name: 'EditableGroupList',
  components: { EditableGroup, draggable },
  props: {
    expandable: {
      required: false,
      type: Boolean,
      default: true,
    },
    externalSave: {
      required: false,
      type: Boolean,
      default: false,
    },
    titleVarName: {
      required: false,
      type: String,
      default: '',
    },
    formArray: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drag: false,
      loaded: false,
      array: [],
      deletedOne: false,
      saving: false,
      disabled: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      legalGuardianUUID: 'authentication/legalGuardianUUID',
    }),
  },
  watch: {
    formArray: {
      handler(newValue) {
        this.setInfoArray(newValue);
      },
    },
  },
  mounted() {
    this.setInfoArray(this.formArray);
    this.loaded = true;
  },
  methods: {
    getTitle(idx) {
      const title = this.array[idx][this.titleVarName];
      return title ?? '';
    },
    startDrag() {
      this.drag = true;
    },
    submitEmission() {
      const patchArray = [];
      for (let i = 0; i < this.array.length; i += 1) {
        const patchJson = deepCopy(this.array[i]);
        delete patchJson.fields;
        patchJson.id = this.array[i].id;
        patchJson.order = i;
        patchArray.push(patchJson);
      }
      this.$emit('setArray', patchArray);
    },
    endDrag() {
      this.drag = false;
      this.submitEmission();
    },
    setInput(index, key, value) {
      this.array[index][key] = value;
      this.submitEmission();
      if (this.array.length > 0) {
        const subarray = this.array.filter((x) => x.fields.filter((y) => y.input.length === 0).length > 0);
        this.disabled = subarray.length > 0;
      }
    },
    add() {
      const fields = deepCopy(this.fields);
      this.array.push({
        ...(this.titleVarName && { show: 0 }),
        fields,
        ...(this.titleVarName && { [this.titleVarName]: '' }),
      });
      this.disabled = true;
    },
    removeAt(idx) {
      this.array.splice(idx, 1);
      this.deletedOne = true;
      this.submitEmission();
      if (this.array.length > 0) {
        const subarray = this.array.filter((x) => x.fields.filter((y) => y.input.length === 0).length > 0);
        this.disabled = subarray.length > 0;
      }
    },
    confirm() {
      this.saving = true;
      if (this.campusDetail && this.legalGuardianUUID) {
        trackMixPanel('click_dashboard_admissions_save_question', {
          campus_code: this.campusDetail.campus_code,
          institution_code: this.campusDetail.institution_code,
        });
      }
      const patchArray = [];

      for (let i = 0; i < this.array.length; i += 1) {
        const patchJson = deepCopy(this.array[i]);
        delete patchJson.fields;
        patchJson.id = this.array[i].id;
        patchJson.order = i;
        patchArray.push(patchJson);
      }
      this.$emit('confirm', patchArray);
      this.saving = false;
    },
    setInfoArray(arrayToParse) {
      const array = deepCopy(arrayToParse ?? this.formArray);
      /* Iterate over each data and fill information */
      array.forEach((object) => {
        /* Create a copy of fields */
        const objectTemp = object;
        objectTemp.fields = deepCopy(this.fields);
        objectTemp.show = 0;
        /* fill fields with data */
        object.fields.forEach((field) => {
          const fieldTemp = field;
          if (field.varName in object) {
            fieldTemp.input = object[field.varName];
          }
        });
      });
      this.array = array;
      if (this.array.length > 0) {
        const subarray = this.array.filter((x) => x.fields.filter((y) => y.input.length === 0).length > 0);
        this.disabled = subarray.length > 0;
      }
    },
  },
};
</script>
