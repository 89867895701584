<template>
  <div
    id="application-period"
    class="dashboard-section-subcontent"
    :style="{ '--padding': padding, '--boxshadow': boxShadow }"
    :class="extraClasses"
    @click="onClick"
    @keydown="onClick"
  >
    <v-icon v-if="icon != ''" class="dashboard-section-subcontent-icon">
      {{ icon }}
    </v-icon>
    <div
      v-if="title != ''"
      :class="titleClass != '' ? `${titleClass} text-left mb-5'` : 'dashboard-text text-left mb-2'"
      style="margin-bottom: 4px"
    >
      {{ title }}
    </div>
    <div class="dashboard-grid" :style="{ '--n-elements-per-row': elementsPerRow }">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'SubContent',
  props: {
    boxShadow: {
      required: false,
      type: String,
      default: '',
    },
    titleClass: {
      required: false,
      type: String,
      default: '',
    },
    padding: {
      required: false,
      type: String,
      default: '30px',
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
    elementsPerRow: {
      required: false,
      type: Number,
      default: 1,
    },
    extraClasses: {
      required: false,
      type: String,
      default: '',
    },
    icon: {
      required: false,
      type: String,
      default: '',
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>
