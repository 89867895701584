var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-between mb-8"},[_c('div',{staticClass:"d-flex"},[_c('SVGIcon',{staticClass:"clickable",attrs:{"icon":"drag-drop.svg","alt-text":"drag","size":"13","name":"dragger"}}),_c('span',{directives:[{name:"t",rawName:"v-t",value:({
          path: 'dashboard.digital_profile.testimonials.title_index',
          args: { index: _vm.index + 1 },
        }),expression:"{\n          path: 'dashboard.digital_profile.testimonials.title_index',\n          args: { index: index + 1 },\n        }"}],staticClass:"ml-10 contacts-dashboard__title"})],1),_c('v-menu',{attrs:{"start":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#646464"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"clickable",on:{"click":function($event){return _vm.$emit('deleteTestimonial', _vm.index)}}},[_c('v-list-item-title',{staticStyle:{"color":"#CCCCCC"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.profile.delete'))+" ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"#CCCCCC"}},[_vm._v(" mdi-delete ")])],1)],1)],1)],1),_c('div',{staticClass:"d-flex flex-column gap-8"},[_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'text',
          varName: 'audiovisual_link',
          dense: true,
          input: _vm.getTestimonialLink(_vm.index),
          textColor: '#F5F5F5',
          title: _vm.$t('dashboard.digital_profile.testimonials.title_input_link'),
          titleText: true,
          backgroundColor: '#F5F5F5',
        },
      ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'text',
          varName: 'names',
          dense: true,
          input: _vm.getTestimonialName(_vm.index),
          textColor: '#F5F5F5',
          title: _vm.$t('dashboard.digital_profile.testimonials.title_input_name'),
          titleText: true,
          backgroundColor: '#F5F5F5',
        },
      ]},on:{"setObject":_vm.setBody}}),_c('EditableGroup',{attrs:{"fields":[
        {
          type: 'dropdown',
          varName: 'label_id',
          dense: true,
          input: _vm.getTestimonialLabel(_vm.index),
          items: [..._vm.getTestimonialOptions],
          openModal: true,
          title: _vm.$t('dashboard.digital_profile.testimonials.title_input_position'),
          titleText: true,
          dropdownColor: '#F5F5F5',
          place: _vm.$t('dashboard.digital_profile.testimonials.title'),
        },
      ]},on:{"setObject":_vm.setBody}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }