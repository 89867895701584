var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TextAtom',{staticClass:"title-sections__location",attrs:{"value":_vm.$t('dashboard.digital_profile.contacts.title'),"tag":"label","font":"poppins","color":"primary-dark","weight":"600","size":"title"}}),_c('TextAtom',{staticClass:"des-sections__location mb-5 d-flex left-text",attrs:{"value":_vm.$t('dashboard.digital_profile.contacts.title_field'),"tag":"label","font":"inter","color":"neutral-800","weight":"400","size":"subtitle"}}),_c('EditableGroup',{staticClass:"mb-5",attrs:{"fields":[
      {
        type: 'textarea',
        title: '',
        varName: 'direction_text',
        input: _vm.getContactsText,
        dense: true,
        fieldProps: {
          label: _vm.$t('dashboard.digital_profile.contacts.add-description'),
        },
      },
    ]},on:{"setVariable":_vm.setBody}}),_c('Draggable',{attrs:{"handle":"[name=dragger]","force-callback":""},model:{value:(_vm.contactsArray),callback:function ($$v) {_vm.contactsArray=$$v},expression:"contactsArray"}},_vm._l((_vm.contactsArray),function(contact,index){return _c('div',{key:contact.label_id},[_c('div',{staticClass:"contacts-dashboard"},[_c('ContactSingle',{key:contact.label,attrs:{"contact":contact,"contacts-array":_vm.contactsArray,"index":index},on:{"deleteContact":_vm.deleteContact,"setKeyValue":function($event){return _vm.setKeyValueAtPosition(index, ...arguments)}}})],1)])}),0),(_vm.contactsArray.length < 5)?_c('div',{staticClass:"d-flex left-text clickable",on:{"click":_vm.add,"keydown":_vm.add}},[_c('SVGIcon',{attrs:{"icon":"more-border.svg","size":"20"}}),_c('TextAtom',{staticClass:"ml-3 clickable",attrs:{"value":_vm.$t('dashboard.digital_profile.contacts.add'),"tag":"label","font":"inter","color":"primary-dark","weight":"400","size":"normal"}})],1):_vm._e(),_c('SaveButton',{attrs:{"loading":_vm.loading},on:{"save":_vm.submitContacts}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }