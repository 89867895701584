var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dashboard-grid",style:({ '--n-elements-per-row': _vm.mobile ? 1 : 2, '--element-size': '1fr' })},[_c('div',[_c('div',{directives:[{name:"t",rawName:"v-t",value:('dashboard.digital_profile.educational_proyect.title'),expression:"'dashboard.digital_profile.educational_proyect.title'"}],staticClass:"title-sections"}),_c('div',{staticClass:"des-sections",staticStyle:{"margin-bottom":"16px"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.educational_proyect.info'))+" ")]),_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'text',
            title: _vm.$t('dashboard.digital_profile.educational_proyect.title_field_pdf'),
            varName: 'pei_pdf',
            input: _vm.currentHeadMasterMainSchool.pei_pdf,
          },
          {
            type: 'text',
            title: _vm.$t('dashboard.digital_profile.educational_proyect.title_field_video'),
            varName: 'educational_project_video',
            input: _vm.videoLink,
          },
        ]},on:{"setVariable":_vm.setBody}})],1),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }