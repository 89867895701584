<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'selectionButton']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
        :subtitle-text="'user_register.disability.subtitle'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :selection-button-info="selectionButtonInfo"
        @setSelectionButtonValue="setButtonsValue"
      />
      <section v-if="disabilityToSet" style="margin-top: 30px">
        <ModalContent
          :content-sections="['subtitle', 'autoComplete']"
          :subtitle-text="'user_register.disability.question'"
          :auto-complete-placeholder="'user_register.disability.placeholder'"
          :auto-complete-items="disabilityList"
          :autocomplete-no-info-error="noInfoError && !category"
          :autocomplete-initial-value="defaultDisability"
          @setAutoComplete="setGroup"
        />
      </section>
    </div>
    <CallToActions
      style="margin-top: 40px"
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      :loader="disabilityLoader"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'ModalDisability',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    inputValues: {
      type: Object,
      default() {
        return {};
      },
    },
    preFill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectionButtonInfo: [
        { label: 'user_register.priority_question.yes', isActive: false },
        { label: 'user_register.priority_question.no', isActive: false },
      ],
      noInfoError: false,
      disabilityList: [],
      disability: null,
      disabilityToSet: null,
      disabilityLoader: false,
      defaultDisability: '',
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      disabilities: 'options/disabilities',
    }),
    isActive() {
      if (this.disability || this.disabilityToSet === false) {
        return true;
      }
      return false;
    },
  },
  watch: {
    inputValues: {
      handler(newVal) {
        if (this.preFill) {
          if (
            newVal
            && Object.keys(newVal).includes('has_disability')
            && newVal.has_disability !== null
          ) {
            if (newVal.has_disability) {
              this.setButtonsValue(0);
            } else {
              this.setButtonsValue(1);
            }
          }
          if (
            newVal
            && Object.keys(newVal).includes('disability_category')
            && newVal.disability_category !== null
          ) {
            this.defaultDisability = newVal.disability_category;
            this.setGroup(newVal.disability_category);
          }
        }
      },
    },
  },
  mounted() {
    this.disabilities.forEach((type) => {
      this.disabilityList.push(type.disability_name);
    });
    if (this.preFill) {
      if (
        this.inputValues
        && Object.keys(this.inputValues).includes('has_disability')
        && this.inputValues.has_disability !== null
      ) {
        if (this.inputValues.has_disability) {
          this.setButtonsValue(0);
        } else {
          this.setButtonsValue(1);
        }
      }
      if (
        this.inputValues
        && Object.keys(this.inputValues).includes('disability_category')
        && this.inputValues.disability_category !== null
      ) {
        this.defaultDisability = this.inputValues.disability_category;
        this.setGroup(this.inputValues.disability_category);
      }
    }
  },
  methods: {
    ...mapActions({ setDisabilities: 'newStudentRegister/setDisabilities' }),
    setGroup(value) {
      if (value) {
        this.disability = value;
      }
      return this.disability;
    },
    nextStep() {
      this.disabilityLoader = true;
      if (!this.isActive) {
        return null;
      }
      let disabilityId = null;
      let hasDisabilities = true;
      if (this.selectionButtonInfo[1].isActive) {
        hasDisabilities = false;
        disabilityId = null;
      } else {
        disabilityId = this.disabilities.find(
          (disability) => disability.disability_name === this.disability,
        ).id;
      }
      const options = {
        disability: disabilityId,
        opt: hasDisabilities,
        uuid: this.currentStudent.uuid,
      };
      this.setDisabilities(options).then(() => {
        this.disabilityLoader = false;
        this.$emit('nextStep');
      });
      return null;
    },
    setButtonsValue(i) {
      if (i === 0) {
        this.disabilityToSet = true;
        this.selectionButtonInfo[0].isActive = true;
        this.selectionButtonInfo[1].isActive = false;
      } else {
        this.disabilityToSet = false;
        this.selectionButtonInfo[0].isActive = false;
        this.selectionButtonInfo[1].isActive = true;
      }
      return i;
    },
  },
};
</script>
