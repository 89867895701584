<template>
  <Subsection
    bg-color="transparent"
    :style="{
      'background-image':
        'url('
        + require('@/assets/dashboard/widgets/location-widget.svg')
        + ')',
      'background-size': 'cover',
      'border-radius': '5px',
      'background-position': 'center',
    }"
    flex="column"
    clickable
    @onClick="toggleModal()"
  >
    <div class="widget-tool--location">
      <p v-t="'dashboard.tools.location'" class="widget-tool--location--title" />
      <p class="widget-tool--location--address">
        {{ address }}
      </p>
      <img
        class="widget-tool--location--icon"
        src="@/assets/dashboard/widgets/location-pin-widget.svg"
        alt="graph"
      />
    </div>

    <v-dialog
      v-model="showModal"
      :content-class="!mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'"
      style="height: 100vh !important"
      @click:outside="toggleModal()"
    >
      <LocationFlow
        :select-step="1"
        in-dashboard
        @closeModal="toggleModal()"
      />
    </v-dialog>
  </Subsection>
</template>

<script>
import LocationFlow from '@/components/templates/LocationFlow.vue';
import '@/styles/dashboard/simulation.scss';
import { mapGetters } from 'vuex';
import Subsection from './Subsection.vue';

export default {
  name: 'LocationSection',
  components: {
    Subsection, LocationFlow,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'favorites/loadingSchoolsDetails',
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
      legalGuardian: 'authentication/legalGuardian',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    address() {
      if (this.legalGuardian.address === null) {
        return '-';
      }
      const separator = this.legalGuardian.address[0].address_details === '' ? '' : ', ';
      return `${this.legalGuardian.address[0].address_details}${separator} ${this.legalGuardian.address[0].location}`;
    },
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>
