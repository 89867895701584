<template>
  <v-container style="height: 100%">
    <v-row no-gutters align="center" style="height: 100%">
      <v-col :cols="cols">
        <v-skeleton-loader v-if="skeletonTypeArray.includes('card')" type="card" class="p-3" />
      </v-col>
      <v-col :cols="cols">
        <div v-if="skeletonTypeArray.includes('twoCols')" class="d-flex" style="height: 308px">
          <v-skeleton-loader type="image" class="mr-5 fill-width" />
          <div class="flex-column fill-width">
            <v-skeleton-loader type="image" class="fill-width mb-5" style="height: 30%" />
            <v-skeleton-loader type="image" class="fill-width" style="height: 30%" />
          </div>
        </div>
      </v-col>
      <v-skeleton-loader
        v-if="skeletonTypeArray.includes('image')"
        type="image"
        class="fill-width"
      />
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'SkeletonAtom',
  props: {
    cols: {
      type: String,
      default: '12',
    },
    // id and name object in array
    skeletonTypeArray: {
      type: Array,
      default: null,
    },
  },
};
</script>
