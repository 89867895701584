import { PAYMENT_STATES } from '@/constants/payments';
import {
  parseConfig,
  defaultScriptCallbacks,
  defaultWidgetCallbacks,
  genericWidgetInterface,
} from './_shared';

const fintocWidgetSetupInterface = ({ provider, flowInitializationData }) => {
  const providerConfig = parseConfig({ providerConfig: provider.config_params });
  const { PROVIDER_CDN: cdn, ...widgetConfig } = providerConfig;
  const { provider_widget_token: token } = flowInitializationData;

  // Fintoc Status -> Payment Status
  const statuses = Object.freeze({
    opened: PAYMENT_STATES.PENDING,
    closed: PAYMENT_STATES.CANCELED,
    payment_created: PAYMENT_STATES.CREATED,
    payment_error: PAYMENT_STATES.FAILED,
    payment_canceled: PAYMENT_STATES.CANCELED,
  });

  const translateStatus = (status) => statuses[status] || PAYMENT_STATES.PENDING;

  const setup = ({ callbacks = defaultScriptCallbacks }) => {
    const script = document.createElement('script');
    script.onload = callbacks.loadedScript || defaultScriptCallbacks.loadedScript;
    script.src = cdn;
    script.async = true;
    document.body.appendChild(script);
  };

  const invoke = ({ config }) => Fintoc.create(config); // eslint-disable-line no-undef

  const openWidget = (widget) => widget.open();

  const destroyWidget = (widget) => {
    widget.close();
    widget.destroy();
  };

  const createConfig = ({ callbacks = defaultWidgetCallbacks }) => {
    const config = {
      widgetToken: token, // Returned from backend after initializing payment intent
      ...widgetConfig,
      onSuccess: callbacks.success || defaultWidgetCallbacks.success,
      onExit: callbacks.exit || defaultWidgetCallbacks.exit,
      onEvent: callbacks.flowEvent || defaultWidgetCallbacks.flowEvent,
    };
    return config;
  };

  return {
    ...genericWidgetInterface(), setup, createConfig, invoke, openWidget, destroyWidget, translateStatus,
  };
};

const fintocWidgetSetupLinkInterface = ({ provider, linkIntent }) => {
  const providerConfig = parseConfig({ providerConfig: provider.config_params });
  const { PROVIDER_CDN: cdn, publicKey } = providerConfig;
  const { widget_token: token } = linkIntent;

  const statuses = {
    WIDGET_OPENED: 'opened',
    WIDGET_CLOSED: 'closed',
  };

  const setup = ({ callbacks = defaultScriptCallbacks }) => {
    const script = document.createElement('script');
    script.onload = callbacks.loadedScript || defaultScriptCallbacks.loadedScript;
    script.src = cdn;
    script.async = true;
    document.body.appendChild(script);
  };

  const widgetInvoker = ({ config }) => Fintoc.create(config); // eslint-disable-line no-undef

  const openWidget = (widget) => widget.open();

  const destroyWidget = (widget) => {
    widget.close();
    widget.destroy();
  };

  const createConfig = ({ callbacks = defaultWidgetCallbacks }) => {
    const config = {
      widgetToken: token, // Returned from backend after initializing payment intent
      publicKey,
      onSuccess: callbacks.success || defaultWidgetCallbacks.success,
      onExit: callbacks.exit || defaultWidgetCallbacks.exit,
      onEvent: callbacks.flowEvent || defaultWidgetCallbacks.flowEvent,
    };
    return config;
  };

  const parseStatus = ([status]) => status;

  return {
    setup, createConfig, widgetInvoker, openWidget, destroyWidget, parseStatus, statuses,
  };
};

export {
  fintocWidgetSetupInterface, fintocWidgetSetupLinkInterface,
};
