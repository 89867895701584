<template>
  <div
    style="padding-top:20px;position:relative;"
  >
    <img
      style="padding-top:10px; padding-bottom:10px; transform: translateX(20px); cursor: pointer;"
      alt="down arrow"
      :src="require('@/assets/dashboard/icons/extra-costs-options.svg')"
      @click="showExtraOptions"
      @keypress="showExtraOptions"
    />
    <v-btn
      v-if="showOptions"
      class="extra-costs-delete-button"
      @click="emitValue(index)"
    >
      {{ $t('dashboard.digital_profile.price.extra_delete') }}
      <img
        style="padding-left:10px;"
        alt="down arrow"
        :src="require('@/assets/dashboard/icons/extra-costs-delete.svg')"
      />
    </v-btn>
  </div>
</template>

<script>

export default {
  name: 'MoreOptionsCosts',
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  methods: {
    showExtraOptions() {
      this.showOptions = !this.showOptions;
    },

    emitValue(index) {
      this.$emit('delete', index);
    },
  },
};
</script>
