var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('EditableGroup',{staticClass:"mb-5",attrs:{"fields":[
      {
        type: 'textarea',
        title: '',
        varName: 'sports_text',
        input: _vm.getSportsText,
        dense: true,
        fieldProps: {
          label: _vm.$t('dashboard.digital_profile.sports.add-description'),
        },
      },
    ]},on:{"setVariable":_vm.setTextBody}}),_c('div',{staticClass:"dashboard-grid",staticStyle:{"background-color":"white"},style:({ '--n-elements-per-row': _vm.mobile ? 1 : 2, '--element-size': '1fr' })},[_c('SportsPanel',{staticClass:"padding height-digital-profile",attrs:{"sports":_vm.campusDetail.sport_campus,"canonical-link":_vm.canonicalLink}}),_c('div',[_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'checkbox',
            title: _vm.$t('dashboard.digital_profile.sports.edit'),
            input: _vm.submittedCampusSportIds,
            varName: 'sport_campus',
            items: _vm.sportsTypes,
            elementsRow: 1,
          },
        ]},on:{"setVariable":_vm.setBody}}),(_vm.otherType.length > 0)?_c('EditableGroup',{attrs:{"fields":[
          {
            type: 'checkInput',
            title: '',
            dense: true,
            input: _vm.getOtherInfo(),
            varName: 'sport_other',
            items: _vm.otherType,
            infoOther: _vm.otherSportsInput,
          },
        ]},on:{"setVariable":_vm.setBody}}):_vm._e()],1),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }