<template>
  <div>
    <MessageSingle :show-modal="showModal" :message="singleMessage" @toggleModal="toggleModal" />

    <v-expansion-panels v-if="categorizedMessages" v-model="panel" accordion class="message-text">
      <v-expansion-panel
        v-for="(value, key, index) in categorizedMessages"
        :key="index"
        hide-details
        expand
      >
        <v-expansion-panel-header
          expand-icon=""
          style="text-align: left; padding-left: 5px; background-color: #2b5bff; color: white"
        >
          <div style="width: 100%; display: flex; align-items: center">
            <span style="margin-left: 20px">{{ key }}</span>
            <div
              style="
                color: #2b5bff;
                background-color: white;
                display: flex;
                padding: 5px;
                margin-left: auto;
                border-radius: 4px;
              "
            >
              <span style="font-weight: bold; margin-right: 5px; margin-top: 2px">
                {{ value.length }}
              </span>
              <img src="@/assets/dashboard/icons/message_unread.svg" alt="message_unread" />
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          style="
            background-color: #eef5ff;
            margin-top: 10px;
            border-radius: 4px 0px 4px 4px;
            padding-top: 18px;
          "
        >
          <div>
            <div class="date">
              <div class="date-button" @click="orderDate" @keydown="orderDate">
                <div class="date-button__title">
                  {{ $t('dashboard.messaging.date') }}
                </div>
                <v-icon v-if="order" color="#0D22A8">
                  mdi-chevron-up
                </v-icon>
                <v-icon v-if="!order" color="#0D22A8">
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>

            <div
              v-for="(message, indexTwo) in value"
              :key="indexTwo"
              style="
                display: flex;
                margin-top: 4px;
                margin-bottom: 4px;
                margin-left: 2px;
                border-radius: 4px;
                padding: 10px;
                background-color: white;
                height: 60px;
              "
            >
              <div
                v-b-tooltip.hover
                :title="message.message_title"
                class="message-span circle-class"
                style="align-items: center; display: flex"
                :style="{ color: getCircleColor(message.message_title), cursor: 'help' }"
              >
                ⬤
              </div>
              <span
                class="message-span"
                style="
                  align-items: center;
                  display: flex;
                  width: 200px;
                  min-width: 120px;
                  text-transform: capitalize;
                  margin-left: 30px;
                "
              >{{ message.username }}
              </span>
              <span
                class="message-span message-body light-text"
                @click="toggleModal(message)"
                @keydown="toggleModal(message)"
              >
                {{ shorten(message.message) }}
              </span>
              <span
                style="margin-left: 30px; align-items: center; display: flex"
                class="light-text date"
              >{{ getDate(message.timestamp) }}
              </span>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
// import Graph from '../sections/Graph.vue';
import { mapActions, mapGetters } from 'vuex';
import MessageSingle from '../../modals/utils/MessageSingle.vue';

export default {
  name: 'ListOfMessages',
  components: { MessageSingle },
  data() {
    return {
      panel: 0,
      chartData: null,
      categorizedMessages: null,
      showModal: false,
      singleMessage: null,
      order: false,
      counter: 0,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
    }),
  },
  mounted() {
    this.getSchoolMessages({ schoolUuid: this.campusDetail.uuid })
    // this.getSchoolMessages({ schoolUuid: '7204520e-806e-4b07-a4c1-9003182a4417' })
      .then((response) => {
        const categorizedMessages = { Externos: [] };
        // const categorizedMessages = Object();
        response.data.forEach((message) => {
          categorizedMessages.Externos.push(message);
          /* if (!(message.message_title in categorizedMessages)) {
            categorizedMessages[message.message_title] = [];
          }
          categorizedMessages[message.message_title].push(message); */
        });
        this.categorizedMessages = categorizedMessages;
        this.categorizedMessages.Externos.forEach((me) => {
          const [dateValues, timeValues] = me.timestamp.split(' ');

          const [year, month, day] = dateValues.split('-');
          const [hours, minutes, seconds] = timeValues.split(':');

          const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
          const meTemp = me;
          meTemp.date = date;
          this.categorizedMessages.Externos.sort((a, b) => new Date(b.date) - new Date(a.date));
        });
      })
      .catch(() => {});
  },
  methods: {
    getCircleColor(messageType) {
      if (messageType === 'estoy interesado en postular al establecimiento') {
        return '#FC80FF';
      }
      if (messageType === 'necesito ayuda') {
        return '#FC2451';
      }
      if (messageType === 'quiero reportar un error') {
        return '#0FD89C';
      }
      if (messageType === 'otro') {
        return '#11D3D9';
      }
      return 'white';
    },
    ...mapActions({
      getSchoolMessages: 'messaging/getSchoolMessages',
    }),
    orderDate() {
      this.order = !this.order;

      if (this.order === true) {
        this.categorizedMessages.Externos.sort((a, b) => new Date(a.date) - new Date(b.date));
      }
      if (this.order === false) {
        this.categorizedMessages.Externos.sort((a, b) => new Date(b.date) - new Date(a.date));
      }
    },

    getDate(timestamp) {
      const d = new Date(timestamp);
      function pad(s) {
        return s < 10 ? `0${s}` : s;
      }
      return [pad(d.getDate()), pad(d.getMonth() + 1), String(d.getFullYear()).slice(-2)].join('/');
    },
    toggleModal(message) {
      this.showModal = !this.showModal;
      this.singleMessage = message;
    },
    shorten(message) {
      const length = 65;
      if (message.length > length) {
        return `${message.slice(0, length - 3)}...`;
      }
      return message;
    },
  },
};
</script>
