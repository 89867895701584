<template>
  <div class="coming-soon">
    <SvgIcon
      :icon="icon"
      size="90"
      class="mb-10"
    />
    <TextAtom
      :value="title"
      weight="500"
      color="primary-600"
      font="poppins"
      size="large"
      class="mb-5"
    />
    <TextAtom
      :value="subtitle"
      weight="500"
      color="neutral-700"
      font="inter"
      size="big"
    />
  </div>
</template>

<script>
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'ComingSoon',
  components: {
    TextAtom, SvgIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
};
</script>
