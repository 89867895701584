<template>
  <section>
    <div
      class="resumed-admission-state"
      :class="{
        'resumed-admission-state--clickable': selectable,
        'resumed-admission-state--column': column,
      }"
      @click="handleClick"
      @keydown.enter="handleClick"
    >
      <URLImage
        v-if="admission.campus.thumb"
        :source="admission.campus.thumb"
        rounded
        :medium="column"
        :small="!column"
        :style="{
          margin: column ? '16px auto 24px auto' : '0 16px 0 0',
          width: column ? '40%' : 'fit-content',
        }"
        style="min-width: 50px;"
      />
      <div
        class="d-flex fill-width"
        :style="{
          'flex-direction': column ? 'column' : 'row',
          'justify-content': column ? 'center' : 'flex-start',
          'align-items': column ? 'center' : 'flex-start',
          margin: column ? '0 auto' : '4px 12px 0 0 ',
        }"
      >
        <div
          class="d-flex flex-column justify-center"
          :class="{
            'align-center': column,
            'fill-width': column,
            'flex-grow-1': !column,
            'mb-4': column,
          }"
        >
          <PrimaryText
            v-if="admission.campus.name"
            class="mb-2"
            :text="admission.campus.name"
            :poppins="column"
            bold
            :big="column"
            :align-left="!column"
            width-auto
          />
          <div v-if="!hideAddress" class="d-flex flex-row">
            <MdiIcon
              v-if="!hideAddressIcon"
              color="#CCCCCC"
              icon="mdi-map-marker-outline"
              small
              style="margin-right: 4px"
            />
            <PrimaryText
              v-if="admission.campus.address"
              :text="admission.campus.address"
              align-left
              neutral500
            />
          </div>
          <AdmissionStatusChip
            v-if="admission.status && !hideStatus"
            :admission-status="admission.status"
            fit-content
            :style="{ 'margin-top': column ? '16px' : '0' }"
          />
        </div>
        <StudentChip
          v-if="!hideStudent"
          :student-name="admission.applicant.name"
          elevated
          style="margin: 16px auto;"
        />

        <InfoBox
          v-if="!hideProgram && program"
          :text="program.level"
          :sub-text="program.shift"
          gray-background
          align-left
          fill-width
          no-margin
          info-padding-small
          thin-line
        />
      </div>
    </div>
  </section>
</template>
<script>
import StudentChip from '@/components/atoms/chips/StudentChip.vue';
import MdiIcon from '@/components/atoms/icons/MdiIcon.vue';
import URLImage from '@/components/atoms/images/Image.vue';
import InfoBox from '@/components/atoms/labels/InfoBox.vue';
import PrimaryText from '@/components/atoms/labels/PrimaryText.vue';
import AdmissionStatusChip from '@/components/molecules/chips/AdmissionStatusChip.vue';

export default {
  name: 'ResumedAdmissionState',
  metaInfo: {
    meta: [],
  },
  components: {
    URLImage,
    PrimaryText,
    MdiIcon,
    AdmissionStatusChip,
    StudentChip,
    InfoBox,
  },
  props: {
    admission: {
      type: Object,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Boolean,
      default: false,
    },
    hideStatus: {
      type: Boolean,
      default: false,
    },
    hideAddress: {
      type: Boolean,
      default: false,
    },
    hideStudent: {
      type: Boolean,
      default: false,
    },
    hideProgram: {
      type: Boolean,
      default: false,
    },
    hideAddressIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    program() {
      if (!this.admission.program?.name) return null;

      const [level, shift, gender] = this.admission.program.name.split(', ');
      return {
        level,
        shift,
        gender,
      };
    },
  },
  methods: {
    handleClick(event) {
      if (this.selectable) {
        this.$emit(event.type);
      }
    },
  },
};
</script>
