<template>
  <div>
    <EditableGroup
      class="mb-5"
      :fields="[
        {
          type: 'textarea',
          title: '',
          varName: 'extraactivities_text',
          input: getExtraactivitiesText,
          dense: true,
          fieldProps: {
            label: $t('dashboard.digital_profile.extracurriculars.add-description'),
          },
        },
      ]"
      @setVariable="setTextBody"
    />
    <div
      class="dashboard-grid"
      style="background-color: white"
      :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr' }"
    >
      <ExtracurricularsPanel
        class="padding height-digital-profile"
        :extracurriculars="campusDetail.extraactivity_campus"
        :canonical-link="canonicalLink"
      />
      <div>
        <EditableGroup
          :fields="[
            {
              type: 'checkbox',
              title: $t('dashboard.digital_profile.extracurriculars.info'),
              input: getExtra,
              varName: 'extraactivity_campus',
              items: extraTypes,
              elementsRow: 1,
            },
          ]"
          @setVariable="setBody"
        />
        <label>
          <input
            id="yourText"
            v-model="otherActivity"
            type="text"
            class="input-otro"
            :disabled="!extraCampus.includes(99)"
          />
        </label>
      </div>

      <SaveButton :loading="saving" @save="save" />
    </div>
  </div>
</template>
<script>
import ExtracurricularsPanel from '@/components/explorer/general/campus_detail/panels/Extracurriculars.vue';
import utils from '@/utils/';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'ExtracurricularsSection',
  components: { EditableGroup, SaveButton, ExtracurricularsPanel },
  props: {
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      body: Object(),
      textBody: {},
      extraCampus: [],
      extraTypes: [],
      extraFind: null,
      otherActivity: null,
      canonicalLink: utils.getCanonicalLink(this.$route.path),
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      extracurricularLabels: 'options/extracurricularLabels',
    }),
    getExtra() {
      try {
        return this.extraCampus;
      } catch {
        return 1;
      }
    },
    getExtraactivitiesText() {
      return this.campusDetail.institutiontext_campus[0]?.extraactivities_text ?? '';
    },
  },
  watch: {
    extraCampus: {
      handler(newIds) {
        const hasOthers = newIds.includes(99);
        if (!hasOthers) {
          this.otherActivity = null;
        }
      },
    },
  },
  async mounted() {
    await this.retrieveOptions({ reset: false });
    this.loaded = true;

    this.campusDetail.extraactivity_campus.forEach((i) => {
      this.extraFind = this.extracurricularLabels.find(
        (extra) => extra.extra_name === i.extra_label.extra_name,
      );
      if (this.extraFind) {
        this.extraCampus.push(this.extraFind.id);
      }
      const otherActivity = this.campusDetail.extraactivity_campus.find(
        (activity) => activity.extra_label.id === 99,
      );
      if (otherActivity) {
        this.otherActivity = otherActivity.descrip;
      }
    });

    const otro = this.extracurricularLabels.find((extra) => extra.id === 99);
    this.extracurricularLabels.forEach((extra) => {
      if (extra.id !== 99) {
        this.extraTypes.push({ label: extra.extra_name, value: extra.id });
      }
    });
    this.extraTypes.sort((a, b) => a.label.localeCompare(b.label));
    if (otro) {
      this.extraTypes.push({ label: otro.extra_name, value: otro.id });
    }

    if (this.campusDetail.extraactivity_campus.find((extra) => extra.extra_label.id === 99)) {
      const found = this.campusDetail.extraactivity_campus.find(
        (extra) => extra.extra_label.id === 99,
      );
      this.otherActivity = found.descrip;
    }
  },
  methods: {
    ...mapActions({
      campusExtracurricularPatch: 'institutions/campusExtracurricularPatch',
      retrieveOptions: 'options/retrieveOptions',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),
    setTextBody(body) {
      this.textBody = body;
    },
    setBody(body) {
      this.extraCampus = [];
      if (body.extraactivity_campus.length > 0) {
        body.extraactivity_campus.forEach((activityId) => {
          this.extraCampus.push(activityId);
        });
        if (!this.extraCampus.includes(99)) {
          this.otherActivity = null;
        }
      }
    },
    save() {
      this.saving = true;
      this.body.extraactivity_campus = [];
      this.extraCampus.forEach((extraId, index) => {
        this.body.extraactivity_campus.push({
          id: extraId,
        });
        if (extraId === 99) {
          Object.assign(this.body.extraactivity_campus[index], { descrip: this.otherActivity });
        }
      });
      const extraActivityPayload = [...this.body.extraactivity_campus];
      extraActivityPayload.map((activity, index) => {
        Object.assign(extraActivityPayload[index], { label_id: activity.id });
        delete extraActivityPayload[index].id;
        return activity;
      });
      this.campusSectionTextPatch({ campusUuid: this.campusDetail.uuid, campusData: this.textBody });
      this.campusExtracurricularPatch(
        { campusUuid: this.campusDetail.uuid, campusData: this.body.extraactivity_campus },
      ).then(() => {
        this.saving = false;
      });
      trackMixPanel('click_dashboard_digital_profile_extracurriculars_save', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
    },
  },
};
</script>
