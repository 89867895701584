<template>
  <div
    class="register-main register-main--spaced p-7"
    :class="{ 'application-selection-modal--mobile': mobile }"
  >
    <section class="fill-width">
      <ModalHeader
        :content-sections="['title', 'subtitle', 'close']"
        :title-text="'dashboard.digital_profile.programs.programs_flow.title'"
        :subtitle-text="'dashboard.digital_profile.programs.programs_flow.subtitle'"
        center-subtitle
        close-no-margin
        @close="$emit('close')"
      />
      <section
        class="application-selection-modal--sections"
        :class="{ 'application-selection-modal--sections--mobile': mobile }"
      >
        <div class="d-flex justify-between">
          <TextAtom
            class="mt-2 d-flex"
            :value="$t('dashboard.digital_profile.programs.programs_flow.grade')"
            tag="label"
            font="inter"
            color="primary-dark"
            weight="400"
            size="subtitle"
          />
          <div class="font-weight-400 mr-2">
            {{ step }} / {{ totalSteps }}
          </div>
        </div>
        <SelectGrades
          v-if="step === 1"
          only-grades
          button-grades
          button-wrap
          @setGradeArray="setGradeSelected"
        />
        <Shifts
          v-if="step === 2"
          class="mt-5"
          @setShifts="setShiftSelected"
        />
        <Gender
          v-if="step === 3"
          class="mt-5"
          @setGender="setGenderSelected"
        />
      </section>
    </section>
    <CallToActions
      class="programs-flow-modal--continue"
      :main-button-text="'user_register.continue'"
      :types="['main']"
      legacy-primary
      :active-value="grades.length !== 0"
      small
      :spaced="false"
      @continue="nextStep"
    />
  </div>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import SelectGrades from '@/components/shared/SelectGrades.vue';
import Gender from './Gender.vue';
import Shifts from './Shifts.vue';

export default {
  name: 'ProgramsSelectionFlow',
  components: {
    ModalHeader,
    SelectGrades,
    TextAtom,
    CallToActions,
    Shifts,
    Gender,
  },
  data() {
    return {
      step: 1,
      totalSteps: 3,
      shifts: [],
      grades: [],
      gender: 0,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    nextStep() {
      if (this.grades.length === 0) {
        return;
      }
      this.step += 1;
      if (this.step === 4) {
        this.step = 1;
        this.$emit('setNewGrade', this.grades, this.shifts, this.gender);
        this.$emit('close');
      }
    },
    setGradeSelected(gradesArray) {
      this.grades = gradesArray.map(({ grade, stage }) => ({
        grade_label_id: grade.id,
        stage_label_id: stage.id,
        order: grade.order,
      }));
    },
    setShiftSelected(shifts) {
      this.shifts = shifts;
    },
    setGenderSelected(gender) {
      this.gender = gender;
    },
  },
};
</script>
