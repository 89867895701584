<template>
  <div>
    <div>
      <div class="pb-10">
        <TextAtom
          class="title-sections__location"
          :value="$t('dashboard.digital_profile.price.title_field')"
          tag="h1"
          font="poppins"
          weight="600"
          size="big"
        />
        <TextAtom
          class="des-sections"
          :value="$t('dashboard.digital_profile.price.info')"
          tag="p"
          font="inter"
          weight="400"
          size="normal"
        />
        <EditableGroup
          class="mt-5"
          :fields="[
            {
              type: 'textarea',
              title: '',
              varName: 'payment_text',
              input: getPriceText,
              dense: true,
              fieldProps: {
                label: $t('dashboard.digital_profile.price.add-description'),
              },
            },
          ]"
          @setVariable="setBody"
        />
        <TextAtom
          class="title-sections ml-0 mb-5 mt-8"
          :value="$t('dashboard.digital_profile.price.yearly-fee')"
          tag="h2"
          font="poppins"
          weight="500"
          size="subtitle"
        />
        <div :class="[mobile ? 'costs-container--mobile' : 'costs-container']">
          <div :class="[mobile ? 'consts-container-content--mobile' : 'consts-container-content']">
            <CostsInputBox
              :quantity="String(tuitionCost)"
              @setValue="tuitionChange"
            />
            <TextAtom
              class="text-left mt-10"
              :value="$t('dashboard.digital_profile.price.category')"
              tag="h2"
              font="poppins"
              color="neutral-800"
              weight="400"
              size="subtitle"
            />
            <div
              class="dashboard-grid"
              :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr' }"
            >
              <div
                v-for="(paymentLabel, index) in [...getPaymentBandLabelsTuition]"
                :key="index"
              >
                <CostsCategoryButton
                  :backgroundcolor="colorDisplayListTuition[index]"
                  :color="colorDisplayTextListTuition[index]"
                  :label="paymentLabel.label"
                  :disabled="disabled_buttonsTuition"
                  :icon="iconsList[index]"
                  :paymentlabel="paymentLabel"
                  @setValue="clickedCategoryTuition(paymentLabel.value, paymentLabel.order)"
                />
              </div>
            </div>
          </div>
        </div>
        <TextAtom
          class="title-sections ml-0 mb-5 mt-8"
          :value="$t('dashboard.digital_profile.price.monthly-fee')"
          tag="h2"
          font="poppins"
          weight="500"
          size="subtitle"
        />
        <div :class="[mobile ? 'costs-container--mobile' : 'costs-container']">
          <div :class="[mobile ? 'consts-container-content--mobile' : 'consts-container-content']">
            <CostsInputBox
              :quantity="String(monthlyFeeCost)"
              @setValue="monthlyFeeChange"
            />
            <TextAtom
              class="text-left mt-10"
              :value="$t('dashboard.digital_profile.price.category')"
              tag="h2"
              font="poppins"
              color="neutral-800"
              weight="400"
              size="subtitle"
            />
            <div
              class="dashboard-grid"
              :style="{ '--n-elements-per-row': mobile ? 1 : 2, '--element-size': '1fr' }"
            >
              <div
                v-for="(paymentLabel, index) in [...getPaymentBandLabelsMonthly]"
                :key="index"
              >
                <CostsCategoryButton
                  :backgroundcolor="colorDisplayListMonthlyFee[index]"
                  :color="colorDisplayTextListMonthlyFee[index]"
                  :label="paymentLabel.label"
                  :disabled="disabled_buttonsMonthlyFee"
                  :icon="iconsList[index]"
                  :paymentlabel="paymentLabel"
                  @setValue="clickedCategoryMonthlyFee(paymentLabel.value, paymentLabel.order)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="Object.keys(getPrograms).length > 0"
        >
          <TextAtom
            class="title-sections ml-0 mb-5 mt-8"
            :value="$t('dashboard.digital_profile.price.monthly_fee_by_program')"
            tag="h2"
            font="poppins"
            weight="500"
            size="subtitle"
          />
          <v-card
            class="programs-card"
            color="#FFFFFF00"
          >
            <Tabs
              v-model="tab"
              :current-year="currentYear"
              :second-year="secondYear"
              :third-year="thirdYear"
              :disabled="tabDisabled"
            />
            <div
              v-for="(gradeTracksList, key) in getPrograms"
              :key="key"
            >
              <div
                v-if="hasProgramsOfTheYear(gradeTracksList)"
                :class="[mobile ? 'costs-container--mobile mb-10' : 'costs-container mb-10']"
              >
                <TextAtom
                  class="costs-container__secondaty_title"
                  :value="key"
                  tag="h3"
                  font="poppins"
                  color="primary-dark"
                  weight="500"
                  size="subtitle"
                />
                <div
                  v-for="(programList, index) in gradeTracksList"
                  :key="index"
                >
                  <div v-if="programHasYear(programList)">
                    <TextAtom
                      class="text-left mt-12 mb-4"
                      :value="index"
                      tag="h3"
                      font="poppins"
                      color="primary-dark"
                      weight="400"
                      size="subtitle"
                    />
                    <div
                      v-for="(program, programIndex) in programList"
                      :key="programIndex"
                    >
                      <div
                        v-if="sameYear(program)"
                        class="d-flex"
                      >
                        <CostsInputBox
                          class="w-2/5"
                          :quantity="String(program.cost)"
                          @setValue="setProgramCost(key, index, programIndex, $event.var)"
                        />
                        <div class="costs-input-box w-1/2">
                          <TextAtom
                            class="costs-gradetrack-title ml-5"
                            :value="program.payment_type_name"
                            tag="p"
                            font="poppins"
                            color="primary-dark"
                            weight="500"
                            size="subtitle"
                          />
                        </div>
                      </div>
                    </div>
                    <hr class="costs-program-separator">
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <div
          v-if="getExtraPayments.length > 0"
        >
          <TextAtom
            class="title-sections ml-0 mb-5 mt-8"
            :value="$t('dashboard.digital_profile.price.extras')"
            tag="h2"
            font="poppins"
            weight="500"
            size="subtitle"
          />
          <div class="costs-container">
            <div
              v-for="(extra, index) in extraPayments"
              :key="index"
            >
              <div class="d-flex">
                <CostsInputBox
                  class="w-2/5"
                  :quantity="String(extra.cost)"
                  @setValue="setExtraCost(index, $event.var)"
                />
                <div class="costs-input-box w-1/2">
                  <v-select
                    v-model="extra.id"
                    class="extra-costs-select"
                    solo
                    flat
                    :items="selectableExtraPayments"
                    :selectable="payment => payment.disabled"
                    item-text="type_name"
                    item-value="id"
                    color="#1E0C61"
                    background-color="#F5F5F5"
                    :placeholder="$t('dashboard.digital_profile.price.type_of_cost')"
                    @input="extrasChange(extra.id)"
                  />
                </div>
                <MoreOptionsCosts
                  :index="index"
                  @delete="deleteExtraPayment"
                />
              </div>
            </div>
          </div>
          <div
            v-if="getExtraPayments.length - extraPayments.length > 0"
            class="text-left"
          >
            <AddExtraCosts
              @click="addExtraPayment"
            />
          </div>
        </div>
      </div>
      <SaveButton :loading="saving" @save="save" />
    </div>
  </div>
</template>
<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import AddExtraCosts from '@/components/molecules/buttons/AddExtraCosts.vue';
import MoreOptionsCosts from '@/components/molecules/buttons/MoreOptionsCosts.vue';
import CostsInputBox from '@/components/molecules/inputs/CostInputBox.vue';
import CostsCategoryButton from '@/components/molecules/sections/CostsCategoryButton.vue';
import Tabs from '@/components/molecules/tabs/Tabs.vue';
import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { mapActions, mapGetters } from 'vuex';
import SaveButton from '../../utils/SaveButton.vue';
import EditableGroup from '../../utils/EditableGroup.vue';

export default {
  name: 'PriceSection',
  components: {
    SaveButton,
    TextAtom,
    CostsInputBox,
    CostsCategoryButton,
    MoreOptionsCosts,
    AddExtraCosts,
    Tabs,
    EditableGroup,
  },
  props: {
    mobile: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      saving: false,
      currency: TENANT_CONFIGURATION.DEFAULTS.CURRENCY,
      body: {},
      tuitionCost: '',
      selectedBandIdTuition: '',
      disabled_buttonsTuition: false,
      monthlyFeeCost: '',
      selectedBandIdMonthlyFee: '',
      disabled_buttonsMonthlyFee: false,
      programsInitialState: {},
      extraPayments: [
        {
          id: '',
          cost: '',
          showOptions: false,
        },
      ],
      textBody: {},
      selectableExtraPayments: [],
      iconsList: [
        'costs-free.svg',
        'costs-low.svg',
        'costs-medium.svg',
        'costs-high.svg',
      ],
      colorsList: [
        '#20D1BF',
        '#44B8EB',
        '#9A7DFF',
        '#5627FF',
      ],
      colorDisplayListTuition: [
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ],
      colorDisplayTextListTuition: [
        'primary-dark',
        'primary-dark',
        'primary-dark',
        'primary-dark',
      ],
      colorDisplayListMonthlyFee: [
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ],
      colorDisplayTextListMonthlyFee: [
        'primary-dark',
        'primary-dark',
        'primary-dark',
        'primary-dark',
      ],
      tab: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      secondYear: new Date().getFullYear() + 1,
      thirdYear: new Date().getFullYear() + 2,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      paymentBandLabels: 'options/paymentBandLabels',
      paymentTypeLabels: 'options/paymentTypeLabels',
    }),

    getPriceText() {
      return this.campusDetail.institutiontext_campus[0]?.payment_text ?? '';
    },
    getPayments() {
      let { institution_payment_campus: paymentList = [] } = this.campusDetail;
      const sortedPayments = [];
      paymentList = paymentList.sort((paymentA, paymentB) => paymentB.year - paymentA.year);
      paymentList.forEach((payment) => {
        const { payment_type: paymentType, program } = payment;
        const paymentValidation = sortedPayments.some(
          (sortedPayment) => sortedPayment.payment_type.id === paymentType.id && sortedPayment.program === program,
        );
        if (!paymentValidation) {
          sortedPayments.push(payment);
        }
      });

      return sortedPayments;
    },

    getPaymentBandLabelsTuition() {
      return this.paymentBandLabels.filter((band) => band.type_code === 'tuition').map((paymentBandLabels) => ({
        value: paymentBandLabels.id,
        label: paymentBandLabels.description,
        begin: paymentBandLabels.begin,
        until: paymentBandLabels.until,
        order: paymentBandLabels.order,
      })).sort((a, b) => a.order - b.order);
    },
    getPaymentBandLabelsMonthly() {
      return this.paymentBandLabels.filter((band) => band.type_code === 'monthly_payment').map((paymentBandLabels) => ({
        value: paymentBandLabels.id,
        label: paymentBandLabels.description,
        begin: paymentBandLabels.begin,
        until: paymentBandLabels.until,
        order: paymentBandLabels.order,
      })).sort((a, b) => a.order - b.order);
    },
    getPaymentTypeLabels() {
      return this.paymentTypeLabels.map((paymentTypeLabels) => ({
        id: paymentTypeLabels.id,
        type_code: paymentTypeLabels.type_code,
        type_name: paymentTypeLabels.type_name,
        disabled: false,
      }));
    },
    getExtraPayments() {
      return this.getPaymentTypeLabels.filter((type) => type.type_code !== 'tuition' && type.type_code !== 'monthly_payment');
    },

    getPrograms() {
      const programsWithCosts = this.campusDetail?.program_campus.map((program) => ({
        programId: program.id,
        gradetrackName: program.gradetrack.gradetrack_name,
        displayName: `${program.gradetrack.gradetrack_name} | ${program.shift_label.shift_name} | ${program.gradetrack.specialty_label.specialty_name}`,
        gradeLabelId: program.gradetrack.grade_label.id,
        gradeLabelName: program.gradetrack.grade_label.grade_name,
        cost: '',
        year: program.year,
      }));

      const programsArray = [];
      programsWithCosts.forEach((program) => {
        const tuitionObj = structuredClone(program);
        tuitionObj.payment_type_id = 1;
        tuitionObj.payment_type_code = 'tuition';
        tuitionObj.payment_type_name = this.$t('dashboard.digital_profile.price.yearly-fee');
        tuitionObj.cost = this.getProgramPayments(tuitionObj.programId, tuitionObj.payment_type_id);

        const monthlyObj = structuredClone(program);
        monthlyObj.payment_type_id = 2;
        monthlyObj.payment_type_code = 'monthly_payment';
        monthlyObj.payment_type_name = this.$t('dashboard.digital_profile.price.monthly-fee');
        monthlyObj.cost = this.getProgramPayments(monthlyObj.programId, monthlyObj.payment_type_id);

        programsArray.push(tuitionObj);
        programsArray.push(monthlyObj);
      });

      const groupedByGrade = {};
      programsArray.forEach((program) => {
        const { gradeLabelName } = program;
        groupedByGrade[gradeLabelName] = groupedByGrade[gradeLabelName] ?? [];
        groupedByGrade[gradeLabelName].push(program);
        return groupedByGrade;
      }, {});

      const groupedByGradetrack = {};
      Object.keys(groupedByGrade).forEach((grade) => {
        groupedByGradetrack[grade] = groupedByGradetrack[grade] ?? {};
        groupedByGrade[grade].forEach((program) => {
          const { displayName } = program;
          groupedByGradetrack[grade][displayName] = groupedByGradetrack[grade][displayName] ?? [];
          groupedByGradetrack[grade][displayName].push(program);
          return groupedByGradetrack;
        }, {});
      });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loadProgramsInitialState(groupedByGradetrack);
      return groupedByGradetrack;
    },
    tabDisabled() {
      return [
        !this.hasProgramsByYear(this.currentYear),
        !this.hasProgramsByYear(this.secondYear),
        !this.hasProgramsByYear(this.thirdYear),
      ];
    },
  },
  async mounted() {
    await this.retrieveOptions({ reset: false });
    this.loadPayments();
    this.extrasChange();
  },
  methods: {
    ...mapActions({
      retrieveOptions: 'options/retrieveOptions',
      campusPaymentV2Patch: 'institutions/campusPaymentV2Patch',
      campusSectionTextPatch: 'institutions/campusSectionTextPatch',
    }),

    loadProgramsInitialState(groupedByGradetrack) {
      this.programsInitialState = structuredClone(groupedByGradetrack);
    },

    loadPayments() {
      // Load tuition
      const tuitionCostFromDb = this.getPayments.filter((payment) => payment.payment_type.type_code === 'tuition' && payment.program === null);
      let tuitionCostInitial = tuitionCostFromDb.length > 0 ? tuitionCostFromDb[0].fixed_value : '';
      tuitionCostInitial = tuitionCostInitial === null ? '' : tuitionCostInitial;
      this.tuitionChange({ tuitionCost: tuitionCostInitial });
      if (this.tuitionCost === '') {
        const CategoryTuitionFromDb = tuitionCostFromDb.length > 0 ? tuitionCostFromDb[0].payment_band.id : '';
        if (CategoryTuitionFromDb !== '') {
          const orderTuitionFromDb = (
            this.getPaymentBandLabelsTuition.filter((band) => band.value === CategoryTuitionFromDb)[0].order
          );
          this.clickedCategoryTuition(CategoryTuitionFromDb, orderTuitionFromDb);
        }
      }

      // Load monthly fee
      const monthlyFeeCostFromDb = this.getPayments.filter((payment) => payment.payment_type.type_code === 'monthly_payment' && payment.program === null);
      let monthlyFeeCostInitial = monthlyFeeCostFromDb.length > 0 ? monthlyFeeCostFromDb[0].fixed_value : '';
      monthlyFeeCostInitial = monthlyFeeCostInitial === null ? '' : monthlyFeeCostInitial;
      this.monthlyFeeChange({ monthlyFeeCost: monthlyFeeCostInitial });
      if (this.monthlyFeeCost === '') {
        const CategoryMonthlyFeeFromDb = monthlyFeeCostFromDb.length > 0 ? monthlyFeeCostFromDb[0].payment_band.id : '';
        if (CategoryMonthlyFeeFromDb !== '') {
          const orderMonthlyFeeFromDb = (
            this.getPaymentBandLabelsMonthly.filter((band) => band.value === CategoryMonthlyFeeFromDb)[0].order
          );
          this.clickedCategoryMonthlyFee(CategoryMonthlyFeeFromDb, orderMonthlyFeeFromDb);
        }
      }

      // The loading per program is done simultaneously when the programs are retrieved

      // Load extras
      const extrasFromDb = this.getPayments.filter((payment) => payment.payment_type.type_code !== 'tuition' && payment.payment_type.type_code !== 'monthly_payment' && payment.program === null);
      this.extraPayments = extrasFromDb.map((extra) => ({
        id: extra.payment_type.id,
        cost: extra.fixed_value,
      }));
      this.extraPayments.forEach((extra) => {
        this.extrasChange(extra.id);
      });
      if (this.extraPayments.length === 0) {
        this.extraPayments.push(
          {
            id: '',
            cost: '',
            showOptions: false,
          },
        );
      }
    },

    getProgramPayments(programId, paymentType) {
      if (
        this.getPayments.filter((payment) => payment.program === programId
        && payment.payment_type.id === paymentType).length > 0
      ) {
        return (
          this.getPayments
            .filter((payment) => payment.program === programId && payment.payment_type.id === paymentType)[0]
            .fixed_value
        );
      }
      return '';
    },

    clickedCategoryTuition(paymentLabelValue, paymentOrder) {
      if (this.selectedBandIdTuition === paymentLabelValue) {
        this.selectedBandIdTuition = '';
        this.disabled_buttonsTuition = false;
        this.restartColorsTuition();
      } else {
        this.selectedBandIdTuition = paymentLabelValue;
        this.restartColorsTuition();
        this.colorDisplayListTuition[paymentOrder] = this.colorsList[paymentOrder];
        this.colorDisplayTextListTuition[paymentOrder] = 'secondary-light';
      }
    },

    clickedCategoryMonthlyFee(paymentLabelValue, paymentOrder) {
      if (this.selectedBandIdMonthlyFee === paymentLabelValue) {
        this.selectedBandIdMonthlyFee = '';
        this.disabled_buttonsMonthlyFee = false;
        this.restartColorsMonthlyFee();
      } else {
        this.selectedBandIdMonthlyFee = paymentLabelValue;
        this.restartColorsMonthlyFee();
        this.colorDisplayListMonthlyFee[paymentOrder] = this.colorsList[paymentOrder];
        this.colorDisplayTextListMonthlyFee[paymentOrder] = 'secondary-light';
      }
    },

    tuitionChange(value = null) {
      this.selectedBandIdTuition = '';
      this.restartColorsTuition();
      this.tuitionCost = Number(Object.entries(value)[0][1]) < 0 ? '0' : Object.entries(value)[0][1];
      if (this.tuitionCost !== '') {
        this.disabled_buttonsTuition = true;
        const paymentLabels = this.getPaymentBandLabelsTuition;
        for (let i = 0; i < paymentLabels.length; i += 1) {
          if (Number(this.tuitionCost) === 0 && paymentLabels[i].until === 0) {
            this.selectedBandIdTuition = paymentLabels[i].value;
            this.colorDisplayListTuition[paymentLabels[i].order] = this.colorsList[paymentLabels[i].order];
            this.colorDisplayTextListTuition[paymentLabels[i].order] = 'secondary-light';
          } else if (paymentLabels[i].begin < this.tuitionCost && paymentLabels[i].until >= this.tuitionCost) {
            this.selectedBandIdTuition = paymentLabels[i].value;
            this.colorDisplayListTuition[paymentLabels[i].order] = this.colorsList[paymentLabels[i].order];
            this.colorDisplayTextListTuition[paymentLabels[i].order] = 'secondary-light';
          }
        }
      } else {
        this.disabled_buttonsTuition = false;
      }
    },

    monthlyFeeChange(value = null) {
      this.selectedBandIdMonthlyFee = '';
      this.restartColorsMonthlyFee();
      this.monthlyFeeCost = Number(Object.entries(value)[0][1]) < 0 ? '0' : Object.entries(value)[0][1];
      if (this.monthlyFeeCost !== '') {
        this.disabled_buttonsMonthlyFee = true;
        const paymentLabels = this.getPaymentBandLabelsMonthly;
        for (let i = 0; i < paymentLabels.length; i += 1) {
          if (this.monthlyFeeCost === '0' && paymentLabels[i].until === 0) {
            this.selectedBandIdMonthlyFee = paymentLabels[i].value;
            this.colorDisplayListMonthlyFee[paymentLabels[i].order] = this.colorsList[paymentLabels[i].order];
            this.colorDisplayTextListMonthlyFee[paymentLabels[i].order] = 'secondary-light';
          } else if (paymentLabels[i].begin < this.monthlyFeeCost && paymentLabels[i].until >= this.monthlyFeeCost) {
            this.selectedBandIdMonthlyFee = paymentLabels[i].value;
            this.colorDisplayListMonthlyFee[paymentLabels[i].order] = this.colorsList[paymentLabels[i].order];
            this.colorDisplayTextListMonthlyFee[paymentLabels[i].order] = 'secondary-light';
          }
        }
      } else {
        this.disabled_buttonsMonthlyFee = false;
      }
    },

    setProgramCost(grade, gradetrack, programindex, quantity) {
      // FIXME: poner esta validación de math.max en el cost-input-box
      this.getPrograms[grade][gradetrack][programindex].cost = Math.max(0, Number(quantity));
    },

    setExtraCost(index, quantity) {
      this.extraPayments[index].cost = Math.max(0, Number(quantity));
    },

    extrasChange(objectId) {
      this.selectableExtraPayments = this.getExtraPayments;
      this.getExtraPayments.forEach((item) => {
        if (item.id === objectId) {
        // eslint-disable-next-line no-param-reassign
          item.disabled = true;
        } else if (!this.extraPayments.map((payment) => payment.id).includes(item.id)) {
        // eslint-disable-next-line no-param-reassign
          item.disabled = false;
        }
      });
    },

    addExtraPayment() {
      this.extraPayments.push({
        id: '',
        cost: '',
        showOptions: false,
      });
    },

    deleteExtraPayment(index) {
      const typeToRelease = this.extraPayments[index].id;
      this.selectableExtraPayments.forEach((item) => {
        if (item.id === typeToRelease) {
        // eslint-disable-next-line no-param-reassign
          item.disabled = false;
        }
      });
      this.extraPayments.splice(index, 1);
    },

    restartColorsTuition() {
      this.colorDisplayListTuition = [
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ];
      this.colorDisplayTextListTuition = [
        'primary-dark',
        'primary-dark',
        'primary-dark',
        'primary-dark',
      ];
    },

    restartColorsMonthlyFee() {
      this.colorDisplayListMonthlyFee = [
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
        '#FFFFFF',
      ];
      this.colorDisplayTextListMonthlyFee = [
        'primary-dark',
        'primary-dark',
        'primary-dark',
        'primary-dark',
      ];
    },

    setPatchBody() {
      const obj = [];

      // FIXME: al escribir el valor con '.' o ',' se cambia el formato a string y el back no lo soporta
      // así que por ahora se hizo este replace para enviar el número sin símbolos
      const temporalTuitionCost = Number(this.tuitionCost.toString().replace('.', '').replace(',', ''));
      const temporalMonthlyFeeCost = Number(this.monthlyFeeCost.toString().replace('.', '').replace(',', ''));

      // Adding tuition cost
      obj.push(
        {
          payment_type: this.paymentTypeLabels.filter((item) => item.type_code === 'tuition')[0].id,
          payment_band: this.selectedBandIdTuition === '' ? null : this.selectedBandIdTuition,
          fixed_value: this.tuitionCost === '' ? null : temporalTuitionCost,
          currency: this.currency,
        },
      );

      // Adding monthly fee cost
      obj.push(
        {
          payment_type: this.paymentTypeLabels.filter((item) => item.type_code === 'monthly_payment')[0].id,
          payment_band: this.selectedBandIdMonthlyFee === '' ? null : this.selectedBandIdMonthlyFee,
          fixed_value: this.monthlyFeeCost === '' ? null : temporalMonthlyFeeCost,
          currency: this.currency,
        },
      );

      // Adding cost per program
      // Iterating over every grade
      for (let gradeIdx = 0; gradeIdx < Object.keys(this.getPrograms).length; gradeIdx += 1) {
        const grades = this.getPrograms[Object.keys(this.getPrograms)[gradeIdx]];

        // Iterating over every gradetrack
        for (let gradetrackIdx = 0; gradetrackIdx < Object.keys(grades).length; gradetrackIdx += 1) {
          const gradetracks = grades[Object.keys(grades)[gradetrackIdx]];

          // Iterating over every payment. If it has changed, add it to the patch body
          for (let gradetrackPaytIdx = 0; gradetrackPaytIdx < Object.keys(gradetracks).length; gradetrackPaytIdx += 1) {
            const gradetrackPayment = gradetracks[Object.keys(gradetracks)[gradetrackPaytIdx]];

            const gradeInitialState = this.programsInitialState[Object.keys(this.getPrograms)[gradeIdx]];
            const gradetrackPaymentInitialState = (
              gradeInitialState[Object.keys(grades)[gradetrackIdx]][Object.keys(gradetracks)[gradetrackPaytIdx]]
            );

            if (gradetrackPayment.cost !== gradetrackPaymentInitialState.cost) {
              obj.push(
                {
                  payment_type: gradetrackPayment.payment_type_id,
                  fixed_value: gradetrackPayment.cost === '' ? null : gradetrackPayment.cost,
                  program_id: gradetrackPayment.programId,
                  currency: this.currency,
                },
              );
            }
          }
        }
      }

      // Adding extra costs
      for (let i = 0; i < this.extraPayments.length; i += 1) {
        if (this.extraPayments[i].id !== '' || this.extraPayments[i].cost !== '') {
          obj.push(
            {
              payment_type: this.extraPayments[i].id,
              fixed_value: this.extraPayments[i].cost === '' ? null : this.extraPayments[i].cost,
              currency: this.currency,
            },
          );
        }
      }

      return {
        payments: obj,
      };
    },

    save() {
      this.saving = true;
      this.body = this.setPatchBody();
      this.campusSectionTextPatch({ campusUuid: this.campusDetail.uuid, campusData: this.textBody });
      this.campusPaymentV2Patch({ campusUuid: this.campusDetail.uuid, campusData: this.body }).then(() => {
        this.saving = false;
      });
      this.saving = false;
    },
    getYear() {
      return new Date().getFullYear() + this.tab;
    },
    sameYear(shift) {
      return shift.year === this.getYear();
    },
    programHasYear(programList) {
      return programList.some((shift) => this.sameYear(shift));
    },
    hasProgramsOfTheYear(grade) {
      return Object.keys(grade).some((program) => this.programHasYear(grade[program]));
    },
    hasProgramsByYear(year) {
      const programs = Object.values(this.getPrograms);
      return programs.some((program) => Object.values(program).flat().some((shift) => shift.year === year));
    },
    setBody(body) {
      this.textBody = body;
    },
  },
};
</script>
