<template>
  <Subsection
    bg-color="transparent"
    :style="{
      'background-image': 'url(' + require('@/assets/dashboard/widgets/sae-widget.svg') + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'border-radius': '5px',
      'background-position': 'center',
    }"
    flex="column"
    clickable
    @onClick="displayReport"
  >
    <div class="mt-1 ml-1">
      <div class="widget-tool">
        {{ $t('dashboard.analytics.reports.sae_results_title_21') }}
      </div>
      <div class="widget-tool--des">
        {{ $t('dashboard.analytics.reports.sae_results_desc') }}
      </div>
    </div>
    <v-dialog
      v-model="launchModal"
      width="70%"
      @click:outside="closeModal()"
    >
      <iframe
        class="sae-results-report-frame"
        title="results-report"
        :src="getSourceUrl()"
        alt="reporte"
        loading="lazy"
      />
    </v-dialog>
  </Subsection>
</template>

<script>
import { mapGetters } from 'vuex';
import Subsection from '@/components/dashboard/sections/Subsection.vue';

export default {
  name: 'SaeResultsReport21',
  components: { Subsection },
  data() {
    return {
      reportCode: 'sae_results_2021',
      launchModal: false,
    };
  },
  computed: {
    ...mapGetters({
      reportsWidgetsData: 'widgets/reportsWidgetsData',
    }),
  },
  mounted() {

  },
  methods: {
    displayReport() {
      this.launchModal = true;
    },
    closeModal() {
      this.launchModal = false;
    },
    getSourceUrl() {
      return this.reportsWidgetsData[this.reportCode].widget_link;
    },
  },
};
</script>
<style scoped>
.sae-results-report {
  cursor: pointer;
  background-color: rgb(197, 197, 255);
  height: 100%;
  width: 100%;
}

.sae-results-report-frame {
  height: 90vh;
}
</style>
