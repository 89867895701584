<template>
  <div class="dashboard-section-content">
    <div :class="!mobile ? 'grid-info' : ''">
      <div class="my-2 text-left">
        <v-btn class="button-select" color="white" dark @click="selectAll()">
          {{ $t('campus-details.vacancies.select-grades') }}
        </v-btn>
      </div>
      <div class="grid-info__vacancies">
        <div v-for="(value, key, i) in vacancies" :key="i" class="text-left">
          <span
            v-if="hasVacancies(value)"
            class="campus-detail__panels__title text-left mb-2 font-weight-medium"
            style="text-decoration: underline"
          >
            <b>{{ key }}</b>
          </span>
          <div
            v-if="hasVacancies(value)"
            class="dashboard-grid"
            :style="{ '--n-elements-per-row': 'auto-fit', '--element-size': '210px' }"
          >
            <div v-for="(vacancy, j) in vacancies[key]" :key="j">
              <div
                :style="{ height: '60px' }"
                :class="[
                  vacancy.state
                    ? 'panel_container_row_item__vacancies'
                    : 'panel_container_row_item__no_vacancies',
                  isSAE ? '' : 'clickable',
                ]"
                class="dashboard-switch dashboard-grid-element text-center"
                @click="toggleSwitch(vacancy)"
                @keydown="toggleSwitch(vacancy)"
              >
                {{ vacancy.shortened }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-info__box">
        {{ $t('campus-details.vacancies.description') }}
        <div class="grid-info__box--white">
          <div class="grid-info__box--white--first">
            <div class="vacanciesIcon" style="float: left" />
            <span style="color: #2b5bff" class="ml-2">
              {{ $t('campus-details.vacancies.yes-vacancies-dash') }}
            </span>
          </div>
          <div class="div-container grid-info__box--white--second">
            <div class="noVacanciesIcon" style="float: right" />
            <span style="color: #2b5bff" class="ml-2">
              {{ $t('campus-details.vacancies.no-vacancies-dash') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <SaveButton v-if="!isSAE" bottom="20px" :loading="saving" @save="save" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import trackMixPanel from '@/utils/mixpanel';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'AdmissionVacancies',
  components: { SaveButton },
  data() {
    return {
      saving: false,
      loaded: false,
      vacancies: Object(),
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      currentStudent: 'authentication/currentStudent',
      stages: 'options/stages',
      grades: 'options/stages',
    }),
    isSAE() {
      return this.campusDetail?.campus_admission_system[0]?.admission_system?.id === 1 ?? null;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mounted() {
    this.setVacancies();
  },
  methods: {
    ...mapActions({
      patchCampus: 'institutions/patchCampus',
    }),
    save() {
      trackMixPanel('click_dashboard_admissions_vacancies_save', {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      this.saving = true;
      const vacanciesPatch = { ...this.vacancies };
      this.stages.forEach((stage) => {
        if (vacanciesPatch[stage.stage_name]) {
          const stageObject = {};
          vacanciesPatch[stage.stage_name].forEach((vacancy) => {
            const gradeId = vacancy.grade_id;
            stageObject[gradeId] = vacancy;
          });
          vacanciesPatch[stage.stage_name] = stageObject;
        }
      });
      this.patchCampus({
        campusUuid: this.campusDetail.uuid,
        campusData: { vacancies: vacanciesPatch },
      }).then(() => {
        this.saving = false;
      });
    },
    toggleSwitch(vacancy) {
      this.vacancies[vacancy.stage_label_name].map((grade) => {
        if (grade.grade_id === vacancy.grade_id) {
          const newGrade = grade;
          if (grade.state) {
            newGrade.state = false;
          } else {
            newGrade.state = true;
          }
          return newGrade;
        }
        return grade;
      });
    },
    selectAll() {
      // eslint-disable-next-line
      for (const index in this.vacancies) {
        // eslint-disable-next-line
        for (const index2 in this.vacancies[index]) {
          this.vacancies[index][index2].state = 1;
        }
      }
      this.vacancies = { ...this.vacancies };
    },
    hasVacancies(section) {
      return Object.entries(section).filter(([value]) => value.state);
    },
    setVacancies() {
      const campusStages = [];
      const campusGrades = [];
      this.campusDetail.program_campus.forEach((program) => {
        const stageId = program.gradetrack.stage_label.id;
        const gradeId = program.gradetrack.grade_label.id;
        if (!campusStages.includes(stageId)) {
          campusStages.push(stageId);
        }
        if (!campusGrades.includes(gradeId)) {
          campusGrades.push(gradeId);
        }
      });
      const vacanciesArray = {};
      this.stages.forEach((stage) => {
        if (campusStages.includes(stage.id)) {
          vacanciesArray[stage.stage_name] = [];
          stage.grades.forEach((grade) => {
            if (campusGrades.includes(grade.id)) {
              const gradeInfo = {
                stage_label_name: stage.stage_name,
                grade_id: grade.id,
                shortened: grade.grade_name,
                state: false,
                vacancies: null,
                order: grade.order,
              };
              vacanciesArray[stage.stage_name].push(gradeInfo);
            }
          });
        }
      });
      this.campusDetail.vacancies.forEach((vacancy) => {
        vacanciesArray[vacancy.stage_label_name].map((grade) => {
          if (vacancy.grade_id === grade.grade_id) {
            const gradeUpdate = grade;
            const newVacancies = vacancy.regular_vacancies + gradeUpdate.vacancies + vacancy.special_1_vacancies;
            const newState = newVacancies > 0;
            gradeUpdate.vacancies = newVacancies;
            gradeUpdate.state = newState;
            return gradeUpdate;
          }
          return grade;
        });
        vacanciesArray[vacancy.stage_label_name].sort((a, b) => a.order - b.order);
      });
      this.vacancies = vacanciesArray;
      this.loaded = true;
    },
  },
};
</script>
