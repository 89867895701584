import { PAYMENT_STATES } from '@/constants/payments';
import paymentsService from '@/services/payments.service';
import {
  parseConfig,
  defaultScriptCallbacks,
  genericWidgetInterface,
} from './_shared';

const kushkiWidgetSetupInterface = ({ provider, flowInitializationData }) => {
  console.log('🚀 ~ file: kushki.js:10 ~ kushkiWidgetSetupInterface:', { provider, flowInitializationData });
  const settings = {
    requiresForm: true,
    formSettings: {
      formId: 'kushki-form',
    },
  };
  const providerConfig = parseConfig({ providerConfig: provider.config_params });
  console.log('🚀 ~ file: kushki.js:19 ~ kushkiWidgetSetupInterface ~ providerConfig:', providerConfig);
  // TODO: We also have the CDN in the flowInitializationData but we are not using it. Decide whether we should use it
  // or remove it from the serializer/response
  const { PROVIDER_CDN: cdn, ...widgetConfig } = providerConfig;
  const {
    tether_payment_id: paymentId,
    tether_payment_intent_id: flowId,
    payment_amount: { next_installment: amount },
  } = flowInitializationData;

  // Fintoc Status -> Payment Status
  const statuses = Object.freeze({
    opened: PAYMENT_STATES.PENDING,
    closed: PAYMENT_STATES.CANCELED,
    payment_created: PAYMENT_STATES.CREATED,
    payment_error: PAYMENT_STATES.FAILED,
    payment_canceled: PAYMENT_STATES.CANCELED,
  });

  const translateStatus = (status) => statuses[status] || PAYMENT_STATES.PENDING;

  const setup = ({ callbacks = defaultScriptCallbacks }) => {
    const script = document.createElement('script');
    script.onload = callbacks.loadedScript || defaultScriptCallbacks.loadedScript;
    script.src = cdn;
    script.async = true;
    document.body.appendChild(script);
  };

  const createConfig = () => {
    const config = {
      ...widgetConfig,
      form: settings.formSettings.formId,
      amount: {
        subtotalIva: 0,
        subtotalIva0: parseInt(amount, 10),
        iva: 0,
      },
    };

    return config;
  };

  const invoke = ({ config }) => {
    // Inject Form field with value
    // FIXME: Validate whether this input is needed or not
    const root = document.getElementById('widget-invoker-root');

    const form = document.createElement('form');
    form.id = settings.formSettings.formId;
    form.method = 'POST';
    form.action = paymentsService.buildSubmitFlowUrl({ paymentId, flowId });

    root.appendChild(form);

    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'cart_id';
    input.value = paymentId;
    form.appendChild(input);

    return new KushkiCheckout(config); // eslint-disable-line no-undef
  };

  const openWidget = () => {};

  const destroyWidget = () => {};

  // Kushki submission flow is handled by the widget itself, so we don't need to do anything here
  const submitFlow = async ({ submissionFlowData }) => submissionFlowData;

  return {
    ...genericWidgetInterface(),
    createConfig,
    destroyWidget,
    invoke,
    openWidget,
    setup,
    submitFlow,
    translateStatus,
    settings,
  };
};

export {
  kushkiWidgetSetupInterface,
};
