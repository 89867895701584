<template>
  <!-- FIXME: This uses a hybrid implementation of title to comply with design -->
  <div class="dashboard-text dashboard-section-title-fullspace graph-title">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'AnalyticsGraphTitle',
  props: {
    title: {
      required: true,
      type: String,
    },
  },
};
</script>

<style></style>
