<template>
  <div>
    <ModalContent
      :content-sections="['selectionButton']"
      :selection-button-info="getGenderArray"
      selection-button-wide
      @setSelectionButtonValue="setButtonsValue"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalContent from '@/components/molecules/modals/Content.vue';
import { LABELS } from '@/constants/explorer/labels';

export default {
  name: 'GenderSection',
  components: {
    ModalContent,
  },
  props: {
    prefill: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectionBtnInfo: {},
    };
  },
  computed: {
    ...mapGetters({
      labelsByKey: 'explorer/labelsByKey',
    }),
    getGenderArray() {
      return this.labelsByKey(LABELS.PROGRAM_GENDERS).map((gender) => ({
        id: gender.id,
        label: gender.gender_name,
        isActive: this.selectionBtnInfo.id === gender.id,
      })) ?? [];
    },
  },
  mounted() {
    this.selectionBtnInfo = {
      id: this.prefill,
    };
  },
  methods: {
    setButtonsValue(index, genderId) {
      this.selectionBtnInfo = { id: genderId };
      this.$emit('setGender', genderId);
    },
  },
};
</script>
