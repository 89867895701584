<template>
  <div class="programs-grade">
    <div class="programs-grade--title">
      <TextAtom
        :value="getGradeLabelName"
        tag="label"
        font="poppins"
        color="neutral-800"
        weight="400"
        size="subtitle"
      />
      <v-menu bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            color="#646464"
            v-bind="attrs"
            v-on="on"
          >
            mdi-dots-horizontal
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            class="clickable"
            @click="deleteGrade()"
          >
            <v-list-item-title style="color: #CCCCCC;">
              {{ $t('dashboard.profile.delete') }}
            </v-list-item-title>
            <v-icon
              class="ml-2"
              color="#CCCCCC"
            >
              mdi-delete
            </v-icon>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div>
      <div class="flex-info flex-row mt-7 gap-7">
        <EditableGroup
          class="flex-1"
          :fields="[
            {
              type: 'dropdown',
              varName: 'grade_id',
              dense: true,
              input: getGradeId(),
              items: [...getGradeOptions],
              openModal: true,
              padding: '0px',
              dropdownColor: '#F5F5F5',
              place: $t('dashboard.digital_profile.programs.grade_list.title'),
            },
          ]"
          @setObject="setGrade(...arguments)"
        />
        <EditableGroup
          class="flex-1"
          :fields="[
            {
              type: 'text',
              varName: 'personalized_name',
              dense: true,
              input: getPersonalizedName(),
              textColor: '#F5F5F5',
              title: $t('dashboard.digital_profile.programs.grade_list.grade_name'),
              titleText: false,
              backgroundColor: '#F5F5F5',
            },
          ]"
          @setObject="setPersonalizedName(...arguments)"
        />
      </div>
      <TextAtom
        :value="$t('dashboard.digital_profile.programs.title')"
        tag="label"
        font="poppins"
        color="primary-dark"
        weight="400"
        size="subtitle"
        class="d-flex mt-12 mb-5"
      />
      <div>
        <div v-for="(grade, index) in getCampusGrade" :key="index">
          <ProgramSingle
            v-if="grade.year === year"
            class="mt-5"
            :grade="grade"
            @deleteProgram="deleteProgram(index, ...arguments)"
            @setGenderSelected="setGender(index, ...arguments)"
            @setModality="setModality(index, ...arguments)"
            @setSpecialty="setSpecialty(index, ...arguments)"
            @setShift="setShift(index, ...arguments)"
          />
        </div>
        <div
          class="d-flex left-text clickable mt-5"
          @click="add"
          @keydown="add"
        >
          <SVGIcon
            icon="more-border-gray.svg"
            size="17"
          />
          <TextAtom
            class="ml-2 clickable"
            :value="$t('dashboard.digital_profile.programs.grade_list.add_program')"
            tag="label"
            font="inter"
            color="neutral-800"
            weight="400"
            size="normal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SVGIcon from '@/components/atoms/icons/SvgIcon.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CONFIG from '@/config';
import EditableGroup from '../../utils/EditableGroup.vue';
import ProgramSingle from './ProgramSingle.vue';

export default {
  name: 'GradeSingle',
  components: {
    SVGIcon,
    TextAtom,
    EditableGroup,
    ProgramSingle,
  },
  props: {
    grades: {
      type: Array,
      default: () => [],
    },
    indexGrade: {
      type: Number,
      required: true,
      default: 0,
    },
    year: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      campusGrades: [],
    };
  },
  computed: {
    ...mapGetters({
      gradesOptions: 'options/grades',
      stageOptions: 'options/stages',
    }),
    getGradeOptions() {
      return this.gradesOptions.map((grade) => ({
        label: grade.grade_name,
        value: grade.id,
      }));
    },
    getGrades() {
      return this.grades;
    },
    getGradeLabelName() {
      const gradeId = this.grades[0]?.gradetrack?.grade_label_id;
      const stageId = this.grades[0]?.gradetrack?.stage_label_id;
      let stage = '';
      if (gradeId !== null) {
        const gradeName = this.gradesOptions.find((item) => item.id === gradeId);
        if (this.hasStageName(stageId)) {
          const stageName = this.stageOptions.find((item) => item.id === stageId);
          stage = `- ${stageName?.stage_name}`;
        }
        return `${gradeName?.grade_name} ${stage}`;
      }
      return `${this.indexGrade + 1}° ${this.$t('dashboard.digital_profile.programs.grade_list.title')}`;
    },
    getCampusGrade() {
      return this.campusGrades;
    },
  },
  watch: {
    grades() {
      this.campusGrades = this.getGrades;
    },
    campusGrades: {
      handler() {
        this.setBody();
      },
      deep: true,
    },
  },
  mounted() {
    this.campusGrades = this.getGrades;
  },
  methods: {
    deleteGrade() {
      this.$emit('deleteGrade');
    },
    getGradeId() {
      return this.grades[0].gradetrack?.grade_label_id;
    },
    getPersonalizedName() {
      return this.campusGrades[0]?.personalized_name;
    },
    deleteProgram(index) {
      if (this.campusGrades.length > 1) {
        this.campusGrades.splice(index, 1);
      } else {
        this.campusGrades = [];
      }
    },
    add() {
      this.campusGrades.push({
        id: undefined,
        gender_label_id: null,
        shift_label_id: this.getDefaultShiftId(),
        personalized_name: this.grades[0]?.personalized_name,
        gradetrack: {
          id: null,
          stage_label_id: this.grades[0]?.gradetrack?.stage_label_id,
          modality_label_id: null,
          specialty_label_id: null,
          grade_label_id: this.grades[0]?.gradetrack?.grade_label_id,
        },
        year: this.year,
      });
    },
    setShift(index, body) {
      this.campusGrades[index].shift_label_id = body.grade_id;
    },
    setGender(index, body) {
      this.campusGrades[index].gender_label_id = body;
    },
    setModality(index, value) {
      this.campusGrades[index].gradetrack.modality_label_id = value.modality_id;
      this.campusGrades[index].gradetrack.id = null;
    },
    setSpecialty(index, value) {
      this.campusGrades[index].gradetrack.specialty_label_id = value.specialty_id;
      this.campusGrades[index].gradetrack.id = null;
    },
    setGrade(value) {
      this.campusGrades.forEach((grade, index) => {
        const { gradetrack } = grade;
        this.$set(this.campusGrades, index, {
          ...grade,
          id: undefined,
          gradetrack: {
            ...gradetrack,
            id: null,
            grade_label_id: value.grade_id,
            modality_label_id: null,
            specialty_label_id: null,
            stage_label_id: this.getStageByGrade(value.grade_id),
          },
          year: this.year,
        });
      });
    },
    setPersonalizedName(value) {
      this.campusGrades.forEach((grade) => {
        const gradeEdit = grade;
        gradeEdit.personalized_name = value.personalized_name;
      });
    },
    setBody() {
      this.$emit('setCampusGrades', this.campusGrades);
    },
    hasStageName(stageId) {
      const { tenant } = CONFIG;
      if ((tenant === 'chile' && (stageId === 4 || stageId === 5))
        || (tenant === 'colombia' && stageId === 5)) {
        return true;
      }
      return false;
    },
    getDefaultShiftId() {
      const { tenant } = CONFIG;
      if (tenant === 'chile') {
        return 3;
      } if (tenant === 'colombia') {
        return 6;
      } if (tenant === 'newhaven') {
        return 1;
      }
      return 1;
    },
    getStageByGrade(gradeId) {
      // TODO: Partially compatible - Update way to obtain the gradeId
      return this.gradesOptions.find((item) => item.id === gradeId)?.stage?.id;
    },
  },
};
</script>
