var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"title-sections"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.languages.edit'))+" "),_c('v-tooltip',{staticStyle:{"margin-left":"1rem"},attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"1rem","margin-top":"0.1rem"},attrs:{"icon":"","dark":"","x-small":"","color":"#1E2D4C"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-information ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.languages.info'))+" ")])])],1),_c('EditableGroup',{staticClass:"mb-5",attrs:{"fields":[
      {
        type: 'textarea',
        title: '',
        varName: 'languages_text',
        input: _vm.getLanguagesText,
        dense: true,
        fieldProps: {
          label: _vm.$t('dashboard.digital_profile.languages.add-description'),
        },
      },
    ]},on:{"setVariable":_vm.setTextBody}}),_c('div',{staticClass:"dashboard-grid",staticStyle:{"background-color":"white"},style:({ '--n-elements-per-row': _vm.mobile ? 1 : 2, '--element-size': '1fr' })},[(_vm.campusDetail.language_campus.length > 0)?_c('div',[_c('LanguagePanel',{staticClass:"padding__language height-digital-profile",attrs:{"languages":_vm.getLanguages}})],1):_vm._e(),_c('div',[_c('div',{staticClass:"des-sections mt-8"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.languages.choose'))+" ")]),_c('div',{staticClass:"des-sections"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.languages.delete'))+" ")]),_vm._l((_vm.languageTypes),function(language,index){return _c('div',{key:index},[(_vm.languageTypes.length > 0)?_c('EditableGroup',{attrs:{"fields":[
            {
              type: 'checkbox',
              title: language.label,
              input: _vm.getLevel(language),
              varName: `${language.value}`,
              items: _vm.levelTypes,
              elementsRow: 1,
            },
          ]},on:{"setVariable":_vm.setBody}}):_vm._e()],1)})],2),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }