var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',[_c('div',{staticClass:"title-sections__location"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.students.title'))+" ")]),_c('div',{staticClass:"des-sections"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.digital_profile.students.info'))+" ")]),_c('EditableGroup',{staticClass:"mb-5 m-4",attrs:{"fields":[
          {
            type: 'textarea',
            title: '',
            varName: 'students_text',
            input: _vm.getStudentsText,
            dense: true,
            fieldProps: {
              label: _vm.$t('dashboard.digital_profile.students.add-description'),
            },
          },
        ]},on:{"setVariable":_vm.setTextBody}}),_c('div',{staticClass:"grid-number",style:({ '--col': _vm.columns })},_vm._l((_vm.getDataStudents),function(stage,index){return _c('div',{key:index},[_c('EditableGroup',{attrs:{"fields":[
              {
                type: 'numberField',
                varName: stage.id.toString(),
                title: _vm.getTitleStage(index),
                extraClasses: 'size-field',
                input: _vm.getDataStage(stage),
              },
            ]},on:{"setObject":_vm.setBody}})],1)}),0)],1),_c('SaveButton',{attrs:{"loading":_vm.saving},on:{"save":_vm.save}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }