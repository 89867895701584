var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"resumed-admission-state",class:{
      'resumed-admission-state--clickable': _vm.selectable,
      'resumed-admission-state--column': _vm.column,
    },on:{"click":_vm.handleClick,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleClick.apply(null, arguments)}}},[(_vm.admission.campus.thumb)?_c('URLImage',{staticStyle:{"min-width":"50px"},style:({
        margin: _vm.column ? '16px auto 24px auto' : '0 16px 0 0',
        width: _vm.column ? '40%' : 'fit-content',
      }),attrs:{"source":_vm.admission.campus.thumb,"rounded":"","medium":_vm.column,"small":!_vm.column}}):_vm._e(),_c('div',{staticClass:"d-flex fill-width",style:({
        'flex-direction': _vm.column ? 'column' : 'row',
        'justify-content': _vm.column ? 'center' : 'flex-start',
        'align-items': _vm.column ? 'center' : 'flex-start',
        margin: _vm.column ? '0 auto' : '4px 12px 0 0 ',
      })},[_c('div',{staticClass:"d-flex flex-column justify-center",class:{
          'align-center': _vm.column,
          'fill-width': _vm.column,
          'flex-grow-1': !_vm.column,
          'mb-4': _vm.column,
        }},[(_vm.admission.campus.name)?_c('PrimaryText',{staticClass:"mb-2",attrs:{"text":_vm.admission.campus.name,"poppins":_vm.column,"bold":"","big":_vm.column,"align-left":!_vm.column,"width-auto":""}}):_vm._e(),(!_vm.hideAddress)?_c('div',{staticClass:"d-flex flex-row"},[(!_vm.hideAddressIcon)?_c('MdiIcon',{staticStyle:{"margin-right":"4px"},attrs:{"color":"#CCCCCC","icon":"mdi-map-marker-outline","small":""}}):_vm._e(),(_vm.admission.campus.address)?_c('PrimaryText',{attrs:{"text":_vm.admission.campus.address,"align-left":"","neutral500":""}}):_vm._e()],1):_vm._e(),(_vm.admission.status && !_vm.hideStatus)?_c('AdmissionStatusChip',{style:({ 'margin-top': _vm.column ? '16px' : '0' }),attrs:{"admission-status":_vm.admission.status,"fit-content":""}}):_vm._e()],1),(!_vm.hideStudent)?_c('StudentChip',{staticStyle:{"margin":"16px auto"},attrs:{"student-name":_vm.admission.applicant.name,"elevated":""}}):_vm._e(),(!_vm.hideProgram && _vm.program)?_c('InfoBox',{attrs:{"text":_vm.program.level,"sub-text":_vm.program.shift,"gray-background":"","align-left":"","fill-width":"","no-margin":"","info-padding-small":"","thin-line":""}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }