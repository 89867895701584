<template>
  <section
    class="p-2 flex risk-widget--content clickable"
    @click="showFeedback()"
    @keydown.enter="showFeedback()"
    @keydown="showFeedback()"
  >
    <img
      :src="$i18n.locale === 'en' ? require('@/assets/dashboard/widgets/feedback-en-widget.svg')
        : require('@/assets/dashboard/widgets/feedback-es-widget.svg')"
      alt="check"
    />
    <div class="risk-widget">
      <h3
        v-t="show ? 'dashboard.digital_profile.feedback.title-available'
          : 'dashboard.digital_profile.feedback.title-not-available'"
        class="risk-widget--title"
      />
      <p
        v-t="'dashboard.digital_profile.feedback.description'"
        class="risk-widget--subtitle"
      />
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'FeedbackSection',
  data() {
    return {
      // TODO: Change it when we have the logic (dates) to show the feedback
      show: false,
    };
  },
  methods: {
    ...mapActions({
      getFeedback: 'feedback/getFeedback',
      activateModal: 'utils/activateModal',
    }),
    showFeedback() {
      this.getFeedback();
      this.activateModal({ name: 'Feedback' });
    },
  },
};
</script>
