<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'selectionButton']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
        :subtitle-text="'user_register.armed_conflict.subtitle'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :selection-button-info="selectionButtonInfo"
        @setSelectionButtonValue="setButtonsValue"
      />
      <section v-if="armedConflictTiSet" style="margin-top: 30px">
        <ModalContent
          :content-sections="['subtitle', 'autoComplete']"
          :subtitle-text="'user_register.armed_conflict.category'"
          :auto-complete-placeholder="'user_register.armed_conflict.placeholder'"
          :auto-complete-items="armedConflictLabels"
          :autocomplete-no-info-error="noInfoError && !category"
          :autocomplete-initial-value="defaultCategory"
          @setAutoComplete="setGroup"
        />
      </section>
    </div>
    <CallToActions
      style="margin-top: 40px"
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      :loader="armedLoader"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'ArmedConflict',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    inputValues: {
      type: Object,
      default() {
        return {};
      },
    },
    preFill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectionButtonInfo: [
        { label: 'user_register.priority_question.yes', isActive: false },
        { label: 'user_register.priority_question.no', isActive: false },
      ],
      noInfoError: false,
      category: null,
      armedConflictTiSet: null,
      armedConflictLabels: [],
      armedLoader: false,
      defaultCategory: '',
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      armedConflictTypes: 'options/armedConflictTypes',
    }),
    isActive() {
      if (this.category || this.armedConflictTiSet === false) {
        return true;
      }
      return false;
    },
  },
  watch: {
    inputValues: {
      handler(newVal) {
        if (this.preFill) {
          if (
            newVal
            && Object.keys(newVal).includes('has_conflict')
            && newVal.has_conflict !== null
          ) {
            if (newVal.has_conflict) {
              this.setButtonsValue(0);
            } else {
              this.setButtonsValue(1);
            }
          }
          if (
            newVal
            && Object.keys(newVal).includes('conflict_category')
            && newVal.conflict_category !== null
          ) {
            this.defaultCategory = newVal.conflict_category;
            this.setGroup(newVal.conflict_category);
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.armedConflictTypes.forEach((type) => {
      this.armedConflictLabels.push(type.category_name);
    });
    if (this.preFill) {
      if (
        this.inputValues
        && Object.keys(this.inputValues).includes('has_conflict')
        && this.inputValues.has_conflict !== null
      ) {
        if (this.inputValues.has_conflict) {
          this.setButtonsValue(0);
        } else {
          this.setButtonsValue(1);
        }
      }
      if (
        this.inputValues
        && Object.keys(this.inputValues).includes('conflict_category')
        && this.inputValues.conflict_category !== null
      ) {
        this.defaultCategory = this.inputValues.conflict_category;
        this.setGroup(this.inputValues.conflict_category);
      }
    }
  },
  methods: {
    ...mapActions({ setPatchArmedConflict: 'newStudentRegister/setPatchArmedConflict' }),
    setGroup(value) {
      if (value) {
        this.category = value;
      }
      return this.category;
    },
    nextStep() {
      this.armedLoader = true;
      if (!this.isActive) {
        return null;
      }
      let categoryID = null;
      let hasArmdedConflict = true;
      if (this.selectionButtonInfo[1].isActive) {
        hasArmdedConflict = false;
        categoryID = null;
      } else {
        categoryID = this.armedConflictTypes.find(
          (armedConflict) => armedConflict.category_name === this.category,
        ).id;
      }
      const options = {
        opt: hasArmdedConflict,
        category: categoryID,
        uuid: this.currentStudent.uuid,
      };
      this.setPatchArmedConflict(options).then(() => {
        this.armedLoader = false;
        this.$emit('nextStep');
      });
      return null;
    },
    setButtonsValue(i) {
      if (i === 0) {
        this.armedConflictTiSet = true;
        this.selectionButtonInfo[0].isActive = true;
        this.selectionButtonInfo[1].isActive = false;
      } else {
        this.armedConflictTiSet = false;
        this.selectionButtonInfo[0].isActive = false;
        this.selectionButtonInfo[1].isActive = true;
      }
      return i;
    },
  },
};
</script>
