<template>
  <div>
    <TextAtom
      class="title-sections__location"
      :value="$t('dashboard.digital_profile.prime.title')"
      tag="label"
      font="poppins"
      color="primary-dark"
      weight="600"
      size="title"
    />
    <TextAtom
      class="des-sections__location mb-5 d-flex left-text"
      :value="$t('dashboard.digital_profile.prime.subtitle')"
      tag="label"
      font="inter"
      color="primary-dark"
      weight="400"
      size="subtitle"
    />

    <div class="bg-gray-section rounded-lg px-4 pb-8">
      <div v-if="!isHeadMaster()" class="full-width d-flex flex-row align-center justify-between">
        <TextAtom
          class="title-sections__location body-1 font-weight"
          :value="$t('dashboard.digital_profile.prime.title_expand')"
          tag="label"
          font="inter"
          color="primary-dark"
          weight="400"
          size="subtitle"
        />
        <v-switch
          v-model="prime"
          small
          color="#461FD2"
          inset
        />
      </div>
      <v-expand-transition>
        <section v-show="prime">
          <div v-if="!isHeadMaster()" class="pr-6 mt-2">
            <label>
              <input
                :id="idPrime"
                v-model="calendly"
                :placeholder="$t('dashboard.digital_profile.prime.calendly_placeholder')"
                class="ml-3 pl-3 des-sections__input_full"
                type="text"
                step="0"
              />
            </label>
          </div>
          <BankInfo />
          <div class="pr-6 mt-8">
            <label>
              <input
                v-model="brandfolder"
                :placeholder="$t('dashboard.digital_profile.prime.brandfolder_placeholder')"
                class="ml-3 pl-3 des-sections__input_full"
                type="text"
                step="0"
              />
            </label>
          </div>
          <div class="title-sections__waiting mt-8">
            <TextAtom
              class="title-sections__waiting--list"
              :value="$t('dashboard.digital_profile.prime.waiting_list.title')"
              tag="label"
              font="poppins"
              color="primary-dark"
              weight="500"
              size="title"
            />
            <TextAtom
              class="title-sections__waiting--list"
              :value="$t('dashboard.digital_profile.prime.waiting_list.subtitle')"
              tag="label"
              font="inter"
              color="neutral-700"
              weight="400"
              size="normal"
            />
            <EditableGroup
              :fields="[
                {
                  type: 'radio',
                  title: '',
                  input: getWaitingListType,
                  varName: 'waiting_list_type',
                  items: waitingListTypes,
                  dense: true,
                },
              ]"
              @setVariable="setWaitingList"
            />
          </div>
        </section>
      </v-expand-transition>
    </div>

    <SaveButton :loading="loading" @save="submitPrime" />
  </div>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import { mapActions, mapGetters } from 'vuex';
import BankInfo from '@/components/organisms/dashboard/BankInfo.vue';
import EditableGroup from '../../utils/EditableGroup.vue';
import SaveButton from '../../utils/SaveButton.vue';

export default {
  name: 'TetherPrime',
  components: {
    SaveButton,
    TextAtom,
    EditableGroup,
    BankInfo,
  },
  data() {
    return {
      loading: false,
      prime: false,
      calendly: '',
      brandfolder: '',
      idPrime: 0,
      waitingListType: 'PUBLIC',
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      currentRole: 'roles/currentRole',
    }),
    waitingListTypes() {
      const waitingList = [
        {
          label: this.$t('dashboard.digital_profile.prime.waiting_list.public.title'),
          subtitle: this.$t('dashboard.digital_profile.prime.waiting_list.public.subtitle'),
          value: 'PUBLIC',
        },
        {
          label: this.$t('dashboard.digital_profile.prime.waiting_list.semi_public.title'),
          subtitle: this.$t('dashboard.digital_profile.prime.waiting_list.semi_public.subtitle'),
          value: 'SEMI_PUBLIC',
        },
        {
          label: this.$t('dashboard.digital_profile.prime.waiting_list.private.title'),
          subtitle: this.$t('dashboard.digital_profile.prime.waiting_list.private.subtitle'),
          value: 'PRIVATE',
        }];
      return waitingList;
    },
    getWaitingListType() {
      return this.campusDetail?.config_campus[0]?.waiting_list_type ?? 'PUBLIC';
    },
  },
  mounted() {
    this.prime = this.campusDetail?.config_campus[0]?.campus_tether_pack;
    this.calendly = this.campusDetail?.config_campus[0]?.campus_calendly;
    this.brandfolder = this.campusDetail?.config_campus[0]?.campus_brandfolder;
    this.idPrime = this.campusDetail?.config_campus[0]?.id;
  },
  methods: {
    ...mapActions({
      campusConfigPatch: 'institutions/campusConfigPatch',
    }),
    setWaitingList(body) {
      this.waitingListType = body.waiting_list_type;
    },
    isHeadMaster() {
      return this.currentRole?.roleInfo?.id === 3;
    },
    async submitPrime() {
      const { uuid } = this.campusDetail;
      if (!this.prime) {
        this.calendly = '';
        this.brandfolder = '';
      }
      const payload = {
        campus_tether_pack: this.prime,
        campus_calendly: this.calendly,
        campus_brandfolder: this.brandfolder,
        waiting_list_type: this.waitingListType,
      };
      this.loading = true;

      await this.campusConfigPatch({ campusUuid: uuid, campusData: payload });
      this.loading = false;
    },
  },
};
</script>
